import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/video_presentations`, {
  item: { include: "image,video" },
});

export default service;
export const getVideoPresentations = service.list.bind(service);
export const getVideoPresentationById = service.item.bind(service);
export const createVideoPresentation = service.create.bind(service);
export const updateVideoPresentation = service.update.bind(service);
export const deleteVideoPresentation = service.delete.bind(service);
