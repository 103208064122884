import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import TiptapOrderedList from "@tiptap/extension-ordered-list";
import CommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CommandButton.vue";
import ListItem from "./list-item";

const OrderedList = TiptapOrderedList.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      button({ editor, t, isCodeViewMode }: CommandButtonArgs) {
        return {
          component: CommandButton,
          componentProps: {
            command: () => {
              editor.commands.toggleOrderedList();
            },
            isActive: editor.isActive("orderedList"),
            icon: "list-ol",
            tooltip: t("editor.extensions.OrderedList.tooltip"),
            isCodeViewMode,
          },
        };
      },
    };
  },

  addExtensions() {
    return [ListItem];
  },
});

export default OrderedList;
