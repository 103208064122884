import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/categories`, {
  list: { include: "type", sort: "name" },
  item: { include: "type" },
});

export default service;
export const getCategories = service.list.bind(service);
export const getCategoryById = service.item.bind(service);
export const createCategory = service.create.bind(service);
export const updateCategory = service.update.bind(service);
export const deleteCategory = service.delete.bind(service);
