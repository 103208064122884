import { FormItemObjDeclaration } from "@/plugins/form-generator-json-v2/types";
import { asyncSelect } from "@/components/form-declaration/default-elements";
import {
  createProduct,
  getProducts,
} from "@/api/points/landings-cms-api/products";
import {
  createProductCategory,
  getProductCategories,
} from "@/api/points/landings-cms-api/categories";
import { createBrand, getBrands } from "@/api/points/landings-cms-api/brands";
import {
  createCertificate,
  getCertificates,
} from "@/api/points/landings-cms-api/certificates";
import {
  createBusinessAdvantage,
  getBusinessAdvantages,
} from "@/api/points/landings-cms-api/business-advantages";
import {
  createFeedback,
  getFeedbacks,
} from "@/api/points/landings-cms-api/feedbacks";
import {
  createOurClient,
  getOurClients,
} from "@/api/points/landings-cms-api/our-clients";
import {
  createCooperationOption,
  getCooperationOptions,
} from "@/api/points/landings-cms-api/cooperation-options";
import {
  createReadyMadeSolution,
  getReadyMadeSolutions,
} from "@/api/points/landings-cms-api/ready-made-solutions";
import {
  createReadyMadeSolutionStage,
  getReadyMadeSolutionStages,
} from "@/api/points/landings-cms-api/ready-made-solution-stages";
import {
  createDirection,
  getDirections,
} from "@/api/points/landings-cms-api/directions";
import {
  createAnalyticCard,
  getAnalyticCards,
} from "@/api/points/landings-cms-api/analytic-cards";
import {
  createCorporateSolution,
  getCorporateSolutions,
} from "@/api/points/landings-cms-api/corporate-solutions";
import {
  createClientDirection,
  getClientDirections,
} from "@/api/points/landings-cms-api/client-directions";
import {
  createCooperationCard,
  getCooperationCards,
} from "@/api/points/landings-cms-api/cooperation-cards";
import {
  createPackageOption,
  getPackageOptions,
} from "@/api/points/landings-cms-api/package-options";
import {
  createFranchiseeIncome,
  getFranchiseeIncomes,
} from "@/api/points/landings-cms-api/franchisee-incomes";
import {
  createBusinessFormat,
  getBusinessFormats,
} from "@/api/points/landings-cms-api/business-formats";
import {
  createMenuItem,
  getMenuItems,
} from "@/api/points/landings-cms-api/menu-items";
import {
  createVideoPresentation,
  getVideoPresentations,
} from "@/api/points/landings-cms-api/video-presentations";
import {
  createEmployee,
  getEmployees,
} from "@/api/points/landings-cms-api/employees";
import {
  createHREmployeeAdvantage,
  getHREmployeeAdvantages,
} from "@/api/points/landings-cms-api/hr-employee-advantages";
import {
  createHRCompanyValue,
  getHRCompanyValues,
} from "@/api/points/landings-cms-api/hr-company-values";
import {
  createHRWorkerCareer,
  getHRWorkerCareers,
} from "@/api/points/landings-cms-api/hr-worker-careers";
import {
  createHROfficeCareer,
  getHROfficeCareers,
} from "@/api/points/landings-cms-api/hr-office-careers";
import {
  createHRUniqueVacancy,
  getHRUniqueVacancies,
} from "@/api/points/landings-cms-api/hr-unique-vacancies";
import {
  createButton,
  getButtons,
} from "@/api/points/landings-cms-api/buttons";
import { SELECT } from "@/dictionary/service-message";

export const product_ids_select = (
  model = "product_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Продукты",
    include: "products",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const products = await getProducts({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return products.data.map((product: any) => ({
        value: product,
        label: `id: ${product.id} - ${product.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить продукт",
      form: async () => {
        const { Products } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-products"
        );
        return Products;
      },
      method: createProduct,
    },
  });

export const product_category_ids_select = (
  model = "product_category_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Категория",
    include: "productCategories",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const categories = await getProductCategories({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return categories.data.map((category: any) => ({
        value: category,
        label: `id: ${category.id} - ${category.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить категорию",
      form: async () => {
        const { Categories } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-categories"
        );
        return Categories;
      },
      method: createProductCategory,
    },
  });

export const brand_ids_select = (model = "brand_ids"): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Бренды",
    include: "brands",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const brands = await getBrands({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return brands.data.map((brand: any) => ({
        value: brand,
        label: `id: ${brand.id} - ${brand.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить бренд",
      form: async () => {
        const { Brands } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-brands"
        );
        return Brands;
      },
      method: createBrand,
    },
  });

export const popup_brand_ids_select = (
  model = "popup_brand_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Бренды для всплывающего окна",
    include: "popupBrands",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const brands = await getBrands({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return brands.data.map((brand: any) => ({
        value: brand,
        label: `id: ${brand.id} - ${brand.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить бренд",
      form: async () => {
        const { Brands } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-brands"
        );
        return Brands;
      },
      method: createBrand,
    },
  });

export const certificates_ids_select = (
  model = "certificate_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Сертификаты",
    include: "certificates",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const certificates = await getCertificates({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return certificates.data.map((certificate: any) => ({
        value: certificate,
        label: `id: ${certificate.id} - ${certificate.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить сертификат",
      form: async () => {
        const { Certificates } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-certificates"
        );
        return Certificates;
      },
      method: createCertificate,
    },
  });

export const business_advantage_ids_select = (
  model = "business_advantage_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Преимущества",
    include: "businessAdvantages",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const businessAdvantages = await getBusinessAdvantages({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return businessAdvantages.data.map((businessAdvantage: any) => ({
        value: businessAdvantage,
        label: `id: ${businessAdvantage.id} - ${businessAdvantage.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить преимущество",
      form: async () => {
        const { BusinessAdvantages } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-business-advantages"
        );
        return BusinessAdvantages;
      },
      method: createBusinessAdvantage,
    },
  });

export const feedback_ids_select = (
  model = "feedback_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Отзывы",
    include: "feedbacks",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const feedbacks = await getFeedbacks({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return feedbacks.data.map((feedback: any) => ({
        value: feedback,
        label: `id: ${feedback.id} - ${feedback.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить отзыв",
      form: async () => {
        const { Feedbacks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-feedbacks"
        );
        return Feedbacks;
      },
      method: createFeedback,
    },
  });

export const our_client_ids_select = (
  model = "our_client_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Наши клиенты",
    include: "ourClients",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const ourClients = await getOurClients({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return ourClients.data.map((ourClient: any) => ({
        value: ourClient,
        label: `id: ${ourClient.id} - ${ourClient.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить клиента",
      form: async () => {
        const { OurClients } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-our-clients"
        );
        return OurClients;
      },
      method: createOurClient,
    },
  });

export const cooperation_option_ids_select = (
  model = "cooperation_option_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Варианты сотрудничества",
    include: "cooperationOptions",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const cooperationOptions = await getCooperationOptions({
        "page[size]": 100,
        // "filter[is_active]": 1,
        ...parameters,
      });
      return cooperationOptions.data.map((cooperationOption: any) => ({
        value: cooperationOption,
        label: `id: ${cooperationOption.id} - ${cooperationOption.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Создать вариант",
      form: async () => {
        const { CooperationOptions } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-cooperation-options"
        );
        return CooperationOptions;
      },
      method: createCooperationOption,
    },
  });

export const ready_made_solution_ids_select = (
  model = "ready_made_solution_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Готовые решения",
    include: "readyMadeSolutions",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const readyMadeSolutions = await getReadyMadeSolutions({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return readyMadeSolutions.data.map((readyMadeSolution: any) => ({
        value: readyMadeSolution,
        label: `id: ${readyMadeSolution.id} - ${readyMadeSolution.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить решение",
      form: async () => {
        const { ReadyMadeSolutions } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-ready-made-solutions"
        );
        return ReadyMadeSolutions;
      },
      method: createReadyMadeSolution,
    },
  });

export const ready_made_solution_stage_ids_select = (
  model = "ready_made_solution_stage_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Этапы развития бизнеса",
    include: "readyMadeSolutionStages",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const readyMadeSolutionStages = await getReadyMadeSolutionStages({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return readyMadeSolutionStages.data.map(
        (readyMadeSolutionStage: any) => ({
          value: readyMadeSolutionStage,
          label: `id: ${readyMadeSolutionStage.id} - ${readyMadeSolutionStage.attributes.title}`,
        })
      );
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить этап",
      form: async () => {
        const { ReadyMadeSolutionStages } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-ready-made-solution-stages"
        );
        return ReadyMadeSolutionStages;
      },
      method: createReadyMadeSolutionStage,
    },
  });

export const direction_ids_select = (
  model = "direction_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Направления бизнеса",
    include: "directions",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const directions = await getDirections({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return directions.data.map((direction: any) => ({
        value: direction,
        label: `id: ${direction.id} - ${direction.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить направление",
      form: async () => {
        const { Directions } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-directions"
        );
        return Directions;
      },
      method: createDirection,
    },
  });

export const analytic_card_ids_select = (
  model = "analytic_card_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Инфографика",
    include: "analyticCards",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const analyticCards = await getAnalyticCards({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return analyticCards.data.map((analyticCard: any) => ({
        value: analyticCard,
        label: `id: ${analyticCard.id} - ${analyticCard.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить инфографику",
      form: async () => {
        const { AnalyticCards } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-analytic-cards"
        );
        return AnalyticCards;
      },
      method: createAnalyticCard,
    },
  });

export const corporate_solution_ids_select = (
  model = "corporate_solution_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Услуги B2G",
    include: "corporateSolutions",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const corporateSolutions = await getCorporateSolutions({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return corporateSolutions.data.map((corporateSolution: any) => ({
        value: corporateSolution,
        label: `id: ${corporateSolution.id} - ${corporateSolution.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить услугу",
      form: async () => {
        const { CorporateSolutions } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-corporate-solutions"
        );
        return CorporateSolutions;
      },
      method: createCorporateSolution,
    },
  });

export const client_direction_ids_select = (
  model = "client_direction_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Клиенты - направления",
    include: "clientDirections",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const clientDirections = await getClientDirections({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return clientDirections.data.map((clientDirection: any) => ({
        value: clientDirection,
        label: `id: ${clientDirection.id} - ${clientDirection.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Создать направление",
      form: async () => {
        const { CorporateSolutions } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-corporate-solutions"
        );
        return CorporateSolutions;
      },
      method: createClientDirection,
    },
  });

export const cooperation_card_ids_select = (
  model = "cooperation_card_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Примеры сотрудничества",
    include: "cooperationCards",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const cooperationCards = await getCooperationCards({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return cooperationCards.data.map((cooperationCard: any) => ({
        value: cooperationCard,
        label: `id: ${cooperationCard.id} - ${cooperationCard.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить пример",
      form: async () => {
        const { CooperationCards } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-cooperation-cards"
        );
        return CooperationCards;
      },
      method: createCooperationCard,
    },
  });

export const package_option_ids_select = (
  model = "package_option_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Пакетные опции",
    include: "packageOptions",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const packageOptions = await getPackageOptions({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return packageOptions.data.map((packageOption: any) => ({
        value: packageOption,
        label: `id: ${packageOption.id} - ${packageOption.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить опцию",
      form: async () => {
        const { PackageOptions } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-package-options"
        );
        return PackageOptions;
      },
      method: createPackageOption,
    },
  });

export const franchisee_income_ids_select = (
  model = "franchisee_income_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Доход франчайзи",
    include: "franchiseeIncomes",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const packageOptions = await getFranchiseeIncomes({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return packageOptions.data.map((packageOption: any) => ({
        value: packageOption,
        label: `id: ${packageOption.id} - ${packageOption.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить доход",
      form: async () => {
        const { FranchiseeIncomes } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-franchisee-incomes"
        );
        return FranchiseeIncomes;
      },
      method: createFranchiseeIncome,
    },
  });

export const business_format_ids_select = (
  model = "business_format_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Форматы бизнеса",
    include: "businessFormats",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const businessFormats = await getBusinessFormats({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return businessFormats.data.map((businessFormat: any) => ({
        value: businessFormat,
        label: `id: ${businessFormat.id} - ${businessFormat.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить формат",
      form: async () => {
        const { BusinessFormats } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-business-formats"
        );
        return BusinessFormats;
      },
      method: createBusinessFormat,
    },
  });

export const menu_item_ids_select = (
  model = "menu_item_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Пункты меню",
    include: "menuItems",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const menuItems = await getMenuItems({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return menuItems.data.map((menuItem: any) => ({
        value: menuItem,
        label: `id: ${menuItem.id} - ${menuItem.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Создать пункт",
      form: async () => {
        const { MenuItems } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-menu-items"
        );
        return MenuItems;
      },
      method: createMenuItem,
    },
  });

export const video_presentation_ids_select = (
  model = "video_presentation_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Видеопрезентации",
    include: "videoPresentations",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const videoPresentations = await getVideoPresentations({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return videoPresentations.data.map((videoPresentation: any) => ({
        value: videoPresentation,
        label: `id: ${videoPresentation.id} - ${videoPresentation.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить видеопрезентацию",
      form: async () => {
        const { VideoPresentations } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-video-presentations"
        );
        return VideoPresentations;
      },
      method: createVideoPresentation,
    },
  });

export const employee_ids_select = (
  model = "employee_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Сотрудники",
    include: "employees",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const employees = await getEmployees({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return employees.data.map((employee: any) => ({
        value: employee,
        label: `id: ${employee.id} - ${employee.attributes.employee_full_name}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить сотрудника",
      form: async () => {
        const { Employees } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-employees"
        );
        return Employees;
      },
      method: createEmployee,
    },
  });

export const hr_employee_advantage_ids_select = (
  model = "hr_employee_advantage_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Преимущества для сотрудников",
    include: "hrEmployeeAdvantages",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const hrEmployeeAdvantages = await getHREmployeeAdvantages({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return hrEmployeeAdvantages.data.map((hrEmployeeAdvantage: any) => ({
        value: hrEmployeeAdvantage,
        label: `id: ${hrEmployeeAdvantage.id} - ${hrEmployeeAdvantage.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить преимущество",
      form: async () => {
        const { HREmployeeAdvantages } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-hr-employee-advantages"
        );
        return HREmployeeAdvantages;
      },
      method: createHREmployeeAdvantage,
    },
  });

export const hr_company_value_ids_select = (
  model = "hr_company_value_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Ценности компании",
    include: "hrCompanyValues",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const hrCompanyValues = await getHRCompanyValues({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return hrCompanyValues.data.map((hrCompanyValue: any) => ({
        value: hrCompanyValue,
        label: `id: ${hrCompanyValue.id} - ${hrCompanyValue.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Создать ценность",
      form: async () => {
        const { HRCompanyValues } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-hr-company-values"
        );
        return HRCompanyValues;
      },
      method: createHRCompanyValue,
    },
  });

export const hr_worker_career_ids_select = (
  model = "hr_worker_career_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Рабочие профессии",
    include: "hrWorkerCareers",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const hrWorkerCareers = await getHRWorkerCareers({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return hrWorkerCareers.data.map((hrWorkerCareer: any) => ({
        value: hrWorkerCareer,
        label: `id: ${hrWorkerCareer.id} - ${hrWorkerCareer.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить профессию",
      form: async () => {
        const { HRWorkerCareers } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-hr-worker-careers"
        );
        return HRWorkerCareers;
      },
      method: createHRWorkerCareer,
    },
  });

export const hr_office_career_ids_select = (
  model = "hr_office_career_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Офисные профессии",
    include: "hrOfficeCareers",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const hrOfficeCareers = await getHROfficeCareers({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return hrOfficeCareers.data.map((hrOfficeCareer: any) => ({
        value: hrOfficeCareer,
        label: `id: ${hrOfficeCareer.id} - ${hrOfficeCareer.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить профессию",
      form: async () => {
        const { HROfficeCareers } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-hr-office-careers"
        );
        return HROfficeCareers;
      },
      method: createHROfficeCareer,
    },
  });

export const hr_unique_vacancy_ids_select = (
  model = "hr_unique_vacancy_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Уникальные вакансии",
    include: "hrUniqueVacancies",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const hrOfficeCareers = await getHRUniqueVacancies({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return hrOfficeCareers.data.map((hrOfficeCareer: any) => ({
        value: hrOfficeCareer,
        label: `id: ${hrOfficeCareer.id} - ${hrOfficeCareer.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить вакансию",
      form: async () => {
        const { HRUniqueVacancies } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-hr-unique-vacancies"
        );
        return HRUniqueVacancies;
      },
      method: createHRUniqueVacancy,
    },
  });

export const button_ids_select = (
  model = "button_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Кнопки",
    include: "buttons",
    filterable: false,
    multiple: true,
    clearable: true,
    presetValue: [],
    note: SELECT.note,
    optionList: async (parameters) => {
      const buttons = await getButtons({
        "page[size]": 100,
        "filter[is_active]": 1,
        ...parameters,
      });
      return buttons.data.map((button: any) => ({
        value: button,
        label: `id: ${button.id} - ${button.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить кнопку",
      form: async () => {
        const { Buttons } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-buttons"
        );
        return Buttons;
      },
      method: createButton,
    },
  });
