<template>
  <div ref="scrollRef" :class="$style['scroll-control-container']">
    <div :class="[$style['scroll-control-wrapper'], $style['left']]">
      <transition
        :enter-from-class="$style['slide-fade-enter-from-left']"
        :leave-to-class="$style['slide-fade-leave-to-left']"
        name="slide-fade"
      >
        <div
          v-if="showLeftScroll"
          :class="$style['scroll-control']"
          @mouseleave="onScroll($event, 'left', true)"
          @mouseover="onScroll($event, 'left', false)"
        >
          <el-icon size="50px">
            <arrow-left />
          </el-icon>
        </div>
      </transition>
    </div>
    <div :class="[$style['scroll-control-wrapper'], $style['right']]">
      <transition
        :enter-from-class="$style['slide-fade-enter-from-right']"
        :leave-to-class="$style['slide-fade-leave-to-right']"
        name="slide-fade"
      >
        <div
          v-if="showRightScroll"
          :class="$style['scroll-control']"
          @mouseleave="onScroll($event, 'right', true)"
          @mouseover="onScroll($event, 'right', false)"
        >
          <el-icon size="50px">
            <arrow-right />
          </el-icon>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref, toRefs, useCssModule, watch } from "vue";
import { ArrowLeft, ArrowRight } from "@element-plus/icons-vue";
import { DataList } from "@/components/types";

interface Props {
  dataList: DataList[];
}

const props = defineProps<Props>();
const { dataList } = toRefs(props);

const $style = useCssModule();

const showLeftScroll = ref(false);
const showRightScroll = ref(false);
const MIN_TABLE_WIDTH = 1308;
const SCROLL_STEP = 10;
const SCROLL_STEP_KEYBOARD = 50;
const SIDEBAR_WIDTH = 300;
const PADDING = 24;
let autoscroll = 0;

const scrollRef = ref<HTMLElement>();
let wrapper: Element;

const scroll = (table: Element | null, direction: string, step: number) => {
  if (!table) {
    return;
  }
  table.scrollLeft = table.scrollLeft + (direction === "left" ? -step : step);
  setControlsVisibility(table);
};

const setControlsVisibility = (table: Element) => {
  showLeftScroll.value = table.scrollLeft !== 0;
  showRightScroll.value =
    Math.round(table.scrollLeft) <
    Math.round(table.scrollWidth - table.clientWidth);
};

const onScroll = (evt: any, direction: string, isStop: boolean) => {
  if (isStop) {
    clearInterval(autoscroll);
    autoscroll = 0;
    return;
  }

  autoscroll =
    autoscroll ||
    setInterval(() => {
      scroll(wrapper, direction, SCROLL_STEP);

      if (!showLeftScroll.value || !showRightScroll.value) {
        clearInterval(autoscroll);
        autoscroll = 0;
      }
    }, 20);
};

const keyDownHandler = (ev: any) => {
  switch (ev.code) {
    case "ArrowRight":
      scroll(wrapper, "right", SCROLL_STEP_KEYBOARD);
      break;
    case "ArrowLeft":
      scroll(wrapper, "left", SCROLL_STEP_KEYBOARD);
      break;
    default:
      return;
  }
};

const scrollDetect = (evt: any) => {
  setControlsVisibility(evt.target);
};

const updateScrollControls = () => {
  setTimeout(() => {
    const tableWidth = document.querySelector(
      ".el-scrollbar__wrap .el-table__body"
    )?.clientWidth;
    const scroll = document.querySelector(
      `.${$style["scroll-control-container"]}` || ".scroll-control-container"
    ) as HTMLElement;
    if (scroll && scroll.parentElement) {
      scroll.style.height = scroll.parentElement.clientHeight + "px";
    }
    showRightScroll.value = !!(
      tableWidth &&
      (tableWidth < MIN_TABLE_WIDTH
        ? tableWidth > window.innerWidth - SIDEBAR_WIDTH - PADDING * 5
        : true)
    );
    wrapper = scrollRef.value?.parentElement.querySelector(
      ".el-scrollbar__wrap"
    );
    if (!wrapper) {
      return;
    }

    wrapper.classList.add("custom-scroll");
    wrapper.addEventListener("scroll", scrollDetect);
  }, 100);
};

onMounted(() => {
  window.addEventListener("keydown", keyDownHandler);
  window.addEventListener("user-options-changed", updateScrollControls);
});

onUnmounted(() => {
  window.removeEventListener("keydown", keyDownHandler);
  window.removeEventListener("user-options-changed", updateScrollControls);
  document
    .querySelector(".el-scrollbar__wrap")
    ?.removeEventListener("scroll", scrollDetect);
});

watch(
  () => dataList.value,
  (value) => {
    if (value) {
      updateScrollControls();
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" module>
.scroll-control-container {
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;

  .scroll-control-wrapper {
    opacity: 0.2;
    position: sticky;
    top: calc(50vh - 70px);
    z-index: 1000;

    &.right {
      float: right;

      & .scroll-control {
        border-radius: 100px 0 0 100px;
        right: 0;

        & :global .el-icon {
          right: 0;
        }
      }
    }

    &.left {
      float: left;

      & .scroll-control {
        border-radius: 0 100px 100px 0;
      }
    }

    .scroll-control {
      background-color: var(--el-color-warning-dark-2);
      cursor: pointer;
      height: 140px;
      width: 70px;

      &:hover {
        background-color: var(--el-color-warning-light-3);
      }

      :global .el-icon {
        color: var(--el-color-white);
        height: inherit;
        position: absolute;
        width: 50px;
      }
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s;
}

.slide-fade-enter-from-left {
  transform: translateX(20px);
  opacity: 0;
}

.slide-fade-leave-to-left {
  opacity: 0;
}

.slide-fade-enter-from-right {
  transform: translateX(-20px);
  opacity: 0;
}

.slide-fade-leave-to-right {
  opacity: 0;
}
</style>
