import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/service_zones`, {
  item: {
    include: "servicePoints",
  },
});

export default service;
export const getServiceZones = service.list.bind(service);
export const getServiceZoneById = service.item.bind(service);
export const createServiceZone = service.create.bind(service);
export const updateServiceZone = service.update.bind(service);
export const deleteServiceZone = service.delete.bind(service);
