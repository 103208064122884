import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import TiptapStrike, { StrikeOptions } from "@tiptap/extension-strike";
import CommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CommandButton.vue";

const Strike = TiptapStrike.extend<StrikeOptions & { bubble?: boolean }>({
  addOptions() {
    return {
      ...this.parent?.(),
      button({ editor, t, isCodeViewMode }: CommandButtonArgs) {
        return {
          component: CommandButton,
          componentProps: {
            command: () => {
              editor.commands.toggleStrike();
            },
            isActive: editor.isActive("strike"),
            icon: "strikethrough",
            tooltip: t("editor.extensions.Strike.tooltip"),
            isCodeViewMode,
          },
        };
      },
    };
  },
});

export default Strike;
