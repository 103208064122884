import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/business_advantage_blocks`, {
  item: { include: "businessAdvantages" },
});

export default service;
export const getBusinessAdvantageBlocks = service.list.bind(service);
export const getBusinessAdvantageBlockById = service.item.bind(service);
export const createBusinessAdvantageBlock = service.create.bind(service);
export const updateBusinessAdvantageBlock = service.update.bind(service);
export const deleteBusinessAdvantageBlock = service.delete.bind(service);
