import { Editor, Extension } from "@tiptap/core";
import {
  convertToPX,
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_SIZES,
} from "@/plugins/tiptap-editor/utils/font-size";
import FontSizeDropdown from "@/plugins/tiptap-editor/components/MenuCommands/FontSizeDropdown.vue";
import TextStyle from "@tiptap/extension-text-style";

export type FontSizeOptions = {
  types: string[];
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    fontSize: {
      setFontSize: (fontSize: string) => ReturnType;
      unsetFontSize: () => ReturnType;
    };
  }
}

const FontSize = Extension.create<FontSizeOptions>({
  name: "fontSize",

  addOptions() {
    return {
      types: ["textStyle"],
      fontSizes: DEFAULT_FONT_SIZES,
      button({ editor }: { editor: Editor }) {
        return {
          component: FontSizeDropdown,
          componentProps: {
            editor,
          },
        };
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          fontSize: {
            default: null,
            parseHTML: (element) => convertToPX(element.style.fontSize) || "",
            renderHTML: (attributes) => {
              if (!attributes.fontSize) {
                return {};
              }

              return {
                style: `font-size: ${attributes.fontSize}px`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setFontSize:
        (fontSize) =>
        ({ chain }) => {
          return chain().setMark("textStyle", { fontSize }).run();
        },
      unsetFontSize:
        () =>
        ({ chain }) => {
          return chain()
            .setMark("textStyle", { fontSize: DEFAULT_FONT_SIZE })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },

  addExtensions() {
    return [TextStyle];
  },
});

export default FontSize;
