import { Service } from "@/api/core/service";
import {
  CommunicationServicePointName,
  getCommunicationServicePathByEndpoint,
} from "@/api/points/communication-api/service-config";
import {
  CustomListRequestOptions,
  GetParametersArgumentsDeclaration,
  IDefaultListResponse,
} from "@/api/core/types";
import { IProviderAccountTypeResponse } from "@/api/points/communication-api/provider-types/types";

const extraOptions = {
  list: {
    include: "providerTypeProps",
  },
  item: {
    include: "providerTypeProps",
  },
};

const service = new Service(
  getCommunicationServicePathByEndpoint(
    CommunicationServicePointName.providerTypes
  ),
  extraOptions
);

export default service;

export const getCommunicationProviderTypes = service.list.bind(service) as (
  parameters: GetParametersArgumentsDeclaration,
  options?: CustomListRequestOptions
) => Promise<IDefaultListResponse<IProviderAccountTypeResponse[]>>;

export const getCommunicationProviderType = service.item.bind(service) as (
  id: number
) => Promise<IProviderAccountTypeResponse>;
