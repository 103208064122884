import { Extension } from "@tiptap/core";
import CodeViewCommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CodeViewCommandButton.vue";
import codemirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/mode/xml/xml.js";
import "codemirror/addon/selection/active-line.js";
import "codemirror/addon/edit/closetag.js";
import { TipTapExtensionId } from "@/plugins/tiptap-editor/constants";

const CodeView = Extension.create({
  name: TipTapExtensionId.codeView,

  addOptions() {
    return {
      ...this.parent?.(),
      readonly: false,
      spellcheck: false,
      styleActiveLine: true,
      autoCloseTags: true,
      codemirror,
      button() {
        return {
          component: CodeViewCommandButton,
        };
      },
    };
  },
});

export default CodeView;
