<template>
  <div>
    <command-button
      :command="openEditImageDialog"
      :enable-tooltip="enableTooltip"
      :tooltip="t('editor.extensions.Image.buttons.image_options.tooltip')"
      icon="ellipsis-h"
    />

    <el-dialog
      v-model="editImageDialogVisible"
      :title="t('editor.extensions.Image.control.edit_image.title')"
      append-to-body
      class="el-tiptap-edit-image-dialog"
      width="400px"
      @open="syncImageAttrs"
    >
      <el-form :model="imageAttrs" label-position="top" size="small">
        <el-form-item
          :label="t('editor.extensions.Image.control.edit_image.form.src')"
        >
          <el-input v-model="imageAttrs.src" autocomplete="off" disabled />
        </el-form-item>

        <el-form-item
          :label="t('editor.extensions.Image.control.edit_image.form.alt')"
        >
          <el-input v-model="imageAttrs.alt" autocomplete="off" />
        </el-form-item>

        <el-form-item>
          <el-col :span="11">
            <el-form-item
              :label="
                t('editor.extensions.Image.control.edit_image.form.width')
              "
              required
            >
              <el-input
                v-model="imageAttrs.width"
                type="number"
                @input="handleWidthInput"
              />
            </el-form-item>
          </el-col>
          <el-col :push="2" :span="11">
            <el-form-item
              :label="
                t('editor.extensions.Image.control.edit_image.form.height')
              "
              required
            >
              <el-input
                v-model="imageAttrs.height"
                type="number"
                @input="handleHeightInput"
              />
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-form>

      <template #footer>
        <el-button round size="small" @click="closeEditImageDialog">
          {{ t("editor.extensions.Image.control.edit_image.cancel") }}
        </el-button>

        <el-button
          :disabled="!imageAttrs.height || !imageAttrs.width"
          round
          size="small"
          type="primary"
          @click="updateImageAttrs"
        >
          {{ t("editor.extensions.Image.control.edit_image.confirm") }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
import { inject, ref } from "vue";
import CommandButton from "../CommandButton.vue";
import { Node as ProseMirrorNode } from "@tiptap/pm/model";

interface Props {
  node: ProseMirrorNode;
  updateAttrs: (attributes: Record<string, any>) => void;
}

const props = defineProps<Props>();

const editImageDialogVisible = ref(false);

const getImageAttrs = () => {
  return {
    src: props.node?.attrs.src,
    alt: props.node?.attrs.alt,
    width: props.node?.attrs.width,
    height: props.node?.attrs.height,
  };
};

const imageAttrs = ref(getImageAttrs());

const t: any = inject("t");
const enableTooltip = inject("enableTooltip", true);

const syncImageAttrs = () => {
  imageAttrs.value = getImageAttrs();
};

const updateImageAttrs = () => {
  let { width, height } = imageAttrs.value;

  width = parseInt(width as string, 10);
  height = parseInt(height as string, 10);

  props.updateAttrs?.({
    alt: imageAttrs.value.alt,
    width: width >= 0 ? width : null,
    height: height >= 0 ? height : null,
  });

  closeEditImageDialog();
};

const openEditImageDialog = () => {
  editImageDialogVisible.value = true;
};

const closeEditImageDialog = () => {
  editImageDialogVisible.value = false;
};

const handleWidthInput = (value: string) => {
  imageAttrs.value.height = Number(
    parseInt(value) / props.node?.attrs.aspectRatio
  ).toFixed(0);
};

const handleHeightInput = (value: string) => {
  imageAttrs.value.width = Number(
    parseInt(value) * props.node?.attrs.aspectRatio
  ).toFixed(0);
};
</script>
