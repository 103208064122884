<template>
  <div :class="$style['filter-tabs']">
    <el-button
      :type="!isFilterSelected ? 'success' : ''"
      text
      @click="tabHandler"
    >
      Все
    </el-button>
    <span v-for="(tab, index) in tabs" :key="index">
      <el-button
        v-if="tab.count"
        :type="isActiveTab(tab) ? 'success' : ''"
        text
        @click="tabHandler(tab)"
      >
        {{ tab.name }}
        ({{ tab.count }})
      </el-button>
    </span>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, toRefs, watch } from "vue";

export interface TheFilterTab {
  name: string;
  filters: { key: string; value: string }[];
}

interface Props {
  data: TheFilterTab[];
  filterData: Record<string, any>;
  service: Record<string, any>;
}

const props = defineProps<Props>();
const { data, filterData, service } = toRefs(props);

const emit = defineEmits(["filterChange", "filterClean", "filterDisable"]);

const tabs = ref();

const getData = async () => {
  const results = await Promise.all(
    tabs.value.map((tab: TheFilterTab) => service.value.list(formFilter(tab)))
  );

  results.forEach(
    (item, index) => (tabs.value[index].count = item?.meta?.total)
  );
};

onMounted(async () => {
  tabs.value = data.value;
  await getData();
});

const isActiveTab = (tab: TheFilterTab) =>
  JSON.stringify(filterData.value) === JSON.stringify(formFilter(tab));

const isFilterSelected = computed(() =>
  tabs.value?.some((tab: TheFilterTab) => isActiveTab(tab))
);

watch(
  filterData,
  () => {
    emit("filterDisable", isFilterSelected.value);
  },
  {
    deep: true,
  }
);

const formFilter = (tab: TheFilterTab) =>
  tab.filters?.reduce(
    (acc, filter) =>
      filter.key && filter.value
        ? { ...acc, [`filter[${filter.key}]`]: filter.value }
        : acc,
    {}
  );

const tabHandler = (tab?: TheFilterTab) => {
  emit("filterClean");

  if (tab) {
    emit("filterChange", formFilter(tab));
  }
};

defineExpose({
  getData,
});
</script>

<style lang="scss" module>
.filter-tabs {
  border-bottom: 2px solid var(--el-border-color-light);
  margin: 0 40px;

  @media (max-width: 640px) {
    white-space: nowrap;
    overflow-x: auto;
    padding: 0 16px;
    margin: 0;
  }

  :global {
    .el-button {
      background: none !important;
      border: none;
      padding: 0 24px;
      margin: 0;
    }
  }
}
</style>
