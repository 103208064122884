import { Plugin } from "vue";
import TiptapEditor from "@/plugins/tiptap-editor/components/TiptapEditor.vue";

const TiptapEditorPlugin: Plugin = {
  install(app) {
    app.component("el-tiptap", TiptapEditor);
  },
};

export * from "./extensions";

export { TiptapEditorPlugin, TiptapEditor };

export default TiptapEditorPlugin;
