import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/partnership_blocks`);

export default service;
export const getPartnershipBlocks = service.list.bind(service);
export const getPartnershipBlockById = service.item.bind(service);
export const createPartnershipBlock = service.create.bind(service);
export const updatePartnershipBlock = service.update.bind(service);
export const deletePartnershipBlock = service.delete.bind(service);
