<template>
  <el-row :="propsElRow">
    <template v-for="(colItem, colItemIndex) in rowItem.cols">
      <form-element
        v-if="colItem.element?.name"
        :key="colItemIndex"
        :element="colItem"
      />
      <template v-else>
        <el-col :key="`${colItemIndex}-col-form-item`" :="colItem?.attrs">
          <template
            v-for="(id, index) in convertPropertyToArray(colItem.id)"
            :key="`${index}-H`"
          >
            <component
              :is="getDynamicColComponent(id, colItem).component"
              :="getDynamicColComponent(id, colItem).attrs"
            />
          </template>
        </el-col>
      </template>
      <el-col
        v-if="allowRenderSystemFields(colItemIndex)"
        :key="`${colItemIndex}-system-field`"
      >
        <form-system-fields />
      </el-col>
    </template>
  </el-row>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, h, inject, reactive, toRefs } from "vue";
import {
  FormLayoutRowPropertyDeclaration,
  PublicFormContextDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import { convertPropertyToArray } from "@/helpers/array-tools";

const FormElement = defineAsyncComponent(
  () => import("@/plugins/form-generator-json-v2/components/FormElement.vue")
);

const FormItemWrapper = defineAsyncComponent(
  () =>
    import("@/plugins/form-generator-json-v2/components/FormItemWrapper.vue")
);
const FormSystemFields = defineAsyncComponent(
  () =>
    import("@/plugins/form-generator-json-v2/components/FormSystemFields.vue")
);

const props = defineProps<{
  rowItem: FormLayoutRowPropertyDeclaration;
  rowIndex: number;
}>();
const { rowItem, rowIndex } = toRefs(props);
const publicFormContext = inject(
  "publicFormContext"
) as PublicFormContextDeclaration;
const { declaration, layoutDeclaration, getFormItem, getFormGroupItem } =
  publicFormContext;

const propsElRow = reactive({
  ...declaration.form.layout?.attrs,
  ...layoutDeclaration?.attrs,
  ...rowItem.value.attrs,
});

const allowRenderSystemFields = (colIndex: number) =>
  layoutDeclaration?.rows &&
  layoutDeclaration?.rows?.length - 1 === rowIndex.value &&
  rowItem.value.cols &&
  rowItem.value.cols?.length - 1 === colIndex &&
  declaration.items.find((item) => item.isService);

const getDynamicColComponent = (id: string, colItem: any) => {
  if (id === "divider") {
    return {
      component: "el-divider",
      attrs: null,
    };
  }
  if (id === "text") {
    return {
      component: h("div", {}, [h(FormElement, { element: colItem })]),
      attrs: null,
    };
  }

  const formItem = colItem.group
    ? getFormGroupItem(colItem.group, id)
    : getFormItem(id);

  if (!formItem) {
    return {
      component: "span",
      attrs: {
        "data-render-error": "no declaration element",
      },
    };
  }
  return {
    component: FormItemWrapper,
    attrs: {
      element: formItem,
      colItem: colItem,
    },
  };
};
</script>
