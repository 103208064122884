import { FormItemRule } from "element-plus";

const takeRules = (
  rules: Record<string, Array<FormItemRule>> | any,
  key: string
): Record<string, any> => rules[key] || rules[`${key}_id`];

const takeValue = (
  data: Record<string, any>,
  key: string
): Record<string, any> => data[key] || data[`${key}_id`];

const hasRequiredError = (
  rules: Record<string, Array<FormItemRule>>,
  data: Record<string, any>,
  key: string
): boolean => {
  const localRules = takeRules(rules, key);
  const isRequred = localRules?.some((el: FormItemRule) => el.required);
  const value = takeValue(data, key);

  return isRequred && (!value || (Array.isArray(value) && !value.length));
};

const hasValidateError = (
  rules: Record<string, Array<FormItemRule>>,
  data: Record<string, any>,
  key: string
): boolean => {
  const localRules = takeRules(rules, key);
  const validateFunction = localRules?.find(
    (el: FormItemRule) => el.validator
  )?.validator;
  const value = takeValue(data, key);

  return validateFunction ? !validateFunction(null, value) : false;
};

const hasMaxError = (
  rules: Record<string, Array<FormItemRule>>,
  data: Record<string, any>,
  key: string
): boolean => {
  const localRules = takeRules(rules, key);
  const maxValue = localRules?.find((el: FormItemRule) => el.max)?.max;
  const value = takeValue(data, key);

  return maxValue && value?.trim().length > maxValue;
};

export const isValid = (
  rules: Record<string, Array<FormItemRule>>,
  data: Record<string, any>,
  key: string
): boolean => {
  return (
    !hasRequiredError(rules, data, key) &&
    !hasValidateError(rules, data, key) &&
    !hasMaxError(rules, data, key)
  );
};

export const getValidateMessage = (
  rules: Record<string, Array<FormItemRule>>,
  data: Record<string, any>,
  key: string
): string =>
  takeRules(rules, key)?.find((el: Record<string, any>) => {
    if (hasRequiredError(rules, data, key)) {
      return el.required;
    }
    if (hasValidateError(rules, data, key)) {
      return el.validator;
    }
    if (hasMaxError(rules, data, key)) {
      return el.max;
    }
  })?.message || "";
