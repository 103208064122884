import { user } from "@/model/user";
import type { Router } from "vue-router";
import { RouteAccess } from "@/router/types";

export default (router: Router): Record<string, boolean> => {
  const { service: accessService, object: accessObject } =
    (router.currentRoute.value?.meta?.access as RouteAccess) || {};

  const accessPoints = [
    "update",
    "show",
    "store",
    "destroy",
    "copy",
    "export",
    "import",
    "update_spec_fields",
    "archive",
    "restore",
    "index",
    "contactConfirmation",
  ];

  const generateAccessFlagName = (point: string): string =>
    `has${point.charAt(0).toUpperCase() + point.slice(1)}Access`;

  return accessPoints.reduce((flags, point): Record<string, boolean> => {
    const flagName = generateAccessFlagName(point);
    flags[flagName] = user.hasAccess(accessService, accessObject, point);
    return flags;
  }, {} as Record<string, boolean>);
};
