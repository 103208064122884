import { PUBLIC_SERVICE_POINT_NAME } from "@/api/points/files-api/service-config";
import {
  DefaultJsonFormContextWithPresets,
  FormItemObjDeclaration,
  FormLayoutPropertyDeclaration,
  ImageLoaderDeclaration,
  ImageLoaderService,
} from "@/plugins/form-generator-json-v2/types";
import { Rule } from "async-validator";
import { VALUE_TYPES } from "./pimProperties";
import { uploadDescImageAPI } from "@/components/base/upload/composables/helpers";
import { ServiceName } from "@/constants";

export interface ProductProperty {
  id: string | number;
  type: keyof typeof VALUE_TYPES;
  name: string;
  is_required: boolean;
  values: Array<string | number>;
  measurement: string;
  value: any;
  created_at: string;
  updated_at: string;
  manual_filled: boolean;
  default?: any;
  isUnfilled?: boolean;
}

export const getPropertyModel = (
  property: Record<string, any>,
  isEditMode = false
): ProductProperty => {
  const is_required = property.attributes.is_common
    ? property.attributes.is_required
    : property.relationships.classifiers[0].attributes.is_required;

  const isMultipleProp = [
    VALUE_TYPES.NUMERIC_LIST_MULTIPLE,
    VALUE_TYPES.TEXT_LIST_MULTIPLE,
  ].includes(property.attributes.type);

  let value = isEditMode
    ? property.attributes.value
    : property.attributes.value || property.attributes.default;

  if (isMultipleProp && value) {
    value = (value as string).split(",");
  }

  return {
    value,
    is_required,
    id: property.id,
    type: property.attributes.type,
    name: property.attributes.name,
    values: property.attributes.values,
    measurement: property.attributes.measurement,
    created_at: property.attributes.created_at,
    updated_at: property.attributes.updated_at,
    manual_filled: property.attributes.manual_filled === false ? false : true,
  };
};

export const FIELDS = {
  MAIN: ["brand_id", "sku", "full_name", "short_name"],
};

export const productImageForm: FormItemObjDeclaration = {
  name: "images",
  id: "images",
  model: "images",
  include: "images",
  element: "pim-images-list",
};

export const productImageService: ImageLoaderService = {
  service: ServiceName.pim,
  objectType: "products",
  submitMapDataCallback: (value: any[]) => value?.map((v) => v.id) || [],
};

export const productImageElement: ImageLoaderDeclaration = {
  buttonText: "Загрузить логотип",
  restrictions: "Логотип Бренда, jpg/png",
  limit: 100,
  maxFileSize: 52428800,
};

export const getDefaultLayout = (): FormLayoutPropertyDeclaration => ({
  rows: [
    {
      cols: [
        { id: "is_active", attrs: { span: 12 } },
        { id: "completion", attrs: { span: 12 } },
      ],
    },
    {
      tabs: [
        {
          label: "Набор характеристик",
          id: "nomenclature",
          rows: [
            {
              cols: [
                { id: "classifier_id", attrs: { span: 24 } },
                { id: "propertiesListing", attrs: { span: 24 } },
                {
                  id: ["nextTabClassifier"],
                  attrs: { span: 24, class: "el-col--flat" },
                },
              ],
            },
          ],
        },
        {
          label: "Общие",
          id: "main",
          disabled: true,
          rows: [
            {
              cols: [
                { id: "full_name", attrs: { span: 24 } },
                { id: "short_name", attrs: { span: 24 } },
                { id: "brand_id", attrs: { span: 24 } },
                { id: "code_1c", attrs: { span: 11 } },
                { id: "code_b2b", attrs: { span: 11, offset: 2 } },
                { id: "sku", attrs: { span: 11 } },
                { id: "code_b2c", attrs: { span: 11, offset: 2 } },
                {
                  id: ["nextTabMain"],
                  attrs: { span: 24, class: "el-col--flat" },
                },
              ],
            },
          ],
        },
        {
          label: "Характеристики",
          id: "properties",
          disabled: true,
          rows: [
            {
              cols: [
                { id: "properties", attrs: { span: 24 } },
                {
                  id: ["nextTabProperties"],
                  attrs: { span: 24, class: "el-col--flat" },
                },
              ],
            },
          ],
        },
        {
          label: "Описание",
          id: "description",
          disabled: true,
          rows: [
            {
              cols: [
                {
                  id: ["description", "description_images"],
                },
                {
                  id: ["nextTab"],
                  attrs: { span: 24, class: "el-col--flat" },
                },
              ],
            },
          ],
        },
        {
          label: "Медиа",
          id: "images",
          disabled: true,
          rows: [
            {
              cols: [
                {
                  id: "images",
                },
                {
                  id: ["nextTab", "defaultSubmit"],
                  attrs: { span: 24, class: "el-col--flat" },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

export const getEditLayout = (): FormLayoutPropertyDeclaration => ({
  rows: [
    {
      cols: [
        { id: "is_active", attrs: { span: 10 } },
        { id: "updateProperties", attrs: { span: 6 } },
        { id: "completion", attrs: { span: 8 } },
      ],
    },
    {
      tabs: [
        {
          label: "Набор характеристик",
          id: "nomenclature",
          rows: [
            {
              cols: [
                { id: "classifier_id", attrs: { span: 24 } },
                { id: "propertiesListing", attrs: { span: 24 } },
                {
                  id: ["defaultSubmit"],
                  attrs: { span: 12, class: "el-col--flat" },
                },
              ],
            },
          ],
        },
        {
          label: "Общие",
          id: "main",
          rows: [
            {
              cols: [
                { id: "full_name", attrs: { span: 24 } },
                { id: "short_name", attrs: { span: 24 } },
                { id: "brand_id", attrs: { span: 24 } },
                { id: "code_1c", attrs: { span: 11 } },
                { id: "code_b2b", attrs: { span: 11, offset: 2 } },
                { id: "sku", attrs: { span: 11 } },
                { id: "code_b2c", attrs: { span: 11, offset: 2 } },
                {
                  id: ["defaultSubmit"],
                  attrs: { span: 24, class: "el-col--flat" },
                },
              ],
            },
          ],
        },
        {
          label: "Характеристики",
          id: "properties",
          rows: [
            {
              cols: [
                { id: "properties", attrs: { span: 24 } },
                {
                  id: ["defaultSubmit"],
                  attrs: { span: 24, class: "el-col--flat" },
                },
              ],
            },
          ],
        },
        {
          label: "Описание",
          id: "description",
          rows: [
            {
              cols: [
                {
                  id: ["description", "description_images"],
                },
                {
                  id: ["defaultSubmit"],
                  attrs: { span: 24, class: "el-col--flat" },
                },
              ],
            },
          ],
        },
        {
          label: "Медиа",
          id: "images",
          rows: [
            {
              cols: [
                {
                  id: "images",
                },
                {
                  id: ["defaultSubmit"],
                  attrs: { span: 24, class: "el-col--flat" },
                },
              ],
            },
          ],
        },
        {
          label: "Аналоги и обновления",
          id: "crosses",
          rows: [
            {
              cols: [
                {
                  id: "crosses",
                },
                {
                  id: ["defaultSubmit"],
                  attrs: { span: 24, class: "el-col--flat" },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

export const requiredBlurRule = (): Array<Rule & { trigger?: string }> => [
  {
    required: true,
    message: "Поле обязательно для заполнения",
  },
];

export const tabValidationObserver = (
  ctx: DefaultJsonFormContextWithPresets,
  model: Record<string, any>
): void => {
  if (ctx.activeTab === "nomenclature") {
    ctx.setFormItemAttr("nextTabClassifier", "disabled", !model.classifier_id);
  }

  if (ctx.activeTab === "main") {
    const isMainFilled = FIELDS.MAIN.every((field) => !!model[field]);
    ctx.setFormItemAttr("nextTabMain", "disabled", !isMainFilled);
  }

  if (ctx.activeTab === "properties") {
    const properties = model.properties || [];
    const isRequirePropertiesFilled = properties
      .filter((prop: any) => prop.is_required)
      .every((prop: any) => {
        const isFilledValue = Array.isArray(prop.value)
          ? prop.value.length
          : prop.value;

        return isFilledValue;
      });

    ctx.setFormItemAttr(
      "nextTabProperties",
      "disabled",
      !isRequirePropertiesFilled
    );
  }
};

export const descriptionImageUploader = async (
  file: File,
  model: string[]
): Promise<Record<string, any>> => {
  const serviceName = ServiceName.pim;
  const response = await uploadDescImageAPI({
    serviceName,
    pointName: "product",
    file,
  });
  const { data } = await response.json();

  if (Array.isArray(model)) {
    model.push(data.id);
  } else {
    model = [data.id];
  }

  return {
    width: data.attributes.width,
    height: data.attributes.height,
    url: `${PUBLIC_SERVICE_POINT_NAME}/${serviceName}/${data.attributes.object_type}/stream/${data.id}`,
  };
};
