import { ref, Ref } from "vue";
import { GeneratorDeclaration } from "@/plugins/form-generator-json-v2/types";

export const useRerenderForm = (declaration: Ref<GeneratorDeclaration>) => {
  const reRenderKey = ref(0);

  declaration.value["reRender"] = () => reRenderKey.value++;

  const reRenderForm = () => reRenderKey.value++;

  return {
    reRenderKey,
    reRenderForm,
  };
};
