import { FORM } from "@/dictionary/validation";
import { FormItemRule } from "element-plus";
import {
  InternalRuleItem,
  ValidateResult,
  ValidateValue,
} from "@/components/types";

export type RulesKeys =
  | "required"
  | "year"
  | "phone"
  | "nipplePhone"
  | "number"
  | "numberThousand"
  | "thread"
  | "max_191"
  | "min_32"
  | "diameter";

const regNumberWithDot = new RegExp("^-?[0-9]*\\.?[0-9]{1,}$");
const regNumberRange = new RegExp("^\\d+\\s?-\\s?\\d+$");
const regNumberDiameter = new RegExp("^\\d+[C,c]?$");
const regNumberWithThousand = new RegExp("^-?[\\d ]*\\.?[\\d ]{1,}$");
const regNipplePhone =
  /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;

export const validation: Record<RulesKeys, FormItemRule> = {
  required: {
    required: true,
    message: FORM.input_required,
  },
  year: {
    message: FORM.input_value_year,
    validator: (
      rule: InternalRuleItem,
      value: ValidateValue
    ): ValidateResult => {
      if (!value?.toString().trim()) {
        return true;
      }
      return /^\d+$/.test(value) && +value > 1900 && +value < 2100;
    },
  },
  phone: {
    message: FORM.input_value_phone,
    validator: (
      rule: InternalRuleItem,
      value: ValidateValue
    ): ValidateResult => {
      if (!value?.toString().trim()) {
        return true;
      }
      return /^[0-9]{7,15}$/.test(value);
    },
  },
  nipplePhone: {
    message: FORM.input_value_nipple_phone,
    validator: (
      rule: InternalRuleItem,
      value: ValidateValue
    ): ValidateResult => {
      if (!value?.toString().trim()) {
        return true;
      }
      return regNipplePhone.test(value);
    },
  },
  numberThousand: {
    message: FORM.input_value_number,
    validator: (
      rule: InternalRuleItem,
      value: ValidateValue
    ): ValidateResult => {
      if (!value?.toString().trim()) {
        return true;
      }
      return regNumberWithThousand.test(value);
    },
  },
  number: {
    message: FORM.input_value_number,
    validator: (
      rule: InternalRuleItem,
      value: ValidateValue
    ): ValidateResult => {
      if (!value?.toString().trim()) {
        return true;
      }
      return regNumberWithDot.test(value);
    },
  },
  thread: {
    message: FORM.input_value_thread,
    validator: (
      rule: InternalRuleItem,
      value: ValidateValue
    ): ValidateResult => {
      if (!value?.toString().trim()) {
        return true;
      }

      const numbers = value.toLowerCase().split("x");
      return (
        numbers.length === 2 &&
        regNumberWithDot.test(numbers[0]) &&
        regNumberWithDot.test(numbers[1])
      );
    },
  },
  max_191: {
    max: 191,
    message: FORM.input_value_max_191,
  },
  min_32: {
    min: 32,
    message: FORM.input_length_min_32,
  },
  diameter: {
    message: FORM.input_value_diameter,
    validator: (
      rule: InternalRuleItem,
      value: ValidateValue
    ): ValidateResult => {
      return (
        value === "all" ||
        regNumberRange.test(value) ||
        !value
          .toString()
          .trim()
          .split(" ")
          .some((item: string) => !regNumberDiameter.test(item))
      );
    },
  },
};

function isRuleItem(item: FormItemRule | string): item is FormItemRule {
  if (typeof item !== "object") {
    return false;
  }

  const keys = [
    "type",
    "required",
    "trigger",
    "pattern",
    "min",
    "max",
    "len",
    "enum",
    "whitespace",
    "fields",
    "options",
    "defaultField",
    "transform",
    "message",
    "asyncValidator",
    "validator",
  ];

  for (const key in item) {
    if (!keys.includes(key)) {
      throw new Error(`Invalid validation rule item: ${JSON.stringify(item)}`);
    }
  }

  return true;
}

export function setValidationRules(
  map: Map<Array<FormItemRule | RulesKeys>, Array<string>>,
  additionalRules: {
    [key: string]: FormItemRule | Array<FormItemRule>;
  } = {}
): { [key: string]: Array<FormItemRule> } {
  const rules: Record<string, Array<FormItemRule>> = {};

  for (const [mapRules, fields] of map) {
    const mapRulesObjects: Array<FormItemRule> = mapRules.map((rule) => {
      if (isRuleItem(rule)) {
        return rule;
      } else if (typeof rule === "string") {
        return validation[rule];
      } else {
        throw new Error("invalid rule type");
      }
    });

    fields.forEach((field) => {
      if (!rules[field]) {
        rules[field] = [...mapRulesObjects];
      } else {
        rules[field]?.push(...mapRulesObjects);
      }

      const rulesOfField = additionalRules[field];
      if (rulesOfField) {
        if (Array.isArray(rulesOfField)) {
          rules[field]?.push(...rulesOfField);
        } else {
          rules[field]?.push(rulesOfField);
        }
        delete additionalRules[field];
      }
    });
  }

  for (const key in additionalRules) {
    const rule = additionalRules[key];

    if (Array.isArray(rule)) {
      rules[key] = rule;
    } else if (rule) {
      rules[key] = [rule];
    }
  }

  return rules;
}
