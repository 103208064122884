import { FormItemObjDeclaration } from "@/plugins/form-generator-json-v2/types";
import {
  adminSwitch,
  asyncSelect,
  DAY_MONTH_YEAR_DATE_FORMAT,
  defaultDatePicker,
  defaultSelect,
} from "@/components/form-declaration/default-elements";
import { getCommunicationTemplates } from "@/api/points/communication-api/templates";
import { MessageStatus } from "@/api/points/communication-api/messages/types";
import {
  defaultPropertiesTable,
  defaultTable,
} from "@/components/form-declaration/default-elements/tables";
import { SortOrderType } from "@/composables/hooks/useSort";
import { PropertiesTableRenderSettings } from "@/components/base/table/types";

export const enum MessageTabName {
  properties = "properties",
  statusLogs = "status_logs",
}

export const enum MessageDataField {
  id = "id",
  body = "body",
  comment = "comment",
  subject = "subject",
}

export const enum MessageStatusLogsField {
  id = "id",
  status = "status",
  statusText = "status_text",
  createdAt = "created_at",
}

export const enum MessageField {
  id = "id",
  from = "from",
  to = "to",
  cc = "cc",
  bcc = "bcc",
  type = "type",
  status = "status",
  is_done = "is_done",
  template = "template",
  statusText = "status_text",
  senderInfo = "sender_info",
  recipientBrinexId = "recipient_brinex_id",
  createdAtGe = "created_at-ge",
  createdAtLe = "created_at-le",
}

export const template_select = (
  {
    model,
  }: {
    model: string;
  } = {
    model: MessageField.template,
  }
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Шаблон",
    include: MessageField.template,
    filterable: false,
    multiple: false,
    optionList: async (params: any) => {
      const { data: templates } = await getCommunicationTemplates({
        "page[size]": 100,
        ...params,
      });

      return templates.map((template) => ({
        value: template,
        label: `${template.attributes.name} - id: ${template.id}`,
      }));
    },
    clearable: true,
    submitMapDataCallback: (value) => value.id,
  });

export const is_done_select: FormItemObjDeclaration = defaultSelect(
  MessageField.is_done,
  "Обработка завершена",
  [
    { value: 1, label: "Да" },
    { value: 0, label: "Нет" },
  ],
  "Выберите значение",
  null,
  {},
  null,
  false,
  "",
  false,
  "",
  true
);

export const status_select: FormItemObjDeclaration = defaultSelect(
  MessageField.status,
  "Статус",
  [
    { value: MessageStatus.created, label: "Создано" },
    { value: MessageStatus.success, label: "Отправлено" },
    { value: MessageStatus.error, label: "Не получилось отправить" },
    { value: MessageStatus.withError, label: "Имеются ошибки при отправке" },
  ],
  "Выберите значение",
  null,
  {},
  null,
  false,
  "",
  false,
  "",
  true
);

const enum MessageType {
  sms = "sms",
  email = "email",
}

export const message_type_select: FormItemObjDeclaration = defaultSelect(
  MessageField.type,
  "Тип",
  [
    { value: MessageType.sms, label: MessageType.sms },
    { value: MessageType.email, label: MessageType.email },
  ],
  "Выберите значение",
  null,
  {},
  null,
  false,
  "",
  false,
  "",
  true
);

export const is_done: FormItemObjDeclaration = adminSwitch(
  "Отправлено",
  0,
  MessageField.is_done
);

const statusLogsTableAttrsDeclaration: Record<string, any> = {
  id: {
    minWidth: 120,
    label: "Id",
  },
  status: {
    minWidth: 120,
    label: "Статус",
  },
  status_text: {
    minWidth: 120,
    label: "Текст статуса",
  },
  created_at: {
    minWidth: 120,
    label: "Дата создания",
  },
};

export const status_logs_table: FormItemObjDeclaration = defaultTable({
  model: MessageTabName.statusLogs,
  include: "messageStatusLogs",
  sortRule: {
    prop: MessageStatusLogsField.createdAt,
    order: SortOrderType.desc,
  },
  attrs: {
    cols: statusLogsTableAttrsDeclaration,
  },
});

const messagesTableRenderSettings: PropertiesTableRenderSettings = {
  attributes: [
    {
      value: MessageField.id,
      label: "Id",
    },
    {
      value: MessageField.status,
      label: "Статус",
    },
    {
      value: MessageField.statusText,
      label: "Текст статуса",
    },
    {
      value: MessageField.bcc,
      label: "Скрытая копия",
    },
    {
      value: MessageField.cc,
      label: "Копия",
    },
    {
      value: MessageField.from,
      label: "От",
    },
    {
      value: MessageField.is_done,
      label: "Отправлено",
    },
    {
      value: MessageField.template,
      label: "Шаблон",
    },
    {
      value: MessageField.to,
      label: "Кому",
    },
    {
      value: MessageField.type,
      label: "Тип",
    },
    {
      value: MessageField.senderInfo,
      label: "Отправитель",
    },
    {
      value: MessageField.recipientBrinexId,
      label: "Получатель brinex_id",
    },
  ],
  relationships: {
    systemToken: [
      {
        value: "name",
        label: "Токен",
      },
    ],
    messageData: [
      {
        value: MessageDataField.body,
        label: "Тело сообщения",
      },
      {
        value: MessageDataField.comment,
        label: "Комментарий",
      },
      {
        value: MessageDataField.subject,
        label: "Тема",
      },
    ],
    providerAccount: [
      {
        value: "name",
        label: "Аккаунт провайдера",
      },
    ],
    template: [
      {
        value: "name",
        label: "Шаблон",
      },
    ],
    system: [
      {
        value: "name",
        label: "Система",
      },
    ],
  },
};

export const message_data_properties: FormItemObjDeclaration =
  defaultPropertiesTable({
    model: MessageTabName.properties,
    renderSettings: messagesTableRenderSettings,
  });

export const messages_datepicker = (
  model: string,
  label = "Дата создания с",
  events = {}
): FormItemObjDeclaration =>
  defaultDatePicker(
    model,
    label,
    "Выберите дату",
    false,
    DAY_MONTH_YEAR_DATE_FORMAT,
    events
  );
