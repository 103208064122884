import {
  FormItemAttrsObjDeclaration,
  FormItemEventsObjDeclaration,
  FormItemObjDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import { d } from "@/helpers/dictionary";
import { Calendar } from "@element-plus/icons-vue";

export const DAY_MONTH_YEAR_DATE_FORMAT = "DD.MM.YYYY";
export const YEAR_MONTH_DAY_DATE_FORMAT = "YYYY-MM-DD";

export const defaultDatePicker = (
  model: string,
  label?: string,
  placeholder = "Выберите дату",
  disabled?: boolean,
  format?: string,
  events: FormItemEventsObjDeclaration = {},
  attrs?: FormItemAttrsObjDeclaration
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model: model,
  element: "date-picker",
  isPayload: true,
  formItemAttrs: {
    label: label ?? d(model),
  },
  placeholder: placeholder,
  disabled,
  attrs: {
    format,
    ...attrs,
  },
  events,
});

export const defaultDateTimePicker = (
  model: string,
  label?: string,
  placeholder?: string
): FormItemObjDeclaration =>
  defaultDatePicker(
    model,
    label,
    placeholder,
    undefined,
    undefined,
    undefined,
    {
      type: "datetime",
      prefixIcon: Calendar,
    }
  );

export const start_date: FormItemObjDeclaration = defaultDatePicker(
  "start_date",
  "Дата начала"
);

export const finish_date: FormItemObjDeclaration = defaultDatePicker(
  "finish_date",
  "Дата окончания"
);

export const validity: FormItemObjDeclaration = defaultDatePicker(
  "validity",
  "Срок действия"
);

export const prolongation_date: FormItemObjDeclaration = defaultDatePicker(
  "prolongation_date",
  "Дата пролонгации"
);
