import {
  FormItemObjDeclaration,
  FormItemAttrsObjDeclaration,
  SelectOptionItemDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import { d } from "@/helpers/dictionary";

export const defaultSelectInput = (
  model: string,
  label?: string,
  placeholder?: string,
  attrs?: FormItemAttrsObjDeclaration,
  selectOptions?: SelectOptionItemDeclaration[],
  selectDisabled = false,
  selectWidth?: number
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model,
  isPayload: true,
  element: "select-input",
  attrs: {
    placeholder,
    ...attrs,
  },
  formItemAttrs: {
    label: label ?? d(model),
  },
  selectOptions,
  selectAttrs: {
    disabled: selectDisabled,
  },
  selectWidth,
});
