import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/countries`, {
  list: {
    sort: "letter_code",
    include: "regions",
  },
  item: { include: "regions" },
});

export default service;
export const getCountries = service.list.bind(service);
export const getCountryById = service.item.bind(service);
export const createCountry = service.create.bind(service);
export const updateCountry = service.update.bind(service);
export const deleteCountry = service.delete.bind(service);
