import { Service } from "@/api/core/service";
import {
  CommunicationServicePointName,
  getCommunicationServicePathByEndpoint,
} from "@/api/points/communication-api/service-config";

const service = new Service(
  getCommunicationServicePathByEndpoint(CommunicationServicePointName.systems),
  {
    list: {},
    item: {},
  }
);

export default service;
export const getCommunicationSystems = service.list.bind(service);
export const getCommunicationSystem = service.item.bind(service);
export const createCommunicationSystem = service.create.bind(service);
export const updateCommunicationSystem = service.update.bind(service);
export const deleteCommunicationSystem = service.delete.bind(service);
