import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";
import {
  CustomListRequestOptions,
  GetParametersArgumentsDeclaration,
  IDefaultListResponse,
} from "@/api/core/types";
import { IBrandsResponse } from "@/api/points/pim-api/brands/types";

const service = new Service(`${SERVICE_POINT_NAME}/brands`, {
  item: { include: "logo,manufacturer" },
  list: { include: "manufacturer" },
});

export default service;
export const getBrands = service.list.bind(service) as (
  parameters: GetParametersArgumentsDeclaration,
  options?: CustomListRequestOptions
) => Promise<IDefaultListResponse<IBrandsResponse[]>>;
