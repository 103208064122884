import { RouteAccess } from "@/router/types";
import WorkingSectionView from "@/views/WorkingSectionView.vue";
import { RouteRecordRaw } from "vue-router";

const access: RouteAccess = {
  service: "landings-cms",
  object: "",
  command: "index",
};

export const HRBlocksRoutesList: RouteRecordRaw & {
  isCategory: boolean;
  hasChildren: boolean;
} = {
  path: "/landings/hr-blocks",
  name: "hr",
  isCategory: true,
  hasChildren: true,
  component: (): Promise<typeof import("*.vue")> =>
    import(/* webpackChunkName: "HRRootBlocks" */ "../../../views/Root.vue"),
  meta: {
    title: "Блоки - HR",
  },
  children: [
    {
      path: "/landings/hr-blocks/menu-item-blocks",
      name: "hr-menu-item-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Меню"',
        access: {
          ...access,
          object: "menu_item_blocks",
        },
      },
    },
    {
      path: "/landings/hr-blocks/menu-items",
      name: "hr-menu-items",
      component: WorkingSectionView,
      meta: {
        title: "Пункты меню",
        access: {
          ...access,
          object: "menu_items",
        },
      },
    },
    {
      path: "/landings/hr-blocks/hr-intro-blocks",
      name: "hr-intro-blocks",
      component: WorkingSectionView,
      meta: {
        title: "HR Первый блок",
        access: {
          ...access,
          object: "hr_intro_blocks",
        },
      },
    },
    {
      path: "/landings/hr-blocks/hr-company-values",
      name: "hr-company-values",
      component: WorkingSectionView,
      meta: {
        title: "HR Ценности компании",
        access: {
          ...access,
          object: "hr_company_values",
        },
      },
    },
    {
      path: "/landings/hr-blocks/hr-career-opportunity-blocks",
      name: "hr-career-opportunity-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'HR Блок "Карьерные возможности"',
        access: {
          ...access,
          object: "hr_career_opportunity_blocks",
        },
      },
    },
    {
      path: "/landings/hr-blocks/hr-worker-careers",
      name: "hr-worker-careers",
      component: WorkingSectionView,
      meta: {
        title: "HR Рабочие профессии",
        access: {
          ...access,
          object: "hr_worker_careers",
        },
      },
    },
    {
      path: "/landings/hr-blocks/hr-office-careers",
      name: "hr-office-careers",
      component: WorkingSectionView,
      meta: {
        title: "HR Офисные профессии",
        access: {
          ...access,
          object: "hr_office_careers",
        },
      },
    },
    {
      path: "/landings/hr-blocks/hr-company-info-blocks",
      name: "hr-company-info-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'HR Блок "О компании"',
        access: {
          ...access,
          object: "hr_company_info_blocks",
        },
      },
    },
    {
      path: "/landings/hr-blocks/hr-about-us-blocks",
      name: "hr-about-us-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'HR Блок "Кто мы"',
        access: {
          ...access,
          object: "hr_about_us_blocks",
        },
      },
    },
    {
      path: "/landings/hr-blocks/hr-employee-advantages",
      name: "hr-employee-advantages",
      component: WorkingSectionView,
      meta: {
        title: "HR Преимущества для сотрудников",
        access: {
          ...access,
          object: "hr_employee_advantages",
        },
      },
    },
    {
      path: "/landings/hr-blocks/hr-about-blocks",
      name: "hr-about-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'HR Блок "О нас"',
        access: {
          ...access,
          object: "hr_about_blocks",
        },
      },
    },
    {
      path: "/landings/hr-blocks/hr-vacancy-blocks",
      name: "hr-vacancy-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'HR Блок "Вакансии"',
        access: {
          ...access,
          object: "hr_vacancy_blocks",
        },
      },
    },
    {
      path: "/landings/hr-blocks/hr-unique-vacancies",
      name: "hr-unique-vacancies",
      component: WorkingSectionView,
      meta: {
        title: "HR Уникальные вакансии",
        access: {
          ...access,
          object: "hr_unique_vacancies",
        },
      },
    },
    {
      path: "/landings/hr-blocks/contact-blocks",
      name: "hr-contact-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Контакты"',
        access: {
          ...access,
          object: "contact_blocks",
        },
      },
    },
  ],
};
