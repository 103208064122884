import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/products`, {
  list: { include: "productCategory" },
  item: { include: "image,productCategory" },
});

export default service;
export const getProducts = service.list.bind(service);
export const getProductById = service.item.bind(service);
export const createProduct = service.create.bind(service);
export const updateProduct = service.update.bind(service);
export const deleteProduct = service.delete.bind(service);
