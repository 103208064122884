import { SelectOptionItemDeclaration } from "@/plugins/form-generator-json-v2/types";

export const useTableCellFormatting = () => {
  const getAssociatedLabelByOption = (
    value: string | number,
    options: SelectOptionItemDeclaration[]
  ): string => {
    if (!options || !options.length) {
      return String(value);
    }

    const associatedLabel = options.find(
      (option) => option.value === value
    )?.label;

    return String(associatedLabel || value);
  };

  return {
    getAssociatedLabelByOption,
  };
};
