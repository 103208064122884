<template>
  <component :is="icon" height="16" v-bind="$attrs" width="16" />
</template>

<script lang="ts" setup>
import { computed, defineAsyncComponent } from "vue";

interface Props {
  name: string;
}

const props = defineProps<Props>();

const icon = computed(() =>
  defineAsyncComponent(() => import(`../../icons/${props.name}.svg`))
);
</script>
