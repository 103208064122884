export const setCookie = (
  name: string,
  value: string,
  options = {} as Record<string, unknown>
): void => {
  options = {
    ...options,
  };
  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}`;

  for (const optionKey in options) {
    updatedCookie += "; " + optionKey;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }
  document.cookie = updatedCookie;
};

export const removeCookie = (
  name: string,
  options = {} as Record<string, unknown>
): void => {
  setCookie(name, "", { ...options, expires: -1 });
};

export const getCookie = (name: string): string | undefined => {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" + name.replace(/([.$?*|{}()[]\/+^])/g, "\\$1") + "=([^;]*)"
    )
  );
  return matches && matches[1] ? decodeURIComponent(matches[1]) : undefined;
};
