import { WorkingSectionsDefaultInterface } from "@/model/working-sections/types";
import { RouteAccess } from "@/router/types";
import { FormItemObjDeclaration } from "@/plugins/form-generator-json-v2/types";
import { ElLoading } from "element-plus";
import {
  computed,
  ComputedRef,
  nextTick,
  reactive,
  ref,
  Ref,
  toRaw,
  unref,
} from "vue";
import { useRouter } from "vue-router";
import useSelection from "@/composables/hooks/useSelection";
import { Service } from "@/api/core/service";
import { isContacts } from "@/helpers/сomparisonStaticValues";
import { isValid } from "@/helpers/massValidate";
import { ServiceName } from "@/constants";

export default (
  section:
    | WorkingSectionsDefaultInterface
    | Ref<WorkingSectionsDefaultInterface>,
  getData: () => Promise<void>,
  service: InstanceType<typeof Service>,
  ignoreModels: string[],
  ignoreWizardModels: string[],
  isWizard: boolean,
  afterDelete?: () => void
): {
  massEditingSection: WorkingSectionsDefaultInterface;
  massEditingForm: ComputedRef<any>;
  isMassEditing: Ref<boolean>;
  massEditRows: (items: any[]) => Promise<void>;
  massDeleteRows: (items: any[]) => Promise<void>;
  onChangeActive: (active: number) => void;
  onCancelMassEditing: () => void;
} => {
  const router = useRouter();
  const { service: accessService, object: accessObject } =
    (router.currentRoute.value?.meta?.access as RouteAccess) || {};

  const reactiveSection = reactive(unref(section));

  const isMassEditing = ref(false);
  const { selection, setSelection } = useSelection();

  const isWheels = ref(reactiveSection.forms.edit?.form.name === "wheels");

  const objectKeys = ["manufacturer", "product_type"];

  const massEditRowsRaw = async (items: Record<string, any>[]) => {
    const loading = ElLoading.service({
      lock: true,
    });

    const promises = [];
    for (const item of items) {
      const request = reactiveSection.service?.update(item.id, item);
      if (!request) return;
      promises.push(request);
      const selectionWithoutCurrent = selection.value.filter(
        (value: any) => value.id !== item.id
      );
      setSelection(selectionWithoutCurrent);
      if (!selection.value.length) {
        isMassEditing.value = false;
      }
    }

    await Promise.allSettled(promises);
    loading.close();
    if (!selection.value.length) {
      getData();
    }
  };

  const getFormItems = (formItems: FormItemObjDeclaration[]) => {
    const items: FormItemObjDeclaration[] = [];

    formItems.forEach((item: FormItemObjDeclaration) => {
      if (item.groupList?.length) {
        item.groupList.forEach((groupItem) => items.push(groupItem));
      } else {
        items.push(item);
      }
    });

    return items;
  };

  const massEditRows = async (items: any[]) => {
    const loading = ElLoading.service({
      lock: true,
    });

    const hasMapCbItemList = reactiveSection.forms.edit?.items.filter(
      (item) => item.submitMapDataCallback
    );

    const mapRules = hasMapCbItemList?.reduce((target: any, key) => {
      target[key.model] = key.submitMapDataCallback;
      return target;
    }, {});

    const promises = [];

    for (const item of items) {
      let object: Record<string, any> = {};
      let requiredError = false;
      let elementForScroll = "";

      (
        getFormItems(reactiveSection.forms.edit?.items || []) ||
        getFormItems(reactiveSection.forms.create?.items || []) ||
        []
      ).forEach((editField) => {
        if (!editField.model) {
          return;
        }

        const colError = !isValid(
          reactiveSection.forms.edit?.form?.rules,
          item,
          editField.model
        );
        const relColError =
          item.relationships?.rear?.attributes &&
          !isValid(
            reactiveSection.forms.edit?.form?.rules,
            item.relationships.rear.attributes,
            editField.model
          );
        const isIgnoreCol =
          ignoreModels.includes(editField.model) ||
          (isWizard && ignoreWizardModels.includes(editField.model));

        requiredError = !!(
          requiredError ||
          (editField.model in item && !isIgnoreCol && (colError || relColError))
        );

        if (requiredError) {
          elementForScroll = elementForScroll || editField.model;
        }

        if (item[editField.model] !== undefined) {
          object[editField.model] = item[editField.model];

          if (
            typeof object[editField.model] === "object" &&
            object[editField.model] !== null &&
            !objectKeys.includes(editField.model)
          ) {
            object[editField.model] = object[editField.model]?.id;
          }
        }

        if (mapRules && mapRules[editField.model] && item[editField.model]) {
          object[editField.model] = mapRules[editField.model](
            item[editField.model]
          );
        }

        if (isContacts(editField.model)) {
          (object.contacts = object.contacts || {})[editField.model] = [
            item[editField.model],
          ];
        }
      });

      if (elementForScroll) {
        const scrollWrapper = document.querySelector(".el-scrollbar__wrap");
        const scrollElement = document.querySelector(
          `[data-id="${elementForScroll}"]`
        );

        if (scrollWrapper && scrollElement) {
          scrollWrapper.scrollLeft = 0;

          setTimeout(() => {
            scrollWrapper.scrollLeft =
              scrollElement.getBoundingClientRect().left -
              scrollWrapper.getBoundingClientRect().left -
              100;
          }, 10);
        }
      }

      if (requiredError) continue;

      let request = null;

      if (isWheels.value) {
        const wheelsPayload: Record<string, Record<string, any>> = {
          front: object,
        };
        const wheelsKeys = [
          "active",
          "bitrix_id",
          "external_code",
          "id",
          "is_stock",
          "load_index",
          "modification_id",
          "rim_diameter",
          "rim_offset",
          "rim_width",
          "tire_aspect_ratio",
          "tire_construction",
          "tire_diameter",
          "tire_pressure",
          "tire_section_width",
          "tire_size",
          "tire_sizing_system",
          "tire_width",
          "type",
          "speed_index",
          "is_strength",
        ];

        if (item?.relationships?.rear?.attributes) {
          wheelsPayload.rear = item.relationships.rear.attributes;
        }

        for (const key in wheelsPayload.front) {
          if (!wheelsKeys.includes(key)) {
            delete wheelsPayload.front[key];
          }
        }

        for (const key in wheelsPayload.rear) {
          if (!wheelsKeys.includes(key)) {
            delete wheelsPayload.rear[key];
          }
        }

        object = wheelsPayload;
      }

      request = reactiveSection.service?.update(item.id, object);

      if (!request) return;
      promises.push(request);
      request
        .then((response: any) => {
          const currentSelectionIndex = selection.value.findIndex(
            (value: any) => value.id === item.id
          );
          if (currentSelectionIndex === -1) {
            return;
          }

          if (response.errors && +response.errors.status === 422) {
            const formItemAttrs: Record<string, any> = {};
            for (const key in response.source) {
              formItemAttrs[key] = response.source[key].join(", ");
            }

            let selectionCopy = toRaw(selection.value);
            selectionCopy = selectionCopy.map((item: any) => toRaw(item));
            selectionCopy[currentSelectionIndex].formItemAttrs = {};
            selectionCopy[currentSelectionIndex].formItemAttrs.error =
              formItemAttrs;
            setSelection(selectionCopy);
            return;
          }

          const selectionWithoutCurrent = selection.value.filter(
            (value: any) => value.id !== item.id
          );
          setSelection(selectionWithoutCurrent);
          if (!selection.value.length) {
            isMassEditing.value = false;
          }
        })
        .catch((error: any) => {
          console.error(error);
        });
    }

    await Promise.allSettled(promises);
    loading.close();
    if (!selection.value.length) {
      getData();
    }
  };

  const onCancelMassEditing = () => {
    isMassEditing.value = false;
    nextTick(() => {
      setSelection([]);
    });
  };

  const onChangeActive = async (active: number) => {
    const activatedSelected: Record<string, any>[] = toRaw(selection.value);
    const rowsForEdit: Record<string, any>[] = [];

    for (let item of activatedSelected) {
      if (accessService === ServiceName.pim && accessObject === "products") {
        const rowData = await reactiveSection.service?.item(item.id);
        const properties = rowData.data.relationships.properties.map(
          (property: Record<string, any>) => {
            const { property_id, value, manual_filled } = property.attributes;
            return {
              property_id,
              value,
              manual_filled,
            };
          }
        );

        item = {
          ...item,
          is_active: active,
          properties,
        };
      } else {
        item.active = active;

        if (isWheels.value && item.relationships?.rear?.attributes) {
          item.relationships.rear.attributes.active = active;
        }
      }

      rowsForEdit.push(item);
    }

    if (accessService === ServiceName.pim && accessObject === "products") {
      massEditRowsRaw(rowsForEdit);
    } else {
      massEditRows(rowsForEdit);
    }
  };

  const massDeleteRows = async (ids: Array<number | string>): Promise<void> => {
    if (!service) {
      return;
    }
    try {
      const promises: any = [];
      ids.forEach((id: number | string) => {
        promises.push(service.delete(id));
      });

      await Promise.all(promises);
      await getData();
      if (afterDelete) afterDelete();
      setSelection([]);
    } catch (e) {
      console.error(e);
    }
  };

  const massEditingForm = computed(() => {
    let items: Record<string, any>[] = [];

    if (reactiveSection.forms.edit?.items.length) {
      items = getFormItems(reactiveSection.forms.edit.items);
    } else if (reactiveSection.forms.create?.items) {
      items = getFormItems(reactiveSection.forms.create.items);
    }

    return {
      ...reactiveSection.forms.edit,
      items: [...items, ...(reactiveSection.forms.service?.items || [])],
    };
  });

  return {
    massEditingSection: reactiveSection,
    massEditingForm,
    isMassEditing,
    massEditRows,
    massDeleteRows,
    onChangeActive,
    onCancelMassEditing,
  };
};
