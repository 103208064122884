<template>
  <el-dialog
    :model-value="showUserImpersonateModal"
    class="impersonate-user-dialog"
    width="400px"
    @close="hideUserImpersonateModal"
  >
    <h3>Войти на сайт</h3>
    <section :class="$style['impersonate-user-data']">
      <div>Вы уверены что хотите войти на сайт под учетной записью:</div>
      <div>{{ impersonatePayload.name }}</div>
      <div>{{ impersonatePayload.role }}</div>
    </section>
    <section :class="$style['impersonate-buttons']">
      <el-button @click="hideUserImpersonateModal"> Я передумал</el-button>
      <el-button type="primary" @click="impersonateUser"> Войти</el-button>
    </section>
  </el-dialog>
</template>

<script lang="ts" setup>
import useImpersonation from "@/components/working-sections/default/composables/useImpersonation";

const {
  showUserImpersonateModal,
  impersonatePayload,
  impersonateUser,
  runImpersonateCommand,
  hideUserImpersonateModal,
} = useImpersonation();

defineExpose({
  runImpersonateCommand,
});
</script>

<style lang="scss" module>
.impersonate-user-data {
  margin-bottom: 24px;

  & > div:first-child {
    margin-bottom: 12px;
  }

  & > div:nth-child(2) {
    font-weight: 700;
  }
}

.impersonate-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
</style>
