import {
  DefaultJsonFormContext,
  FormItemAttrsObjDeclaration,
  FormItemEventsObjDeclaration,
  FormItemObjDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import { d } from "@/helpers/dictionary";
import { VALUE_TYPES } from "@/helpers/pimProperties";
import { ColumnType } from "@/model/working-sections/constants";

export const defaultTagInput = (
  model: string,
  label?: string,
  placeholder?: string,
  attrs?: FormItemAttrsObjDeclaration
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model,
  isPayload: true,
  element: "tag-input",
  attrs: {
    placeholder,
    ...attrs,
  },
  formItemAttrs: {
    label: label ?? d(model),
  },
});

export const defaultArrayInput = (
  model: string,
  label?: string,
  placeholder?: string,
  attrs?: FormItemAttrsObjDeclaration,
  formAttrs?: Record<string, unknown>,
  events?: FormItemEventsObjDeclaration
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model,
  isPayload: true,
  element: "array-input",
  attrs: {
    placeholder,
    ...attrs,
  },
  formItemAttrs: {
    label: label ?? d(model),
    ...formAttrs,
  },
  submitMapDataCallback: (value: any) => (value ? value : []),
  events,
});

export const properties_tag_input = defaultArrayInput(
  "values",
  "Список значений",
  "Укажите значение",
  { type: ColumnType.hidden },
  { class: "el-form-item--hidden" },
  {
    onChange: function (this: DefaultJsonFormContext, value: string[]): void {
      const defaultInput = this.getFormItem("default");
      const classifiersTable = this.getFormItem("classifiers");

      const isRange = this.formModel?.type === VALUE_TYPES.RANGE;
      const isList =
        (this.formModel?.type as string).endsWith("_list") ||
        (this.formModel?.type as string).endsWith("_multiple");

      if (defaultInput) {
        if (isList) {
          const options = value.map((item) => ({
            value: item,
            label: item,
          }));

          defaultInput.selectOptions = options;
          defaultInput.element = "select";

          if (classifiersTable?.attrs?.cols?.default) {
            classifiersTable.attrs.cols.default.options = options;
          }
        } else if (isRange && ![null, undefined].includes(value[1] as any)) {
          const downLimit = Number(value[0]);
          const upLimit = Number(value[1]);

          const defaultValue = Number(this.formModel?.default);

          defaultInput.element = "number-input";

          if (defaultInput.attrs) {
            defaultInput.attrs.min = downLimit;
            defaultInput.attrs.max = upLimit;
          }

          if (defaultValue && downLimit && downLimit > defaultValue) {
            this.formModel.default = String(downLimit);
          }

          if (defaultValue && upLimit && upLimit < defaultValue) {
            this.formModel.default = String(upLimit);
          }

          if (classifiersTable?.attrs?.cols?.default) {
            classifiersTable.attrs.cols.default.attrs.min = downLimit;
            classifiersTable.attrs.cols.default.attrs.max = upLimit;
          }
        }
      }
    },
  }
);

export const properties_synonyms: FormItemObjDeclaration = {
  name: "synonyms",
  id: "synonyms",
  model: "synonyms",
  isPayload: true,
  element: "tag-input",
  submitMapDataCallback: (value: string[]) =>
    value ? value?.map((v) => v.toLowerCase()) : [],
  formItemAttrs: {
    label: "Синонимы",
  },
};
