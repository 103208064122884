import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import TiptapHighlight from "@tiptap/extension-highlight";
import { COLOR_SET } from "@/plugins/tiptap-editor/utils/color";
import HighlightPopover from "../components/MenuCommands/HighlightPopover.vue";

const Highlight = TiptapHighlight.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      multicolor: true,
      colors: COLOR_SET,
      button({ editor, isCodeViewMode }: CommandButtonArgs) {
        return {
          component: HighlightPopover,
          componentProps: {
            editor,
          },
          isCodeViewMode,
        };
      },
    };
  },
});

export default Highlight;
