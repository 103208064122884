import { fetchRequest } from "@/api/core";
import { SERVICE_POINT_NAME } from "@/api/points/auth-api/service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/system_objects`, {
  list: { include: "service" },
  item: { include: "service" },
});

export default service;
export const getSystemObjects = service.list.bind(service);
export const getSystemObjectById = service.item.bind(service);
export const createSystemObject = service.create.bind(service);
export const updateSystemObject = service.update.bind(service);
export const deleteSystemObject = service.delete.bind(service);
export const fillSystemObjects = async (service: string): Promise<any> => {
  try {
    const result = await fetchRequest({
      point: `${SERVICE_POINT_NAME}/system_objects/system_update`,
      payload: {
        service,
      },
      fetchMethod: "POST",
    });
    return result;
  } catch (e) {
    return false;
  }
};
