interface IProviderAccountFieldsStorage {
  _fieldsData: Record<string, string>;
  fieldsData: Record<string, string>;
}

export const providerAccountFieldsStorage: IProviderAccountFieldsStorage = {
  _fieldsData: {},

  get fieldsData(): Record<string, string> {
    return this._fieldsData;
  },

  set fieldsData(value: any) {
    this._fieldsData = JSON.parse(value as string);
  },
};
