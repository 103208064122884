<template>
  <slot v-if="!hideHeader" name="head">
    <the-head-title
      :deep="isWizard ? 3 : 0"
      :title="defaultSectionDeclaration.text.title"
    />
  </slot>
  <slot name="main">
    <section :class="['main', $style['main']]">
      <div v-if="!isMassEditing" :class="$style['top-wrapper']">
        <the-breadcrumb v-if="isWizard" :breadcrumbs="breadcrumbs" />

        <base-notification
          v-if="shouldShowNotification"
          :message="notificationMessage"
          @on-message-click="redirectToFirstPage(true)"
        />
        <section
          :class="[
            $style['top'],
            {
              [$style['top_wizard']]: isWizard,
            },
          ]"
        >
          <div v-if="!selection.length" :class="$style['top-filters']">
            <the-filter
              :data-list-filter="dataListFilter"
              :exclude-default-filters-options="
                isWizard ? false : excludeDefaultFiltersOptions
              "
              :filter-button-disabled="filterDisabled"
              :filter-data="filterData"
              :filter-options="filterOptions"
              :has-export-button="isWizard ? false : isExportButtonVisible"
              :has-filter-active="isWizard"
              :has-filter-attributes="isWizard"
              :has-filter-form="hasFilterForm"
              @filter-toggle="toggleFilter"
              @filter-change="filterChangeHandler"
              @filter-clean="cleanFilter"
              @filter-data-list="filterDataListHandler"
            >
              <template #start>
                <el-button
                  v-if="
                    hasArchiveAccess &&
                    router.currentRoute.value.path === '/pim/products'
                  "
                  :class="[
                    'active-button',
                    {
                      [$style['archive-active-button']]:
                        filterPayload['filter[trashed]'],
                    },
                  ]"
                  @click="onChangeArchiveActivity"
                >
                  <el-icon :class="$style['archive-button-icon']">
                    <FolderDelete />
                  </el-icon>
                  Архив
                </el-button>
              </template>
            </the-filter>
            <the-table-filter
              v-if="!isWizard && !isMobile"
              :class="$style['top-buttons']"
              :cols="sectionDeclaration.cols"
            />
            <el-upload
              v-if="showUpload"
              ref="file"
              :action="downloadLink"
              :before-upload="onBeforeUpload"
              :class="$style['uploader']"
              :headers="headers"
              :on-error="onError"
              :on-success="onSuccess"
              :show-file-list="false"
            >
              <el-button
                v-tooltip="{
                  disabled: isUploadable,
                  text: TOOLTIP_TEXT_RIGHTS.replace('__ACTION__', 'импорт'),
                }"
                :disabled="!isUploadable"
                :icon="Upload"
                size="large"
              />
            </el-upload>

            <the-mobile-filter
              v-if="isMobile"
              :class="$style['columns-config']"
              :cols="defaultSectionDeclaration.cols"
              options-placement="bottom-end"
            />
          </div>
          <actions-massive
            v-else
            :click-massive-menu-handler="clickMassiveMenuHandler"
            :create-form="createForm"
            :hide-delete="hideDelete"
            :is-create-in="isCreateInModels || isCreateInBrands"
            :is-mass-editing="isMassEditing"
            :is-wizard="isWizard"
            @toggle-mass-editing="isMassEditing = !isMassEditing"
          />
          <the-header-buttons
            v-if="!hideCreateButton"
            :create-button-text="defaultSectionDeclaration.text.createBtn"
            :should-navigate-to-detail-page="shouldNavigateToDetailPage"
            :has-create="hasStoreAccess"
            :is-admin="isAdmin"
            :service="service"
            @after-delete="getData"
          />
        </section>

        <section v-if="!isMassEditing && showFilterTags && !dataList.loading">
          <the-filter-tags
            :data="filterData"
            :filterForm="filterForm"
            @clear="cleanFilter"
            @remove-tag="onRemoveTag"
          />
        </section>
      </div>
      <the-filter-tabs
        v-if="sectionDeclaration.forms?.filter?.tabsFilterData"
        ref="filterTabs"
        :data="sectionDeclaration.forms.filter.tabsFilterData"
        :filterData="filterData"
        :service="service"
        @filter-change="filterChangeHandler"
        @filter-clean="cleanFilter"
        @filter-disable="filterDisableHandler"
      />
      <the-table
        v-if="!isMassEditing"
        :bulks="
          isWizard ? sectionDeclaration.bulks : defaultSectionDeclaration.bulks
        "
        :cell-class-name="tableCellClassName"
        :class="[
          {
            [$style['landings-table']]: isLandings,
          },
        ]"
        :cols="sectionDeclarationTable.cols"
        :data-list="dataList"
        :double-click-handler="doubleClickHandler"
        :filter-attributes="isWizard ? filterData['filter[attributes]'] : false"
        :has-actions="hasUpdateAccess || hasDestroyAccess || hasShowAccess"
        :hide-burger="hideBurgerMenu"
        :init-sort="sectionDeclaration.initSort"
        :isWizard="isWizard"
        :row-class-name="getTableRowClass"
        :selection-change-handler="setSelection"
        @expand-change="expandedRows = $event"
      >
        <template #actions="scope">
          <actions-single
            :click-menu-handler="clickMenuHandler"
            :export-access="hasExportAccess || isAdmin"
            :hide-delete="hideDelete ?? false"
            :is-create-in="isCreateInModels || isCreateInBrands"
            :is-modification="
              defaultSectionDeclaration.forms.create.form.name ===
              'modifications'
            "
            :is-wizard="isWizard"
            :scope="scope"
            @open-delete-modal="openDeleteModal"
          />
        </template>
      </the-table>
      <the-table-mass-editor
        v-if="isMassEditing"
        :cell-class-name="tableCellClassName"
        :click-massive-menu-handler="clickMassiveMenuHandler"
        :cols="sectionDeclaration.editableCols ?? {}"
        :create-form="createForm"
        :declaration="massEditingForm"
        :expanded-rows="expandedRows"
        :ignore-models="ignoreModels"
        :is-code-editable="isCodeEditable"
        :is-create-in="isCreateInModels || isCreateInBrands"
        :is-wizard="isWizard"
        @cancel="onCancelMassEditing"
        @save="massEditRows"
      />
      <the-pagination v-if="!isMassEditing" />
    </section>
  </slot>
  <section :class="['modals', $style['modals']]">
    <modal-form
      :data-list="dataList"
      :edit-form="editForm"
      :handlers="{ getData, toggleActivation }"
      :hide-actions="!!hideModalActions"
      :is-copy="isCopy"
      :is-wizard="isWizard"
      :merged-edit-form="mergedEditForm"
      :outer-props="props"
      :preset-values="presetValues"
      :state="state"
      :updated-row-id="updatedRowId"
      @reset-data="resetData"
      @before-close="beforeCloseHandler"
    />
    <modal-filter
      ref="filter"
      :filter-clean-handler="cleanFilter"
      :filter-form="filterForm"
      :filter-handler="changeFilter"
      :filter-loading="filterLoading"
      :filter-toggle="filterToggle"
      :preview-filter-data="previewFilterData"
      :set-filter-toggle="setFilterToggle"
      @onCloseFilter="onCloseFilter"
    />

    <modal-impersonation v-if="isImpersonatable" ref="impersonation" />
  </section>
  <the-delete-modal
    :delete-data="deleteData"
    :is-opened="isDeleteModalOpened"
    :mass-delete-rows="massDeleteRows"
    @close-delete-modal="closeDeleteModal"
  />
  <the-nipple-modal
    v-if="defaultSectionDeclaration.nippleModal"
    :data="nippleModalData"
    :declaration="defaultSectionDeclaration"
    :is-nipple-modal="isNippleModal"
    @closeModal="closeNippleModal"
  />
</template>

<script lang="ts" setup>
import {
  computed,
  ComputedRef,
  onMounted,
  reactive,
  ref,
  Ref,
  toRaw,
  unref,
  watch,
} from "vue";
import TheHeaderButtons from "@/components/the/TheHeaderButtons.vue";
import TheBreadcrumb from "@/components/the/TheBreadcrumb.vue";
import TheFilter from "@/components/the/TheFilter.vue";
import TheTable from "@/components/the/TheTable.vue";
import ThePagination from "@/components/the/ThePagination.vue";
import BaseNotification from "@/components/base/BaseNotification.vue";
import { ElButton, ElForm, ElUpload } from "element-plus";
import { GetParametersArgumentsDeclaration } from "@/api/core/types";
import {
  FilterOption,
  GeneratorDeclaration,
  SectionSettings,
} from "@/plugins/form-generator-json-v2/types";
import type { RouteAccess } from "@/router/types";
import { FolderDelete, Upload } from "@element-plus/icons-vue";
import { WorkingSectionsDefaultInterface } from "@/model/working-sections/types";
import { useRouter } from "vue-router";
import useSectionModal from "@/composables/hooks/modals/useSectionModal";
import useFilter from "@/composables/hooks/useFilter";
import useSectionPagination from "@/composables/hooks/useSectionPagination";
import useSelection from "@/composables/hooks/useSelection";
import useSort, { SortOrderType } from "@/composables/hooks/useSort";
import useToggle from "@/composables/hooks/useToggle";
import useWizardBreadcrumbs from "@/composables/hooks/useWizardBreadcrumbs";
import useTableFilters from "@/components/working-sections/default/composables/useTableFilters";
import useTableDecorationsHandlers from "@/components/working-sections/default/composables/useTableDecorationsHandlers";
import useTableDataHandlers from "@/components/working-sections/default/composables/useTableDataHandlers";
import useAccessRights from "@/composables/hooks/useAccessRights";
import useUploadHandlers from "@/composables/hooks/useUploadHandlers";
import useTableStyles from "@/components/working-sections/default/composables/useTableStyles";
import useMassEditing from "@/components/working-sections/default/composables/useMassEditing";
import useSectionDeclaration from "@/composables/hooks/useSettingsDeclaration";
import { DataList } from "@/components/types";
import TheTableFilter from "@/components/the/TheTableFilter.vue";
import { QUERY_PARAM } from "@/components/config";
import { user } from "@/model/user";
import TheFilterTags from "@/components/the/TheFilterTags.vue";
import TheTableMassEditor from "@/components/the/TheTableMassEditor.vue";
import modalForm from "./components/modals/form.vue";
import modalFilter from "./components/modals/filter.vue";
import modalImpersonation from "./components/modals/impersonation.vue";
import ActionsSingle from "./components/actions/Single.vue";
import ActionsMassive from "./components/actions/Massive.vue";
import TheMobileFilter from "@/components/the/TheMobileFilter.vue";
import TheHeadTitle from "@/components/the/TheHeadTitle.vue";
import TheFilterTabs from "@/components/the/TheFilterTabs.vue";
import TheDeleteModal from "@/components/the/TheDeleteModal.vue";
import TheNippleModal from "@/components/the/TheNippleModal.vue";
import vTooltip from "@/directives/vTooltip";
import { TOOLTIP_TEXT_RIGHTS } from "@/dictionary/tooltip";
import useAdaptive from "@/composables/hooks/useAdaptive";
import { Command } from "@/components/working-sections/default/components/actions/constants";
import {
  getPathParams,
  instanceId,
  redirectToFirstPage,
} from "@/helpers/route";
import { stringDeclension } from "@/helpers/dictionary";
import useDeleteModal from "@/composables/hooks/modals/useDeleteModal";
import useNippleModal from "@/composables/hooks/modals/useNippleModal";
import useWebSocketMessageHandler from "@/composables/hooks/useWebSocketMessageHandler";
import { IImpersonation } from "@/components/working-sections/default/composables/useImpersonation";
import { Service } from "@/api/core/service";
import { ICommand } from "@/components/working-sections/default/components/actions/types";
import { ServiceName } from "@/constants";
import { WSEvent } from "@/composables/hooks/useWebsocket";

const { getDefaultFilters } = useFilter();

const { isMobile } = useAdaptive();

const props = defineProps<{
  defaultSectionDeclaration: WorkingSectionsDefaultInterface;
}>();

const router = useRouter();
const isWizard = router.currentRoute.value.path.includes(ServiceName.wizard);
const isLandings = router.currentRoute.value.path.includes("landings");
const isMainEntity: boolean = [
  "brands",
  "models",
  "generations",
  "modifications",
].includes(router.currentRoute.value.name?.toString() ?? "");

const sectionDeclaration = useSectionDeclaration(
  props.defaultSectionDeclaration
);
const sectionDeclarationTable = useSectionDeclaration(
  props.defaultSectionDeclaration
);
const service: InstanceType<typeof Service> =
  props.defaultSectionDeclaration.service ||
  (props.defaultSectionDeclaration.methods as InstanceType<typeof Service>);

const isCreateInModels =
  isWizard &&
  props.defaultSectionDeclaration.forms.create.form.name === "models";
const isCreateInBrands =
  isWizard &&
  props.defaultSectionDeclaration.forms.create.form.name === "brands";

const presetData: ComputedRef<Record<string, any>> = computed(() => {
  const { meta, params } = router.currentRoute.value;
  if (meta.preset) {
    return {
      [meta.preset as string]: params.id,
    };
  }
  return {};
});

const dataList = reactive<DataList>({
  loading: false,
  value: [],
});

const ignoreModels = ["commands"];
const ignoreWizardModels = ["brand_id", "model_id"];
const idSort = [
  "manufacturer_service_pim_manufacturers",
  "product_types_service_pim_product_types",
];

const { breadcrumbs } = useWizardBreadcrumbs(dataList);
const { show: showModal } = useSectionModal(QUERY_PARAM);
const { sortProp, sortOrder } = useSort();
const {
  setData: setPaginationData,
  pageSize,
  pageNumber,
  data: paginationData,
  setPageNumber,
} = useSectionPagination();
const {
  isToggled: filterToggle,
  setToggleValue: setFilterToggle,
  toggleValue: toggleFilter,
} = useToggle();
const { selection, setSelection } = useSelection();
const { data: payloadFilter } = useFilter();

const createForm = reactive(
  props.defaultSectionDeclaration.forms.create || ({} as GeneratorDeclaration)
);
const filterForm = reactive(
  props.defaultSectionDeclaration.forms.filter || ({} as GeneratorDeclaration)
);
const editForm = reactive(
  props.defaultSectionDeclaration.forms.edit ||
    props.defaultSectionDeclaration.forms.create ||
    ({} as GeneratorDeclaration)
);

const serviceForm = reactive(
  props.defaultSectionDeclaration.forms.service || ({} as GeneratorDeclaration)
);
const mergedEditForm = computed(
  (): GeneratorDeclaration & { service: InstanceType<typeof Service> } => {
    return {
      ...editForm,
      items: [...editForm.items, ...serviceForm.items],
      service,
    };
  }
);
const state = reactive({
  activeCollapseItems: [],
  action: Command.CREATE,
});

const shouldNavigateToDetailPage = ref<boolean>(
  props.defaultSectionDeclaration.shouldNavigateToDetailPage || false
);

const { service: accessService, object: accessObject } =
  (router.currentRoute.value?.meta?.access as RouteAccess) || {};
const {
  hasUpdateAccess,
  hasShowAccess,
  hasStoreAccess,
  hasDestroyAccess,
  hasExportAccess,
  hasImportAccess,
  hasUpdateSpecFieldsAccess,
  hasArchiveAccess,
} = useAccessRights(router);
const isAdmin = ref(user.isAdmin());
const importAccess = ref(
  user.getProfile()?.access?.vehicles?.includes("models_import")
);

const file = ref<InstanceType<typeof ElUpload>>();
const { downloadLink, headers, onBeforeUpload, onSuccess, onError } =
  useUploadHandlers();

const filterOptions: ComputedRef<FilterOption[]> = computed(
  () => filterForm.options || []
);
const excludeDefaultFiltersOptions: ComputedRef<boolean> = computed(
  () =>
    !!props.defaultSectionDeclaration?.forms.filter?.settings?.filter
      ?.excludeDefaultOptions
);
const hasFilterForm: ComputedRef<boolean> = computed(() =>
  isWizard
    ? !!(filterForm && Object.keys(filterForm).length)
    : !!filterForm?.items?.length
);

const isCopy = ref<boolean>(false);
const updatedRowId = ref(0);

const impersonation = ref<IImpersonation | null>(null);
const runImpersonateCommand = async (command: ICommand): Promise<void> => {
  await impersonation.value?.runImpersonateCommand(command);
};

const presetValues = reactive<{
  edit: Record<string, any> | null;
  service: Record<string, any> | null;
  log: Record<string, any> | null;
}>({
  edit: isWizard ? presetData.value : {},
  service: {},
  log: {},
});
const expandedRows = ref<Array<Record<string, any>>>([]);

const isExportButtonVisible: ComputedRef<boolean> = computed(
  () =>
    !!props.defaultSectionDeclaration?.forms.filter?.settings?.isExportAllowed
);

const filterTabs = ref();

const {
  hideHeader,
  hideDelete = false,
  hideCreateButton,
  hideBurgerMenu,
  hideModalActions,
} = sectionDeclaration.value.settings as SectionSettings;

const onChangeArchiveActivity = () => {
  if (!filterPayload.value["filter[trashed]"]) {
    filterPayload.value = {
      ...filterPayload.value,
      "filter[trashed]": "1",
    };
    previewFilterData.value = {
      ...previewFilterData.value,
      "filter[trashed]": 1,
    };
  } else {
    const filterPayloadCopy = toRaw(filterPayload.value);
    delete filterPayloadCopy["filter[trashed]"];
    filterPayload.value = {
      ...filterPayloadCopy,
    };
    delete previewFilterData.value["filter[trashed]"];
  }
};

const dataPayload: ComputedRef<GetParametersArgumentsDeclaration> = computed(
  () => {
    const payload: GetParametersArgumentsDeclaration = {
      "page[number]": pageNumber.value,
      "page[size]": pageSize.value,
      ...filterPayload.value,
    };

    if (
      sortProp.value &&
      props.defaultSectionDeclaration.cols[sortProp.value]?.sortable
    ) {
      const sortBy =
        props.defaultSectionDeclaration.cols[sortProp.value]?.sortBy ||
        sortProp.value;

      const sortSuffix = idSort.includes(sortBy) ? ".id" : "";

      payload.sort =
        (sortOrder.value === SortOrderType.asc ? "" : "-") +
        sortBy +
        sortSuffix;
      setSelection([]);
    }
    return payload;
  }
);

const dataQuery: ComputedRef<Record<string, any>> = computed(() => ({
  pageNumber: pageNumber.value,
  pageSize: pageSize.value,
  sortProp: sortProp.value,
  sortOrder: sortOrder.value,
  ...filterPayload.value,
}));

const resetData = () => {
  updatedRowId.value = 0;
  presetValues.edit = isWizard ? presetData.value : {};
  presetValues.service = {};
  presetValues.log = {};
  isCopy.value = false;
};

const filter: Ref<{ filterInstance: InstanceType<typeof ElForm> }> = ref(
  {} as Ref<{ filterInstance: InstanceType<typeof ElForm> }>
);

const filterDisabled = ref(false);

const { isDeleteModalOpened, deleteData, openDeleteModal, closeDeleteModal } =
  useDeleteModal();

const afterDelete = () => {
  if (filterTabs.value?.getData) {
    filterTabs.value.getData();
  }
};

const {
  filterData,
  filterPayload,
  previewFilterData,
  showFilterTags,
  onRemoveTag,
  onCloseFilter,
  filterCleanHandler,
  filterChangeHandler,
  filterHandler,
  dataListFilter,
  filterDataListHandler,
  filterDisableHandler,
  filterLoading,
} = useTableFilters(
  isWizard,
  service,
  filterForm,
  router,
  setPageNumber,
  setFilterToggle,
  payloadFilter,
  paginationData,
  pageNumber,
  filter,
  dataList,
  filterDisabled
);

const { getData, editRow, toggleActivation } = useTableDataHandlers(
  isWizard,
  service,
  props.defaultSectionDeclaration,
  state,
  isCopy,
  presetValues,
  updatedRowId,
  editForm,
  dataList,
  setPaginationData,
  dataPayload,
  accessObject,
  accessService,
  serviceForm,
  router,
  isWizard ? presetData : null,
  isWizard ? getDefaultFilters : undefined
);

const {
  massEditingForm,
  isMassEditing,
  massDeleteRows,
  massEditRows,
  onChangeActive,
  onCancelMassEditing,
} = useMassEditing(
  sectionDeclaration,
  getData,
  service,
  ignoreModels,
  ignoreWizardModels,
  isWizard,
  afterDelete
);

const { tableCellClassName, getTableRowClass } = useTableStyles(
  dataList,
  accessObject
);

const { isNippleModal, nippleModalData, showNippleImports, closeNippleModal } =
  useNippleModal(
    props.defaultSectionDeclaration.nippleModal || false,
    service,
    getData
  );

const {
  clickMenuHandler,
  clickMassiveMenuHandler,
  doubleClickHandler,
  beforeCloseHandler,
} = useTableDecorationsHandlers(
  isWizard,
  state,
  service,
  props.defaultSectionDeclaration,
  onChangeActive,
  massDeleteRows,
  editRow,
  toggleActivation,
  getData,
  runImpersonateCommand,
  showNippleImports
);

const showUpload = isCreateInModels || isCreateInBrands;
const isUploadable =
  (isCreateInBrands && (importAccess.value || isAdmin)) ||
  (isCreateInModels && (hasImportAccess || isAdmin));

const isImpersonatable =
  !isWizard && user.data.profile.admin && accessObject === "users";
const isCodeEditable =
  !isWizard && isMainEntity ? hasUpdateSpecFieldsAccess : false;

const { sectionsCreatedInstances } = useWebSocketMessageHandler();

const createdInstancesQty = computed((): number => {
  const { section } = getPathParams();

  return sectionsCreatedInstances.value[section] || 0;
});

const shouldShowNotification = computed((): boolean =>
  Boolean(
    unref(createdInstancesQty) > 0 &&
      props.defaultSectionDeclaration?.settings?.showWSNotification
  )
);

const notificationMessage = computed((): string => {
  const recordsLabel = stringDeclension(unref(createdInstancesQty), [
    "новую запись",
    "новых записи",
    "новых записей",
  ]);

  return `Показать ${unref(createdInstancesQty)} ${recordsLabel}`;
});

onMounted(async () => {
  sectionsCreatedInstances.value = {};

  const res: Record<string, any> = await getData();
  previewFilterData.value = {
    ...previewFilterData.value,
    filterCountButtonSubmit: res?.meta?.total || 0,
  };
});

onMounted(async () => {
  const id = instanceId();

  if (!id) {
    return;
  }

  if (id && id !== "create") {
    editRow(id);
  } else {
    showModal(id);
  }
});

watch(dataPayload, async () => {
  const res = await getData();
  isWizard && setSelection([]);
  previewFilterData.value = {
    ...previewFilterData.value,
    filterCountButtonSubmit: res?.meta?.total || 0,
  };
});

watch(dataQuery, (query) => {
  router.push({
    ...router.currentRoute.value,
    query,
  });
});

watch([filterData, pageNumber], () => setSelection([]));

const resetSectionsCreatedInstance = () => {
  window.dispatchEvent(new CustomEvent(WSEvent.resetSectionsCreatedInstance));
};

const cleanFilter = () => {
  resetSectionsCreatedInstance();
  filterCleanHandler();
};

const changeFilter = (form) => {
  resetSectionsCreatedInstance();
  filterHandler(form);
};
</script>

<style lang="scss" module>
.top-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 40px 40px 16px;

  @media (max-width: 640px) {
    padding: 24px 16px 16px;
  }

  .top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
    align-items: flex-start;

    @media (max-width: 640px) {
      gap: inherit;
    }

    &_wizard {
      @media (max-width: 640px) {
        justify-content: flex-start;
      }
    }

    &_edit {
      @media (max-width: 640px) {
        width: 100%;
        flex-direction: row;
      }
    }

    &-left {
      @media (max-width: 640px) {
        width: 100%;
      }
    }

    .el-row {
      @media (max-width: 640px) {
        width: 100%;
        margin: 0 !important;
      }
    }
  }
}

.top-wrapper + :global.table-wrapper {
  padding-top: 0;
}

.left {
  left: 25px;
  margin: 0;
  position: absolute;
}

.main {
  :global {
    .archive-button > span {
      gap: 9px;
      display: flex;
      align-items: center;
    }

    .el-form-item--hidden {
      display: none;
    }

    .scroll-overlay {
      padding: 4px;
      margin-top: -48px;
      margin-bottom: 24px;
      position: sticky;
      bottom: 0;
      z-index: 22;
      background: var(--el-color-white);
    }

    .auto-width .el-table__body,
    .auto-width .el-table__header {
      width: auto !important;
    }
  }
}

.archive-button-icon {
  color: var(--el-text-color-primary);
  font-size: 16px;
}

.archive-active-button {
  background: var(--el-color-primary-light-9);
  border: 1px solid var(--el-color-primary-dark-2);
  color: var(--el-color-primary-dark-2);
}

.archive-button-icon {
  color: var(--el-color-primary-dark-2);
}

.top-filters {
  display: flex;
  flex-grow: 1;
  max-width: 800px;

  @media (max-width: 640px) {
    flex-wrap: wrap;
  }
}

.modals {
  :global .impersonate-user-dialog {
    & > .el-dialog__header {
      padding: 0;
      box-shadow: none;
    }

    & > .el-dialog__body {
      color: var(--el-text-color-primary);
      padding: 24px;

      & > h3 {
        font-size: var(--el-font-size-large);
        line-height: 26px;
      }
    }
  }
}

.filter-container {
  @media (max-width: 640px) {
    margin: 0 !important;
  }
}

.uploader {
  @media (max-width: 640px) {
    margin: 0 0 8px;
  }
}

.columns-config {
  margin-left: 10px;
  width: fit-content;
}

.landings-table {
  :global {
    .el-table {
      td {
        &.minimized {
          @media (max-width: 640px) {
            left: 16px;
          }
        }
      }
    }
  }
}
</style>
