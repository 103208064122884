import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/wheels/pair`, {
  list: {
    include: "modification.generation.model.brand",
  },
  item: {
    include: "modification.generation.model.brand",
  },
});

export default service;
export const getWheels = service.list.bind(service);
export const getWheelById = service.item.bind(service);
export const createWheel = service.create.bind(service);
export const updateWheel = service.update.bind(service);
export const deleteWheel = service.delete.bind(service);
