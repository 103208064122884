import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import TiptapHistory from "@tiptap/extension-history";
import CommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CommandButton.vue";

const History = TiptapHistory.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      button({ editor, t, isCodeViewMode }: CommandButtonArgs) {
        return [
          {
            component: CommandButton,
            componentProps: {
              command: () => {
                editor.commands.undo();
              },

              icon: "undo",
              tooltip: t("editor.extensions.History.tooltip.undo"),
              isCodeViewMode,
            },
          },
          {
            component: CommandButton,
            componentProps: {
              command: () => {
                editor.commands.redo();
              },

              icon: "redo",
              tooltip: t("editor.extensions.History.tooltip.redo"),
              isCodeViewMode,
            },
          },
        ];
      },
    };
  },
});

export default History;
