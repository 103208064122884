<template>
  <div :class="$style['head__buttons']">
    <div v-if="selection.length" :class="$style['bulk']">
      Выбрано {{ selection.length }} из {{ maxSelection }}
      <el-popover
        :content="SELECT_MESSAGE"
        :popper-class="$style['selection-popover']"
        placement="top-end"
        trigger="hover"
        width="220"
      >
        <template #reference>
          <el-icon :class="['black', $style['selection-icon']]" size="16px">
            <Warning />
          </el-icon>
        </template>
      </el-popover>
    </div>
    <div v-else :class="$style['add']">
      <el-button
        v-tooltip="{
          disabled: hasCreate,
          text: TOOLTIP_TEXT_RIGHTS.replace('__ACTION__', 'создание'),
          handler: () => createHandler(),
        }"
        :disabled="!hasCreate"
        size="large"
        type="primary"
      >
        <el-icon v-if="isMobile">
          <plus />
        </el-icon>

        <span v-else>{{ createButtonText }}</span>
      </el-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import useSelection from "@/composables/hooks/useSelection";
import useSectionModal from "@/composables/hooks/modals/useSectionModal";
import useAdaptive from "@/composables/hooks/useAdaptive";
import { WorkingSectionsDefaultInterface } from "@/model/working-sections/types";
import vTooltip from "@/directives/vTooltip";
import { SELECT_MESSAGE, TOOLTIP_TEXT_RIGHTS } from "@/dictionary/tooltip";
import { Plus, Warning } from "@element-plus/icons-vue";

const { isMobile } = useAdaptive();
const router = useRouter();

interface Props {
  createButtonText: string;
  hasCreate?: boolean;
  service?: WorkingSectionsDefaultInterface["service"];
  shouldNavigateToDetailPage?: boolean;
  isAdmin?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  hasCreate: true,
});

const { selection, maxSelection } = useSelection();

const { show } = useSectionModal();

const createHandler = () => {
  if (props.shouldNavigateToDetailPage) {
    router.push(`${router.currentRoute.value.path.replace(/\/+$/, "")}/create`);
    return;
  }

  show("create");
};
</script>

<style lang="scss" module>
.head__buttons {
  .add {
    @media (max-width: 640px) {
      z-index: 4;
      position: fixed;
      right: 16px;
      bottom: 72px;
    }
  }
}

.selection-popover {
  font-size: 12px !important;
  text-align: left !important;
}

.selection-icon {
  cursor: pointer;
  top: 2px;
}

.bulk {
  font-size: 14px;
  line-height: 22px;
  margin: 10px 0 0;

  button {
    margin-left: 16px;
  }
}
</style>
