import { fetchRequest, getPaginationQueryString } from "@/api/core";
import {
  BatchDefaultPayload,
  CustomListRequestOptions,
  GetParametersArgumentsDeclaration,
} from "@/api/core/types";

export class Service {
  constructor(
    public endpoint: string = "",
    public extra: Record<string, any> = {
      list: {} as CustomListRequestOptions,
      item: {},
    },
    public extraOptions: Record<string, any> = {
      list: {} as CustomListRequestOptions,
      item: {},
      create: {},
      update: {},
      batch: {},
    }
  ) {}

  public async list(
    parameters: GetParametersArgumentsDeclaration,
    options?: CustomListRequestOptions
  ): Promise<any> {
    const queryParams = getPaginationQueryString({
      ...this.extra.list,
      ...parameters,
    });
    const requestOptions = {
      ...this.extraOptions.list,
      ...options,
    };
    const request = fetchRequest({
      point: `${this.endpoint}/?${queryParams}`,
      fetchMethod: "GET",
    });
    try {
      const res = await request;

      if (requestOptions?.getAllItems && res.meta.last_page > 1) {
        const promises: Promise<any>[] = [];
        for (let i = 1; i < res.meta.last_page; i++) {
          promises.push(
            this.list(
              { ...parameters, "page[number]": i + 1 },
              { ...requestOptions, getAllItems: false }
            )
          );
        }
        const promisesResult = await Promise.all(promises);
        const promisesResultMapData = promisesResult.map((item) => item.data);
        res.data = res.data.concat(...promisesResultMapData);
      }
      return res;
    } catch (e) {
      return e;
    }
  }

  public async item(id: number | string): Promise<any> {
    try {
      const queryParams = new URLSearchParams(this.extra.item).toString();
      return fetchRequest({
        point: `${this.endpoint}/${id}/?${queryParams}`,
        fetchMethod: "GET",
      });
    } catch (e) {
      return e;
    }
  }

  public async create(payload: Record<string, any>): Promise<any> {
    try {
      return fetchRequest({
        point: `${this.endpoint}`,
        payload,
        fetchMethod: "POST",
      });
    } catch (e) {
      return e;
    }
  }

  public async update(
    id: number | string,
    payload: Record<string, any>
  ): Promise<any> {
    try {
      return fetchRequest({
        point: `${this.endpoint}/${id}`,
        payload,
        fetchMethod: "PUT",
      });
    } catch (e) {
      return e;
    }
  }

  public async delete(id: number | string): Promise<any> {
    try {
      return fetchRequest({
        point: `${this.endpoint}/${id}`,
        fetchMethod: "DELETE",
      });
    } catch (e) {
      return e;
    }
  }

  public async copy(id: number, payload: Record<string, any>): Promise<any> {
    try {
      return fetchRequest({
        point: `${this.endpoint}/copy/${id}`,
        payload,
        fetchMethod: "POST",
      });
    } catch (e) {
      return e;
    }
  }

  public async restore(id: number | string): Promise<any> {
    try {
      return await fetchRequest({
        point: `${this.endpoint}/restore/${id}`,
        fetchMethod: "POST",
      });
    } catch (err) {
      return err;
    }
  }

  public async batch(payload: BatchDefaultPayload): Promise<any> {
    try {
      return fetchRequest({
        point: `${this.endpoint}/batch`,
        payload,
        fetchMethod: "POST",
      });
    } catch (err) {
      return err;
    }
  }
}
