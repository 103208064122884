import { IProviderAccountResponse } from "@/api/points/communication-api/provider-accounts/types";
import { ISystemResponse } from "@/api/points/communication-api/systems/types";
import { ITemplateResponse } from "@/api/points/communication-api/templates/types";

export const enum MessageStatus {
  created = "created",
  error = "error",
  success = "success",
  withError = "with_errors",
}

export interface MessagesAttrs {
  sender_info: number;
  from: string | null;
  to: string;
  cc: string | null;
  bcc: string | null;
  type: string;
  status: MessageStatus;
  template_id: number;
  status_text: string | null;
  system_id: number;
  provider_account_id: number;
  is_done: boolean;
  created_at: string;
  updated_at: string;
}

export interface MessageDataAttrs {
  body: number;
  subject: string | null;
  comment: string;
  system_token_id: number;
  created_at: string;
  updated_at: string;
}

export interface MessageStatusLogAttr {
  id: number;
  status: MessageStatus;
  status_text: string | null;
  created_at: string;
}

export interface IMessagesResponse {
  attributes: MessagesAttrs;
  id: number;
  type: string;
  relationships: {
    providerAccount: IProviderAccountResponse;
    system: ISystemResponse;
    templates: ITemplateResponse;
    messageData: {
      type: string;
      id: number;
      attributes: MessageDataAttrs;
    } | null;
  };
}
