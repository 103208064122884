import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/stocks`, {
  item: { include: "stockZones,businessUnit" },
});

export default service;
export const getStocks = service.list.bind(service);
export const getStockById = service.item.bind(service);
export const createStock = service.create.bind(service);
export const updateStock = service.update.bind(service);
export const deleteStock = service.delete.bind(service);
