import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/bodies`, {
  list: {
    sort: "name",
  },
});

export default service;
export const getBodies = service.list.bind(service);
export const getBodyById = service.item.bind(service);
export const createBody = service.create.bind(service);
export const updateBody = service.update.bind(service);
export const deleteBody = service.delete.bind(service);
