import { ref } from "vue";

const MIN_TABLE_WIDTH = 1308;
const SIDEBAR_WIDTH = 300;
const COMPARISONS_SIDEBAR_WIDTH = 294;
const CONTROL_COLUMN_WIDTH = 56;
const PADDING = 24;
const CALIBRATING_DELTA = 15;
const MIN_RESIZE_COL_WIDTH = 20;

const isColStrutWidth = ref(false);

export const setStretchColumn = (
  cols: Record<string, any>,
  isComparisons: boolean,
  isHideActions: boolean,
  path: string
): void => {
  let fullWidth = 0;
  let tableWidth =
    window.innerWidth -
    SIDEBAR_WIDTH -
    2 * CONTROL_COLUMN_WIDTH -
    5 * PADDING -
    CALIBRATING_DELTA;
  let stretch: Record<string, any> | null = null;
  let stretchIndex = 0;

  const userOptions = ref<Record<string, any>>(
    JSON.parse(localStorage.getItem("user_options") ?? "{}")
  );

  const colStrutWidth = userOptions.value[path]?.colStrutWidth?.width || 0;

  tableWidth > MIN_TABLE_WIDTH &&
    (tableWidth = MIN_TABLE_WIDTH - 2 * CONTROL_COLUMN_WIDTH - 2 * PADDING);

  isComparisons && (tableWidth -= COMPARISONS_SIDEBAR_WIDTH);
  isHideActions && (tableWidth += 2 * CONTROL_COLUMN_WIDTH);

  if (
    cols.id?.settings?.sort === 0 ||
    cols.active?.settings?.sort === 0 ||
    cols.is_active?.settings?.sort === 0
  ) {
    stretchIndex = 1;
  }
  if (
    stretchIndex &&
    (cols.id?.settings?.sort === 1 ||
      cols.active?.settings?.sort === 1 ||
      cols.is_active?.settings?.sort === 1)
  ) {
    stretchIndex = 2;
  }

  for (const column in cols) {
    if (!cols[column].settings || cols[column].settings?.visible) {
      fullWidth +=
        +cols[column].initialWidth ||
        +cols[column].width ||
        +cols[column].minWidth;
    }

    cols[column].width = cols[column].initialWidth || cols[column].width;
    const isColStretchable = !["id", "active", "is_active"].includes(column);

    if (
      !stretch &&
      cols[column].settings?.visible &&
      (cols[column].settings?.sort === stretchIndex ||
        (!cols[column].settings?.sort && isColStretchable))
    )
      stretch = cols[column];
  }

  if (tableWidth > fullWidth) {
    isColStrutWidth.value = true;
  }

  stretch &&
    (stretch.initialWidth =
      stretch.initialWidth || stretch.width || stretch.minWidth);

  if (stretch && tableWidth > fullWidth) {
    stretch.width =
      tableWidth -
      fullWidth +
      (+stretch.initialWidth || +stretch.width || +stretch.minWidth) -
      colStrutWidth;
    stretch.width =
      stretch.width > MIN_RESIZE_COL_WIDTH
        ? stretch.width
        : MIN_RESIZE_COL_WIDTH;
  }
};

export default isColStrutWidth;
