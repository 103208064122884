export const enum ColumnType {
  date = "date",
  boolean = "boolean",
  activity = "activity",
  picture = "picture",
  function = "function",
  time = "time",
  string = "string",
  text = "text",
  hidden = "hidden",
  editable = "editable",
  shortDate = "shortDate",
  dateWithoutTimezone = "dateWithoutTimezone",
  dateWithSeconds = "dateWithSeconds",
}

export const tableDateTypesFormatTo: ColumnType[] = [
  ColumnType.dateWithSeconds,
  ColumnType.date,
];

export const dateColumns = (sortable = false) => ({
  created_at: {
    ...(sortable ? { sortable: "custom" } : {}),
    width: 200,
    type: ColumnType.date,
    resize: false,
  },
  updated_at: {
    ...(sortable ? { sortable: "custom" } : {}),
    width: 200,
    type: ColumnType.date,
    resize: false,
  },
});
