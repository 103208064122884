import { BlockCase } from "@/helpers/landingBlockCase/types";

export const HR_LANDING_CASE: BlockCase[] = [
  {
    block_id: "intro_block_id",
    disableValue: true,
  },
  {
    block_id: "franchise_first_block_id",
    disableValue: true,
  },
  {
    block_id: "franchise_contact_block_id",
    disableValue: true,
  },
  {
    block_id: "hr_intro_block_id",
    disableValue: false,
  },
  {
    block_id: "hr_career_opportunity_block_id",
    disableValue: false,
    sort_id: "hr_career_opportunity_block_sort",
  },
  {
    block_id: "hr_company_info_block_id",
    disableValue: false,
    sort_id: "hr_company_info_block_sort",
  },
  {
    block_id: "hr_about_us_block_id",
    disableValue: false,
    sort_id: "hr_about_us_block_sort",
  },
  {
    block_id: "hr_about_block_id",
    disableValue: false,
    sort_id: "hr_about_block_sort",
  },
  {
    block_id: "hr_vacancy_block_id",
    disableValue: false,
    sort_id: "hr_vacancy_block_sort",
  },
  {
    block_id: "automotive_product_block_id",
    disableValue: true,
    sort_id: "automotive_product_block_sort",
  },
  {
    block_id: "brand_and_certificate_block_id",
    disableValue: true,
    sort_id: "brand_and_certificate_block_sort",
  },
  {
    block_id: "video_presentation_block_id",
    disableValue: true,
    sort_id: "video_presentation_block_sort",
  },
  {
    block_id: "team_block_id",
    disableValue: true,
    sort_id: "team_block_sort",
  },
  {
    block_id: "business_to_consumer_block_id",
    disableValue: true,
    sort_id: "business_to_consumer_block_sort",
  },
  {
    block_id: "hr_block_id",
    disableValue: true,
    sort_id: "hr_block_sort",
  },
  {
    block_id: "geography_block_id",
    disableValue: true,
    sort_id: "geography_block_sort",
  },
  {
    block_id: "feedback_block_id",
    disableValue: true,
    sort_id: "feedback_block_sort",
  },
  {
    block_id: "work_with_corporate_client_block_id",
    disableValue: true,
    sort_id: "work_with_corporate_client_block_sort",
  },
  {
    block_id: "wholesale_customer_block_id",
    disableValue: true,
    sort_id: "wholesale_customer_block_sort",
  },
  {
    block_id: "business_advantage_block_id",
    disableValue: true,
    sort_id: "business_advantage_block_sort",
  },
  {
    block_id: "analytic_block_id",
    disableValue: true,
    sort_id: "analytic_block_sort",
  },
  {
    block_id: "partnership_block_id",
    disableValue: true,
    sort_id: "partnership_block_sort",
  },
  {
    block_id: "contact_block_id",
    disableValue: false,
  },
  {
    block_id: "corporate_solution_and_product_block_id",
    disableValue: true,
    sort_id: "corporate_solution_and_product_block_sort",
  },
  {
    block_id: "business_offer_block_id",
    disableValue: true,
    sort_id: "business_offer_block_sort",
  },
  {
    block_id: "our_client_case_block_id",
    disableValue: true,
    sort_id: "our_client_case_block_sort",
  },
  {
    block_id: "cooperation_card_block_id",
    disableValue: true,
    sort_id: "cooperation_card_block_sort",
  },
  {
    block_id: "business_format_block_id",
    disableValue: true,
    sort_id: "business_format_block_sort",
  },
  {
    block_id: "support_block_id",
    disableValue: true,
    sort_id: "support_block_sort",
  },
  {
    block_id: "income_calculator_block_id",
    disableValue: true,
    sort_id: "income_calculator_block_sort",
  },
  {
    block_id: "our_network_block_id",
    disableValue: true,
    sort_id: "our_network_block_sort",
  },
];
