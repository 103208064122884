<template>
  <span>
    <el-tooltip
      :content="tooltip"
      :disabled="!enableTooltip || readonly || isInteractableInCodeViewMode"
      :show-after="350"
      effect="dark"
      placement="top"
    >
      <div :class="commandButtonClass" @click="onClick" @mousedown.prevent>
        <v-icon :name="icon" />
      </div>
    </el-tooltip>
  </span>
</template>

<script lang="ts" setup>
import { computed, inject, toRefs, unref } from "vue";
import { ElTooltip } from "element-plus";
import { noop } from "@/plugins/tiptap-editor/utils/shared";
import VIcon from "../Icon/Icon.vue";
import { TipTapExtensionId } from "@/plugins/tiptap-editor/constants";

interface Props {
  icon: string;
  extensionId: TipTapExtensionId | null;
  isCodeViewMode: boolean;
  isActive?: boolean;
  tooltip: string;
  enableTooltip: boolean;
  command: (...args: any) => any;
  readonly?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  command: noop,
  extensionId: null,
  isCodeViewMode: false,
});

const { extensionId, isCodeViewMode } = toRefs(props);

const commandButtonClass = computed(() => {
  return {
    "el-tiptap-editor__command-button": true,
    "el-tiptap-editor__command-button--active": props.isActive,
    "el-tiptap-editor__command-button--readonly":
      props.readonly || !unref(isInteractableInCodeViewMode),
  };
});

const extensionsAccessibleInCodeViewMode: TipTapExtensionId[] = inject(
  "extensionsAccessibleInCodeViewMode",
  []
);

const isInteractableInCodeViewMode = computed((): boolean => {
  if (!unref(isCodeViewMode)) {
    return true;
  }

  if (!extensionId.value) {
    return false;
  }

  return extensionsAccessibleInCodeViewMode.includes(extensionId.value);
});

const onClick = () => {
  if (!props.readonly || unref(isInteractableInCodeViewMode)) props.command();
};
</script>
