import {
  FormItemAttrsObjDeclaration,
  FormItemObjDeclaration,
  FormLayoutPropertyDeclaration,
  FormLayoutRowColsPropertyDeclaration,
  GeneratorDeclaration,
  PresetValues,
} from "@/plugins/form-generator-json-v2/types";
import { ComponentInternalInstance, Ref, ref, UnwrapRef, watch } from "vue";
import { ElForm } from "element-plus";

export const useFilterMode = (
  declaration: Ref<GeneratorDeclaration>,
  layoutDeclaration: Ref<FormLayoutPropertyDeclaration | undefined>,
  formCtx: Ref<UnwrapRef<ComponentInternalInstance | null>>,
  formRef: Ref<InstanceType<typeof ElForm> | undefined>,
  presetValues: Ref<PresetValues | null>,
  formModel: Record<string, unknown>
) => {
  const watchChanges = ref(false);
  let timer: ReturnType<typeof setTimeout> | undefined;

  const onChangeFilter = () => {
    const { onChangeFilter } = formCtx.value
      ?.attrs as FormItemAttrsObjDeclaration;
    if (typeof onChangeFilter === "function" && formRef.value) {
      onChangeFilter(formRef.value.$parent, true);
    }
  };

  if (
    declaration.value.form.name.indexOf("-filter") !== -1 &&
    declaration.value.form.name.indexOf("-filter-value-modal") === -1
  ) {
    const submitButtons: Array<FormLayoutRowColsPropertyDeclaration> = [];

    if (!layoutDeclaration.value?.rows) {
      layoutDeclaration.value = {
        rows: [
          {
            cols: [],
          },
        ],
        attrs: {
          gutter: 24,
        },
      };
      declaration.value.items.forEach((item: FormItemObjDeclaration) => {
        const element: FormLayoutRowColsPropertyDeclaration = {
          id: item.id.toString(),
          attrs: {},
        };
        const fullWidthItems = ["filter[is_common]"];
        if (
          item.id.toString().toLowerCase().indexOf("submit") !== -1 ||
          item.id === "filterClearButton"
        ) {
          submitButtons.unshift(element);
          return;
        } else if (!fullWidthItems.includes(item.id.toString())) {
          element.attrs = {
            ...element.attrs,
            lg: 12,
          };
        }
        layoutDeclaration.value?.rows &&
          layoutDeclaration.value?.rows[0]?.cols?.push(element);
      });
    }
    if (layoutDeclaration.value?.rows) {
      layoutDeclaration.value?.rows[0]?.cols?.push({
        id: submitButtons.map((item) => item.id as string),
        attrs: {
          class: "el-col--flat",
        },
      });
    }
  }
  if (declaration.value.form.name.indexOf("-filter") !== -1) {
    watch(
      () => presetValues.value,
      (value) => {
        watchChanges.value = !!(
          value &&
          (!Object.keys(value).length ||
            Object.keys(value).length < declaration.value.items.length)
        );
      },
      {
        immediate: true,
        deep: true,
        flush: "pre",
      }
    );

    watch(
      () => presetValues.value,
      () => {
        watchChanges.value = true;
      },
      {
        immediate: true,
        deep: true,
        flush: "post",
      }
    );

    watch(
      formModel,
      () => {
        if (!watchChanges.value) {
          return;
        }

        if (timer) {
          clearTimeout(timer);
        }

        timer = setTimeout(() => {
          onChangeFilter();
        }, 500);
      },
      {
        immediate: true,
      }
    );
  }
};
