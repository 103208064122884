const isNumeric = (str: any) => {
  if (typeof str !== "string") return false;
  return !isNaN(parseFloat(str));
};

export const mutateArrElemStrToNum = (arr: any[]): number[] => {
  const newArr: number[] = [];
  arr.forEach((item) => {
    if (isNumeric(item)) {
      newArr.push(+item);
    }
  });

  return newArr;
};
