<template>
  <command-button
    :command="openLink"
    :enable-tooltip="enableTooltip"
    :tooltip="t('editor.extensions.Link.open.tooltip')"
    icon="external-link"
  />
</template>

<script lang="ts" setup>
import { inject } from "vue";
import { Editor } from "@tiptap/vue-3";
import CommandButton from "../CommandButton.vue";

interface Props {
  editor: Editor;
  url: string;
}

const props = defineProps<Props>();

const t: any = inject("t");
const enableTooltip = inject("enableTooltip", true);

const openLink = () => {
  if (props.url) {
    const newTab = window.open();
    if (newTab) {
      newTab.opener = null;
      newTab.location.href = props.url;
    }
  }
};
</script>
