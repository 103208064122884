import { authRefresh } from "@/api/points/auth-api/auth";
import { getUserAvatar } from "@/api/points/auth-api/users";
import {
  ParsedAccessTokenDataDeclaration,
  UserProfile,
} from "@/api/core/types";
import type {
  RouteLocationNormalized,
  Router,
  RouteRecordRaw,
} from "vue-router";
import type { RouteAccess } from "@/router/types";
import { PUBLIC_SERVICE_POINT_NAME } from "@/api/points/files-api/service-config";
import { removeCookie, setCookie } from "@/helpers/cookie";
import {
  getUserOptionsByUser,
  updateUserOptions,
} from "@/api/points/auth-api/user_options";

export enum RefreshToken {
  REFRESH = "refresh",
  EXPIRE = "expire",
  NONE = "none",
}

type RouteRecord = RouteRecordRaw & {
  isCategory: boolean;
  hasChildren: boolean;
  children: Array<RouteRecord>;
};

export type User = {
  data: ParsedAccessTokenDataDeclaration;
  isLoggedIn: boolean;
  token: string;
  time: number;
  refreshPromise: any;
  router: Router | null;
  checkRefreshTimer: () => RefreshToken;
  refresh: () => Promise<boolean>;
  getToken: () => string;
  getBearerToken: () => string;
  getActualToken: () => Promise<string>;
  setToken: (access: string, refresh: string) => void;
  setEncodeToken: () => boolean;
  removeToken: () => void;
  getProfile: () => UserProfile;
  getAvatar: () => Promise<string>;
  getUserOptions: () => Promise<any>;
  updateUserOptions: (data: any, isNoMessage: boolean) => Promise<any>;
  removeUserOptions: () => Promise<any>;
  isAdmin: () => boolean;
  getAccess: () => any;
  hasAccess: (service: string, object: string, command: string) => boolean;
  checkAccess: (route: RouteLocationNormalized) => boolean;
  buildMenu: (router: Router) => Array<RouteRecord>;
  setRouter: (router: Router) => void;
  goLogin: () => void;
  checkUserToken: () => void;
};

export const REFRESH_TOKEN = "refresh_token";
export const ACCESS_TOKEN = "access_token";

const REFRESH_FROM = 30 * 1000;
const REFRESH_TO = 5 * 60 * 1000;

export const user: User = {
  data: {} as ParsedAccessTokenDataDeclaration,
  isLoggedIn: false,
  token: "",
  time: 0,
  refreshPromise: null,
  router: null,
  checkRefreshTimer() {
    const currentTime = this.time - Date.now();
    if (REFRESH_FROM <= currentTime && currentTime <= REFRESH_TO) {
      return RefreshToken.REFRESH;
    }
    if (currentTime < REFRESH_FROM) {
      return RefreshToken.EXPIRE;
    }
    return RefreshToken.NONE;
  },
  async checkUserToken() {
    const token = this.checkRefreshTimer();
    if (token === RefreshToken.EXPIRE || token === RefreshToken.REFRESH) {
      const message =
        token === RefreshToken.EXPIRE
          ? "Токен обновлен по истечению срока жизни"
          : "Токен обновлен по таймеру";

      const res = await this.refresh();
      if (res) {
        console.warn(message);
      } else {
        this.goLogin();
      }
    }
  },
  async refresh() {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    if (!refreshToken) {
      this.removeToken();
      this.refreshPromise = null;
      return false;
    }
    if (!this.refreshPromise) {
      this.refreshPromise = authRefresh(refreshToken).finally(() => {
        this.refreshPromise = null;
      });
    }
    try {
      const res = await this.refreshPromise;
      if (res?.errors?.status === 401) {
        this.removeToken();
        return false;
      }
      this.setToken(res.data.token, res.data.refresh);
      return true;
    } catch (e) {
      console.error(e);
    }
    return false;
  },
  getToken() {
    if (!this.token) {
      this.token = localStorage.getItem(ACCESS_TOKEN) || "";
      this.setEncodeToken();
    }
    return this.token;
  },
  getBearerToken() {
    const token = this.getToken();
    return `Bearer ${token}`;
  },
  async getActualToken() {
    const tokenStatus = this.checkRefreshTimer();

    if (tokenStatus === RefreshToken.EXPIRE) {
      await user.refresh();
    }

    return this.getToken();
  },
  setToken(access, refresh) {
    this.token = access;
    this.setEncodeToken();
    localStorage.setItem(REFRESH_TOKEN, refresh);
    localStorage.setItem(ACCESS_TOKEN, access);
    setCookie("authorization", access, { path: "/" });
  },
  setEncodeToken() {
    if (!this.token) {
      return false;
    }
    const base64Url = this.token.split(".")[1];
    if (!base64Url) {
      return false;
    }
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
        .join("")
    );
    this.data = JSON.parse(jsonPayload);
    this.time = this.data.exp * 1000;
    this.isLoggedIn = true;
    return true;
  },
  removeToken() {
    this.token = "";
    this.isLoggedIn = false;
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(ACCESS_TOKEN);
    removeCookie("authorization", { path: "/" });
  },
  getProfile() {
    return (this.data as any).profile || {};
  },
  isAdmin() {
    return this.getProfile().admin;
  },
  getAccess() {
    return this.getProfile().access || {};
  },
  hasAccess(service = "", object = "", command = "") {
    if (this.isAdmin()) {
      return true;
    }

    const access = this.getAccess();
    if (service && !access[service]) {
      return false;
    }

    return access[service].includes(`${object}_${command}`);
  },
  checkAccess(route: RouteLocationNormalized) {
    const routeAccess = (route?.meta?.access as RouteAccess) || {};
    if (this.isAdmin() || !Object.keys(routeAccess).length) {
      return true;
    }

    const { service = "", object = "", command = "" } = routeAccess;

    return this.hasAccess(service, object, command);
  },
  buildMenu(router) {
    const visibleRoutes = router.options.routes.filter((route) =>
      route.meta ? !route.meta.hiddenRoute : true
    );

    const checkMenu = (menu: any[]) =>
      menu.filter((route) => {
        if (route.children) {
          const checkParent = this.checkAccess(route);
          if (checkParent) {
            route.children = checkMenu(route.children);
            if (route.children.length) {
              return checkParent;
            }
          }
          return false;
        }
        return this.checkAccess(route);
      });

    return checkMenu(visibleRoutes);
  },
  async getAvatar(): Promise<string> {
    const avatars = await getUserAvatar();
    if (avatars?.length) {
      const { id, attributes } = avatars[0];
      return `${PUBLIC_SERVICE_POINT_NAME}/auth/${attributes.object_type}/stream/${id}?width=200`;
    }
    return "";
  },
  async getUserOptions() {
    if (localStorage.getItem("user_options")) {
      return JSON.parse(localStorage.getItem("user_options") ?? "{}");
    } else {
      const response = await getUserOptionsByUser();
      localStorage.setItem("user_options", response?.data.attributes.data);
      return response?.data.attributes.data;
    }
  },
  async updateUserOptions(data: Record<string, any>, isNoMessage: boolean) {
    const dataString = JSON.stringify({
      ...JSON.parse(localStorage.getItem("user_options") || "{}"),
      ...data,
    });
    await updateUserOptions({ data: dataString }, isNoMessage);
    localStorage.setItem("user_options", dataString);
    window.dispatchEvent(
      new CustomEvent("user-options-changed", {
        detail: {
          userOptions: JSON.parse(localStorage.getItem("user_options") ?? "{}"),
        },
      })
    );
  },
  async removeUserOptions() {
    localStorage.removeItem("user_options");
  },
  setRouter(router) {
    this.router = router;
  },
  goLogin() {
    if (this.router) {
      const route = this.router.currentRoute.value;
      this.router.replace({
        path: "/login",
        query: route.fullPath !== "/" ? { redirect: route.fullPath } : {},
      });
    }
  },
};
