import { computed, ComputedRef } from "vue";
import { TableColumnCtx } from "element-plus/es/components/table/src/table-column/defaults";
import { DataList } from "@/components/types";
import useSelection from "@/composables/hooks/useSelection";

export default (
  dataList: DataList,
  accessObject: string
): {
  tableCellClassName: ComputedRef<
    | ""
    | (({ row, column }: { row: any; column: TableColumnCtx<any> }) => string)
  >;
  getTableRowClass: ({ row }: { row: any }) => string;
} => {
  const { selection } = useSelection();

  const tableCellClassName = computed(() => {
    if (!dataList.value || !dataList.value?.length) {
      return "";
    }

    return ({ row, column }: { row: any; column: TableColumnCtx<any> }) => {
      const classes = [];
      if (row.deleted_at && column.property) {
        classes.push("transparent-lighter-filled");
      }
      if (column.level > 1) {
        classes.push("expand-col");
      }

      return classes.join(" ");
    };
  });

  const getTableRowClass = ({ row }: { row: any }) => {
    const classNames = [];
    const ids = selection.value.map((item: any) => item.id);
    if (ids.includes(row.id)) {
      classNames.push("el-table__row--active");
    }
    if (accessObject === "wheel_pairs" && !row.relationships?.rear) {
      classNames.push("no-expand");
    }
    return classNames.join(" ");
  };

  return {
    tableCellClassName,
    getTableRowClass,
  };
};
