import { RefreshToken, user } from "@/model/user";

export const uploadDescImageAPI = async ({
  serviceName,
  pointName,
  file,
}: {
  serviceName: string;
  pointName: string;
  file: File;
}): Promise<any> => {
  const body = new FormData();
  const tokenStatus = user.checkRefreshTimer();
  const headers: HeadersInit = {
    "X-Authorization": `Bearer ${user.getToken()}`,
  };

  if (tokenStatus === RefreshToken.EXPIRE) {
    await user.refresh();

    headers["X-Authorization"] = `Bearer ${user.getToken()}`;
  }

  body.append("file", file);

  try {
    return fetch(
      `/service/file-storage/api/v1/private/${serviceName}/${pointName}`,
      {
        body,
        method: "post",
        headers,
      }
    );
  } catch (err) {
    return err;
  }
};
