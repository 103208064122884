<template>
  <div :class="$style['top-edit']">
    <el-button
      v-if="!isMassEditing"
      v-tooltip="{
        disabled: hasUpdateAccess,
        text: TOOLTIP_TEXT_RIGHTS.replace('__ACTION__', 'редактирование'),
        handler: () => emit('toggleMassEditing'),
      }"
      :disabled="!hasUpdateAccess"
      plain
      type="default"
    >
      Редактировать
    </el-button>
    <el-button
      v-if="!hideDelete && !hasDestroyAccess"
      v-tooltip="{
        disabled: hasDestroyAccess,
        text: TOOLTIP_TEXT_RIGHTS.replace('__ACTION__', 'удаление'),
      }"
      :disabled="!hasDestroyAccess"
      plain
      type="danger"
    >
      Удалить
    </el-button>
    <el-popconfirm
      v-if="!isMassEditing && hasDestroyAccess && !hideDelete"
      :icon="InfoFilled"
      cancel-button-text="Нет"
      confirm-button-text="Да"
      icon-color="red"
      title="Вы уверены, что хотите удалить?"
      width="auto"
      @confirm="clickMassiveMenuHandler({ type: Command.REMOVE })"
    >
      <template #reference>
        <el-button plain type="danger">Удалить</el-button>
      </template>
    </el-popconfirm>
    <massive-drop
      :click-massive-menu-handler="clickMassiveMenuHandler"
      :create-form="createForm"
      :is-create-in="isCreateIn"
      :is-mass-editing="isMassEditing"
      :is-wizard="isWizard"
    />
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import { InfoFilled } from "@element-plus/icons-vue";
import vTooltip from "@/directives/vTooltip";
import { TOOLTIP_TEXT_RIGHTS } from "@/dictionary/tooltip";
import useAccessRights from "@/composables/hooks/useAccessRights";
import { Command } from "@/components/working-sections/default/components/actions/constants";
import MassiveDrop from "@/components/working-sections/default/components/actions/MassiveDrop.vue";
import {
  BaseActionsProps,
  ICommand,
} from "@/components/working-sections/default/components/actions/types";

interface Props extends BaseActionsProps {
  clickMassiveMenuHandler: (command: ICommand) => Promise<void>;
}

defineProps<Props>();

const router = useRouter();
const emit = defineEmits(["toggleMassEditing"]);

const { hasUpdateAccess, hasDestroyAccess } = useAccessRights(router);
</script>

<style lang="scss" module>
.top-edit {
  display: flex;

  & > *:not(:first-child) {
    margin: 0 0 0 10px;
  }
}
</style>
