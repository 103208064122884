import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/analytic_blocks`, {
  item: { include: "analyticCards" },
});

export default service;
export const getAnalyticBlocks = service.list.bind(service);
export const getAnalyticBlockById = service.item.bind(service);
export const createAnalyticBlock = service.create.bind(service);
export const updateAnalyticBlock = service.update.bind(service);
export const deleteAnalyticBlock = service.delete.bind(service);
