<template>
  <el-dialog
    :class="$style['delete-modal']"
    :model-value="isOpened"
    align-center
    width="400"
    @close="closeModal"
  >
    <template #header>Удаление элемента</template>
    <div :class="$style['delete-modal__wrapper']">
      Вы уверены, что хотите удалить?
    </div>
    <template #footer>
      <div :class="$style['delete-modal__footer']">
        <el-button @click="closeModal"> Нет, я передумал</el-button>
        <el-button type="primary" @click="deleteHandler"> Удалить</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import { toRefs } from "vue";
import { DeleteData } from "@/composables/hooks/modals/useDeleteModal";

const props = defineProps<{
  isOpened: boolean;
  deleteData: DeleteData;
  massDeleteRows: (items: string[] | number[], api: any) => Promise<void>;
}>();

const { isOpened, deleteData } = toRefs(props);

const emit = defineEmits(["closeDeleteModal"]);

const closeModal = () => {
  emit("closeDeleteModal");
};

const deleteHandler = () => {
  props.massDeleteRows(deleteData.value.items);
  emit("closeDeleteModal");
};
</script>

<style lang="scss" module>
.delete-modal {
  :global {
    .el-dialog__body {
      padding: 24px;
    }
  }

  &__footer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    :global {
      .el-button {
        width: calc((100% - 12px) / 2);
      }
    }
  }
}
</style>
