import LayoutWorkingSection from "@/layout/LayoutWorkingSection.vue";
import { Files } from "@element-plus/icons-vue";
import { catalogVehiclesWizardRoute } from "@/router/catalog-vehicles-wizard/index";
import {
  BODIES,
  BRANDS,
  CATALOG_CAR,
  CATEGORIES,
  COUNTRIES,
  GENERATIONS,
  MODELS,
  MODIFICATIONS,
  REGIONS,
  TYPES,
  UPDATE,
  WHEELS,
} from "@/dictionary/header";
import WorkingSectionView from "@/views/WorkingSectionView.vue";
import { RouteAccess } from "../types";

const access: RouteAccess = {
  service: "vehicles",
  object: "",
  command: "index",
};

export const catalogVehiclesRoutesList = [
  {
    path: "/catalog-vehicles",
    name: "catalog-vehicles",
    component: LayoutWorkingSection,
    meta: {
      title: CATALOG_CAR,
      icon: Files,
      clickable: false,
      hasBlock: true,
    },
    children: [
      catalogVehiclesWizardRoute,
      {
        path: "/catalog-vehicles/comparisons/:category?/:type?",
        name: "comparisons",
        params: {
          category: "manual",
          type: "all",
        },
        component: (): Promise<typeof import("*.vue")> =>
          import(
            /* webpackChunkName: "Comparisons" */ "@/views/catalog-vehicles/Comparisons.vue"
          ),
        meta: {
          title: UPDATE,
          access: {
            ...access,
            object: "comparisons",
          },
        },
      },
      {
        path: "/catalog-vehicles/types",
        name: "types",
        component: WorkingSectionView,
        meta: {
          title: TYPES,
          access: {
            ...access,
            object: "vehicle_types",
          },
        },
      },
      {
        path: "/catalog-vehicles/categories",
        name: "categories",
        component: WorkingSectionView,
        meta: {
          title: CATEGORIES,
          access: {
            ...access,
            object: "vehicle_categories",
          },
        },
      },
      {
        path: "/catalog-vehicles/brands",
        name: "brands",
        component: WorkingSectionView,
        meta: {
          title: BRANDS,
          access: {
            ...access,
            object: "brands",
          },
        },
      },
      {
        path: "/catalog-vehicles/models",
        name: "models",
        component: WorkingSectionView,
        meta: {
          title: MODELS,
          access: {
            ...access,
            object: "models",
          },
        },
      },
      {
        path: "/catalog-vehicles/generations",
        name: "generations",
        component: WorkingSectionView,
        meta: {
          title: GENERATIONS,
          access: {
            ...access,
            object: "generations",
          },
        },
      },
      {
        path: "/catalog-vehicles/modifications",
        name: "modifications",
        component: WorkingSectionView,
        meta: {
          title: MODIFICATIONS,
          access: {
            ...access,
            object: "modifications",
          },
        },
      },
      {
        path: "/catalog-vehicles/bodies",
        name: "bodies",
        component: WorkingSectionView,
        meta: {
          title: BODIES,
          access: {
            ...access,
            object: "bodies",
          },
        },
      },
      {
        path: "/catalog-vehicles/wheels",
        name: "wheels",
        component: WorkingSectionView,
        meta: {
          title: WHEELS,
          access: {
            ...access,
            object: "wheel_pairs",
          },
        },
      },
      {
        path: "/catalog-vehicles/regions",
        name: "regions",
        component: WorkingSectionView,
        meta: {
          title: REGIONS,
          access: {
            ...access,
            object: "regions",
          },
        },
      },
      {
        path: "/catalog-vehicles/countries",
        name: "countries",
        component: WorkingSectionView,
        meta: {
          title: COUNTRIES,
          access: {
            ...access,
            object: "countries",
          },
        },
      },
    ],
  },
];
