import { SERVICE_POINT_NAME } from "@/api/points/dictionaries-api/service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/counteragent_activities`, {
  list: {
    sort: "name",
  },
  item: {},
});

export default service;
export const getCounteragentActivities = service.list.bind(service);
export const getCounteragentActivitiesById = service.item.bind(service);
export const createCounteragentActivities = service.create.bind(service);
export const updateCounteragentActivities = service.update.bind(service);
export const deleteCounteragentActivities = service.delete.bind(service);
