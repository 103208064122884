<template>
  <KdButton
    v-if="declaration?.useKdUi"
    :ref="declaration.id"
    v-model="model"
    :="declaration.attrs"
    :class="$style['base-button']"
  >
    {{ declaration.text }}
  </KdButton>
  <el-button
    v-else
    :ref="declaration.id"
    v-model="model"
    :="declaration.attrs"
    :class="$style['base-button']"
  >
    {{ declaration.text }}
  </el-button>
</template>
<script lang="ts" setup>
import { computed, toRefs } from "vue";
import { FormModel } from "@/plugins/form-generator-json-v2/types";
import { KdButton } from "kd-ui-lib";

type Props = {
  formModel?: FormModel | null;
  declaration: {
    name?: string;
    id?: string;
    model?: string;
    element?: any;
    text?: string;
    type?: string;
    attrs?: Record<string, any>;
    useKdUi?: boolean;
  };
};

const props = withDefaults(defineProps<Props>(), {
  formModel: null,
});
const { declaration, formModel } = toRefs(props);
const model = computed({
  get: () =>
    formModel.value && declaration.value.model
      ? formModel.value[declaration.value.model]
      : {},
  set: (val) => {
    if (formModel.value && declaration.value.model)
      formModel.value[declaration.value.model] = val;
  },
});
</script>
<style lang="scss" module>
.base-button:global.hidden {
  display: none;
}
</style>
