<template>
  <div ref="el" :class="$style['drag']" :style="style">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  CSSProperties,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";

interface Props {
  height: number;
  width?: number;
}

const props = defineProps<Props>();

const el = ref<null | { style: any }>(null);

const divState = reactive({
  init: false,
  x: 0,
  y: props.height,
  width: props.width,
  height: props.height,
  isDragging: false,
  dragStartY: props.height,
});

function resize(e: any) {
  if (el.value?.style?.top) {
    el.value.style.top = e.target.innerHeight - divState.height + "px";
  }
}

window.addEventListener("resize", resize);

onUnmounted(() => {
  window.removeEventListener("resize", resize);
});

const makeDraggable = (element: any, divState: any) => {
  const style = computed<CSSProperties>(() => {
    if (divState.init) {
      return {
        position: "absolute",
        left: divState.x + "px",
        top: element.value.parentElement?.clientHeight - divState.height + "px",
        width: divState.width ? divState.width + "px" : "100%",
        height: divState.height + "px",
        cursor: "move",
      };
    }

    return {};
  });

  const onMouseDown = (e: any) => {
    if (e.target !== element._value) {
      return;
    }

    const { clientY } = e;
    divState.dragStartY = clientY;
    divState.isDragging = true;

    document.addEventListener("mouseup", onMouseUp);
    document.addEventListener("mousemove", onMouseMove);
  };

  const onMouseMove = (e: any) => {
    const { clientY } = e;
    if (element.value.parentElement?.clientHeight - clientY < 70) {
      return;
    }
    divState.y = clientY - divState.dragStartY;
    divState.height = element.value.parentElement?.clientHeight - clientY;
  };

  const onMouseUp = () => {
    divState.isDragging = false;
    divState.dragStartY = 0;
    document.removeEventListener("mouseup", onMouseUp);
    document.removeEventListener("mousemove", onMouseMove);
  };

  watch(element, (element) => {
    if (!(element instanceof HTMLElement)) return;
    const rect = element?.getBoundingClientRect();

    divState.init = true;
    divState.x = Math.round(rect.x || divState.x);
    divState.y = Math.round(rect.y || divState.y);
    divState.width = Math.floor(rect.width || divState.width);
    divState.height = Math.floor(rect.height || divState.height);

    element.addEventListener("mousedown", onMouseDown);
  });

  return {
    style,
  };
};

const { style } = makeDraggable(el, divState);
</script>

<style lang="scss" module>
.drag {
  height: 30%;
  margin: 0;
  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  z-index: 1999;
}
</style>
