import { SERVICE_POINT_NAME } from "@/api/points/dictionaries-api/service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/counteragents`, {
  list: {
    sort: "name",
    include: "activity,headCounteragent,region",
  },
  item: {
    include: "activity,headCounteragent,region",
  },
});

export default service;
export const getCounteragents = service.list.bind(service);
export const getCounteragentsById = service.item.bind(service);
export const createCounteragents = service.create.bind(service);
export const updateCounteragents = service.update.bind(service);
export const deleteCounteragents = service.delete.bind(service);
