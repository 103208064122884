import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/hr_intro_blocks`, {
  item: { include: "hrCompanyValues" },
});

export default service;
export const getHRIntroBlocks = service.list.bind(service);
export const getHRIntroBlockById = service.item.bind(service);
export const createHRIntroBlock = service.create.bind(service);
export const updateHRIntroBlock = service.update.bind(service);
export const deleteHRIntroBlock = service.delete.bind(service);
