export const FORM = {
  input_email: "Введите email",
  input_email_invalid: "Некорректный формат e-mail",
  input_required: "Поле обязательно для заполнения",
  input_length_min: "Минимум 8 символов",
  input_length_min_32: "Минимум 32 символа",
  input_password: "Введите пароль",
  input_password_repeat: "Повторите пароль",
  input_password_confirm_error: "Второй пароль не совпадает с первым",
  input_value_number: "Значение должно быть числом",
  input_value_interval: "Допустимый диапазон значения от 60 до 175",
  input_value_year: "Значение должно быть числом в диапазоне от 1900 до 2100",
  input_value_phone: "Значение должно быть числом от 7 до 15 символов",
  input_value_nipple_phone: "Неверный формат",
  input_value_thread: "Значение должно быть в формате 14x1.5",
  input_value_max_191: "Количество символов не должно превышать 191",
  input_value_diameter: "Неверный формат",
};
export const DIGIT_CODE = {
  enter_code_confirm: "Введите код подтверждения",
  enter_code_confirm_length_min: "Код должен быть 6-значным",
};
export const NO_DATA_TEXT =
  "Нет подходящих данных или нет вариантов для типов ТС";
