import localeInterface from "element-plus/lib/locale/lang/ru.d";

export const customize = (config: typeof localeInterface): any => {
  if (!config) {
    return;
  }

  const conf = { ...config };
  conf.el.pagination.goto = "перейти на";
  conf.el.pagination.pageClassifier = "стр";
  conf.el.pagination.pagesize = " / стр";

  return conf;
};
