<template>
  <div :class="['common-layout', $style['common-layout']]">
    <el-container>
      <the-side-bar />
      <el-container>
        <el-main class="custom-scroll" @scroll="headerTableFixed">
          <the-mobile-bottom-menu v-if="isMobile" />
          <the-header v-else />
          <div class="main-background">
            <div class="limiter">
              <slot />
            </div>
          </div>
          <the-footer v-if="!isMobile" />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script lang="ts" setup>
import TheSideBar from "@/components/the/TheSideBar.vue";
import TheHeader from "@/components/the/TheHeader.vue";
import TheFooter from "@/components/the/TheFooter.vue";
import TheMobileBottomMenu from "@/components/the/TheMobileBottomMenu.vue";
import useAdaptive from "@/composables/hooks/useAdaptive";
import { onMounted, provide, ref } from "vue";
import { user } from "@/model/user";

const avatar = ref<string>("");
provide("avatar", avatar);

const { isMobile } = useAdaptive();

const headerTableFixed = () => {
  const tbody = document.querySelector(".el-table__body-wrapper");
  const tbodyTop = tbody?.getBoundingClientRect().top;
  const headerHeight = document.querySelector("header")?.clientHeight || 0;
  const headerTable = document.querySelector(".el-table__header-wrapper");
  const fixedColumnTable = document.querySelector(".el-table__fixed-right");

  const tableFit = document.querySelector(".el-table--fit");
  if (tbodyTop && tbodyTop - headerHeight >= 0) {
    headerTable?.classList.remove("fixed-class");
    fixedColumnTable?.classList.remove("change-fixed");
    tableFit?.classList.add("table-size");
    return;
  }
  const main = document.querySelector(".main");
  const top = headerTable?.getBoundingClientRect().top;
  const topFixed = main?.getBoundingClientRect().top;
  if (top && topFixed && top === headerHeight && topFixed < 0) return;
  if (top && top - headerHeight <= 0) {
    headerTable?.classList.add("fixed-class");
    fixedColumnTable?.classList.add("change-fixed");
    const leftSpace = tbody?.getBoundingClientRect().left;
    const rightSpace =
      window.innerWidth - (tbody?.getBoundingClientRect().right || 0);
    document.body.style.setProperty("--left-space", `${leftSpace}px`);
    document.body.style.setProperty("--right-space", `${rightSpace}px`);
    document.body.style.setProperty("--top-space", `${headerHeight}px`);
  }
};
onMounted(async () => {
  avatar.value = await user.getAvatar();
});
</script>

<style lang="scss" module>
:root {
  --left-space: 0;
  --right-space: 0;
  --top-space: 0;
  --height-table: 0;
}

.common-layout {
  height: 100%;

  @media (max-width: 640px) {
    height: calc(100vh - 52px);
  }

  :global {
    .table-size {
      .el-table__fixed-right-patch {
        display: none;
      }

      .el-table__fixed-right {
        right: 0 !important;
      }
    }

    .fixed-class {
      position: fixed;
      top: var(--top-space);
      right: var(--right-space);
      left: var(--left-space);
      z-index: 100;
      width: auto;
      background-color: inherit;

      .cell {
        visibility: visible;
      }

      .is-right {
        text-align: center;
      }

      .el-table__cell--actions {
        text-align: center;
      }
    }

    .change-fixed {
      right: 0 !important;

      .el-table__fixed-body-wrapper {
        top: 0 !important;
      }
    }

    .el-main {
      align-items: center;
      display: flex;
      flex-direction: column;
      line-height: 20px;
      height: 100%;
      overflow: auto;
      padding: {
        top: 0;
        right: 24px;
        bottom: 0;
        left: 0;
      }

      @media (max-width: 640px) {
        padding-right: 0;
      }
    }

    .el-container {
      height: 100%;
    }

    .el-header {
      display: flex;
      align-items: center;
      height: 70px;
    }

    .el-footer {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      vertical-align: middle;
    }

    .el-aside {
      &.collapse {
        width: 80px;
        transition: width 0.2s ease-out 0.35s;
      }
    }

    .el-footer {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
      vertical-align: middle;
    }

    .main-background {
      align-items: center;
      background-color: var(--el-fill-color-light);
      border-radius: 24px;
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
      padding: 14px 24px 14px 24px;
      width: 100%;

      @media (max-width: 640px) {
        border-radius: 0;
        padding: 0;
      }
    }

    .limiter {
      max-width: 1308px;
      width: 100%;
    }
  }
}
</style>
