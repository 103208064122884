import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/properties`, {
  list: { flat: true },
  item: { include: "logo,classifiers" },
});

export default service;
export const getProperties = service.list.bind(service);
export const getProperty = service.item.bind(service);
export const updateProperty = service.update.bind(service);
