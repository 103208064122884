import { ref, Ref } from "vue";

const isMobileMenuVisible: Ref<boolean> = ref(false);

const setMobileMenuVisibility = (val: boolean): void => {
  isMobileMenuVisible.value = val;
};

const toggleMobileMenuVisibility = (): void => {
  isMobileMenuVisible.value = !isMobileMenuVisible.value;
};

interface Result {
  isMobileMenuVisible: Ref<boolean>;
  setMobileMenuVisibility: (val: boolean) => void;
  toggleMobileMenuVisibility: () => void;
}

export default (): Result => ({
  isMobileMenuVisible,
  setMobileMenuVisibility,
  toggleMobileMenuVisibility,
});
