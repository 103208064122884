import { readonly, ref, Ref, watch } from "vue";

const MENU_COLLAPSE_KEY = "menuCollapse";
const menuCollapse = JSON.parse(
  localStorage.getItem(MENU_COLLAPSE_KEY) || "false"
);
const isMenuCollapsed = ref(menuCollapse);

watch(isMenuCollapsed, (value) => {
  localStorage.setItem(MENU_COLLAPSE_KEY, JSON.stringify(value));
});

const toggleCollapse = (): void => {
  isMenuCollapsed.value = !isMenuCollapsed.value;
};

interface Result {
  isMenuCollapsed: Readonly<Ref>;
  toggleCollapse: () => void;
}

export default (initValue?: boolean): Result => {
  if (initValue) {
    isMenuCollapsed.value = initValue;
  }

  return {
    isMenuCollapsed: readonly(isMenuCollapsed),
    toggleCollapse,
  };
};
