<template>
  <div
    :class="[
      $style['filter-container'],
      {
        [$style['filter-container_full-width']]:
          hasFilterActive || hasFilterActive,
      },
    ]"
  >
    <div :class="$style['filter-container__wrapper']">
      <el-button
        v-if="isMobile && !isTableSearchShown"
        :icon="Search"
        @click="setTableSearchVisibility(true)"
      />
      <div v-else :class="$style['input-with-select']">
        <el-input
          v-model="filterSearch"
          :class="$style['search-input']"
          clearable
          placeholder="Поиск"
          @click="showClick"
          @keyup.enter="handleEnter"
        />
        <el-dropdown
          ref="dropdown"
          :class="{ activeFocusSearch: isFocusSearch }"
          trigger="contextmenu"
          @command="onCommand"
          @visible-change="handleVisible"
        >
          <span :class="$style['el-dropdown-link']"> </span>
          <template #dropdown>
            <el-radio-group
              v-model="filterSelect"
              :class="$style['search-radio-dropdown']"
            >
              <el-radio
                v-for="item in filterSelectOptions"
                :key="item.model"
                :label="item"
                :value="item"
                border
                size="small"
                >{{ item.label }}
              </el-radio>
            </el-radio-group>
            <el-dropdown-menu :class="$style['search-dropdown-menu']">
              <div v-for="(item, index) in filteredList" :key="index">
                <el-dropdown-item :command="item" :label="item" :value="item">
                  <span>{{ item.value }}</span>
                  <el-button
                    v-if="item.isDelete"
                    :icon="Close"
                    circle
                    @click.stop.prevent="deleteItemSearch(item)"
                  />
                </el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-button
          :class="$style['search-button-dropdown']"
          :icon="Search"
          size="large"
          @click="handleEnter"
        />
      </div>
      <slot name="start"></slot>
      <el-badge
        v-if="hasFilterForm"
        :hidden="filterButtonDisabled || !hasFilterData || !filtersCount"
        :value="filtersCount"
      >
        <el-button
          :disabled="filterButtonDisabled"
          title="Фильтр"
          @click="emit('filterToggle')"
        >
          <el-icon>
            <Filter />
          </el-icon>
        </el-button>
      </el-badge>

      <el-button
        v-if="hasExportButton"
        :class="$style['filter-container__export-btn']"
        size="small"
        title="Экспорт по текущему URL"
        @click="handleExportClick"
      >
        <el-icon>
          <Upload />
        </el-icon>
      </el-button>
    </div>
    <el-select
      v-if="hasFilterActive"
      v-model="filterActive"
      :class="$style['filter-select']"
      clearable
      placeholder="Активность"
      @change="emit('filterChange', changeData)"
    >
      <el-option :value="1" label="Активно" />
      <el-option :value="0" label="Неактивно" />
    </el-select>
    <el-select
      v-if="hasFilterAttributes"
      v-model="filterAttribute"
      :class="$style['filter-select']"
      placeholder="Атрибуты"
      @change="emit('filterChange', changeData)"
    >
      <el-option
        v-for="(attr, index) in attributesList"
        :key="index"
        :disabled="attr.disabled"
        :label="attr.label"
        :value="attr.value"
      />
    </el-select>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  ComputedRef,
  onMounted,
  onUpdated,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import { FilterOption } from "@/plugins/form-generator-json-v2/types";
import { Close, Filter, Search, Upload } from "@element-plus/icons-vue";
import { useRoute, useRouter } from "vue-router";
import { EXPORT_TYPES } from "@/helpers/pimExport";
import { attributesList } from "@/helpers/attributesList";
import { DataList } from "@/components/types";
import { debounce } from "@/helpers/functions";
import useSearchHistory, {
  SearchOption,
} from "@/composables/hooks/useSearchHistory";
import useAdaptive from "@/composables/hooks/useAdaptive";
import useTableSearch from "@/composables/hooks/useTableSearch";
import { ServiceName } from "@/constants";

interface Props {
  dataListFilter: DataList;
  filterButtonText?: string;
  hasFilterForm?: boolean;
  hasExportButton?: boolean;
  filterData: Record<string, any>;
  filterOptions: FilterOption[];
  hasFilterActive?: boolean;
  hasFilterAttributes?: boolean;
  excludeDefaultFiltersOptions?: boolean;
  filterButtonDisabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  filterButtonText: "Фильтр",
  hasFilterForm: false,
  hasFilterActive: false,
  hasFilterAttributes: false,
  excludeDefaultFiltersOptions: false,
});

const emit = defineEmits([
  "filterToggle",
  "filterChange",
  "filterClean",
  "filterDataList",
]);

const dropdown = ref();
const isFocusSearch = ref(false);

const { isMobile } = useAdaptive();
const { isTableSearchShown, setTableSearchVisibility } = useTableSearch();

const _router = useRouter();
const route = useRoute();
const path = computed(() => route.path.replaceAll(/\/[0-9]+/g, ""));
const isWizard = _router.currentRoute.value.path.includes(ServiceName.wizard);

const handleExportClick = () => {
  const currentUrl = _router.currentRoute.value.fullPath;
  const routeData = _router.resolve({
    name: "pim-tools",
    query: {
      type: EXPORT_TYPES.BY_URL,
      url: encodeURI(currentUrl),
    },
  });
  _router.push(routeData.href);
};

const { filterData } = toRefs(props);
const dataListFilter = reactive(props.dataListFilter);
const filterSearch: number | string = ref("");
const filterActive = ref();
const filterAttribute = ref();

onMounted(() => {
  filterAttribute.value = filterData.value["filter[attributes]"];
});

onUpdated(() => {
  filterActive.value = filterData.value["filter[active]"];
});

const hasFilterData: ComputedRef<boolean> = computed(() => {
  const { ...result } = filterData.value;
  delete result["filter[attributes]"];
  return !!(result && Object.keys(result).length);
});

const defaultFilterOptions = [
  { model: "filter[name]", label: "по наименованию" },
  { model: "filter[id]", label: "по ID" },
];

const formattedFilterOptions: ComputedRef<FilterOption[]> = computed(() => {
  const filters = !props.excludeDefaultFiltersOptions
    ? [...defaultFilterOptions, ...props.filterOptions]
    : props.filterOptions;

  return filters
    .reduceRight((unique: FilterOption[], item: FilterOption) => {
      const exists = unique.find(
        (uniqueItem: FilterOption) => uniqueItem.model === item.model
      );

      if (!exists) {
        unique.push(item);
      }

      return unique;
    }, [])
    .filter((item) => !item.disabled)
    .reverse();
});

const filterSelect = ref<FilterOption>(formattedFilterOptions.value[0] || {});
const filterSelectOptions = ref<FilterOption[]>(formattedFilterOptions.value);

const filtersCount = computed(() => {
  let count = 0;
  const { query } = _router.currentRoute.value;

  for (const key in query) {
    if (
      !key.includes("filter[") ||
      (key.includes("filter[attributes]") && filterData.value[key]) ||
      (Array.isArray(query[key]) && !query[key]?.length) ||
      !query[key]
    ) {
      continue;
    }

    count++;
  }

  return count;
});

const dataFilter = () => {
  const data: Record<string, any> = {};

  if (typeof filterSearch?.value === "string" && filterSearch.value.trim()) {
    data[filterSelect.value.model] = filterSearch.value.trim();
  }
  if (typeof filterActive.value === "number") {
    data["filter[active]"] = filterActive.value;
  }
  if (typeof filterAttribute.value === "string") {
    data["filter[attributes]"] = filterAttribute.value;
  }
  if (
    _router.currentRoute.value?.meta?.filter &&
    _router.currentRoute.value?.params?.id
  ) {
    data[String(_router.currentRoute.value.meta.filter)] =
      _router.currentRoute.value.params.id;
  }

  return data;
};

watch(filterData, (value) => {
  const keys = Object.keys(value);

  if (!keys.length || (isWizard && keys.length <= 1)) {
    filterSearch.value = "";
    return;
  }

  keys.forEach((key) => {
    if (key === filterSelect.value.model) {
      filterSearch.value = value[key];
    }
  });
});

watch(dataListFilter, (data) => {
  const modelName = filterSelect?.value.model.split("[")[1].split("]")[0];

  if (data.value?.length > 0) {
    let label: string | number = "";

    data.value.forEach((item) => {
      switch (modelName) {
        case "id":
          label = item.id;
          break;
        case "name":
          label = item.attributes.name;
          break;
        case "body_name":
          label = item.relationships?.body.attributes.name;
          break;
        case "ws_body_name":
          label = item.relationships?.wsBody.attributes.name;
          break;
        case "domain":
          label = item.attributes.domain;
          break;
        case "title":
          label = item.attributes.title;
          break;
        case "email":
          label = item.relationships?.contacts?.find(
            (contact: any) => contact.attributes.type === "email"
          )?.attributes?.data;
          break;
      }

      if (label) {
        const itemFilter: SearchOption = {
          model: filterSelect.value.model,
          value: label,
        };

        listData.value.push(itemFilter);
      }
    });
  }
});

const handleVisible = (visible: boolean) => {
  isFocusSearch.value = visible;
};

const showClick = () => {
  if (!dropdown.value) return;

  dropdown.value.handleOpen();
  updateValueFilter();
  updateDataList();
};

const handleEnter = () => {
  if (!filterSearch.value) return;

  emit("filterChange", changeData.value);
  dropdown.value.handleClose();
};

const onCommand = (command: SearchOption) => {
  filterSearch.value = filterData.value[filterSelect.value.model] || "";
  filterSearch.value = command.value;

  const dataFilterIsWizard = isWizard
    ? {
        [command.model]: command.value,
        "filter[attributes]": "wheels",
      }
    : { [command.model]: command.value };

  emit("filterChange", dataFilterIsWizard);
};

const {
  getListModelSearchHistory,
  addItemSearchHistory,
  deleteItemSearch,
  updateValueFilter,
  isFilterClean,
  listSearchHistoryUserOption,
  listDropdownFilter,
  listData,
  listModelSearchHistoryUserOption,
} = useSearchHistory(path.value, filterSelect, filterSearch, _router);

watch(isFilterClean, () => {
  emit("filterClean");
});

const changeData: ComputedRef<Record<string, any>> = computed(() => {
  const isAddSearchValue = listSearchHistoryUserOption.value.some(
    (item) =>
      item.model === filterSelect.value.model &&
      item.value === filterSearch.value
  );

  if (String(filterSearch.value).trim().length && !isAddSearchValue) {
    addItemSearchHistory();
  }

  return dataFilter();
});

const getDataList = async () => {
  if (filterSearch.value.length) {
    emit("filterDataList", dataFilter());
  }
};

const updateDataList = (data?: any) => {
  listData.value = [];
  delayGetDataList(data);
};

const delayGetDataList = debounce(getDataList, 1000);

watch(filterSearch, (value) => {
  updateValueFilter();

  updateDataList(
    listModelSearchHistoryUserOption.value.length < 5 && value.length > 0
      ? value
      : ""
  );
});

watch(filterSelect, (value) => {
  getListModelSearchHistory();

  listDropdownFilter.value = listModelSearchHistoryUserOption.value.filter(
    (item) => item.model === value.model
  );

  if (filterSearch.value.length > 0) {
    updateValueFilter();
  } else {
    listDropdownFilter.value = listModelSearchHistoryUserOption.value;
  }

  updateDataList();
});

const filteredList: ComputedRef = computed(() => {
  const searchString = String(filterSearch.value)?.toLowerCase() || "";
  const filteredList = listDropdownFilter.value.filter((item: any) => {
    return String(item.value).toLowerCase().includes(searchString);
  });

  let mergedArray = [...filteredList, ...listData.value];

  if (!mergedArray.length) {
    mergedArray = listDropdownFilter.value;
  }

  const resultsKeys: string[] = [];

  return mergedArray
    .reduce((result, el) => {
      const value = String(el.value).toLowerCase();

      if (!resultsKeys.includes(value)) {
        resultsKeys.push(value);
        result.push(el);
      }

      return result;
    }, [])
    .slice(0, 5);
});
</script>

<style lang="scss" module>
.filter-container {
  display: flex;
  gap: 10px;
  margin-right: 10px;

  &_full-width {
    width: 100%;
  }

  @media (max-width: 640px) {
    flex-wrap: wrap;
    margin: 0 0 8px !important;
  }

  &__wrapper {
    @media (max-width: 640px) {
      width: 100%;
      margin: 0 8px 0 0;
    }
  }

  &__export-btn {
    color: var(--el-color-black);
    font-size: 14px;
  }

  & > div {
    display: flex;
    gap: 10px;
  }

  :global {
    .el-button-group {
      display: flex;

      > button {
        height: 100%;
        color: var(--el-color-black);
        font-size: 14px;
      }
    }

    .el-button {
      &.is-disabled {
        background-color: var(--el-color-primary-light-9);
      }
    }

    .el-input-group__append {
      box-shadow: none;
      border: var(--el-input-border, var(--el-border-color));
      border-right: 0;
      border-radius: 0;
      border-left: 0;
      min-width: max-content;

      .el-button,
      .el-button:hover {
        border: 0;
        border-right: 1px solid var(--el-border-color);
        border-left: 1px solid var(--el-border-color);
      }
    }

    .el-select {
      @media (max-width: 640px) {
        width: calc((100% - 10px) / 2);
      }
    }
  }
}

.input-with-select {
  display: flex;
  position: relative;

  :global {
    .el-input {
      max-width: 400px;
    }

    .el-button,
    .el-button:hover {
      background-color: var(--el-color-primary-light-9);
      border: 1px solid var(--el-border-color);
      border-radius: 4px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    .el-dropdown {
      width: 272px;
      border: 1px solid transparent;
      border-radius: 4px;
      cursor: pointer;

      &.activeFocusSearch {
        border: 1px solid #008859;
      }

      .el-icon {
        vertical-align: middle;
      }
    }

    .el-input__wrapper {
      height: 38px;
      border: var(--el-input-border, var(--el-border-color));
      border-right: 0;
      border-radius: 4px 0 0 4px;
      box-shadow: none;

      .el-input__inner {
        border-right: none;
        padding-right: 10px;
      }
    }
  }

  .el-dropdown-link {
    width: 272px;
    line-height: 30px;
    display: inline-block;
    padding-right: 50px;
  }

  .el-input-group__append {
    background-color: var(--el-color-white);
    height: 40px;
    box-shadow: none;
    border: var(--el-input-border, var(--el-border-color));
    border-right: 0;
    border-radius: 0;
    border-left: 0;
    min-width: max-content;
    padding: 0px 18px 0 4px;

    .el-button,
    .el-button:hover {
      border: 0;
      border-right: 1px solid var(--el-border-color);
      border-left: 1px solid var(--el-border-color);
    }

    .el-button {
      height: 38px;
    }
  }

  .search-button-dropdown {
    height: 38px;
    position: absolute;
    top: 1px;
    right: 1px;
  }

  .el-input-group__append {
    padding: 0 18px 0 4px;
  }

  .el-input-group--append {
    transition: var(--el-transition-border);

    .el-input__inner:hover + .el-input-group__append {
      border-color: var(--el-input-hover-border, var(--el-border-color-hover));

      .el-button {
        border-left-color: var(
          --el-input-hover-border,
          var(--el-border-color-hover)
        );
      }
    }

    .el-input__inner:focus + .el-input-group__append {
      outline: 0;
      border-color: var(--el-input-focus-border, var(--el-color-primary));

      .el-button {
        border-left-color: var(
          --el-input-focus-border,
          var(--el-color-primary)
        );
      }
    }
  }
}

.filter-select {
  :global {
    .el-select__wrapper {
      width: 100%;
    }
  }
}

.search-input {
  width: calc(100% - 55px);
  z-index: 1;
  position: absolute;
  left: 1px;
  top: 1px;

  .el-input__wrapper {
    height: 38px;
    border-radius: 4px 0 0 4px;
  }
}

.search-radio-dropdown {
  width: 272px;
  padding: 12px 12px 0;

  :global {
    .el-radio {
      height: 24px;
      margin: 0;

      &.is-checked {
        background: #e6f4ef;
      }

      &.is-bordered {
        margin-right: 10px;
        margin-bottom: 10px;
      }

      .el-radio__input {
        display: none;
      }

      &.is-bordered + .el-radio.is-bordered {
        margin-left: 0;
      }
    }
  }
}

.search-dropdown-menu {
  padding-bottom: 5px;

  :global {
    .el-dropdown-menu__item {
      height: 32px;
      justify-content: space-between;

      span {
        width: 215px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .el-button {
      &.is-circle {
        height: 14px;
        width: 14px;

        border: none;
        background: transparent;
      }
    }
  }
}
</style>
