import { ref, Ref, watch } from "vue";
import { Service } from "@/api/core/service";
import useWebsocket, {
  WSMessageAction,
  WSMessageType,
} from "@/composables/hooks/useWebsocket";

interface Return {
  isNippleModal: Ref<boolean>;
  nippleModalData: Ref<Record<string, any>>;
  showNippleImports: (id: string | number) => void;
  closeNippleModal: () => void;
}

export default (
  isNipple: boolean,
  service: InstanceType<typeof Service>,
  getData: () => void
): Return => {
  const isNippleModal = ref(false);
  const nippleModalId = ref<string | number>("");
  const nippleModalData = ref();

  const { message } = useWebsocket();

  const getDataById = async (id: string | number) => {
    if (!service) return;
    return await service.item(id);
  };

  const showNippleImports = async (id: string | number) => {
    nippleModalId.value = id;

    try {
      const res = await getDataById(id);
      nippleModalData.value = res.data;
      isNippleModal.value = true;
    } catch (e) {
      console.error(e);
    }
  };

  const closeNippleModal = () => {
    isNippleModal.value = false;
    nippleModalId.value = "";
  };

  watch(message, async () => {
    if (
      isNipple &&
      message.value.type === WSMessageType.event &&
      message.value.params.object_type === "log_import" &&
      (message.value.action === WSMessageAction.created ||
        message.value.action === WSMessageAction.modified)
    ) {
      getData();

      if (
        isNippleModal.value &&
        nippleModalId.value &&
        message.value.action === WSMessageAction.modified &&
        message.value.params.object_id === String(nippleModalId.value)
      ) {
        showNippleImports(nippleModalId.value);
      }
    }
  });

  return {
    isNippleModal,
    nippleModalData,
    showNippleImports,
    closeNippleModal,
  };
};
