import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/business_unit_types`);

export default service;
export const getBusinessUnitTypes = service.list.bind(service);
export const getBusinessUnitTypeById = service.item.bind(service);
export const createBusinessUnitType = service.create.bind(service);
export const updateBusinessUnitType = service.update.bind(service);
export const deleteBusinessUnitType = service.delete.bind(service);
