import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import TiptapItalic, { ItalicOptions } from "@tiptap/extension-italic";
import CommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CommandButton.vue";

const Italic = TiptapItalic.extend<ItalicOptions & { bubble?: boolean }>({
  addOptions() {
    return {
      ...this.parent?.(),
      button({ editor, t, isCodeViewMode }: CommandButtonArgs) {
        return {
          component: CommandButton,
          componentProps: {
            command: () => {
              editor.commands.toggleItalic();
            },
            isActive: editor.isActive("italic"),
            icon: "italic",
            tooltip: t("editor.extensions.Italic.tooltip"),
            isCodeViewMode,
          },
        };
      },
    };
  },
});

export default Italic;
