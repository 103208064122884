import { FormItemObjDeclaration } from "@/plugins/form-generator-json-v2/types";
import { defaultSelect } from "@/components/form-declaration/default-elements";

export const item_type: FormItemObjDeclaration = defaultSelect(
  "type",
  "Тип",
  [
    { value: "static-url", label: "Статический URL" },
    { value: "telegram-url", label: "Ссылка на telegram" },
    { value: "vk-url", label: "Ссылка на vk" },
    { value: "tiktok-url", label: "Ссылка на tiktok" },
    { value: "drive2-url", label: "Ссылка на drive2" },
    { value: "catalog", label: "Каталог" },
    { value: "text", label: "Текст" },
    { value: "text-with-phone", label: "Телефон (текст с иконкой телефона)" },
    { value: "email", label: "Электронная почта" },
  ],
  "Выберите тип ссылки"
);

export const landing_type: FormItemObjDeclaration = defaultSelect(
  "landing_type",
  "Тип лендинга",
  [
    { value: "main", label: "Основной" },
    { value: "franchise", label: "Франшизный" },
    { value: "hr", label: "HR" },
  ],
  "Выберите тип лендинга",
  false,
  undefined,
  undefined,
  undefined,
  "При выборе определенного типа (дизайна) лендинга" +
    " изменится набор блоков, доступных для прикрепления к этому типу."
);

export const target: FormItemObjDeclaration = defaultSelect(
  "target",
  "Открыть в",
  [
    { value: "_blank", label: "Новой вкладке" },
    { value: "_self", label: "Той же вкладке" },
  ]
);

export const menu: FormItemObjDeclaration = defaultSelect("menu", "Меню", [
  { value: "header", label: "Шапка" },
  { value: "footer", label: "Подвал" },
]);

export const profession_level: FormItemObjDeclaration = defaultSelect(
  "profession_level",
  "Уровень профессии",
  [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
  ]
);

export const stock_zones_type: FormItemObjDeclaration = defaultSelect(
  "type",
  "Тип зоны",
  [
    { value: "transit", label: "Транзитный" },
    { value: "materials", label: "Материалы" },
    { value: "seasonal_storage", label: "Сезонное хранение" },
    { value: "main", label: "Основной" },
    { value: "sale_warehouse", label: "Склад продажи" },
  ],
  "Выберите тип",
  undefined,
  undefined,
  undefined,
  true
);
