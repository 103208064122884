import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/hr_employee_advantages`, {
  item: { include: "image,additional_image" },
});

export default service;
export const getHREmployeeAdvantages = service.list.bind(service);
export const getHREmployeeAdvantageById = service.item.bind(service);
export const createHREmployeeAdvantage = service.create.bind(service);
export const updateHREmployeeAdvantage = service.update.bind(service);
export const deleteHREmployeeAdvantage = service.delete.bind(service);
