<template>
  <div :class="$style['the-tasks']">
    <template v-if="isMobile">
      <el-badge :hidden="!tasksLength" is-dot>
        <el-icon :size="20" @click="openModal">
          <Bell />
        </el-icon>
      </el-badge>
      <el-dialog
        v-if="tasksList.length"
        :class="$style['the-tasks-dialog']"
        :model-value="isOpenModal"
        @close="closeModal"
      >
        <template #header>Уведомления</template>
        <tasks-list-el
          :tasks-list="tasksList"
          class="tasks"
          @scroll="onScrollModal"
        />
      </el-dialog>
    </template>
    <el-dropdown
      v-else
      ref="TaskMenu"
      :popper-class="$style['the-tasks-dropdowm']"
      max-height="580"
      trigger="click"
      @visible-change="handleVisible"
    >
      <el-badge :hidden="!tasksLength" :value="tasksLength">
        <el-icon :size="32">
          <Bell />
        </el-icon>
      </el-badge>
      <template v-if="tasksList.length" #dropdown>
        <div :class="$style['the-tasks-drop']">
          <div :class="$style['the-tasks-drop__top']">Уведомления</div>
          <tasks-list-el :tasks-list="tasksList" class="tasks" />
        </div>
      </template>
    </el-dropdown>
  </div>
</template>

<script lang="ts" setup>
import { nextTick, onMounted, reactive, ref, toRaw, watch } from "vue";
import { Bell } from "@element-plus/icons-vue";
import TasksListEl from "@/components/tasks/TasksList.vue";
import { getTasksUser, markRead } from "@/api/points/tasks-api/tasks";
import useWebsocket, { WSMessageType } from "@/composables/hooks/useWebsocket";
import useAdaptive from "@/composables/hooks/useAdaptive";
import { debounce } from "@/helpers/functions";
import { user } from "@/model/user";
import { Task } from "@/components/tasks/types";

const { isMobile } = useAdaptive();
const { message } = useWebsocket();

const GAP = 10;
const MODALGAP = 60;

const TaskMenu = ref();
const isOpenModal = ref(false);

const tasksLength = ref(0);
const tasksList = ref<Task[]>([]);

const readedIds = ref<Set<number>>(new Set());
const sendedIds = ref<Set<number>>(new Set());

const paging = reactive<{
  queryData: Record<string, unknown>;
  loading: boolean;
  lastPage: number;
}>({
  queryData: { order: "-id", "page[size]": 10 },
  loading: false,
  lastPage: 1,
});

const userId = user.getProfile().id;

let channel: BroadcastChannel;

const getActiveTasksLength = async () => {
  const res = await getTasksUser({ "filter[is_read]": 0 });
  tasksLength.value = res.meta?.total;
};

const loadData = async (append = false) => {
  if (paging.loading) return;

  paging.loading = true;

  paging.queryData = {
    ...paging.queryData,
    "page[number]": 1,
  };

  const res = await getTasksUser(paging.queryData);

  tasksList.value = append
    ? [...(res.data ?? []), ...tasksList.value]
    : res.data ?? [];
  paging.lastPage = res.meta?.last_page;

  paging.loading = false;
};

const dataListAccumulator = () => {
  const handleScrollOrigin = TaskMenu.value?.scrollbar?.handleScroll;

  if (handleScrollOrigin) {
    TaskMenu.value.scrollbar.wrapRef.onscroll = () => {
      handleScrollOrigin();

      const { scrollHeight, scrollTop, offsetHeight } =
        TaskMenu.value.scrollbar.wrapRef;

      checkPaging(scrollTop, scrollHeight, offsetHeight);
      checkScrolledTasks();
    };
  }
};

const onScrollModal = ({
  target: { scrollTop, scrollHeight, offsetHeight },
}: {
  target: {
    scrollTop: number;
    scrollHeight: number;
    offsetHeight: number;
  };
}) => {
  checkPaging(scrollTop, scrollHeight, offsetHeight);
  checkScrolledTasks();
};

const checkPaging = (
  scrollTop: number,
  scrollHeight: number,
  offsetHeight: number
) => {
  if (offsetHeight + scrollTop >= scrollHeight - GAP) {
    pagingHandler();
  }
};

const pagingHandler = async () => {
  if (paging.loading || !tasksList.value.length) {
    return;
  }

  let nextPage = toRaw(paging.queryData["page[number]"] as number);
  if (++nextPage > paging.lastPage) {
    return;
  }

  paging.loading = true;
  paging.queryData = {
    ...paging.queryData,
    "page[number]": nextPage,
  };

  try {
    const res = await getTasksUser(paging.queryData);
    tasksList.value = [...tasksList.value, ...(res.data ?? [])];
  } finally {
    paging.loading = false;
  }
};

const openModal = async () => {
  if (!tasksList.value.length) {
    await loadData();
  }

  if (tasksList.value.length) {
    isOpenModal.value = true;

    nextTick(() => {
      checkScrolledTasks();
    });
  }
};

const closeModal = () => {
  isOpenModal.value = false;
  markTasksReadedLocaly();
};

const handleVisible = async (visible: any) => {
  if (visible) {
    if (!tasksList.value.length) {
      await loadData();
    }

    if (tasksList.value.length) {
      nextTick(() => {
        checkScrolledTasks();
      });
    }
  }

  if (visible === false && tasksList.value.length) {
    markTasksReadedLocaly();
  }
};

const checkScrolledTasks = async () => {
  let counter;

  const container = isMobile.value
    ? document.querySelector(".tasks")
    : TaskMenu.value.scrollbar.wrapRef;
  const containerTop = container.scrollTop;
  const containerBottom = containerTop + container.clientHeight;

  if (!containerBottom) return;

  const children = isMobile.value
    ? container.children
    : container.querySelector(".tasks").children;

  for (const key in children) {
    const item = children[key];

    if (item instanceof HTMLElement) {
      const itemBottom = item.offsetTop + item.clientHeight;

      if (itemBottom < containerBottom + MODALGAP) {
        counter = +key + 1;
      }
    }
  }

  if (counter) {
    tasksList.value.slice(0, counter).forEach((item: Record<string, any>) => {
      if (!sendedIds.value.has(item.id) && item.attributes.is_read === false) {
        readedIds.value.add(item.id);
      }
    });

    if (readedIds.value.size) {
      delayMarkTasksReaded();
    }
  }
};

const markTasksReaded = async () => {
  await markRead({ ids: [...readedIds.value] });
  getActiveTasksLength();

  channel.postMessage("refresh-tasks");

  sendedIds.value = new Set([...sendedIds.value, ...readedIds.value]);
  readedIds.value.clear();
};

const markTasksReadedLocaly = () => {
  tasksList.value.forEach((item: Record<string, any>) => {
    if (readedIds.value.has(item.id) || sendedIds.value.has(item.id)) {
      item.attributes.is_read = true;
    }
  });
};

const delayMarkTasksReaded = debounce(markTasksReaded, 1000);

onMounted(async () => {
  await getActiveTasksLength();
  dataListAccumulator();

  channel = new BroadcastChannel("tasks-data");

  channel.addEventListener("message", (event) => {
    if (event.data === "refresh-tasks") {
      getActiveTasksLength();

      if (tasksList.value.length) {
        loadData();
      }
    }
  });
});

watch(message, async () => {
  if (
    message.value.type === WSMessageType.event &&
    message.value.params.object_type === "task" &&
    [...message.value.params.users].includes(String(userId))
  ) {
    await getActiveTasksLength();

    if (tasksList.value.length) {
      await loadData(true);
    }
  }
});
</script>

<style lang="scss" module>
.the-tasks {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 20px 0 0;

  @media (max-width: 640px) {
    width: 20%;
    height: 100%;
    margin: 0;
  }

  &-dropdowm {
    width: 600px;
    border: none !important;
  }

  &-drop {
    &__top {
      border-bottom: 1px solid var(--el-border-color);
      font-size: 18px;
      font-weight: 400;
      padding: 24px;
    }
  }

  &-dialog {
    :global {
      .el-dialog__body {
        height: calc(100vh - 58px);
        max-height: calc(100vh - 58px);
        overflow: hidden;
        padding: 0 !important;
      }
    }
  }
}
</style>
