<template>
  <section v-show="!data.loading" :class="$style['pagination']">
    <el-pagination
      v-if="isMobile"
      :current-page="data.current_page"
      :page-size="data.per_page"
      :pager-count="3"
      :total="data.total"
      background
      hide-on-single-page
      @current-change="setPageNumber"
    />
    <el-pagination
      v-else
      :current-page="data.current_page"
      :default-page-size="20"
      :layout="layout"
      :page-count="data.last_page"
      :page-size="data.per_page"
      :page-sizes="[10, 20, 50, 100]"
      :pager-count="7"
      :total="data.total"
      background
      hide-on-single-page
      @current-change="setPageNumber"
      @size-change="setPageSize"
    />
  </section>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import useSectionPagination from "@/composables/hooks/useSectionPagination";
import useAdaptive from "@/composables/hooks/useAdaptive";

const { data, setPageSize, setPageNumber } = useSectionPagination();
const { isMobile } = useAdaptive();
const layout = computed(() =>
  data.value.last_page > 1 ? "total, sizes, prev, pager, next, jumper" : "total"
);
</script>

<style lang="scss" module>
.pagination {
  position: relative;
  bottom: 0;
  margin-bottom: 0;
  margin-top: auto;

  :global {
    .el-pagination {
      display: flex;
      font-weight: 400;
      justify-content: flex-end;
      padding: 0 24px 24px 24px;
      margin-left: -5px;
      margin-right: -5px;
      font-size: 13px;

      @media (max-width: 1024px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      @media (max-width: 640px) {
        padding: 0 16px 24px;
        margin: 16px 0 0;
      }

      button,
      .el-pager > li {
        border-radius: 4px;
        height: 32px;
        line-height: 22px;
        margin: 0 4px;
        min-width: 32px;
        padding: 5px 12px;
        font-size: 13px;
        font-weight: 400;

        &:not(.is-active) {
          background-color: #f4f4f5;
        }

        &.more {
          padding: 0;
        }
      }

      .el-pagination__total {
        @media (max-width: 640px) {
          display: none;
        }
      }

      .el-pagination__jump {
        @media (max-width: 640px) {
          width: 100%;
          display: flex;
          justify-content: center;
          margin: 16px 0 0;
        }
      }
    }

    .el-pagination__sizes {
      margin-right: auto;
    }

    .el-pagination .el-select .el-input {
      width: 100px;
    }

    .el-input {
      --el-input-height: 28px;
      width: 100px;
      font-size: 13px;
      line-height: 26px;

      .el-input__inner {
        padding: 0;
      }
    }

    .select-trigger .el-tooltip__trigger > .el-input {
      width: 100px;
    }

    .el-pagination__jump .el-input {
      width: 46px;
    }

    .is-last {
      @media (max-width: 768px) {
        margin: 10px 0 0 !important;
      }
    }
  }
}
</style>
