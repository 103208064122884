import { onMounted, onUnmounted, Ref, ref } from "vue";
import {
  WSEvent,
  WSMessageAction,
  WSMessageType,
} from "@/composables/hooks/useWebsocket";
import { getPathParams } from "@/helpers/route";

const sectionsCreatedInstances = ref<Record<string, number>>({});

const handleWSMessageParse = (event: any) => {
  const message = event.detail.webSocket.parsedMessage;
  const { service, section } = getPathParams();
  const shouldIncreaseCreatedInstancesQty =
    message.action === WSMessageAction.created &&
    message.type === WSMessageType.event &&
    message?.params?.service === service &&
    message?.params?.object_type === section;

  if (shouldIncreaseCreatedInstancesQty) {
    const sectionValue = sectionsCreatedInstances.value[section] || 0;

    sectionsCreatedInstances.value[section] = sectionValue + 1;
  }
};

const clearSectionsCreatedInstances = () => {
  const { section } = getPathParams();
  sectionsCreatedInstances.value[section] = 0;
};

export default (): {
  sectionsCreatedInstances: Ref<Record<string, number>>;
} => {
  onMounted(() => {
    window.addEventListener(WSEvent.messageParsed, handleWSMessageParse);
    window.addEventListener(
      WSEvent.resetSectionsCreatedInstance,
      clearSectionsCreatedInstances
    );
  });

  onUnmounted(() => {
    window.removeEventListener(WSEvent.messageParsed, handleWSMessageParse);
    window.removeEventListener(
      WSEvent.resetSectionsCreatedInstance,
      clearSectionsCreatedInstances
    );
  });

  return {
    sectionsCreatedInstances,
  };
};
