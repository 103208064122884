<template>
  <el-dropdown
    :disabled="isCodeViewMode"
    placement="bottom"
    trigger="click"
    @command="toggleFontSize"
  >
    <command-button
      :enable-tooltip="enableTooltip"
      :readonly="isCodeViewMode"
      :tooltip="t('editor.extensions.FontSize.tooltip')"
      icon="font-size"
    />

    <template #dropdown>
      <el-dropdown-menu class="el-tiptap-dropdown-menu">
        <!-- default size -->
        <el-dropdown-item
          :class="{
            'el-tiptap-dropdown-menu__item--active':
              activeFontSize === defaultSize,
          }"
          :command="defaultSize"
          class="el-tiptap-dropdown-menu__item"
        >
          <span data-font-size="default">{{
            t("editor.extensions.FontSize.default")
          }}</span>
        </el-dropdown-item>

        <el-dropdown-item
          v-for="fontSize in fontSizes"
          :key="fontSize"
          :class="{
            'el-tiptap-dropdown-menu__item--active':
              fontSize === activeFontSize,
          }"
          :command="fontSize"
          class="el-tiptap-dropdown-menu__item"
        >
          <span :data-font-size="fontSize">{{ fontSize }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script lang="ts" setup>
import { computed, inject } from "vue";
import { Editor, getMarkAttributes } from "@tiptap/vue-3";
import CommandButton from "./CommandButton.vue";
import { DEFAULT_FONT_SIZE as defaultSize } from "@/plugins/tiptap-editor/utils/font-size";

interface Props {
  editor: Editor;
}

const props = defineProps<Props>();

const t: any = inject("t");
const enableTooltip = inject("enableTooltip", true);
const isCodeViewMode = inject("isCodeViewMode", false);

const fontSizes = computed<Array<string>>(() => {
  const fontSizeOptions = props.editor.extensionManager.extensions.find(
    (e) => e.name === "fontSize"
  )?.options;
  return fontSizeOptions.fontSizes;
});

const activeFontSize = computed(() => {
  return getMarkAttributes(props.editor.state, "textStyle").fontSize || "";
});

const toggleFontSize = (size: string) => {
  if (size === activeFontSize.value) {
    props.editor.commands.unsetFontSize();
  } else {
    props.editor.commands.setFontSize(size);
  }
};
</script>
