import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/product_categories`, {
  item: { include: "products,image,additional_image" },
});

export default service;
export const getProductCategories = service.list.bind(service);
export const getProductCategoryById = service.item.bind(service);
export const createProductCategory = service.create.bind(service);
export const updateProductCategory = service.update.bind(service);
export const deleteProductCategory = service.delete.bind(service);
