import { FormItemSimpleListing } from "@/plugins/form-generator-json-v2/types";

export const properties_listing: FormItemSimpleListing = {
  name: "propertiesListing",
  id: "propertiesListing",
  model: "properties_list",
  element: "simple-listing",
  isPayload: false,
  isLoading: true,
  emptyText: "Связанные характеристики не найдены",
  formItemAttrs: {
    label: "Характеристики, связанные с выбранным набором характеристик:",
  },
};
