<template>
  <div class="el-tiptap-editor__menu-bar">
    <component
      :is="spec.component"
      v-for="(spec, i) in generateCommandButtonComponentSpecs()"
      :key="'command-button' + i"
      v-bind="spec.componentProps"
      v-on="spec.componentEvents || {}"
    />
  </div>
</template>

<script lang="ts" setup>
import { inject } from "vue";
import { Editor } from "@tiptap/core";

interface Props {
  editor: Editor;
  isCodeViewMode: boolean;
}

const props = defineProps<Props>();
const t: any = inject("t");
// eslint-disable-next-line vue/no-dupe-keys
const isCodeViewMode = inject("isCodeViewMode", false);

const generateCommandButtonComponentSpecs = () => {
  const extensionManager = props.editor.extensionManager;

  return extensionManager.extensions.reduce((acc: any, extension: any) => {
    const { button } = extension.options;

    if (!button || typeof button !== "function") return acc;

    const menuBtnComponentSpec = button({
      editor: props.editor,
      t,
      extension,
      isCodeViewMode,
    });

    if (Array.isArray(menuBtnComponentSpec)) {
      return [...acc, ...menuBtnComponentSpec];
    }

    return [...acc, menuBtnComponentSpec];
  }, []);
};
</script>
