<template>
  <div v-if="hasChildren" :class="$style['branch']">
    <span class="spaced">{{ title }}:</span>
    <the-recursive
      v-for="(value, index) in props.data"
      :key="index"
      :data="value"
      :title="index"
    />
  </div>
  <div v-else :class="$style['branch']">
    <span class="spaced">{{ title }}:</span>
    <span>{{ props }}</span>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  title: string | number;
  data: any;
}

const props = defineProps<Props>();

const hasChildren =
  typeof props.data === "object" &&
  props.data &&
  (props.data.length || Object.keys(props.data).length);
</script>

<style lang="scss" module>
.branch {
  color: var(--el-text-color-regular);
  font-size: 14px;
  line-height: 22px;
  margin-left: 20px;
  padding: 5px;

  :global {
    .spaced {
      padding-right: 10px;
    }
  }
}
</style>
