import {
  FormItemElement,
  FormItemEventsObjDeclaration,
  FormItemObjDeclaration,
  PublicFormContextDeclaration,
} from "@/plugins/form-generator-json-v2/types";

export const getFormElementComponentName = (
  item: FormItemObjDeclaration,
  ctx: PublicFormContextDeclaration,
  formModel?: Record<string, unknown>
): {
  componentName: string;
  componentProps: Record<string, unknown>;
} => {
  let componentName: string;

  const events: FormItemEventsObjDeclaration = Object.assign({}, item.events);
  for (const key in events) {
    const applyEvent = events[key];
    events[key] = (...rest: any[]) => {
      if (applyEvent) {
        return applyEvent.apply(ctx, rest);
      } else {
        return null;
      }
    };
  }
  const props: Record<string, unknown> = {
    declaration: item,
    formModel: formModel || ctx.formModel,
    ...events,
  };

  switch (item.element) {
    case "input":
      componentName = "base-input";
      break;
    case "input-range":
      componentName = "base-input-range";
      break;
    case "input-action-button":
      componentName = "base-input-action-button";
      break;
    case "input-with-open-close-button":
      componentName = "base-input-with-open-close-button";
      break;
    case "slider":
      componentName = "base-slider";
      break;
    case "date-picker":
      componentName = "base-date-picker";
      break;
    case "time-picker":
      componentName = "base-time-picker";
      break;
    case "time-select":
      componentName = "base-time-select";
      break;
    case "rate":
      componentName = "base-rate";
      break;
    case "switch":
    case "switch-admin":
      componentName = "base-switch";
      break;
    case "checkbox":
    case "checkbox-button":
    case "checkbox-group":
      componentName = "base-checkbox";
      break;
    case "select":
      componentName = "base-select";
      break;
    case "multiple-select":
      componentName = "multiple-select";
      break;
    case "upload":
      componentName = "base-upload";
      break;
    case "upload-avatar":
      componentName = "base-upload-avatar";
      break;
    case "upload-file":
      componentName = "base-upload-file";
      break;
    case "upload-image":
      componentName = "base-upload-image";
      break;
    case "upload-card-image":
      componentName = "base-upload-card-image";
      break;
    case "upload-logo":
      componentName = "base-upload-logo";
      break;
    case "counter":
      componentName = "base-counter";
      break;
    case "radio":
    case "radio-button":
    case "radio-group":
      componentName = "base-radio";
      break;
    case "button":
      componentName = "base-button";
      break;
    case "button-dropdown":
      componentName = "base-button-dropdown";
      break;
    case "async-dependent-entity-select":
      componentName = "base-async-dependent-entity-select";
      break;
    case "tree-command":
      componentName = "base-tree-command";
      break;
    case "tag-input":
      componentName = "base-tag-input";
      break;
    case "select-input":
      componentName = "base-select-input";
      break;
    case "editable-table":
      componentName = "base-editable-table";
      break;
    case "list-item-table":
      componentName = "list-item-table";
      break;
    case "table":
      componentName = "base-table";
      break;
    case "simple-listing":
      componentName = "base-simple-listing";
      break;
    case "array-input":
      componentName = "base-array-input";
      break;
    case "range-input":
      componentName = "base-range-input";
      break;
    case "number-input":
      componentName = "base-number-input";
      break;
    case "properties-list":
      componentName = "pim-properties-list";
      break;
    case "editor":
      componentName = "editor";
      break;
    case "pim-tiptap":
      componentName = "pim-tiptap-editor";
      break;
    case "pim-progress":
      componentName = "pim-progress";
      break;
    case "pim-autofill-button":
      componentName = "pim-autofill-button";
      break;
    case "pim-crosses":
      componentName = "pim-crosses";
      break;
    case "pim-images-list":
      componentName = "pim-images-list";
      break;
    case "pim-description-images":
      componentName = "pim-description-images";
      break;
    case "form-group":
      componentName = "div";
      break;
    case "base-collapse-container":
      componentName = "base-collapse-container";
      break;
    case "base-collapse-item":
      componentName = "base-collapse-item";
      break;
    default:
      componentName = "base-error";
  }
  return {
    componentName,
    componentProps: props,
  };
};

export const getDefaultValue = (
  element: FormItemElement
):
  | Array<unknown>
  | string
  | boolean
  | number
  | null
  | Record<string, unknown> => {
  switch (element) {
    case "checkbox-group":
    case "tag-input":
    case "array-input":
    case "select-input":
    case "pim-images-list":
    case "editable-table":
    case "list-item-table":
    case "table":
    case "multiple-select":
      return [];
    case "input":
    case "input-range":
    case "input-action-button":
    case "radio":
    case "radio-button":
    case "time-select":
      return "";
    case "checkbox":
    case "switch":
    case "switch-admin":
      return false;
    case "counter":
    case "rate":
    case "slider":
      return 0;
    case "form-group":
    case "base-collapse-container":
    case "base-collapse-item":
      return {};
    default:
      return null;
  }
};

export const itemMutatingCallback = (
  items: Array<FormItemObjDeclaration>,
  actions: {
    removeFields?: Array<string>;
    isMassEditingDisabled?: Array<string>;
    mutateItems?: Record<string, Record<string, any>>;
  } = {
    removeFields: [],
    isMassEditingDisabled: [],
    mutateItems: {},
  }
): Array<FormItemObjDeclaration> => {
  const results: Array<FormItemObjDeclaration> = [];

  for (let item of items) {
    if (actions.removeFields?.includes(item.id.toString())) {
      continue;
    }

    if (actions.isMassEditingDisabled?.includes(item.model)) {
      item.isMassEditingDisabled = true;
    }

    for (const mutateItem in actions.mutateItems) {
      if (mutateItem === item.model) {
        item = {
          ...item,
          ...actions.mutateItems[mutateItem],
        };
        break;
      }
    }

    results.push(item);
  }
  return results;
};
