import LayoutWorkingSection from "@/layout/LayoutWorkingSection.vue";
import { Tickets } from "@element-plus/icons-vue";
import { mainBlocksRoutesList } from "@/router/landings-cms/main-blocks";
import { franchiseBlocksRoutesList } from "@/router/landings-cms/franchise-blocks";
import { HRBlocksRoutesList } from "@/router/landings-cms/hr-blocks";
import { LANDINGS, LANDINGS_CMS } from "@/dictionary/header";
import WorkingSectionView from "@/views/WorkingSectionView.vue";
import { RouteRecordRaw } from "vue-router";
import { RouteAccess } from "../types";

const access: RouteAccess = {
  service: "landings-cms",
  object: "",
  command: "index",
};

export const landingsCMSRoutesList: Array<RouteRecordRaw> = [
  {
    path: "/landings",
    name: "landings",
    component: LayoutWorkingSection,
    meta: {
      title: LANDINGS_CMS,
      icon: Tickets,
      clickable: false,
    },
    children: [
      {
        path: "/landings/constructor",
        name: "constructor",
        component: WorkingSectionView,
        meta: {
          title: LANDINGS,
          access: {
            ...access,
            object: "landings",
          },
        },
      },
      mainBlocksRoutesList,
      franchiseBlocksRoutesList,
      HRBlocksRoutesList,
    ],
  },
];
