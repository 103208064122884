import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/client_zones`);

export default service;
export const getClientZones = service.list.bind(service);
export const getClientZoneById = service.item.bind(service);
export const createClientZone = service.create.bind(service);
export const updateClientZone = service.update.bind(service);
export const deleteClientZone = service.delete.bind(service);
