import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(
  `${SERVICE_POINT_NAME}/brand_and_certificate_blocks`,
  {
    item: { include: "brands,popupBrands,certificates" },
  }
);

export default service;
export const getBrandAndCertificateBlocks = service.list.bind(service);
export const getBrandAndCertificateBlockById = service.item.bind(service);
export const createBrandAndCertificateBlock = service.create.bind(service);
export const updateBrandAndCertificateBlock = service.update.bind(service);
export const deleteBrandAndCertificateBlock = service.delete.bind(service);
