<template>
  <section>
    <el-button size="large" @click="showFilterDialog = true">
      <icon-columns />
    </el-button>

    <el-dialog v-model="showFilterDialog" destroy-on-close>
      <template #header> Настройки колонок</template>
      <template #default>
        <el-alert
          v-if="showAlert"
          :class="$style['alert']"
          title="Минимальное количество столбцов для отображения равно трём"
          type="error"
          @close="showAlert = false"
        >
        </el-alert>
        <el-tree
          ref="tree"
          :allow-drop="onCheckDrop"
          :class="$style['tree']"
          :data="data"
          :default-checked-keys="defaultCheckedKeys"
          :expand-on-click-node="false"
          :props="{
            class: (data, _) => ({
              [$style['node-element']]: true,
              [$style['group']]: !!data?.group,
              [$style['disabled']]: draggingKey && data.key !== draggingKey,
            }),
          }"
          class="tree"
          default-expand-all
          draggable
          node-key="key"
          show-checkbox
          @check="onClickNode"
          @node-drag-start="onNodeDragStart"
          @node-drag-end="onNodeDragEnd"
          @node-drop="onNodeDrop"
          @node-click="onClickNode"
        >
          <template v-slot="{ data }">
            <div v-if="data?.group" :class="$style['group-node']">
              <span :class="$style['group-label']">
                {{ data.label }}
              </span>

              <el-icon>
                <template v-if="data.index === 0">
                  <SortDown />
                </template>
                <template v-else-if="data.index === data.length - 1">
                  <SortUp />
                </template>
                <template v-else>
                  <Sort />
                </template>
              </el-icon>
            </div>

            <template v-else>
              <div
                :class="[
                  $style['node'],
                  {
                    [$style['dragging']]: data.key === draggingKey,
                  },
                ]"
              >
                <span>
                  {{ data.label }}
                </span>
                <el-icon>
                  <template v-if="data.index === 0">
                    <SortDown />
                  </template>
                  <template v-else-if="data.index === data.length - 1">
                    <SortUp />
                  </template>
                  <template v-else>
                    <Sort />
                  </template>
                </el-icon>
              </div>
            </template>
          </template>
        </el-tree>
      </template>
      <template #footer>
        <el-button
          :disabled="saveIsLoading"
          :loading="saveIsLoading"
          type="primary"
          @click="onSave"
        >
          Сохранить
        </el-button>
      </template>
    </el-dialog>
  </section>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import { d } from "@/helpers/dictionary";
import { useRoute } from "vue-router";
import { Sort, SortDown, SortUp } from "@element-plus/icons-vue";
import iconColumns from "@/static/icons/columns.svg";
import { ElTree } from "element-plus";
import { user } from "@/model/user";
import Node from "element-plus/es/components/tree/src/model/node";
import { TableColumn } from "@/model/working-sections/types";
import { TreeNodeData } from "element-plus/es/components/tree-v2/src/types";

interface TableFilterColumn extends Pick<TableColumn, "label"> {
  key: string;
  visible: boolean;
  index: number;
  onlyEdit: boolean;
  group?: string;
  children?: Array<
    TableFilterColumn & {
      parent?: string;
    }
  >;
}

const props = withDefaults(
  defineProps<{
    cols: Record<string, TableColumn>;
  }>(),
  {}
);

const route = useRoute();
const path = computed(() => route.path.replaceAll(/\/[0-9]+/g, ""));

const tree = ref<InstanceType<typeof ElTree>>();
const draggingKey = ref();
const showFilterDialog = ref(false);
const showAlert = ref(false);
const saveIsLoading = ref(false);

const userOptions = reactive(
  JSON.parse(localStorage.getItem("user_options") || "{}")
);

const getDataUserOption = () => {
  userOptions.value = JSON.parse(localStorage.getItem("user_options") || "{}");
};

const data = computed(() => {
  let index = 0;
  const cols: TableFilterColumn[] = [];
  let additionalCols: TableFilterColumn[] = [];
  const groupsPositions: Record<string, number> = {};

  for (const key in props.cols) {
    const { settings, group, label, onlyEdit = false } = props.cols[key] || {};

    const row: TableFilterColumn = {
      key,
      label: (label as string)?.trim() || d(key) || key,
      visible: settings?.visible ?? true,
      index: settings?.sort ?? index,
      onlyEdit,
    };

    if (
      settings?.sort === undefined &&
      (key === "created_at" || key === "updated_at")
    ) {
      additionalCols.push(row);
      continue;
    }

    if (group) {
      const groupPosition = groupsPositions[group];

      if (groupPosition === undefined) {
        groupsPositions[group] = row.index;

        cols[row.index] = {
          ...row,
          label: d(group),
          key: group,
          group,
          children: [
            {
              ...row,
              index: row.index,
              parent: group,
            },
          ],
        };

        index++;
      } else {
        cols[groupPosition]?.children?.push({
          ...row,
          parent: group,
        });
        cols[groupPosition]?.children?.sort((a, b) => a.index - b.index);
      }

      continue;
    }

    cols.push(row);
    index++;
  }

  additionalCols = additionalCols.map((col) => {
    col.index = index;
    index++;
    return col;
  });

  return [...cols.flat(), ...additionalCols]
    .filter((col: TableFilterColumn) => !col.onlyEdit)
    .sort((col1, col2) => (col1.index > col2.index ? 1 : -1))
    .reduce((acc: Array<Record<string, any>>, current) => {
      if (!current) {
        return acc;
      }

      if (current.children) {
        current.children.sort((col1, col2) =>
          col1.index > col2.index ? 1 : -1
        );
      }

      return [...acc, current];
    }, []);
});

const defaultCheckedKeys = computed(() => {
  const checked = [];
  for (const value of data.value) {
    if (value?.children) {
      value.children.forEach((childrenItem: Record<string, any>) => {
        if (childrenItem?.visible) {
          checked.push(childrenItem.key);
        }
      });
    } else if (value?.visible) {
      checked.push(value.key);
    }
  }
  return checked;
});

const onNodeDragStart = (node: any) => {
  draggingKey.value = node.key;
};

const onNodeDragEnd = () => {
  draggingKey.value = null;
};

const onCheckDrop = (draggingNode: any, dropOnNode: any, type: any) =>
  !(
    type === "inner" ||
    draggingNode.level !== dropOnNode.level ||
    (draggingNode.data.parent &&
      draggingNode.data.parent !== dropOnNode.data.parent &&
      dropOnNode.data.parent)
  );

const onNodeDrop = (node: TreeNodeData) => {
  if (node.childNodes.length) {
    node.childNodes.forEach((childNode: { key: string; checked: boolean }) => {
      tree.value?.setChecked(childNode.key, childNode.checked, false);
    });
  } else if (node.checked) {
    tree.value?.setChecked(node.key, true, false);
  }
};

const onClickNode = (
  node: TableFilterColumn,
  nodeParams: {
    id: string;
  }
) => {
  const checked = tree.value?.getNode(node.key).checked;
  const checkedNodes = tree.value?.getCheckedNodes() || [];

  showAlert.value = checkedNodes?.length < 4;

  if (showAlert.value && checked) {
    return;
  }

  tree.value?.setChecked(
    node.key,
    nodeParams?.id ? !checked : checked ?? false,
    false
  );
  showAlert.value = false;
};

const onSave = async () => {
  getDataUserOption();
  const nodes = tree.value?.data.map((node: any) =>
    tree.value?.getNode(node.key)
  );

  if (!nodes?.length) {
    return;
  }

  showAlert.value = nodes.length < 3;
  if (showAlert.value) return;

  let index = 0;
  const userSettings = {
    [path.value]: {
      ...userOptions[path.value],
      cols: nodes.reduce(
        (
          acc: Record<
            string,
            {
              visible: boolean;
              sort: number;
            }
          >,
          node
        ) => {
          if (!node) {
            return acc;
          }

          if (node.childNodes.length) {
            node.childNodes.forEach((childNode: Node) => {
              acc[childNode.key] = {
                visible: childNode.checked,
                sort: index,
              };
              index++;
            });
          } else {
            acc[node.key] = {
              visible: node.checked,
              sort: index,
            };
            index++;
          }

          return acc;
        },
        {}
      ),
    },
  };

  saveIsLoading.value = true;

  try {
    await user.updateUserOptions(userSettings, false);
  } finally {
    saveIsLoading.value = false;
    showFilterDialog.value = false;
  }
};
</script>

<style lang="scss" module>
.alert {
  font-weight: 400;
  padding: 8px 16px;
  margin-bottom: 12px;

  :global {
    .el-alert__content {
      padding: 0;
    }

    .el-alert__closebtn {
      color: var(--el-color-danger);
    }
  }
}

.node {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;

  &.dragging {
    opacity: 0.24;
  }

  :global {
    .drag-icon {
      cursor: grabbing;
    }
  }
}

.group {
  padding: 12px;
  background: var(--el-fill-color);

  & > :global.el-tree-node__content > .el-checkbox {
    width: 0;
    opacity: 0;
    margin: 0;
    height: 0;
  }

  :global {
    .el-tree-node:focus > .el-tree-node__content {
      background: transparent;
    }

    .el-tree-node__children {
      padding-top: 8px;
    }

    .el-tree-node__content:hover {
      background-color: transparent;
      cursor: auto;
    }

    .el-tree-node__expand-icon {
      width: 0;
      opacity: 0;
      margin: 0;
      height: 0;
    }

    .el-tree-node__children > .el-tree-node {
      background: #ffffff;

      & > .el-tree-node__content {
        padding-left: 0;
      }
    }
  }
}

.group-node {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.group-label {
  font-weight: 700;
  height: 22px;
  margin-bottom: 8px;
}

.tree {
  :global {
    .el-tree__drop-indicator {
      height: 2px;
      transform: translateY(16px);
    }

    .el-tree-node.is-checked > .el-tree-node__content {
      background-color: transparent;

      :local .node > span {
        color: var(--el-color-primary);
      }
    }

    .el-tree-node__children > .el-tree-node {
      margin-bottom: 8px;
    }

    .el-tree-node__content:hover,
    .el-tree-node:focus > .el-tree-node__content {
      background-color: transparent;
    }

    .el-checkbox {
      pointer-events: none;
    }
  }
}

.node-element {
  border: 1px solid var(--el-border-color);
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 700;

  &:global.el-tree-node {
    height: 42px;
    margin-bottom: 12px;

    &:local.group {
      height: fit-content;
    }

    &.is-checked {
      border: 1px solid var(--el-color-primary);
    }

    &.disabled {
      color: #a8abb2;

      .node > span {
        color: #a8abb2;
      }
    }

    .el-tree-node__content {
      height: 100%;
    }
  }
}
</style>
