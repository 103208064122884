import { reactive, Ref, ref, watch } from "vue";
import { GeneratorDeclaration } from "@/plugins/form-generator-json-v2/types";
import { FormItemRule } from "element-plus";

export const useFormValidation = (declaration: Ref<GeneratorDeclaration>) => {
  const rules = reactive<Record<string, Array<FormItemRule>> | any>({});
  if (declaration.value.form) {
    for (const key in declaration.value.form.rules) {
      rules[key] = ref(declaration.value.form?.rules[key]);
    }
  }
  const setValidationRules = (key: string, value: Record<string, unknown>) => {
    if (!rules[key]) rules[key] = [];
    rules[key] = ref([...rules[key], value]);
  };

  const removeValidationRules = (key: string) => {
    rules[key] = ref([]);
  };

  if (declaration.value.form.rules) {
    watch(declaration.value.form.rules, (newRules) => {
      for (const rule in newRules) {
        rules[rule] = ref(newRules[rule]);
      }
    });
  }

  return {
    rules,
    setValidationRules,
    removeValidationRules,
  };
};
