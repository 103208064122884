import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/feedback_blocks`, {
  item: { include: "feedbacks" },
});

export default service;
export const getFeedbackBlocks = service.list.bind(service);
export const getFeedbackBlockById = service.item.bind(service);
export const createFeedbackBlock = service.create.bind(service);
export const updateFeedbackBlock = service.update.bind(service);
export const deleteFeedbackBlock = service.delete.bind(service);
