<template>
  <el-breadcrumb
    v-if="innerBreadcrumbs && innerBreadcrumbs.length"
    :class="$style['the-breadcrumb']"
  >
    <el-breadcrumb-item
      v-for="(breadcrumb, index) in innerBreadcrumbs"
      :key="index"
      :to="isClickable(breadcrumb, index) ? breadcrumb : null"
    >
      <router-link
        v-if="isClickable(breadcrumb, index)"
        v-slot="{ navigate, href }"
        :to="{
          path: breadcrumb.path || '',
          query: {
            'filter[attributes]': breadcrumb.query['filter[attributes]'],
          },
        }"
        custom
      >
        <a :href="href" @click="navigate">{{ breadcrumb.meta.title }}</a>
      </router-link>
      <template v-else-if="isUseTitleFromProps(breadcrumb)">
        {{ title }}
      </template>
      <template v-else>
        {{ breadcrumb.meta.title }}
      </template>
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script lang="ts" setup>
import { ref, toRefs, watch } from "vue";
import { useRouter } from "vue-router";
import { Breadcrumb, BreadcrumbProps } from "@/components/types";

interface Props extends BreadcrumbProps {
  breadcrumbs?: Breadcrumb[];
  title?: string;
}

const props = withDefaults(defineProps<Props>(), {
  deep: 0,
});

const { breadcrumbs } = toRefs(props);
const router = useRouter();

const generateBreadcrumbs = (): Breadcrumb[] => {
  let breadcrumbsList =
    breadcrumbs.value ||
    router.currentRoute.value.matched.map(
      (routeMatch) => router.resolve(routeMatch) as Breadcrumb
    );

  if (!breadcrumbs.value) {
    const rootBreadcrumb = router.resolve({ path: "/" }) as Breadcrumb;
    breadcrumbsList.unshift(rootBreadcrumb);
  }

  if (props.deep) {
    breadcrumbsList = breadcrumbsList.slice(0, props.deep);
  }

  return breadcrumbsList;
};

const innerBreadcrumbs = ref<Breadcrumb[]>(generateBreadcrumbs());

const isClickable = (breadcrumb: Breadcrumb, index: number): boolean =>
  !(
    index === innerBreadcrumbs.value.length - 1 ||
    !(breadcrumb.meta?.clickable ?? true)
  );

const useTitleFromProps = [
  "Редактирование программы",
  "Редактирование ДКУ",
  "Сезонное хранение",
];
const isUseTitleFromProps = (breadcrumb: Breadcrumb): boolean =>
  !!(useTitleFromProps.includes(breadcrumb.meta.title) && props.title);

watch(
  () => props.breadcrumbs,
  (newBreadcrumbs) => {
    innerBreadcrumbs.value = newBreadcrumbs as Breadcrumb[];
  }
);
</script>

<style lang="scss" module>
.the-breadcrumb:global.el-breadcrumb {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.01px;

  .el-breadcrumb__inner {
    color: var(--el-text-color-secondary);

    &.is-link,
    a {
      font-weight: 400;
      transition: var(--el-transition-color);
      color: var(--el-text-color-secondary);
    }

    &.is-link:hover,
    &:hover a {
      color: var(--el-color-primary);
    }
  }

  .el-breadcrumb__item:last-child > .el-breadcrumb__inner {
    color: var(--el-text-color-primary);
  }
}
</style>
