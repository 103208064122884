<template>
  <el-select
    v-if="filterCols.length"
    v-model="filterValue"
    :class="$style['select']"
    :placement="optionsPlacement"
    :suffix-icon="DCaret"
    @change="changeHandler"
  >
    <el-option
      v-for="item in filterCols"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import { d } from "@/helpers/dictionary";
import DCaret from "@/static/icons/d-caret.svg";
import useSort, { SortOrderType } from "@/composables/hooks/useSort";
import { VariationPlacement } from "@popperjs/core/lib/enums";
import { TableColumn } from "@/model/working-sections/types";

const { setSortProp, setSortOrder } = useSort();

interface Props {
  cols: Record<string, TableColumn>;
  optionsPlacement?: VariationPlacement;
}

const props = withDefaults(defineProps<Props>(), {
  optionsPlacement: "bottom-start",
});

const filterCols = computed(() => {
  const tableCols = props.cols;
  const result = [];

  for (const key in tableCols) {
    if (
      tableCols[key]?.sortable &&
      tableCols[key]?.settings?.visible !== false &&
      !tableCols[key]?.class?.includes("mobile")
    ) {
      const labelText = tableCols[key]?.label ? tableCols[key]?.label : d(key);

      result.push(
        {
          label: `${labelText} (по возрастанию)`,
          value: `${key}:${SortOrderType.asc}`,
        },
        {
          label: `${labelText} (по убыванию)`,
          value: `${key}:${SortOrderType.desc}`,
        }
      );
    }
  }

  return result;
});

const filterValue = ref<string>("");

const changeHandler = () => {
  setSortProp(filterValue.value.split(":")[0] || "id");
  setSortOrder(filterValue.value.split(":")[1] || SortOrderType.desc);
};
</script>

<style lang="scss" module>
.select {
  :global {
    .el-select__wrapper {
      justify-content: center;
      padding: 12px 20px;
      width: min-content;

      @media (max-width: 425px) {
        gap: 0;
      }

      .el-select__caret {
        .d-caret__bottom-arrow {
          fill: var(--el-color-black);
        }

        &.is-reverse {
          .d-caret__bottom-arrow {
            fill: var(--el-color-primary-dark-2);
          }
        }
      }
    }
  }
}
</style>
