import {
  FormItemEventsObjDeclaration,
  FormItemObjDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import {
  createSystemService,
  getSystemServiceById,
  getSystemServices,
} from "@/api/points/auth-api/system_services";
import {
  createSystemObject,
  getSystemObjectById,
  getSystemObjects,
} from "@/api/points/auth-api/system_objects";
import {
  createUsersGroup,
  getUsersGroupById,
  getUsersGroups,
} from "@/api/points/auth-api/users_groups";
import {
  createBrand,
  getBrandById,
  getBrands,
} from "@/api/points/vehicles-api/brands";
import {
  createGeneration,
  getGenerationById,
  getGenerations,
} from "@/api/points/vehicles-api/generations";
import {
  createRegion,
  getRegionById,
  getRegions,
} from "@/api/points/vehicles-api/regions";
import {
  createBody,
  getBodies,
  getBodyById,
} from "@/api/points/vehicles-api/bodies";
import {
  createModel,
  getModelById,
  getModels,
} from "@/api/points/vehicles-api/models";
import {
  createModification,
  getModificationById,
  getModifications,
} from "@/api/points/vehicles-api/modifications";
import {
  createCountry,
  getCountries,
  getCountryById,
} from "@/api/points/vehicles-api/countries";
import { getCounteragents } from "@/api/points/dictionaries-api/counteragents";
import { getCounteragentContracts } from "@/api/points/dictionaries-api/counteragent-contracts";
import { getCounteragentActivities } from "@/api/points/dictionaries-api/counteragent-activities";
import { getGeoRegions } from "@/api/points/dictionaries-api/geo-regions";
import {
  createCommunicationSystem,
  getCommunicationSystems,
} from "@/api/points/communication-api/systems";
import { getBrands as getPIBrands } from "@/api/points/pim-api/brands";

export const defaultAsyncDependentEntity = ({
  model,
  text,
  filteredByList = [
    { model: "name", title: "по имени" },
    { model: "id", title: "по ID" },
  ],
  include = "",
  multiple = false,
  getDataMethod,
  asyncSelectOptionsParams = {},
  getLabelText,
  submitMapDataCallback,
  modalForm = null,
  clearable = false,
  isMassEditingDisabled = false,
  filterValueModalForm = null,
  filterId = "filter[id]",
  events,
  collapseTags = false,
  allOption = false,
  valueKey = "id",
}: {
  model: string;
  getDataMethod: (...arg: any) => Promise<any>;
  asyncSelectOptionsParams?: Record<string, unknown>;
  getLabelText?: (item: any) => string;
  submitMapDataCallback?: (...arg: any) => Promise<any>;
  text: string;
  filteredByList?: Array<{ model: string; title: string; isArray?: boolean }>;
  include?: string;
  multiple?: boolean;
  modalForm?: any;
  clearable?: boolean;
  filterValueModalForm?: any;
  isMassEditingDisabled?: boolean;
  filterId?: string;
  events?: FormItemEventsObjDeclaration;
  collapseTags?: boolean;
  allOption?: boolean;
  valueKey?: string;
}): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model: model,
  element: "async-dependent-entity-select",
  isPayload: true,
  filteredByList,
  filterId,
  include,
  text,
  formItemAttrs: {
    label: text,
  },
  attrs: {
    multiple,
    clearable,
    collapseTags,
    allOption,
    "value-key": valueKey,
  },
  getDataMethod,
  asyncSelectOptionsParams,
  getLabelText,
  submitMapDataCallback,
  modalForm,
  isMassEditingDisabled,
  filterValueModalForm,
  events,
});

export const groups = defaultAsyncDependentEntity({
  model: "groups",
  text: "Группы пользователей",
  include: "groups",
  multiple: true,
  getDataMethod: getUsersGroups,
  submitMapDataCallback: (value) => value.map((item: any) => item.id),
  modalForm: {
    createModalTitle: "Добавить группу",
    form: async () => {
      const { UsersGroups } = await import(
        "@/components/form-declaration/administration/form-declaration-users-groups"
      );
      return UsersGroups;
    },
    method: createUsersGroup,
    afterCreate: getUsersGroupById,
  },
});

export const service_id = defaultAsyncDependentEntity({
  model: "service_id",
  text: "Сервис",
  include: "service",
  getDataMethod: getSystemServices,
  submitMapDataCallback: (value) => value.id,
  modalForm: {
    createModalTitle: "Добавить cервис",
    form: async () => {
      const { Services } = await import(
        "@/components/form-declaration/administration/form-declaration-services"
      );
      return Services;
    },
    method: createSystemService,
    afterCreate: getSystemServiceById,
  },
});

export const object_id = defaultAsyncDependentEntity({
  model: "object_id",
  text: "Объект",
  include: "object",
  getDataMethod: getSystemObjects,
  submitMapDataCallback: (value) => value.id,
  modalForm: {
    createModalTitle: "Добавить объект",
    form: async () => {
      const { Objects } = await import(
        "@/components/form-declaration/administration/form-declaration-objects"
      );
      return Objects;
    },
    method: createSystemObject,
    afterCreate: getSystemObjectById,
  },
});

export const brand_id = defaultAsyncDependentEntity({
  model: "brand_id",
  text: "Марка",
  include: "brand",
  clearable: true,
  filteredByList: [
    { model: "name", title: "по имени" },
    { model: "code", title: "по коду" },
    { model: "id", title: "по ID" },
  ],
  getDataMethod: getBrands,
  submitMapDataCallback: (value) => value.id,
  modalForm: {
    createModalTitle: "Добавить марку",
    form: async () => {
      const { default: brandsCreateForm } = await import(
        "@/declarations/catalog-vehicles/brands/forms/create"
      );

      return brandsCreateForm;
    },
    method: createBrand,
    afterCreate: getBrandById,
  },
});

export const generation_id = defaultAsyncDependentEntity({
  model: "generation_id",
  text: "Поколение",
  include: "generation",
  clearable: true,
  filteredByList: [
    { model: "name", title: "по имени" },
    { model: "code", title: "по коду" },
    { model: "id", title: "по ID" },
  ],
  getDataMethod: getGenerations,
  getLabelText: (item) =>
    `${item?.attributes?.name}
    ${item?.relationships?.model?.relationships?.brand?.attributes?.name}
    ${item?.relationships?.model?.attributes?.name} - id: ${item?.id}`,
  submitMapDataCallback: (value) => value.id,
  modalForm: {
    createModalTitle: "Добавить поколение",
    form: async () => {
      const { default: generationsCreateForm } = await import(
        "@/declarations/catalog-vehicles/generations/forms/create"
      );

      return generationsCreateForm;
    },
    method: createGeneration,
    afterCreate: getGenerationById,
  },
});

export const regions_id = (isFilter = false): FormItemObjDeclaration =>
  defaultAsyncDependentEntity({
    model: isFilter ? "filter[regions_id]" : "regions_id",
    text: "Регионы",
    include: "regions",
    multiple: !isFilter,
    getDataMethod: getRegions,
    submitMapDataCallback: isFilter
      ? (value) => value.id
      : (value) => value?.map((item: any) => item.id),
    modalForm: !isFilter
      ? {
          createModalTitle: "Добавить регион",
          form: async () => {
            const { default: regionsCreateForm } = await import(
              "@/declarations/catalog-vehicles/regions/forms/create"
            );

            return regionsCreateForm;
          },
          method: createRegion,
          afterCreate: getRegionById,
        }
      : null,
  });

export const bodies_id = (isFilter = false): FormItemObjDeclaration =>
  defaultAsyncDependentEntity({
    model: isFilter ? "filter[bodies_id]" : "bodies_id",
    text: "Кузова",
    include: "bodies",
    multiple: !isFilter,
    getDataMethod: getBodies,
    submitMapDataCallback: isFilter
      ? (value) => value.id
      : (value) => value.map((item: any) => item.id),
    modalForm: !isFilter
      ? {
          createModalTitle: "Добавить кузов",
          form: async () => {
            const { default: bodiesCreateForm } = await import(
              "@/declarations/catalog-vehicles/bodies/forms/create"
            );

            return bodiesCreateForm;
          },
          method: createBody,
          afterCreate: getBodyById,
        }
      : null,
  });

export const bodies_id_body_migrations = defaultAsyncDependentEntity({
  model: "body_id",
  text: "Каталог",
  include: "body",
  clearable: true,
  getDataMethod: getBodies,
  submitMapDataCallback: (value) => value.id,
  modalForm: {
    createModalTitle: "Добавить кузов",
    form: async () => {
      const { default: bodiesCreateForm } = await import(
        "@/declarations/catalog-vehicles/bodies/forms/create"
      );

      return bodiesCreateForm;
    },
    method: createBody,
    afterCreate: getBodyById,
  },
});

export const bodies_id_bm = defaultAsyncDependentEntity({
  model: "filter[body_id]",
  text: "Каталог",
  include: "body_id",
  clearable: true,
  multiple: false,
  getDataMethod: getBodies,
  submitMapDataCallback: (value) => value?.id,
});

export const model_id = defaultAsyncDependentEntity({
  model: "model_id",
  text: "Модель",
  include: "model",
  getDataMethod: getModels,
  clearable: true,
  getLabelText: (item) =>
    `${item?.relationships?.brand?.attributes?.name} ${item?.attributes?.name} - id: ${item?.id}`,
  submitMapDataCallback: (value) => value.id,
  modalForm: {
    createModalTitle: "Добавить модель",
    form: async () => {
      const { default: modelsCreateForm } = await import(
        "@/declarations/catalog-vehicles/models/forms/create"
      );

      return modelsCreateForm;
    },
    method: createModel,
    afterCreate: getModelById,
  },
});

export const modification_id = defaultAsyncDependentEntity({
  model: "modification_id",
  text: "Модификация",
  include: "modification",
  getDataMethod: getModifications,
  clearable: true,
  getLabelText: (item) =>
    `${item?.attributes?.name}
    ${item?.relationships?.generation?.attributes?.name}
    ${item?.relationships?.generation?.relationships?.model?.relationships?.brand?.attributes?.name}
    ${item?.relationships?.generation?.relationships?.model?.attributes?.name} - id: ${item?.id}`,
  submitMapDataCallback: (value) => value.id,
  modalForm: {
    createModalTitle: "Добавить модификацию",
    form: async () => {
      const { default: modificationsCreateForm } = await import(
        "@/declarations/catalog-vehicles/modifications/forms/create"
      );

      return modificationsCreateForm;
    },
    method: createModification,
    afterCreate: getModificationById,
  },
});

export const country_id_select = defaultAsyncDependentEntity({
  model: "countries_id",
  text: "Коды стран",
  include: "countries",
  multiple: true,
  getDataMethod: getCountries,
  getLabelText: (item) => `${item.attributes.letter_code} - id: ${item.id}`,
  submitMapDataCallback: (value) => value?.map((item: any) => item.id),
  modalForm: {
    createModalTitle: "Добавить страну",
    form: async () => {
      const { default: countriesCreateForm } = await import(
        "@/declarations/catalog-vehicles/countries/forms/create"
      );

      return {
        ...countriesCreateForm,
        items: countriesCreateForm.items.filter(
          (item) => !["regions_id"].includes(item.name)
        ),
      };
    },
    method: createCountry,
    afterCreate: getCountryById,
  },
});

export const head_counteragent_id_select = defaultAsyncDependentEntity({
  model: "head_counteragent_id",
  text: "Головной контрагент",
  getDataMethod: getCounteragents,
  submitMapDataCallback: (value) => value.id,
});

export const main_contract_id_select = defaultAsyncDependentEntity({
  model: "main_contract_id",
  text: "Основной договор",
  getDataMethod: getCounteragentContracts,
  submitMapDataCallback: (value) => value.id,
});

export const activity_id_select = defaultAsyncDependentEntity({
  model: "activity_id",
  text: "Основной вид деятельности",
  getDataMethod: getCounteragentActivities,
  submitMapDataCallback: (value) => value.id,
});

export const business_region_id_select = defaultAsyncDependentEntity({
  model: "business_region_id",
  text: "Бизнес регион",
  getDataMethod: getGeoRegions,
  submitMapDataCallback: (value) => value.id,
});

export const parent_id_select = defaultAsyncDependentEntity({
  model: "parent_id",
  text: "Родительский регион",
  getDataMethod: getGeoRegions,
  submitMapDataCallback: (value) => value.id,
});

export const communication_system_id_select = defaultAsyncDependentEntity({
  model: "system_id",
  text: "Система",
  include: "system",
  clearable: true,
  getDataMethod: getCommunicationSystems,
  submitMapDataCallback: (value) => value.id,
  filteredByList: [{ model: "name", title: "по имени" }],
  modalForm: {
    createModalTitle: "Добавить систему",
    form: async () => {
      const { Systems } = await import(
        "@/components/form-declaration/communication/form-declaration-systems"
      );

      return {
        ...Systems,
        items: Systems.items.filter(
          (item) => !["system_id"].includes(item.name)
        ),
      };
    },
    method: createCommunicationSystem,
  },
});

export const PIM_brand_id_select = defaultAsyncDependentEntity({
  model: "brand_id",
  text: "Бренд",
  getDataMethod: getPIBrands,
  submitMapDataCallback: (value) => value.id,
});
