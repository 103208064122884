import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/video_presentation_blocks`, {
  item: { include: "videoPresentations" },
});

export default service;
export const getVideoPresentationBlocks = service.list.bind(service);
export const getVideoPresentationBlockById = service.item.bind(service);
export const createVideoPresentationBlock = service.create.bind(service);
export const updateVideoPresentationBlock = service.update.bind(service);
export const deleteVideoPresentationBlock = service.delete.bind(service);
