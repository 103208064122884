<template>
  <el-aside
    :class="[
      $style['aside'],
      {
        [$style['collapse']]: isMenuCollapsed && !isMobile,
        [$style['active']]: isMobileMenuVisible,
      },
    ]"
    width="auto"
  >
    <div :class="$style['aside__top']">
      <router-link :class="$style['aside__logo']" to="/">
        <logo :show-aside="!isMenuCollapsed || isMobile" />
      </router-link>
      <el-icon
        v-if="isMobile"
        size="16"
        @click="setMobileMenuVisibility(false)"
      >
        <close />
      </el-icon>
    </div>
    <div
      v-if="isMobile && isDeclarationSearchVisible"
      :class="$style['aside-search']"
    >
      <the-menu-search />
    </div>
    <div v-else :class="[$style['aside-menu'], 'custom-scroll']">
      <div v-if="isMobile" :class="$style['aside__title']">Категории</div>
      <section>
        <el-menu
          :collapse="isMenuCollapsed && !isMobile"
          :default-active="routeActive"
          router
          unique-opened
        >
          <template v-for="(elem, index) in routerList" :key="index">
            <el-sub-menu
              v-if="elem.children && elem.children.length"
              :index="elem.path"
            >
              <template #title>
                <el-icon v-if="elem.name === 'nipple'" :size="15">
                  <nipple />
                </el-icon>
                <el-icon v-else-if="elem?.meta?.icon">
                  <component :is="elem.meta.icon" />
                </el-icon>
                <span>{{ elem?.meta?.title }}</span>
                <span
                  v-if="elem?.meta?.isDev"
                  :class="$style['aside-menu__mark']"
                  >dev</span
                >
              </template>
              <template
                v-for="(nestedItem, nestedIndex) in elem.children"
                :key="`${index}-${nestedIndex}`"
              >
                <el-menu
                  v-if="
                    nestedItem.isCategory &&
                    nestedItem.children &&
                    nestedItem.children.length
                  "
                  :default-active="routeActive"
                >
                  <el-sub-menu
                    :index="nestedItem.path"
                    :route="nestedItem"
                    :unique-opened="false"
                  >
                    <template #title>
                      <router-link
                        v-slot="{ navigate, href }"
                        :to="nestedItem"
                        custom
                      >
                        <a :href="href" @click="navigate">{{
                          nestedItem?.meta?.title
                        }}</a>
                      </router-link>
                    </template>
                    <el-menu-item
                      v-for="(
                        nestedSubItem, nestedSubIndex
                      ) in nestedItem.children"
                      :key="`${nestedIndex}-${nestedSubIndex}`"
                      :index="nestedSubItem.path"
                      :route="nestedSubItem"
                      class="el-menu-item-flat"
                    >
                      <router-link
                        v-slot="{ navigate, href }"
                        :to="nestedSubItem"
                        custom
                      >
                        <a :href="href" @click="navigate">{{
                          nestedSubItem?.meta?.title
                        }}</a>
                      </router-link>
                    </el-menu-item>
                  </el-sub-menu>
                </el-menu>
                <el-menu-item
                  v-else-if="!nestedItem?.meta?.hiddenRoute"
                  :disabled="nestedItem?.meta?.disabled"
                  :index="nestedItem.path"
                  :route="nestedItem"
                >
                  <router-link
                    v-slot="{ navigate, href }"
                    :to="nestedItem"
                    custom
                  >
                    <a :href="href" @click="navigate">{{
                      nestedItem?.meta?.title
                    }}</a>
                  </router-link>
                </el-menu-item>
              </template>
            </el-sub-menu>
            <el-menu-item
              v-else
              :class="{ 'hide-mobile': elem.path === '/' }"
              :index="elem.path"
              :route="elem"
            >
              <el-icon v-if="elem?.meta?.icon">
                <component :is="elem.meta.icon" />
              </el-icon>
              <span>{{ elem?.meta?.title }}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </section>
    </div>
  </el-aside>
</template>

<script lang="ts" setup>
import { RouteRecordNormalized, useRouter } from "vue-router";
import Logo from "@/components/svg/Logo.vue";
import Nipple from "@/components/svg/Nipple.vue";
import TheMenuSearch from "@/components/the/TheMenuSearch.vue";
import useCollapseMenu from "@/composables/hooks/useCollapseMenu";
import useAdaptive from "@/composables/hooks/useAdaptive";
import useDeclarationSearch from "@/composables/hooks/useDeclarationSearch";
import useMobileMenu from "@/composables/hooks/useMobileMenu";
import { ref, watchEffect } from "vue";
import { Close } from "@element-plus/icons-vue";
import { user } from "@/model/user";

const { isMobile } = useAdaptive();
const { isDeclarationSearchVisible } = useDeclarationSearch();
const { isMobileMenuVisible, setMobileMenuVisibility } = useMobileMenu();
const { isMenuCollapsed } = useCollapseMenu();

const router = useRouter();
const routerList = user.buildMenu(router);
const routeActive = ref<string>("");

const preparePath = (path: string, separator = "/") =>
  path.split(separator).slice(0, 3).join(separator);

watchEffect(() => {
  const currentRoute = preparePath(router.currentRoute.value.path);
  const routes = router
    .getRoutes()
    .filter((route) => preparePath(route.path) == currentRoute);

  const route =
    routes.length === 1
      ? (routes[0] as RouteRecordNormalized)
      : (routes.sort(
          (left, right) => left.path.length - right.path.length
        )[0] as RouteRecordNormalized);
  routeActive.value = route?.path || "/";

  if (isMobile) setMobileMenuVisibility(false);
});
</script>

<style lang="scss" module>
$height: 34px;
$lineHeight: 24px;

.aside {
  overflow: hidden;
  width: 300px;
  max-width: 100%;
  transition: width 0.2s ease-in 0s;
  will-change: width;

  @media (max-width: 640px) {
    width: 100%;
    height: 100vh;
    background: var(--el-color-white);
    box-shadow: 0 -46px 39px rgba(0, 0, 0, 0.25);
    opacity: 0;
    pointer-events: none;
    z-index: 5;
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    transition: opacity 0.3s ease-out;
  }

  &.active {
    @media (max-width: 640px) {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.collapse {
    width: 80px;
    transition: width 0.2s ease-out 0.35s;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;

    @media (max-width: 640px) {
      box-shadow: var(--el-box-shadow-lighter);
      z-index: 1;
      position: relative;
      padding: 8px 16px;
      margin: 0 0 16px;
    }
  }

  &__logo {
    @media (max-width: 640px) {
      height: 32px;
    }
  }

  &__title {
    @media (max-width: 640px) {
      font-size: 20px;
      font-weight: 700;
      padding: 0 16px;
      margin: 0 0 8px;
    }
  }
}

.aside-menu {
  overflow: auto;
  max-height: calc(100vh - 91px);
  padding: 0 16px;

  @media (max-width: 640px) {
    padding: 0 0 120px;
  }

  > :global.el-menu {
    border-right: none;
    overflow: auto;
    padding: {
      right: 16px;
      left: 16px;
    }
  }

  :global {
    .el-menu {
      border-right: none;
      overflow: auto;

      & > .el-menu {
        padding: {
          right: 0;
          left: 22px;
        }
      }

      a {
        width: 100%;
        line-height: $height;
        text-decoration: none;
        color: var(--el-text-color-primary);
      }

      .el-menu-item,
      .el-sub-menu__title {
        border-radius: 4px;
        font-size: 14px;
        line-height: $lineHeight;
        height: fit-content;
        min-height: $height;
        white-space: break-spaces;
        padding: {
          top: 2px;
          bottom: 2px;
          right: 8px;
          left: 8px !important;
        }

        @media (max-width: 640px) {
          background-color: transparent !important;
          padding: 0 16px !important;
        }

        a {
          line-height: $lineHeight;
        }
      }

      .el-sub-menu .el-menu .el-menu-item {
        padding-left: 30px !important;
      }

      .el-sub-menu .el-menu .el-menu-item-flat {
        padding-left: 8px !important;
      }

      .el-menu-item,
      .el-sub-menu {
        .el-icon {
          margin: {
            top: 0;
            right: 4px;
            bottom: 0;
            left: 0;
          }
          width: 18px;
        }
      }

      .el-sub-menu .el-sub-menu__icon-arrow {
        right: 0;
        top: unset;
        padding-right: 0;

        @media (max-width: 640px) {
          right: 16px;
        }
      }

      .el-sub-menu {
        &.is-opened,
        &.is-active {
          .el-sub-menu__title {
            color: var(--el-color-primary);
          }
        }

        .el-menu {
          .el-menu-item {
            @media (max-width: 640px) {
              padding: 0 0 0 40px !important;
            }
          }
        }

        .el-menu-item {
          @media (max-width: 640px) {
            margin: 0 0 4px;
          }
        }

        .el-sub-menu {
          @media (max-width: 640px) {
            border-bottom: none;
          }

          .el-sub-menu__title {
            @media (max-width: 640px) {
              padding: 8px 16px 8px 18px !important;
            }
          }

          .el-menu {
            .el-menu-item {
              @media (max-width: 640px) {
                padding: 0 0 0 20px !important;
              }
            }
          }
        }
      }

      .el-menu-item {
        color: var(--el-text-color-primary);
        transition: background-color 0.3s;
        text-decoration: none;
        color: inherit;
        transition: color 0.3s;

        &:hover,
        &.is-active {
          color: var(--el-color-primary);

          a {
            color: var(--el-color-primary);
          }
        }

        &.is-active {
          background-color: var(--el-color-primary-light-9);
          border-radius: 4px;
        }
      }

      &--collapse {
        width: auto;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        align-items: center;

        .el-sub-menu .el-icon,
        .el-menu-item .el-icon {
          margin: 0;
        }
      }
    }

    .el-menu.el-menu--vertical {
      overflow-x: hidden;
    }

    .el-menu-item.el-menu-item-flat > a {
      white-space: pre-wrap;
    }
  }

  &__mark {
    font-size: 11px;
    color: var(--el-color-error);
    text-transform: uppercase;
    position: relative;
    top: -6px;
    left: 6px;
  }
}

.menu-popper {
  .el-menu--popup .el-menu-item,
  .el-menu--popup .el-sub-menu__title {
    font-size: 14px;
    line-height: $lineHeight;
    height: $height;
  }

  .el-menu--popup .el-menu-item {
    color: var(--el-text-color-primary);
    transition: background-color 0.3s;
    text-decoration: none;
    color: inherit;
    transition: color 0.3s;
  }

  .el-menu--popup .el-menu-item:hover,
  .el-menu--popup .el-menu-item.is-active {
    color: var(--el-color-primary);
    border-radius: 4px;
  }

  .el-menu--popup .el-menu-item.is-active {
    background-color: var(--el-color-primary-light-9);
    border-radius: 4px;
  }

  .el-sub-menu {
    &:hover {
      border-radius: 4px;
    }
  }

  .scroll-overlay {
    bottom: -5px !important;
    margin-top: -25px !important;
  }
}

.hide-mobile {
  @media (max-width: 640px) {
    display: none;
  }
}

.aside-search {
  @media (max-width: 640px) {
    padding: 0 16px;
  }
}

:global {
  .el-menu-item {
    &.is-disabled {
      pointer-events: none;
    }
  }
}
</style>
