import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/franchisee_incomes`);

export default service;
export const getFranchiseeIncomes = service.list.bind(service);
export const getFranchiseeIncomeById = service.item.bind(service);
export const createFranchiseeIncome = service.create.bind(service);
export const updateFranchiseeIncome = service.update.bind(service);
export const deleteFranchiseeIncome = service.delete.bind(service);
