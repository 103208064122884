import getRelationship from "@/helpers/getRelationship";
import { Service } from "@/api/core/service";
import { PresetValues } from "@/plugins/form-generator-json-v2/types";

export function useWheels(): {
  updateOrCreateWheels: ({
    payload,
    updatedRowId,
    service,
  }: {
    payload: Record<string, any>;
    updatedRowId?: number | undefined;
    service: InstanceType<typeof Service>;
  }) => Promise<any>;
  getWheelsPresetValues: ({
    presetData,
    data,
  }: {
    presetData: Record<string, any>;
    data: Record<string, any>;
  }) => Record<string, any>;
  getWheelsToggledData: (
    active: boolean,
    data: Record<string, any>
  ) => Record<string, any>;
} {
  async function updateOrCreateWheels({
    payload,
    updatedRowId,
    service,
  }: {
    payload: Record<string, any>;
    updatedRowId?: number;
    service: InstanceType<typeof Service>;
  }) {
    const wheelsPayload: Record<string, any> = {};
    let front_id = null;
    const isWheelPair =
      payload.type === "front-rear" &&
      payload.front &&
      payload.rear &&
      Object.keys(payload.front).length &&
      Object.keys(payload.rear).length;
    const commonKeys = ["active", "modification_id", "is_stock", "type"];
    const frontRearKeys = [
      "bitrix_id",
      "external_code",
      "id",
      "is_stock",
      "load_index",
      "modification_id",
      "rim_diameter",
      "rim_offset",
      "rim_width",
      "tire_aspect_ratio",
      "tire_construction",
      "tire_diameter",
      "tire_pressure",
      "tire_section_width",
      "tire_size",
      "tire_sizing_system",
      "tire_width",
      "speed_index",
      "is_strength",
    ];

    if (isWheelPair) {
      wheelsPayload.front = {};
      wheelsPayload.rear = {};
      frontRearKeys.forEach((key) => {
        wheelsPayload.front[key] = payload.front[key];
        wheelsPayload.rear[key] = payload.rear[key];
      });
      commonKeys.forEach((key) => {
        wheelsPayload.front[key] = payload[key];
        wheelsPayload.rear[key] = payload[key];
      });

      if (!updatedRowId) {
        wheelsPayload.front.type = "front";
        wheelsPayload.rear.type = "rear";
      } else {
        delete wheelsPayload.front.type;
        delete wheelsPayload.rear.type;
      }

      front_id = payload.rear.front_id;
    } else {
      wheelsPayload.front = {};
      frontRearKeys.forEach(
        (key) => (wheelsPayload.front[key] = payload.front[key])
      );
      commonKeys.forEach((key) => (wheelsPayload.front[key] = payload[key]));
    }

    let result = null;

    if (updatedRowId) {
      result = await service.update(front_id ?? updatedRowId, wheelsPayload);
    } else {
      result = await service.create(wheelsPayload);
    }

    return result;
  }

  function getWheelsPresetValues({
    presetData,
    data,
  }: {
    presetData: Record<string, any>;
    data: Record<string, any>;
  }) {
    const presetValues: PresetValues = {};
    const frontRelation = getRelationship("front", data);
    const rearRelation = getRelationship("rear", data);

    if (!frontRelation && !rearRelation) {
      presetValues.front = {
        ...presetData,
        ...data.attributes,
      };
      return presetValues;
    }

    presetValues[frontRelation ? "rear" : "front"] = {
      active: data.attributes.active,
      modification_id: data.attributes.modification_id,
      rim_width: data.attributes.rim_width,
      rim_diameter: data.attributes.rim_diameter,
      rim_offset: data.attributes.rim_offset,
      tire_width: data.attributes.tire_width,
      tire_diameter: data.attributes.tire_diameter,
      tire_aspect_ratio: data.attributes.tire_aspect_ratio,
      is_stock: data.attributes.is_stock,
      type: data.attributes.type,
      tire_sizing_system: data.attributes.tire_sizing_system,
      tire_construction: data.attributes.tire_construction,
      speed_index: data.attributes.speed_index,
      tire_section_width: data.attributes.tire_section_width,
      tire_pressure: data.attributes.tire_pressure,
      load_index: data.attributes.load_index,
      is_strength: data.attributes.is_strength,
    };
    if (frontRelation) {
      presetValues.rear.front_id = frontRelation.id;
    }

    const secondRelation = frontRelation ? frontRelation : rearRelation;
    presetValues[frontRelation ? "front" : "rear"] = {
      active: secondRelation.attributes.active,
      modification_id: secondRelation.attributes.modification_id,
      rim_width: secondRelation.attributes.rim_width,
      rim_diameter: secondRelation.attributes.rim_diameter,
      rim_offset: secondRelation.attributes.rim_offset,
      tire_width: secondRelation.attributes.tire_width,
      tire_diameter: secondRelation.attributes.tire_diameter,
      tire_aspect_ratio: secondRelation.attributes.tire_aspect_ratio,
      is_stock: secondRelation.attributes.is_stock,
      type: secondRelation.attributes.type,
      tire_sizing_system: secondRelation.attributes.tire_sizing_system,
      tire_construction: secondRelation.attributes.tire_construction,
      speed_index: secondRelation.attributes.speed_index,
      tire_section_width: secondRelation.attributes.tire_section_width,
      tire_pressure: secondRelation.attributes.tire_pressure,
      load_index: secondRelation.attributes.load_index,
      is_strength: secondRelation.attributes.is_strength,
    };

    return presetValues;
  }

  function getWheelsToggledData(active: boolean, data: Record<string, any>) {
    let toogledData = { front: { active: active } };

    if (data.relationships?.rear?.attributes) {
      toogledData = {
        ...toogledData,
        ...{ rear: { active: active } },
      };
    }

    return toogledData;
  }

  return {
    updateOrCreateWheels,
    getWheelsPresetValues,
    getWheelsToggledData,
  };
}
