<template>
  <section>
    <section :class="$style['top']">
      <el-button type="success" @click="emit('save', selectionRef)">
        Сохранить
      </el-button>
      <el-button plain type="default" @click="emit('cancel')">
        Отменить
      </el-button>
      <massive-drop
        :click-massive-menu-handler="clickMassiveMenuHandler"
        :create-form="createForm"
        :is-create-in="isCreateIn"
        :is-mass-editing="false"
        :is-wizard="isWizard"
      />
    </section>
    <section :class="$style['table-wrapper']">
      <el-form ref="form" :model="selectionRef" :rules="rules">
        <the-scroll-controls v-if="!isMobile" :dataList="selectionRef" />
        <el-table
          ref="tableRef"
          :cell-class-name="cellClassName"
          :data="selectionRef"
          :expand-row-keys="expandedRows.map((row) => row.id)"
          :row-class-name="rowClassName"
          :row-key="(row) => row.id"
          table-layout="fixed"
          @expand-change="onExpandChange"
        >
          <el-table-column
            :class-name="`${$style['bulk']} ${$style['selectable-disabled']}`"
            type="selection"
            width="56"
          />
          <el-table-column
            :class-name="`${$style['table-burger']} ${$style['no-right-border']}`"
            width="80"
          >
            <el-button
              :class="$style['burger-button']"
              :icon="MoreFilled"
              size="small"
            />
          </el-table-column>
          <el-table-column v-if="hasExpandColumn()" type="expand" width="34">
            <template #default="scope">
              <el-table
                :class="$style['expand-table']"
                :data="expandedRows.filter((row: Record<string, any>) => row.id === scope.row.id)"
                :row-class-name="rowClassName"
                :show-header="false"
                table-layout="auto"
              >
                <el-table-column width="56" />
                <el-table-column width="114" />
                <el-table-column
                  v-for="(expandCol, key) in massEditCols"
                  :key="key"
                  :align="expandCol.align || 'left'"
                  :class-name="`${expandCol.key} ${expandCol.class}`"
                  :prop="expandCol.key"
                  :sortable="false"
                  :type="void 0"
                  v-bind="expandCol"
                >
                  <template #default>
                    <template v-if="isWheelEditing && key === 'type'">
                      {{
                        wheelTypeText(scope.row.relationships?.rear?.attributes)
                      }}
                    </template>
                    <el-form-item
                      v-else-if="
                        expandedColumns.includes(key) &&
                        scope.row.relationships?.rear
                      "
                      :error="scope.row?.formItemAttrs?.error[key]"
                      :prop="`[${scope.$index}]${expandTarget.reduce((acc: string, value: string) => `${acc}[${value}]`, '')}[${key}]`"
                      :rules="rules[key]"
                    >
                      <span
                        :class="{
                          'cell-error': !isValid(
                            rules,
                            scope.row.relationships?.rear?.attributes,
                            key
                          ),
                        }"
                      >
                        <the-mobile-table-title :propKey="key" />
                        <component
                          :is="
                            renderElement(
                              expandCol,
                              key,
                              scope.row.relationships?.rear?.attributes || {},
                              scope.$index
                            )
                          "
                        />
                        <el-popover
                          v-if="!ignoreModels.includes(key)"
                          :content="
                            getValidateMessage(
                              rules,
                              scope.row.relationships?.rear?.attributes,
                              key
                            )
                          "
                          :popper-class="$style['validate-popover']"
                          placement="top-end"
                          trigger="hover"
                          width="220"
                        >
                          <template #reference>
                            <el-icon class="fail custom-icon" size="14px">
                              <WarningFilled />
                            </el-icon>
                          </template>
                        </el-popover>
                      </span>
                    </el-form-item>
                    <template v-else>
                      <template v-if="isActive(key)">
                        <el-tooltip
                          :content="scope.row[key] ? 'Активный' : 'Неактивный'"
                          class="box-item"
                          effect="light"
                          placement="top"
                          popper-class="tooltip-default"
                        >
                          <div
                            :class="[
                              'dot',
                              {
                                success: scope.row[key],
                                inactive: !scope.row[key],
                              },
                            ]"
                          />
                        </el-tooltip>
                      </template>
                      <template v-else-if="key === 'binding'">
                        <el-icon v-if="!scope.row[key]" class="success">
                          <circle-check />
                        </el-icon>
                        <el-icon v-else class="fail">
                          <icon-chain />
                        </el-icon>
                      </template>
                      <template
                        v-else-if="expandCol.type === ColumnType.boolean"
                      >
                        <template v-if="scope.row[key]">
                          <el-icon class="success">
                            <circle-check-filled />
                          </el-icon>
                        </template>
                        <template v-if="!scope.row[key]">
                          <el-icon class="fail">
                            <circle-close-filled />
                          </el-icon>
                        </template>
                      </template>
                      <template v-else-if="key === 'attributes'">
                        <el-icon
                          v-if="scope.row.attributes_status"
                          class="success"
                        >
                          <circle-check />
                        </el-icon>
                        <div v-else class="fail">
                          <el-icon>
                            <icon-chain />
                          </el-icon>
                          <span
                            v-if="scope.row.attributes_text"
                            class="spaced"
                            >{{ scope.row.attributes_text }}</span
                          >
                        </div>
                      </template>
                      <template
                        v-else-if="expandCol.type === ColumnType.activity"
                      >
                        <template v-if="scope.row[key]">
                          <el-tag> Активный</el-tag>
                        </template>
                        <template v-if="!scope.row[key]">
                          <el-tag type="danger"> Неактивный</el-tag>
                        </template>
                      </template>
                      <template
                        v-else-if="expandCol.type === ColumnType.picture"
                      >
                        <template
                          v-if="
                            expandCol.isRelationship &&
                            Array.isArray(scope.row?.relationships?.images) &&
                            scope.row?.relationships?.images.length
                          "
                        >
                          <img
                            :src="`${PUBLIC_SERVICE_POINT_NAME}/pim/${expandCol.objectType}/stream/${scope.row.relationships.images[0].id}?width=100`"
                            alt="picture"
                            height="50"
                            width="50"
                          />
                        </template>
                        <template
                          v-else-if="
                            expandCol.isRelationship &&
                            Array.isArray(scope.row?.relationships?.logo) &&
                            scope.row?.relationships?.logo.length
                          "
                        >
                          <img
                            :src="`${PUBLIC_SERVICE_POINT_NAME}/vehicles/${scope.row?.relationships?.logo[0].attributes.object_type}/stream/${scope.row.relationships.logo[0].id}?width=50`"
                            alt="picture"
                            height="50"
                            width="50"
                          />
                        </template>
                        <template v-else>
                          <span> - </span>
                        </template>
                      </template>
                      <template v-else-if="expandCol.type === ColumnType.date">
                        {{ scope.row[key] ? formatDate(scope.row[key]) : "-" }}
                      </template>
                      <template
                        v-else-if="
                          expandCol.isRelationship &&
                          Array.isArray(scope.row?.relationships?.[key])
                        "
                      >
                        <div
                          v-for="item in scope.row?.relationships?.[key]"
                          :key="item?.id"
                        >
                          <el-icon>
                            <Phone v-if="item?.attributes?.type === 'phone'" />
                            <Message
                              v-if="item?.attributes?.type === 'email'"
                            />
                          </el-icon>
                          <template v-if="expandCol.displayFields">
                            <template
                              v-for="(field, key) in expandCol.displayFields"
                              :key="key"
                            >
                              {{
                                item.type === key
                                  ? item?.attributes[field]
                                  : "-"
                              }}
                            </template>
                          </template>
                          <template v-else>
                            {{
                              item?.attributes?.name ||
                              item?.attributes?.data ||
                              "-"
                            }}
                          </template>
                        </div>
                      </template>
                      <template
                        v-else-if="
                          expandCol.isRelationship &&
                          !Array.isArray(scope.row?.relationships?.[key])
                        "
                      >
                        <div :class="$style['expand-no-input']">
                          {{
                            getRelationship(key, scope.row)?.attributes?.name ||
                            getRelationship(key, scope.row)?.attributes?.data ||
                            "-"
                          }}
                        </div>
                      </template>
                      <template
                        v-else-if="
                          typeof expandCol.type === ColumnType.function
                        "
                      >
                        <router-link
                          v-if="expandCol.class === $style['amount']"
                          :to="`${expandCol.type(scope.row)?.link}${
                            filterAttributes
                              ? '?filter[attributes]=' + filterAttributes
                              : ''
                          }`"
                        >
                          <div></div>
                          <div>{{ expandCol.type(scope.row)?.value }}</div>
                          <div>
                            <el-icon :size="16">
                              <arrow-right />
                            </el-icon>
                          </div>
                        </router-link>
                        <component
                          :is="expandCol.type(scope.row, key)"
                          v-else-if="expandCol.class == $style['tsx']"
                        />
                        <template v-else>
                          {{ expandCol.type(scope.row, key) }}
                        </template>
                      </template>
                      <template v-else-if="expandCol.selectOptions">
                        <div :class="$style['expand-no-input']">
                          {{
                            expandCol.selectOptions.find(
                              (item: any) => item.value === scope.row[key]
                            )?.label ||
                            scope.row[key] ||
                            "-"
                          }}
                        </div>
                      </template>
                      <template v-else-if="key === 'openModal'">
                        <slot
                          :column="scope.column"
                          :row="scope.row"
                          name="openModal"
                        />
                      </template>
                      <template v-else-if="key === 'openItem'">
                        <slot
                          :column="scope.column"
                          :row="scope.row"
                          name="openItem"
                        />
                      </template>
                      <template>
                        <template v-if="scope.row[key] === 0">0</template>
                        <template v-else>
                          {{ scope.row[key] || "-" }}
                        </template>
                      </template>
                    </template>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>
          <el-table-column
            v-for="(col, key) in massEditCols"
            :key="key"
            :align="col.align || 'left'"
            :class-name="col.class"
            :label="col.label || d(key)"
            :prop="key"
            :sortable="false"
            :type="void 0"
            v-bind="col"
          >
            <template #header>
              <div :data-id="key"></div>
              <el-popover
                :content="col.label || d(key)"
                :offset="2"
                :popper-class="$style['custom-style-el-popover']"
                :trigger="col ? 'hover' : 'click'"
                placement="top"
              >
                <template #reference>
                  <span :class="{ [$style['custom-head']]: col.headerPadding }">
                    {{
                      (col.label || d(key)).length > 15
                        ? (col.label || d(key)).slice(0, 15) + "..."
                        : col.label || d(key)
                    }}
                    <el-icon
                      v-if="col.noModified"
                      :class="$style['header-icon']"
                      size="14px"
                    >
                      <Lock />
                    </el-icon>
                  </span>
                </template>
              </el-popover>
            </template>
            <template #default="scope">
              <template v-if="col.children">
                <el-table-column
                  v-for="(childrenCol, index) in col.children"
                  :key="`${childrenCol.key}-${index}`"
                  :align="childrenCol.align || 'left'"
                  :class-name="$style[childrenCol.class]"
                  :label="
                    childrenCol.label !== undefined
                      ? childrenCol.label
                      : d(childrenCol.key)
                  "
                  :prop="childrenCol.key"
                  :sortable="false"
                  :type="void 0"
                  v-bind="childrenCol"
                >
                  <template #defaul="innerScope">
                    {{ innerScope.row || "-" }}
                  </template>
                </el-table-column>
              </template>
              <template v-else-if="isWheelEditing && key === 'type'">
                {{ wheelTypeText(scope.row) }}
              </template>
              <template v-else-if="isActive(key)">
                <div
                  v-tooltip="{
                    text: scope.row[key] ? 'Активный' : 'Неактивный',
                  }"
                  :class="[
                    'dot',
                    {
                      success: scope.row[key],
                      inactive: !scope.row[key],
                    },
                  ]"
                />
              </template>
              <el-form-item
                v-else
                :error="scope.row?.formItemAttrs?.error[key]"
                :prop="`[${scope.$index}][${key}]`"
              >
                <span
                  :class="{
                    'cell-error': !isValid(rules, scope.row, key),
                  }"
                >
                  <the-mobile-table-title :col="col" :propKey="key" />
                  <component
                    :is="renderElement(col, key, scope.row, scope.$index)"
                    :is-code-editable="isCodeEditable"
                  />
                  <el-popover
                    v-if="!ignoreModels.includes(key)"
                    :content="getValidateMessage(rules, scope.row, key)"
                    :popper-class="$style['validate-popover']"
                    placement="top-end"
                    trigger="hover"
                    width="220"
                  >
                    <template #reference>
                      <el-icon class="fail custom-icon" size="14px">
                        <WarningFilled />
                      </el-icon>
                    </template>
                  </el-popover>
                </span>
              </el-form-item>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </section>
  </section>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent } from "vue";

export default defineComponent({
  components: {
    "base-input": defineAsyncComponent({
      loader: () => import("@/components/base/input/BaseInput.vue"),
    }),
    "base-input-range": defineAsyncComponent({
      loader: () => import("@/components/base/input/BaseInputRange.vue"),
    }),
    "base-input-action-button": defineAsyncComponent({
      loader: () => import("@/components/base/input/BaseInputActionButton.vue"),
    }),
    "base-button": defineAsyncComponent({
      loader: () => import("@/components/base/button/BaseButton.vue"),
    }),
    "base-select": defineAsyncComponent({
      loader: () => import("@/components/base/select/BaseSelect.vue"),
    }),
    "multiple-select": defineAsyncComponent({
      loader: () => import("@/components/base/select/BaseSelect.vue"),
    }),
    "base-counter": defineAsyncComponent({
      loader: () => import("@/components/base/BaseCounter.vue"),
    }),
    "base-checkbox": defineAsyncComponent({
      loader: () => import("@/components/base/BaseCheckbox.vue"),
    }),
    "base-radio": defineAsyncComponent({
      loader: () => import("@/components/base/BaseRadio.vue"),
    }),
    "base-slider": defineAsyncComponent({
      loader: () => import("@/components/base/BaseSlider.vue"),
    }),
    "base-date-picker": defineAsyncComponent({
      loader: () => import("@/components/base/BaseDatePicker.vue"),
    }),
    "base-rate": defineAsyncComponent({
      loader: () => import("@/components/base/BaseRate.vue"),
    }),
    "base-switch": defineAsyncComponent({
      loader: () => import("@/components/base/BaseSwitch.vue"),
    }),
    "base-time-picker": defineAsyncComponent({
      loader: () => import("@/components/base/BaseTimePicker.vue"),
    }),
    "base-time-select": defineAsyncComponent({
      loader: () => import("@/components/base/select/BaseTimeSelect.vue"),
    }),
    "base-upload": defineAsyncComponent({
      loader: () => import("@/components/base/upload/BaseUpload.vue"),
    }),
    "base-upload-avatar": defineAsyncComponent({
      loader: () => import("@/components/base/upload/BaseUploadAvatar.vue"),
    }),
    "base-upload-logo": defineAsyncComponent({
      loader: () => import("@/components/base/upload/BaseUploadLogo.vue"),
    }),
    "base-upload-file": defineAsyncComponent({
      loader: () => import("@/components/base/upload/BaseUploadFile.vue"),
    }),
    "base-upload-image": defineAsyncComponent({
      loader: () => import("@/components/base/upload/BaseUploadImage.vue"),
    }),
    "base-upload-card-image": defineAsyncComponent({
      loader: () => import("@/components/base/upload/BaseUploadCardImage.vue"),
    }),
    "base-async-dependent-entity-select": defineAsyncComponent({
      loader: () =>
        import("@/components/base/select/BaseAsyncDependentEntitySelect.vue"),
    }),
    "base-tree-command": defineAsyncComponent({
      loader: () => import("@/components/base/BaseTreeCommand.vue"),
    }),
    "base-collapse-container": defineAsyncComponent({
      loader: () =>
        import("@/components/base/collapse/BaseCollapseContainer.vue"),
    }),
    "base-collapse-item": defineAsyncComponent({
      loader: () => import("@/components/base/collapse/BaseCollapseItem.vue"),
    }),
    "base-tag-input": defineAsyncComponent({
      loader: () => import("@/components/base/input/BaseTagInput.vue"),
    }),
    "base-range-input": defineAsyncComponent({
      loader: () => import("@/components/base/input/BaseRangeInput.vue"),
    }),
    "base-array-input": defineAsyncComponent({
      loader: () => import("@/components/base/input/BaseArrayInput.vue"),
    }),
    "base-number-input": defineAsyncComponent({
      loader: () => import("@/components/base/input/BaseNumberInput.vue"),
    }),
    "base-select-input": defineAsyncComponent({
      loader: () => import("@/components/base/input/BaseSelectInput.vue"),
    }),
    "base-editable-table": defineAsyncComponent({
      loader: () => import("@/components/base/table/BaseEditableTable.vue"),
    }),
    "base-simple-listing": defineAsyncComponent({
      loader: () => import("@/components/base/BaseSimpleListing.vue"),
    }),
  },
});
</script>

<script lang="ts" setup>
import {
  ref,
  toRaw,
  h,
  resolveDynamicComponent,
  onMounted,
  computed,
  reactive,
  watch,
  onUnmounted,
  nextTick,
  onBeforeMount,
} from "vue";
import { d } from "@/helpers/dictionary";
import useSelection from "@/composables/hooks/useSelection";
import { GeneratorDeclaration } from "@/plugins/form-generator-json-v2/types";
import getRelationship from "@/helpers/getRelationship";
import { getFormElementComponentName } from "@/plugins/form-generator-json-v2/core";
import TheScrollControls from "@/components/the/TheScrollControls.vue";
import { CellCls } from "element-plus/es/components/table/src/table/defaults";
import {
  CircleCheck,
  CircleCheckFilled,
  CircleCloseFilled,
  ArrowRight,
  Phone,
  Message,
  WarningFilled,
  Lock,
  MoreFilled,
} from "@element-plus/icons-vue";
import iconChain from "@/static/icons/chain.svg";
import { PUBLIC_SERVICE_POINT_NAME } from "@/api/points/files-api/service-config";
import formatDate from "@/helpers/formatDate";
import { useRouter } from "vue-router";
import { isActive, isContacts } from "@/helpers/сomparisonStaticValues";
import { isValid, getValidateMessage } from "@/helpers/massValidate";
import wheelTypeText from "@/helpers/wheelTypeText";
import useAdaptive from "@/composables/hooks/useAdaptive";
import MassiveDrop from "@/components/working-sections/default/components/actions/MassiveDrop.vue";
import TheMobileTableTitle from "@/components/the/TheMobileTableTitle.vue";
import vTooltip from "@/directives/vTooltip";
import { ColumnType } from "@/model/working-sections/constants";
import { ICommand } from "@/components/working-sections/default/components/actions/types";

const { isMobile } = useAdaptive();

interface Props {
  isWizard: boolean;
  isCreateIn: boolean;
  declaration: GeneratorDeclaration;
  cols: Record<string, any>;
  expandedRows: Record<string, any>[];
  filterAttributes?: any;
  cellClassName?: CellCls<any>;
  isCodeEditable?: boolean;
  clickMassiveMenuHandler: (command: ICommand) => Promise<void>;
  createForm: GeneratorDeclaration;
  ignoreModels: string[];
}

const props = withDefaults(defineProps<Props>(), {
  expandedRows: () => [],
});
const emit = defineEmits(["save", "cancel"]);

const router = useRouter();
const tableRef = ref();
const { selection } = useSelection();
const selectionRef = ref(toRaw(selection.value));
const cols = reactive(toRaw(props.cols));
const rules = reactive<any>({});
const form = ref();
const reactiveDeclaration = ref<GeneratorDeclaration>(props.declaration);
const expandedRows = ref<Record<string, any>[]>(props.expandedRows);
const expandTarget = ref<string[]>([]);
const ignoreModels = ref<string[]>(props.ignoreModels);

const isWheelEditing =
  router.currentRoute.value.path === "/catalog-vehicles/wheels";

if (isWheelEditing) {
  expandTarget.value = ["relationships", "rear", "attributes"];
}

const colsWithInputs = reactive<Record<string, any>>({});
const colsWithHiddenButton = reactive<Record<string, any>>({});
const colsWithoutButton = reactive<Record<string, any>>({});
const noModifiedCols = reactive<Record<string, any>>({});
const massEditCols = ref<Record<string, any>>({});

reactiveDeclaration.value?.items.forEach((el) => {
  if (
    !el.isService &&
    (el.element.includes("select") || el.element.includes("input")) &&
    el.include
  ) {
    colsWithInputs[el.name] = colsWithInputs[el.include] = true;
  }

  if (
    el.include &&
    (el.element === "async-dependent-entity-select" ||
      ((el.element === "multiple-select" || el.element === "select") &&
        el.modalForm &&
        !el.isMassEditingDisabled &&
        !el?.attrs?.filterable))
  ) {
    colsWithHiddenButton[el.name] = colsWithHiddenButton[el.include] = true;
  }

  if (el.element === "input-action-button") {
    colsWithoutButton[el.name] = colsWithoutButton[el?.include] = true;
  }

  if ((el.isMassEditingDisabled || el.isService) && el.include) {
    noModifiedCols[el.name] = noModifiedCols[el.include] = true;
  }
});

onBeforeMount(() => modifyColumns());

const modifyColumns = () => {
  massEditCols.value = Object.entries(cols).reduce(
    (accumulator, [key, col]) => {
      if (col?.settings?.visible === false || col?.onlyEdit) {
        return { ...accumulator };
      }

      const modifiedCol = {
        ...col,
        headerPadding: !!colsWithInputs[key],
        noModified: !!noModifiedCols[key],
        class:
          col.class ||
          "" +
            (colsWithHiddenButton[key] ? " hide-button" : "") +
            (colsWithoutButton[key] ? " no-button" : "") +
            (isActive(key) ? " no-right-border" : ""),
        ...(isActive(key) && { width: 10 }),
      };

      delete modifiedCol.sortable;
      delete modifiedCol.align;

      return { ...accumulator, [key]: modifiedCol };
    },
    {}
  );
};

for (const key in reactiveDeclaration.value?.form?.rules) {
  rules[key] = ref(reactiveDeclaration.value.form?.rules[key]);
}

watch(selection, (newSelection) => {
  if (!newSelection.length) {
    emit("cancel");
  } else {
    newSelection = toRaw(newSelection);
    newSelection = newSelection.map((item: any) => toRaw(item));
    selectionRef.value = newSelection;
  }
});

onMounted(() => {
  nextTick(() => {
    tableRef.value && tableRef.value.toggleAllSelection();
  });
});

watch(selectionRef.value, () => {
  if (tableRef.value && !tableRef.value.getSelectionRows().length) {
    tableRef.value && tableRef.value.toggleAllSelection();
  }
});

onUnmounted(() => {
  reactiveDeclaration.value.items.forEach((item: any) => {
    if (item.element.indexOf("select") !== -1) {
      item.attrs["collapse-tags"] = false;
      item.attrs["hide-selected-filter"] = false;
      if (item.modalForm && item.usePopupFilter) {
        item.usePopupFilter = false;
      }
    }

    if (item.isMassEditingDisabled) {
      item.attrs.disabled = false;
    }
  });
});

const getItemsRelationships = () => {
  for (const item of reactiveDeclaration.value.items || []) {
    for (const selectionItem of selectionRef.value) {
      selectionItem[item.model] = item.include
        ? getRelationship(item.include, selectionItem)
        : selectionItem[item.model];

      if (isContacts(item.model) && selectionItem.relationships?.contacts) {
        const contactsData = selectionItem.relationships.contacts.find(
          (el: Record<string, any>) => {
            return el?.attributes?.type === item.model;
          }
        );

        selectionItem[item.model] = contactsData?.attributes?.data;
      }
    }
  }
};

getItemsRelationships();

const renderElement = (col: any, element: any, data: any, index: number) => {
  const formItem = reactiveDeclaration.value.items.find(
    (item: any) => item.model === element || item.include === element
  );
  if (!formItem || ignoreModels.value.includes(formItem.model)) {
    return h("div", data[element] ?? "-");
  }

  let colData = data[formItem.model];
  if (formItem.model === "wsBody") {
    colData = data.relationships.wsBody.attributes.name;
    selectionRef.value[index].wsBody =
      data.relationships.wsBody.attributes.name;
  }

  if (colData && colData.constructor === Array) {
    colData = colData.join(", ");
  } else if (colData) {
    if (col.type === ColumnType.date) {
      colData = formatDate(colData);
    }
  } else if (colData === 0) {
    colData = "0";
  } else {
    colData = "-";
  }
  if (formItem.isService) {
    return h("span", { ...formItem.props }, colData);
  }

  if (!formItem.attrs) {
    formItem.attrs = {};
  }

  if (formItem.element.indexOf("select") !== -1) {
    formItem.attrs["collapse-tags"] = isMobile.value;

    if (formItem.filterValueModalForm || formItem.isMassEditingDisabled) {
      formItem.attrs["hide-selected-filter"] = true;
    }
    if (formItem.modalForm && !formItem.isMassEditingDisabled) {
      formItem.usePopupFilter = true;
    }
  }

  if (formItem.isMassEditingDisabled) {
    formItem.attrs.disabled = true;
  }

  if (formItem.id === "code" && !props.isCodeEditable) {
    formItem.attrs.readlonly = true;
  }

  let isExpandColumn = false;

  if (isWheelEditing && !data?.relationships) {
    isExpandColumn = true;
  }

  formItem.id === "code" &&
    props.isCodeEditable &&
    (formItem.attrs.readonly = false);

  const itemAttrs = Object.assign({}, formItem?.attrs);
  const componentName = getFormElementComponentName(
    formItem,
    {
      formModel: selectionRef.value[index],
      isCodeEditable: props.isCodeEditable,
    } as any,
    isExpandColumn
      ? selectionRef.value[index].relationships?.rear?.attributes
      : selectionRef.value[index]
  );
  const component = resolveDynamicComponent(componentName.componentName);
  const renderComponent = h(component as string, componentName.componentProps);
  return h(renderComponent, { ...formItem.props, ...itemAttrs });
};

const onExpandChange = (
  _row: Record<string, any>,
  rows: Record<string, any>[]
) => {
  expandedRows.value = rows;
};

const hasExpandColumn = () => {
  return !!props.declaration.items.find((column) => {
    return (
      column.model === "type" &&
      column.selectOptions?.find((option) => option.value === "front")
    );
  });
};

const expandedColumns = computed(() => {
  if (!selectionRef.value.length) {
    return [];
  }

  if (isWheelEditing) {
    return [
      "id",
      "type",
      "rim_width",
      "rim_diameter",
      "rim_offset",
      "tire_width",
      "tire_diameter",
      "tire_aspect_ratio",
      "tire_sizing_system",
      "tire_construction",
      "tire_pressure",
      "speed_index",
      "load_index",
      "is_strength",
    ];
  } else {
    return [];
  }
});

const rowClassName = ({ row }: { row: any }) => {
  let classRow = "";

  if (isWheelEditing && !row.relationships?.rear) {
    classRow += "no-expand";
  }

  for (const key in row) {
    if (
      !ignoreModels.value.includes(key) &&
      (isValidMassEditRow(row, key) ||
        (row.relationships?.rear?.attributes &&
          isValidMassEditRow(row.relationships.rear.attributes, key)))
    ) {
      classRow += " error-row";
      break;
    }
  }

  return classRow;
};

const isValidMassEditRow = (row: any, key: string): boolean => {
  const rawMassEditCols = toRaw(massEditCols);
  return (
    (key in rawMassEditCols || key.replace("_id", "") in rawMassEditCols) &&
    !isValid(rules, row, key)
  );
};
</script>

<style lang="scss" module>
.top {
  display: flex;
  gap: 12px;
  align-items: flex-start;
  padding: 40px 0 16px 40px;

  @media (max-width: 640px) {
    padding: 24px 16px 16px;
  }

  :global {
    button {
      margin: 0;
    }

    .el-button + .el-button {
      margin: 0;
    }
  }
}

.selectable-disabled :global .el-checkbox__inner {
  background-color: var(--el-checkbox-disabled-input-fill);
  border-color: var(--el-checkbox-disabled-border-color);
  cursor: not-allowed;

  &::after {
    border: 1px solid var(--el-text-color-placeholder);
    border-left: 0;
    border-top: 0;
    border-width: 2.5px !important;
  }
}

.table-wrapper {
  height: 100%;
  max-height: 100%;
  padding-bottom: 16px;

  @media (max-width: 640px) {
    padding: 0;
  }

  :global {
    .el-table__body {
      border-collapse: collapse;
      overflow-x: auto;
      margin-left: 24px;
      margin-right: 24px;
    }

    .el-table {
      border-bottom: none;

      &:before {
        height: 0;
      }

      table {
        @media (max-width: 640px) {
          display: block;
          max-width: 100%;
        }
      }

      thead {
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: var(--el-text-color-placeholder);

        @media (max-width: 640px) {
          height: auto;
          margin: 0;
        }

        tr {
          @media (max-width: 640px) {
            padding: 16px 0;
          }

          &:not(:first-child) {
            @media (max-width: 640px) {
              display: none;
            }
          }
        }

        th {
          @media (max-width: 640px) {
            height: 24px;
            padding: 0 16px !important;
          }

          &.el-table__cell {
            padding: 0;

            @media (max-width: 640px) {
              display: none;
            }

            &.is-leaf {
              border-bottom: 1px dashed var(--lt-color-border-light);
              padding: 16px 10px;

              .cell {
                height: 24px;
                padding: 0;

                .el-checkbox {
                  height: fit-content;
                }
              }
            }

            & > .cell {
              word-break: normal;
            }

            .cell .el-checkbox__inner {
              height: 24px;
              width: 24px;

              &:after {
                height: 12px;
                left: 7px;
                top: 2px;
                width: 6px;
              }
            }
          }
        }
      }

      tbody {
        @media (max-width: 640px) {
          display: flex;
          flex-direction: column;
        }
      }

      tr {
        @media (max-width: 640px) {
          height: auto;
          display: flex;
          flex-direction: column;
          position: relative;
          padding: 8px 0 0;
        }

        &.el-table__row {
          @media (max-width: 640px) {
            padding: 54px 0 6px;
          }

          &.error-row {
            @media (max-width: 640px) {
              background-color: var(--el-color-danger-light-7);
            }
          }
        }
      }

      td {
        @media (max-width: 640px) {
          display: flex;
        }

        &.el-table__cell {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: var(--el-text-color-regular);
          border-right: 1px solid var(--el-color-white);
          border-bottom: 1px dashed var(--lt-color-border-light);
          padding: 4px 10px;

          @media (max-width: 640px) {
            border-right: none;
            text-align: left;
            padding: 0 16px 10px !important;
          }

          &:first-child {
            .el-checkbox {
              height: 32px;
            }
          }

          &:last-child,
          &.no-right-border {
            border-right: none;
          }

          &.el-table__expanded-cell {
            padding: 0;

            @media (max-width: 640px) {
              padding: 0 !important;
            }

            .el-table__row {
              @media (max-width: 640px) {
                padding: 0;
              }

              .minimized {
                @media (max-width: 640px) {
                  display: none;
                }
              }
            }

            .empty-col {
              @media (max-width: 640px) {
                display: none;
              }
            }
          }

          &.amount .cell > a {
            width: auto;
            display: inline-flex;
            color: var(--el-text-color-regular);
            text-decoration: none;

            &:hover > * {
              background-color: white;
            }

            & > :nth-child(1) {
              border: 1px solid var(--el-border-color-base);
              border-right: none;
              border-radius: 100px 0 0 100px;
              width: 16px;
            }

            & > :nth-child(2) {
              border-top: 1px solid var(--el-border-color-base);
              border-bottom: 1px solid var(--el-border-color-base);
            }

            & > :nth-child(3) {
              border: 1px solid var(--el-border-color-base);
              border-left: none;
              border-radius: 0 100px 100px 0;
              padding-left: 7px;
              padding-right: 15px;
            }

            .el-icon {
              vertical-align: text-top;
              padding-top: 1px;
            }
          }

          &.activity .cell {
            text-overflow: initial;
          }

          &.amount .cell > a {
            width: auto;
            display: inline-flex;
            color: var(--el-text-color-regular);
            text-decoration: none;

            &:hover > * {
              background-color: white;
            }

            & > :nth-child(1) {
              border: 1px solid var(--el-border-color-base);
              border-right: none;
              border-radius: 100px 0 0 100px;
              width: 16px;
            }

            & > :nth-child(2) {
              border-top: 1px solid var(--el-border-color-base);
              border-bottom: 1px solid var(--el-border-color-base);
            }

            & > :nth-child(3) {
              border: 1px solid var(--el-border-color-base);
              border-left: none;
              border-radius: 0 100px 100px 0;
              padding-left: 7px;
              padding-right: 15px;
            }

            .el-icon {
              vertical-align: text-top;
              padding-top: 1px;
            }
          }

          &.activity .cell {
            text-overflow: initial;
          }

          &.minimized {
            padding: 0;

            @media (max-width: 640px) {
              position: absolute;
              left: 54px;
              top: 28px;
              padding: 0 !important;
            }
          }

          &.el-table__expand-column {
            @media (max-width: 640px) {
              border-right: none;
              z-index: 2;
              position: absolute;
              right: 18px;
              bottom: 0;
            }
          }

          & > .cell {
            word-break: break-word;

            .el-icon.success {
              color: var(--el-color-success);
            }

            .el-icon.fail {
              color: var(--el-color-error);
            }
          }

          .cell {
            padding: 0;

            .el-checkbox__inner {
              height: 24px;
              width: 24px;

              &:after {
                height: 12px;
                left: 7px;
                top: 2px;
                width: 6px;
              }
            }

            .el-form-item {
              margin: 0;
            }
          }
        }

        .cell {
          @media (max-width: 640px) {
            width: 100%;
            padding: 0 !important;
          }
        }

        .cell-error {
          .custom-icon {
            @media (max-width: 640px) {
              top: inherit;
              bottom: 12px;
            }
          }
        }
      }
    }

    .el-table__header {
      border-bottom: 1px dashed var(--el-border-color);
      margin-left: 24px;
      margin-right: 24px;

      @media (max-width: 640px) {
        background: #fff;
        margin: 0;
      }
    }

    .el-table__body {
      @media (max-width: 640px) {
        margin: 0;
      }
    }

    .el-scrollbar__view {
      @media (max-width: 640px) {
        max-width: 100%;
      }
    }

    .fixed-class {
      @media (max-width: 640px) {
        position: static;
      }
    }

    .el-table__row {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: var(--el-text-color-primary);
      border-bottom: 1px dashed var(--el-border-color);

      &:hover {
        .el-table__cell {
          background-color: var(--el-fill-color-lighter) !important;
          border-right: 1px solid var(--el-fill-color-lighter);
        }
      }

      &.error-row {
        .el-table__cell {
          background-color: #faedef !important;
          border-right: 1px solid #faedef;
        }

        &:hover {
          .el-table__cell {
            background-color: var(--el-color-danger-light-7) !important;
            border-right: 1px solid var(--el-color-danger-light-7);
          }
        }
      }
    }

    .no-expand .el-table__expand-icon {
      display: none;
      pointer-events: none;
    }

    .el-table__fixed-right::before {
      height: 0;
    }

    .dot {
      width: 8px;
      height: 8px;
      border: 1px solid var(--el-color-white);
      border-radius: 100%;
    }

    .inactive {
      color: var(--el-text-color-disabled);
      background-color: var(--el-text-color-disabled);
    }

    .success {
      color: var(--el-color-success);
      background-color: var(--el-color-success);
    }

    .el-checkbox {
      pointer-events: none;
    }

    .el-checkbox__inner {
      --el-checkbox-checked-icon-color: var(--el-text-color-placeholder);

      background: #f5f7fa !important;
      border: 1px solid var(--kd-border-pim-color) !important;

      &::after {
        border: 1.5px solid rgba(8, 8, 10, 0.32);
        border-left: 0;
        border-top: 0;
      }
    }

    .el-button--small {
      padding: 6px 7px;
      border-radius: calc(var(--el-border-radius-base) - 1px);
      width: 36px;
      height: 24px;
      min-height: 24px;

      &:hover,
      &:focus {
        border-color: var(--el-color-primary);
      }
    }

    .el-scrollbar {
      padding: 0 0 6px;
    }

    .hide-button {
      .el-button.entity-add {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
      }

      .el-button {
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
      }
    }

    .no-button {
      .el-button {
        display: none;

        @media (max-width: 640px) {
          display: block;
        }
      }
    }

    .el-input__suffix {
      opacity: 0;
      transition: var(--el-transition-box-shadow);
    }

    .el-input__wrapper {
      background-color: transparent !important;
      box-shadow: none !important;

      @media (max-width: 640px) {
        box-shadow: 0 0 0 1px
          var(--el-input-border-color, var(--el-border-color)) inset !important;
      }

      &.is-focus {
        background-color: var(
          --el-input-bg-color,
          var(--el-fill-color-blank)
        ) !important;
        box-shadow: 0 0 0 1px var(--el-input-focus-border-color) inset !important;

        .el-input__suffix {
          opacity: 1;
        }
      }
    }

    .el-textarea__inner {
      background-color: transparent !important;
      box-shadow: none !important;
      resize: none;

      @media (max-width: 640px) {
        box-shadow: 0 0 0 1px
          var(--el-input-border-color, var(--el-border-color)) inset !important;
      }

      &:focus {
        background-color: var(
          --el-input-bg-color,
          var(--el-fill-color-blank)
        ) !important;
        box-shadow: 0 0 0 1px var(--el-input-focus-border-color) inset !important;
        resize: auto;

        & + .el-input__count {
          opacity: 1;
        }
      }

      & + .el-input__count {
        opacity: 0;
      }
    }

    .el-tree {
      background-color: transparent;
    }

    .el-select__tags {
      .el-select__input {
        display: none;
      }
    }

    .el-select__wrapper {
      &:not(.is-focused) {
        box-shadow: none;
        background-color: transparent;

        .el-select__suffix {
          opacity: 0;
        }

        &.is-hovering {
          box-shadow: none;
          background-color: transparent;
        }
      }
    }

    .el-table__body-wrapper .el-table-column--selection > .cell,
    .el-table__header-wrapper .el-table-column--selection > .cell {
      height: fit-content;
    }

    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background: transparent;
    }

    .cell-error {
      .custom-icon {
        opacity: 1;
        pointer-events: auto;
      }

      .el-input__wrapper {
        &.is-focus {
          box-shadow: 0 0 0 1px var(--el-color-error, var(--el-color-error))
            inset !important;
        }
      }
    }

    .custom-icon {
      width: 44px;
      opacity: 0;
      pointer-events: none;
      cursor: pointer;
      position: absolute;
      right: -2px;
      bottom: 12px;
      margin: auto;
      transition: var(--el-transition-all);
    }

    .el-table__inner-wrapper {
      &:before {
        display: none;
      }
    }
  }

  td {
    &.bulk {
      @media (max-width: 640px) {
        position: absolute;
        left: 0;
        top: 16px;
      }
    }
  }

  .bulk {
    @media (max-width: 640px) {
      display: block !important;
    }
  }
}

.expand-table {
  :global .el-table__body {
    margin: 0;
  }
}

.expand-no-input {
  padding: 0 11px;

  @media (max-width: 640px) {
    padding: 0;
  }
}

.header-icon {
  top: 2px;
}

.custom-head {
  padding: 0 11px;
}

.burger-button {
  background-color: var(--el-fill-color-light);
  pointer-events: none;
}

.custom-style-el-popover {
  width: 100% !important;
  max-width: 220px !important;
  text-align: center !important;
}

.validate-popover {
  font-size: 12px !important;
  text-align: left;
}

.no-right-border {
  border-right: none;
}

.table-burger {
  @media (max-width: 640px) {
    position: absolute !important;
    right: 0;
    top: 16px;
  }
}
</style>
