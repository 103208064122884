import { ref, Ref, watch } from "vue";
import {
  FormLayoutPropertyDeclaration,
  GeneratorDeclaration,
} from "@/plugins/form-generator-json-v2/types";

export const useLayout = (declaration: Ref<GeneratorDeclaration>) => {
  const layoutDeclaration = ref<FormLayoutPropertyDeclaration | undefined>(
    declaration.value.form.layout
  );

  const setLayout = (layout: FormLayoutPropertyDeclaration) => {
    layoutDeclaration.value = layout;
    isFlat.value = (layoutDeclaration.value?.rows || []).length === 0;
  };
  const isFlat = ref<boolean>(
    (layoutDeclaration.value?.rows || []).length === 0
  );

  watch(
    () => layoutDeclaration.value,
    (layout) => {
      if (layout) {
        setLayout(layout);
      }
    },
    { immediate: true, deep: true }
  );

  return {
    layoutDeclaration,
    isFlat,
    setLayout,
  };
};
