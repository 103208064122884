<template>
  <div class="link-bubble-menu">
    <slot name="prepend" />

    <open-link-command-button :url="linkAttrs.href" />
    <edit-link-command-button :editor="editor" :init-link-attrs="linkAttrs" />
    <unlink-command-button :editor="editor" />
  </div>
</template>

<script lang="ts" setup>
import { Editor } from "@tiptap/vue-3";
import { computed } from "vue";
import OpenLinkCommandButton from "@/plugins/tiptap-editor/components/MenuCommands/Link/OpenLinkCommandButton.vue";
import EditLinkCommandButton from "@/plugins/tiptap-editor/components/MenuCommands/Link/EditLinkCommandButton.vue";
import UnlinkCommandButton from "@/plugins/tiptap-editor/components/MenuCommands/Link/UnlinkCommandButton.vue";

interface Props {
  editor: Editor;
}

const props = defineProps<Props>();

const linkAttrs = computed(() => props.editor.getAttributes("link"));
</script>

<style lang="scss">
.link-bubble-menu {
  display: flex;
}
</style>
