import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/business_format_blocks`, {
  item: { include: "businessFormats" },
});

export default service;
export const getBusinessFormatBlocks = service.list.bind(service);
export const getBusinessFormatBlockById = service.item.bind(service);
export const createBusinessFormatBlock = service.create.bind(service);
export const updateBusinessFormatBlock = service.update.bind(service);
export const deleteBusinessFormatBlock = service.delete.bind(service);
