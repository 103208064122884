<template>
  <div>
    <command-button
      :command="openEditLinkDialog"
      :enable-tooltip="enableTooltip"
      :tooltip="t('editor.extensions.Link.edit.tooltip')"
      icon="edit"
    />

    <el-dialog
      v-model="editLinkDialogVisible"
      :title="t('editor.extensions.Link.edit.control.title')"
      append-to-body
      class="el-tiptap-edit-link-dialog"
      width="400px"
    >
      <el-form :model="linkAttrs" label-position="right" size="small">
        <el-form-item
          :label="t('editor.extensions.Link.edit.control.href')"
          prop="href"
        >
          <el-input v-model="linkAttrs.href" autocomplete="off" />
        </el-form-item>

        <el-form-item prop="openInNewTab">
          <el-checkbox v-model="linkAttrs.openInNewTab">
            {{ t("editor.extensions.Link.edit.control.open_in_new_tab") }}
          </el-checkbox>
        </el-form-item>
      </el-form>

      <template #footer>
        <el-button round size="small" @click="closeEditLinkDialog">
          {{ t("editor.extensions.Link.edit.control.cancel") }}
        </el-button>

        <el-button
          round
          size="small"
          type="primary"
          @click="updateLinkAttrs"
          @mousedown.prevent
        >
          {{ t("editor.extensions.Link.edit.control.confirm") }}
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="ts" setup>
import { inject, ref } from "vue";
import { Editor } from "@tiptap/vue-3";
import {
  ElButton,
  ElCheckbox,
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
} from "element-plus";
import CommandButton from "../CommandButton.vue";

interface Props {
  editor: Editor;
  initLinkAttrs: Record<string, any>;
}

const props = defineProps<Props>();

const t: any = inject("t");
const enableTooltip = inject("enableTooltip", true);

const linkAttrs = ref(props.initLinkAttrs);
const editLinkDialogVisible = ref(false);

const updateLinkAttrs = () => {
  if (linkAttrs.value.openInNewTab) {
    props.editor?.commands.setLink({
      href: linkAttrs.value.href,
      target: "_blank",
    });
  } else {
    props.editor?.commands.setLink({ href: linkAttrs.value.href });
  }

  closeEditLinkDialog();
};

const openEditLinkDialog = () => {
  editLinkDialogVisible.value = true;
};

const closeEditLinkDialog = () => {
  editLinkDialogVisible.value = false;
};
</script>
