import {
  DefaultJsonFormContext,
  FormItemObjDeclaration,
} from "@/plugins/form-generator-json-v2/types";

export const defaultSwitch = (
  placeholder: string,
  presetValue: number,
  model: string,
  activeText?: string,
  note?: string,
  activeValue?: boolean | string,
  inactiveValue?: boolean | string,
  disabled?: boolean
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model: model,
  element: "switch",
  isPayload: true,
  presetValue: +presetValue,
  formItemAttrs: {
    label: placeholder,
    note,
  },
  attrs: {
    "active-value": activeValue || 1,
    "active-text": activeText,
    "inactive-value": inactiveValue ?? 0,
    disabled,
  },
});

const pimSwitch = (
  model: string,
  presetValue: boolean
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model: model,
  element: "switch",
  presetValue: presetValue,
  isPayload: true,
  attrs: {
    activeText: "Активность",
  },
  submitMapDataCallback: (v: boolean) => +v,
});

export const propertySwitch = (model: string): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model: model,
  element: "switch",
  presetValue: false,
  isPayload: true,
  attrs: {
    activeText: "Выбрать все виды",
  },
  events: {
    onChange: async function (this: DefaultJsonFormContext, value: any) {
      this.setFormItemAttr(
        "classifiers_list",
        "placeholder",
        value ? "Все виды" : "Выберите значение"
      );

      this.setFormItemAttr("classifiers_list", "disabled", value);

      this.setFormItemVisibility("classifiers", !value);

      if (value) {
        this.formModel.classifiers_list = null;
        this.formModel.classifiers = [];
      }
    },
  },
});

export const propertySwitchFilter = (
  model: string
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model: model,
  element: "switch",
  isPayload: true,
  attrs: {
    activeText: "Общая характеристика",
  },
  doNotPassEmpty: true,
  submitMapDataCallback: (v: boolean) => +v,
});

export const adminSwitch = (
  placeholder: string,
  presetValue: number,
  model: string
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model: model,
  element: "switch-admin",
  isPayload: true,
  presetValue: +presetValue,
  formItemAttrs: {
    label: placeholder,
  },
  attrs: {
    "active-value": 1,
    "inactive-value": 0,
  },
});

export const is_active = (
  model = "is_active",
  presetValue = true
): FormItemObjDeclaration => pimSwitch(model, presetValue);

export const active = (
  placeholder = "",
  presetValue = 1,
  model = "active",
  activeText = "Активный"
): FormItemObjDeclaration =>
  defaultSwitch(placeholder, presetValue, model, activeText);

export const landings_is_active = (
  model = "is_active"
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model: model,
  element: "switch",
  presetValue: 1,
  isPayload: true,
  attrs: {
    "active-value": 1,
    "inactive-value": 0,
  },
  formItemAttrs: {
    label: "Активность",
    note: `
      Если этот элемент уже привязан к блоку или лендингу,
      при скрытии он автоматически открепится от него.
      Вы сможете привязать к блоку/лендингу другой активный блок,
      перейдя в настройки соответствующего блока/лендинга.
    `,
  },
  submitMapDataCallback: (v: number) => +v,
});

export const is_premium = (
  placeholder = "Премиум-вакансия",
  presetValue = 1,
  model = "is_premium",
  note = "Для премиум вакансий отображается специальная иконка"
): FormItemObjDeclaration =>
  defaultSwitch(placeholder, presetValue, model, undefined, note);

export const filter_is_active = (
  placeholder = "Активность",
  presetValue = 1,
  model = "is_active"
): FormItemObjDeclaration => defaultSwitch(placeholder, presetValue, model);

export const is_hidden = (model = "is_hidden"): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model: model,
  element: "switch",
  presetValue: 0,
  isPayload: true,
  attrs: {
    "active-value": 1,
    "inactive-value": 0,
  },
  formItemAttrs: {
    label: "Скрыть раздел",
  },
  submitMapDataCallback: (v: boolean) => +v,
});

export const filter_is_hidden = (
  placeholder = "Скрыть раздел",
  presetValue = 0,
  model = "is_hidden"
): FormItemObjDeclaration => defaultSwitch(placeholder, presetValue, model);

export const all_active_brands_for_popup = (
  model = "all_active_brands_for_popup"
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model: model,
  element: "switch",
  presetValue: 0,
  isPayload: true,
  attrs: {
    "active-value": 1,
    "inactive-value": 0,
  },
  formItemAttrs: {
    label: "Выбрать все активные бренды для всплывающего окна",
  },
  submitMapDataCallback: (v: number) => +v,
});

export const all_active_certificates = (
  model = "all_active_certificates"
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model: model,
  element: "switch",
  presetValue: 0,
  isPayload: true,
  attrs: {
    "active-value": 1,
    "inactive-value": 0,
  },
  formItemAttrs: {
    label: "Выбрать все активные сертификаты",
  },
  submitMapDataCallback: (v: number) => +v,
});

export const buSwitch = (
  model: string,
  label: string
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model: model,
  element: "switch",
  presetValue: false,
  isPayload: true,
  formItemAttrs: {
    label,
  },
  submitMapDataCallback: (v: boolean) => +v,
});

export const in_group = (
  placeholder = "Партнерская организация",
  presetValue = 0,
  model = "in_group"
): FormItemObjDeclaration => defaultSwitch(placeholder, presetValue, model);

export const has_wc = (
  placeholder = "Наличие санузла",
  presetValue = 0,
  model = "has_wc"
): FormItemObjDeclaration => defaultSwitch(placeholder, presetValue, model);

export const has_water_bottle = (
  placeholder = "Наличие питьевой воды",
  presetValue = 0,
  model = "has_water_bottle"
): FormItemObjDeclaration => defaultSwitch(placeholder, presetValue, model);

export const has_sofa = (
  placeholder = "Наличие ТВ",
  presetValue = 0,
  model = "has_sofa"
): FormItemObjDeclaration => defaultSwitch(placeholder, presetValue, model);

export const has_tv = (
  placeholder = "Наличие дивана",
  presetValue = 0,
  model = "has_tv"
): FormItemObjDeclaration => defaultSwitch(placeholder, presetValue, model);

export const show_background = (
  placeholder = "Показывать фоновое изображение",
  presetValue = 0,
  model = "show_background"
): FormItemObjDeclaration => defaultSwitch(placeholder, presetValue, model);

export const admin: FormItemObjDeclaration = adminSwitch(
  "Права администратора",
  0,
  "admin"
);

export const defaultFilterActive: FormItemObjDeclaration = defaultSwitch(
  "Фильтр по активности",
  0,
  "filter[active]"
);

export const is_deleted: FormItemObjDeclaration = adminSwitch(
  "Удален",
  0,
  "is_deleted"
);

export const is_canceled: FormItemObjDeclaration = adminSwitch(
  "Аннулирован",
  0,
  "is_canceled"
);

export const is_buyer: FormItemObjDeclaration = adminSwitch(
  "Покупатель",
  0,
  "is_buyer"
);

export const is_supplier: FormItemObjDeclaration = adminSwitch(
  "Поставщик",
  0,
  "is_supplier"
);

export const is_exclude_from_bonus_program: FormItemObjDeclaration =
  adminSwitch("Исключен", 0, "is_exclude_from_bonus_program");
