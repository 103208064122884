import { RouteRecordRaw } from "vue-router";
import { Orange } from "@element-plus/icons-vue";
import { RouteAccess } from "../types";
import {
  CAR_MODELS,
  MANUFACTURERS,
  NIPPLE,
  NIPPLE_IMPORTS,
  OPERATIONS_PRICES,
  OPERATIONS_TYPES,
  OPERATIONS,
  SERVICE_CENTERS,
  SETTINGS,
  TERMINALS,
} from "@/dictionary/header";
import WorkingSectionView from "@/views/WorkingSectionView.vue";
import LayoutWorkingSection from "@/layout/LayoutWorkingSection.vue";
import { ServiceName } from "@/constants";

const access: RouteAccess = {
  service: ServiceName.nipple,
  object: "",
  command: "index",
};

const NIPPLE_PATH = `/${ServiceName.nipple}`;

export const nippleRoutesList: RouteRecordRaw[] = [
  {
    path: NIPPLE_PATH,
    name: ServiceName.nipple,
    component: LayoutWorkingSection,
    meta: {
      title: NIPPLE,
      icon: Orange,
      isDev: true,
      clickable: false,
    },
    children: [
      {
        path: `${NIPPLE_PATH}/imports`,
        name: "nipple-imports",
        component: WorkingSectionView,
        meta: {
          title: NIPPLE_IMPORTS,
          access: {
            ...access,
            object: "log_import",
          },
        },
      },
      {
        path: `${NIPPLE_PATH}/service-centers`,
        name: "service-centers",
        component: WorkingSectionView,
        meta: {
          title: SERVICE_CENTERS,
          access: {
            ...access,
            object: "service_centers",
          },
        },
      },
      {
        path: `${NIPPLE_PATH}/settings`,
        name: "nipple-settings",
        component: (): Promise<typeof import("*.vue")> =>
          import(
            /* webpackChunkName: "Versions" */ "@/views/nipple/Settings.vue"
          ),
        meta: {
          title: SETTINGS,
          access: {
            ...access,
            object: "settings",
          },
        },
      },
      {
        path: `${NIPPLE_PATH}/terminals`,
        name: "terminals",
        component: WorkingSectionView,
        meta: {
          title: TERMINALS,
          access: {
            ...access,
            object: "terminals",
          },
        },
      },
      // {
      //   path: "/nipple/users",
      //   name: "nipple-users",
      //   component: WorkingSectionView,
      //   meta: {
      //     title: USERS_NIPPLE,
      //     access: {
      //       ...access,
      //       object: "users",
      //     },
      //   },
      // },
      {
        path: "/nipple/operation-types",
        name: "operation-types",
        component: WorkingSectionView,
        meta: {
          title: OPERATIONS_TYPES,
          access: {
            ...access,
            object: "operation_types",
          },
        },
      },
      {
        path: "/nipple/car-models",
        name: "car-models",
        component: WorkingSectionView,
        meta: {
          title: CAR_MODELS,
          access: {
            ...access,
            object: "car_models",
          },
        },
      },
      {
        path: "/nipple/manufacturers",
        name: "manufacturers",
        component: WorkingSectionView,
        meta: {
          title: MANUFACTURERS,
          access: {
            ...access,
            object: "manufacturers",
          },
        },
      },
      {
        path: "/nipple/operations",
        name: "operations",
        component: WorkingSectionView,
        meta: {
          title: OPERATIONS,
          access: {
            ...access,
            object: "operations",
          },
        },
      },
      {
        path: "/nipple/operation-prices",
        name: "operation-prices",
        component: WorkingSectionView,
        meta: {
          title: OPERATIONS_PRICES,
          access: {
            ...access,
            object: "operation_prices",
          },
        },
      },
      // {
      //   path: "/nipple/wheel-storages",
      //   name: "wheel-storages",
      //   component: WorkingSectionView,
      //   meta: {
      //     title: WHEEL_STORAGES,
      //     access: {
      //       ...access,
      //       object: "wheel_storages",
      //     },
      //   },
      // },
      // {
      //   path: "/nipple/wheel-storages/edit/:id?",
      //   name: "wheel-storages-edit",
      //   component: (): Promise<typeof import("*.vue")> =>
      //     import("@/views/nipple/WheelStorages.vue"),
      //   meta: {
      //     title: WHEEL_STORAGES_EDIT,
      //     access: {
      //       ...access,
      //       object: "WHEEL_STORAGES",
      //       command: "update",
      //     },
      //     hiddenRoute: true,
      //   },
      // },
      // {
      //   path: "/nipple/season-storage-operations",
      //   name: "season-storage-operations",
      //   component: WorkingSectionView,
      //   meta: {
      //     title: SEASON_STORAGE_OPERATIONS,
      //     access: {
      //       ...access,
      //       object: "season_storage_operations",
      //     },
      //   },
      // },
    ],
  },
];
