import { onMounted, ref } from "vue";
import { user } from "@/model/user";

const WS_URL = `${
  window.location.protocol.includes("https") ? "wss" : "ws"
}://${window.location.host}/service/notifications/ws`;
const WS_ACTIVE_STATUSES = [0, 1, 2];
const ERROR_CONNECTIONS_LIMIT = 5;

const webSocket: Record<string, any> = ref({});
const message: Record<string, any> = ref({});

let errorConnectionsCounter = 0;
let isNeedToRefresh = false;

export enum WSMessageAction {
  created = "objects/created",
  modified = "objects/modified",
  removed = "objects/removed",
  postprocess = "objects/postprocess",
  event = "users/event",
}

export enum WSMessageType {
  event = "event",
  welcome = "welcome",
}

export enum WSEvent {
  messageParsed = "message-parsed",
  resetSectionsCreatedInstance = "reset-sections-created-instance",
}

interface WSMessage {
  type: WSMessageType;
  action?: WSMessageAction;
  cid?: string;
  router?: string;
  code?: number;
  params?: {
    service?: string;
    object_type?: string;
    object_id?: number;
    users?: string[];
  };
}

interface Result {
  message: typeof message;
}

const handleWebSocketOpen = (token: string) => {
  if (webSocket.value) {
    webSocket.value.send(JSON.stringify({ type: "auth", token }));
  }
};

const handleWebSocketMessage = (event: MessageEvent) => {
  const parsedEventData: WSMessage = JSON.parse(event.data);

  window.dispatchEvent(
    new CustomEvent(WSEvent.messageParsed, {
      detail: { webSocket: { parsedMessage: parsedEventData } },
    })
  );

  if (parsedEventData.code === 400) {
    isNeedToRefresh = true;
    errorConnectionsCounter++;
    webSocket.value?.close();
  } else {
    message.value = parsedEventData;
  }
};

const handleWebSocketClose = () => {
  setTimeout(() => {
    if (errorConnectionsCounter < ERROR_CONNECTIONS_LIMIT) {
      createConnection();
    }
  }, 1000);
};

const handleWebSocketError = () => {
  errorConnectionsCounter++;
  webSocket.value?.close();
};

const createConnection = async () => {
  if (isNeedToRefresh) {
    await user.refresh();
    isNeedToRefresh = false;
  }

  const token = user.getToken();

  if (
    token &&
    (!webSocket.value ||
      !WS_ACTIVE_STATUSES.includes(webSocket.value.readyState))
  ) {
    webSocket.value = new WebSocket(WS_URL);

    webSocket.value.addEventListener("open", () => handleWebSocketOpen(token));
    webSocket.value.addEventListener("message", handleWebSocketMessage);
    webSocket.value.addEventListener("close", handleWebSocketClose);
    webSocket.value.addEventListener("error", handleWebSocketError);
  }
};

export default (): Result => {
  onMounted(() => {
    createConnection();
  });

  return {
    message,
  };
};
