import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/regions`, {
  list: {
    sort: "name",
    include: "countries",
  },
  item: { include: "countries" },
});

export default service;
export const getRegions = service.list.bind(service);
export const getRegionById = service.item.bind(service);
export const createRegion = service.create.bind(service);
export const updateRegion = service.update.bind(service);
export const deleteRegion = service.delete.bind(service);
