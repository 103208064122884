import { onMounted, ref, Ref } from "vue";
import { user } from "@/model/user";
import { Router } from "vue-router";

export interface SearchOption {
  isDelete?: boolean;
  model: string;
  value: string;
}

const userOptions = ref<
  Record<
    string,
    {
      searchHistory?: SearchOption[];
      cols?: Record<string, object>;
      colStrutWidth?: Record<string, object>;
      colsWidth?: Record<string, object>[];
    }
  >
>({});

const listSearchHistoryUserOption = ref<SearchOption[]>([]);
const listDropdownFilter = ref<SearchOption[]>([]);
const listData = ref<SearchOption[]>([]);
const listModelSearchHistoryUserOption = ref<SearchOption[]>([]);
const isFilterClean = ref(false);

const userOptionsSearchHistory: SearchOption = {
  model: "",
  value: "",
};

export default (
  path: string,
  filterSelect: Ref<any>,
  filterSearch: Ref<string>,
  _router: Router
) => {
  const initSearchHistory = async () => {
    userOptions.value = await user.getUserOptions();
    getListSearchHistory();
  };

  const getListSearchHistory = () => {
    listSearchHistoryUserOption.value =
      userOptions.value[path]?.searchHistory || [];
    listSearchHistoryUserOption.value.forEach((item: any) => {
      item.isDelete = true;
    });
  };

  const getListModelSearchHistory = () => {
    listModelSearchHistoryUserOption.value =
      listSearchHistoryUserOption.value.filter(
        (item: any) => item.model === filterSelect.value.model
      );

    initSearchHistory();
  };

  const changeSearchHistory = async (searchHistory: any[]) => {
    try {
      await user.updateUserOptions(
        {
          [path]: {
            ...userOptions.value[path],
            searchHistory,
          },
        },
        true
      );
    } finally {
      initSearchHistory();
    }
  };

  const addItemSearchHistory = () => {
    listSearchHistoryUserOption.value?.forEach((item: any) => {
      delete item.isDelete;
    });

    userOptionsSearchHistory.model = filterSelect.value.model;
    userOptionsSearchHistory.value = filterSearch.value;

    listSearchHistoryUserOption.value.unshift(userOptionsSearchHistory);

    changeSearchHistory(listSearchHistoryUserOption.value);
  };

  const deleteItemSearch = (itemSearch: SearchOption) => {
    listSearchHistoryUserOption.value =
      listSearchHistoryUserOption.value.filter(
        (item: any) => item.value !== itemSearch.value
      );

    changeSearchHistory(listSearchHistoryUserOption.value);

    const currentQuery = _router.currentRoute.value.query;

    for (const key in currentQuery) {
      if (key === itemSearch.model && currentQuery[key] === itemSearch.value) {
        isFilterClean.value = !isFilterClean.value;
      }
    }

    updateValueFilter();
  };

  const updateValueFilter = () => {
    getListModelSearchHistory();
    listDropdownFilter.value = listModelSearchHistoryUserOption.value.filter(
      (item: any) => item.model === filterSelect.value.model
    );

    initSearchHistory();
  };

  onMounted(() => initSearchHistory());

  return {
    getListSearchHistory,
    getListModelSearchHistory,
    addItemSearchHistory,
    deleteItemSearch,
    updateValueFilter,
    isFilterClean,
    listSearchHistoryUserOption,
    listDropdownFilter,
    listData,
    listModelSearchHistoryUserOption,
  };
};
