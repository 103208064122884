import { RouteRecordRaw } from "vue-router";
import { RouteAccess } from "../types";
import {
  COMMUNICATION,
  COMMUNICATION_MESSAGES,
  COMMUNICATION_PROVIDER_ACCOUNTS,
  COMMUNICATION_SYSTEM_TOKENS,
  COMMUNICATION_SYSTEMS,
  COMMUNICATION_TEMPLATES,
} from "@/dictionary/header";
import {
  CommunicationServicePointName,
  getCommunicationServicePageLinkByEndpoint,
} from "@/api/points/communication-api/service-config";

import LayoutWorkingSection from "@/layout/LayoutWorkingSection.vue";
import WorkingSectionView from "@/views/WorkingSectionView.vue";
import { Message } from "@element-plus/icons-vue";
import { ServiceName } from "@/constants";

const access: RouteAccess = {
  service: ServiceName.communication,
  object: "",
  command: "index",
};

export const communicationRoutesList: Array<RouteRecordRaw> = [
  {
    path: getCommunicationServicePageLinkByEndpoint(),
    name: ServiceName.communication,
    component: LayoutWorkingSection,
    meta: {
      title: COMMUNICATION,
      icon: Message,
      clickable: false,
      isDev: true,
    },
    children: [
      {
        path: getCommunicationServicePageLinkByEndpoint(
          CommunicationServicePointName.systems
        ),
        name: CommunicationServicePointName.systems,
        component: WorkingSectionView,
        meta: {
          title: COMMUNICATION_SYSTEMS,
          access: {
            ...access,
            object: CommunicationServicePointName.systems,
          },
        },
      },
      {
        path: getCommunicationServicePageLinkByEndpoint(
          CommunicationServicePointName.systemTokens
        ),
        name: CommunicationServicePointName.systemTokens,
        component: WorkingSectionView,
        meta: {
          title: COMMUNICATION_SYSTEM_TOKENS,
          access: {
            ...access,
            object: CommunicationServicePointName.systemTokens,
          },
        },
      },
      {
        path: getCommunicationServicePageLinkByEndpoint(
          CommunicationServicePointName.providerAccounts
        ),
        name: CommunicationServicePointName.providerAccounts,
        component: WorkingSectionView,
        meta: {
          title: COMMUNICATION_PROVIDER_ACCOUNTS,
          access: {
            ...access,
            object: CommunicationServicePointName.providerAccounts,
          },
        },
      },
      {
        path: getCommunicationServicePageLinkByEndpoint(
          CommunicationServicePointName.templates
        ),
        name: CommunicationServicePointName.templates,
        component: WorkingSectionView,
        meta: {
          title: COMMUNICATION_TEMPLATES,
          access: {
            ...access,
            object: CommunicationServicePointName.templates,
          },
        },
      },
      {
        path: getCommunicationServicePageLinkByEndpoint(
          CommunicationServicePointName.messages
        ),
        name: CommunicationServicePointName.messages,
        component: WorkingSectionView,
        meta: {
          title: COMMUNICATION_MESSAGES,
          access: {
            ...access,
            object: CommunicationServicePointName.messages,
          },
        },
      },
    ],
  },
];
