<template>
  <el-dialog
    :before-close="() => setFilterToggle(false)"
    :model-value="filterToggle"
  >
    <template #header>Фильтр</template>
    <json-form
      ref="filterInstance"
      :declaration="filterForm"
      :preset-values="previewFilterData"
      class="data-form"
      @clear="filterCleanHandler(false)"
      @close="onCloseFilter"
      @change-filter="filterHandler"
    />
    <template #footer>
      <modal-footer
        :filter-loading="filterLoading"
        :form-model="previewFilterData"
        :mode="FooterModalType.filter"
        default-text="Применить"
        @clear="filterCleanHandler(false)"
        @close="onCloseFilter"
        @event="onEvent"
        @hideModal="hideModal"
      />
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import {
  DefaultJsonFormContext,
  FormModel,
  FormObjDeclarationEvents,
  GeneratorDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import ModalFooter from "@/components/footer/ModalFooter.vue";

import JsonForm from "@/plugins/form-generator-json-v2/index.vue";
import useSectionModal from "@/composables/hooks/modals/useSectionModal";
import { QUERY_PARAM } from "@/components/config";
import { FooterModalType } from "@/components/footer/constants";

defineProps<{
  setFilterToggle: (value: boolean) => void;
  filterToggle: boolean;
  filterForm: GeneratorDeclaration;
  previewFilterData: FormModel;
  filterCleanHandler: (toggleFilter: boolean) => void;
  filterHandler: (data: Record<string, any>) => void;
  filterLoading: boolean;
}>();
const emit = defineEmits(["onCloseFilter"]);

const filterInstance = ref<DefaultJsonFormContext>();
const { hide: hideModal } = useSectionModal(QUERY_PARAM);

const onCloseFilter = async (formModel: Record<string, unknown>) => {
  emit("onCloseFilter", formModel, filterInstance.value);
};

const onEvent = (event: string) => {
  const form = filterInstance.value?.declaration.form;

  if (!form || !form?.events) {
    return;
  }

  const eventCb = form?.events[event as keyof FormObjDeclarationEvents];

  if (eventCb) {
    eventCb.call(form);
  }
};

defineExpose({ filterInstance });
</script>
