import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/franchise_contact_blocks`);

export default service;
export const getFranchiseContactBlocks = service.list.bind(service);
export const getFranchiseContactBlockById = service.item.bind(service);
export const createFranchiseContactBlock = service.create.bind(service);
export const updateFranchiseContactBlock = service.update.bind(service);
export const deleteFranchiseContactBlock = service.delete.bind(service);
