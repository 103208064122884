<template>
  <el-popover
    ref="popoverRef"
    :disabled="isCodeViewMode"
    placement="bottom"
    popper-class="el-tiptap-popper"
    trigger="click"
  >
    <div class="color-set">
      <div v-for="color in colorSet" :key="color" class="color__wrapper">
        <div
          :class="{ 'color--selected': selectedColor === color }"
          :style="{
            'background-color': color,
          }"
          class="color"
          @mousedown.prevent
          @click.stop="confirmColor(color)"
        />
      </div>

      <div class="color__wrapper">
        <div
          class="color color--remove"
          @mousedown.prevent
          @click.stop="confirmColor()"
        />
      </div>
    </div>

    <template #reference>
      <span>
        <command-button
          :enable-tooltip="enableTooltip"
          :readonly="isCodeViewMode"
          :tooltip="t('editor.extensions.TextHighlight.tooltip')"
          icon="highlight"
        />
      </span>
    </template>
  </el-popover>
</template>

<script lang="ts" setup>
import { computed, inject, ref, unref } from "vue";
import { Editor, getMarkAttributes } from "@tiptap/vue-3";
import { ElPopover } from "element-plus";
import CommandButton from "./CommandButton.vue";

interface Props {
  editor: Editor;
}

const props = defineProps<Props>();

const t = inject<any>("t");
const enableTooltip = inject("enableTooltip", true);
const isCodeViewMode = inject("isCodeViewMode", false);

const popoverRef = ref();

const confirmColor = (color?: string) => {
  if (color) {
    props.editor.commands.setHighlight({ color });
  } else {
    props.editor.commands.unsetHighlight();
  }

  unref(popoverRef).hide();
};

const selectedColor = computed<string>(() => {
  return getMarkAttributes(props.editor.state, "highlight").color || "";
});

const colorSet = computed<Array<string>>(() => {
  const colorOptions = props.editor.extensionManager.extensions.find(
    (e) => e.name === "highlight"
  )?.options;
  return colorOptions.colors;
});
</script>
