<template>
  <el-popover
    ref="popoverRef"
    :disabled="isCodeViewMode"
    placement="bottom"
    popper-class="el-tiptap-popper"
    trigger="click"
  >
    <div class="color-set">
      <div v-for="color in colorSet" :key="color" class="color__wrapper">
        <div
          :class="{ 'color--selected': selectedColor === color }"
          :style="{
            'background-color': color,
          }"
          class="color"
          @mousedown.prevent
          @click.stop="confirmColor(color)"
        />
      </div>

      <div class="color__wrapper">
        <div
          class="color color--remove"
          @mousedown.prevent
          @click.stop="confirmColor()"
        />
      </div>
    </div>

    <div class="color-hex">
      <el-input
        v-model="colorText"
        class="color-hex__input"
        maxlength="7"
        placeholder="HEX"
        size="small"
      />

      <el-button
        class="color-hex__button"
        size="small"
        text
        type="primary"
        @click="confirmColor(colorText)"
      >
        OK
      </el-button>
    </div>

    <template #reference>
      <span>
        <command-button
          :enable-tooltip="enableTooltip"
          :readonly="isCodeViewMode"
          :tooltip="t('editor.extensions.TextColor.tooltip')"
          icon="font-color"
        />
      </span>
    </template>
  </el-popover>
</template>

<script lang="ts" setup>
import { computed, inject, ref, unref, watch } from "vue";
import { Editor, getMarkAttributes } from "@tiptap/vue-3";
import { ElButton, ElInput, ElPopover } from "element-plus";
import CommandButton from "./CommandButton.vue";

interface Props {
  editor: Editor;
}

const props = defineProps<Props>();

const t: any = inject("t");
const enableTooltip = inject("enableTooltip", true);
const isCodeViewMode = inject("isCodeViewMode", false);

const popoverRef = ref();
const colorText = ref("");

const confirmColor = (color?: string) => {
  if (color) {
    props.editor.commands.setColor(color);
  } else {
    props.editor.commands.unsetColor();
  }

  unref(popoverRef).hide();
};

const selectedColor = computed<string>(() => {
  return getMarkAttributes(props.editor.state, "textStyle").color || "";
});

const colorSet = computed<Array<string>>(() => {
  const colorOptions = props.editor.extensionManager.extensions.find(
    (e) => e.name === "color"
  )?.options;
  return colorOptions.colors;
});

watch(selectedColor, (color) => {
  colorText.value = color;
});
</script>
