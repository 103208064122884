import { BASE_URL } from "@/api/core/config";
import { ref, Ref } from "vue";
import { RefreshToken, user } from "@/model/user";
import { ElMessage } from "element-plus";
import { NOTIFICATION_IMPORT_WAS_SUCCESSFUL } from "@/dictionary/notification";

interface Return {
  downloadLink: string;
  headers: Ref<Headers>;
  onBeforeUpload: () => Promise<void>;
  onSuccess: () => void;
  onError: (error: Error) => void;
}

export default (): Return => {
  const downloadLink = `${BASE_URL}vehicles/api/v1/models/import`;
  const headers = ref(
    new Headers({
      "X-Authorization": user.getBearerToken(),
    })
  );

  const onBeforeUpload = async (): Promise<void> => {
    const tokenStatus = user.checkRefreshTimer();

    if (tokenStatus === RefreshToken.EXPIRE) {
      await user.refresh();
      headers.value.set("X-Authorization", user.getBearerToken());
    }
  };

  const onSuccess = (): void => {
    ElMessage.success(NOTIFICATION_IMPORT_WAS_SUCCESSFUL);
  };

  const onError = (error: Error): void => {
    let errorMessage = "";
    const errorData = JSON.parse(error.message);

    for (const item in errorData.source) {
      const errorItem = Object.values(errorData.source[item]);

      if (errorItem.length) {
        errorMessage += `${errorItem};`;
      }
    }

    errorMessage = errorMessage || errorData.errors?.title || "";

    if ("status" in error && parseInt(error.status as string) === 403) {
      errorMessage = "Доступ запрещен";
    }

    ElMessage.error(errorMessage);
  };

  return {
    downloadLink,
    headers,
    onBeforeUpload,
    onSuccess,
    onError,
  };
};
