<template>
  <div>
    <command-button
      :command="() => toggleFullscreen(!isFullscreen)"
      :enable-tooltip="enableTooltip"
      :icon="isFullscreen ? 'compress' : 'expand'"
      :is-active="isFullscreen"
      :is-code-view-mode="isCodeViewMode"
      :tooltip="buttonTooltip"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, inject } from "vue";
import { Editor } from "@tiptap/core";
import CommandButton from "./CommandButton.vue";

interface Props {
  editor: Editor;
  isCodeViewMode: boolean;
}

defineProps<Props>();

const t: any = inject("t");
const enableTooltip = inject("enableTooltip", true);
const isFullscreen = inject("isFullscreen", false);
const toggleFullscreen = inject<any>("toggleFullscreen");

const buttonTooltip = computed(() => {
  return isFullscreen
    ? t("editor.extensions.Fullscreen.tooltip.exit_fullscreen")
    : t("editor.extensions.Fullscreen.tooltip.fullscreen");
});
</script>
