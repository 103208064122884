import { user } from "@/model/user";
import { House } from "@element-plus/icons-vue";
import type { RouteRecordRaw } from "vue-router";
import router from "..";
import { HOME_PAGE } from "@/dictionary/header";
import { ERROR_ACCESS_CLOSED, ERROR_NOTING } from "@/dictionary/error";

export const defaultRoutesList: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "login",
    component: (): Promise<typeof import("*.vue")> =>
      import(/* webpackChunkName: "Login" */ "@/views/Login.vue"),
    meta: {
      noRequiresAuth: true,
      hiddenRoute: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    redirect: () => {
      user.removeToken();
      user.removeUserOptions();
      router.go(0);
      return { name: "login", query: { message: "exit" } };
    },
    meta: {
      noRequiresAuth: true,
      hiddenRoute: true,
    },
  },
  {
    path: "/",
    name: "index",
    component: (): Promise<typeof import("*.vue")> =>
      import(/* webpackChunkName: "Home" */ "@/views/Home.vue"),
    meta: {
      title: HOME_PAGE,
      icon: House,
    },
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () =>
      import(/* webpackChunkName: "Error" */ "@/views/Error.vue"),
    meta: {
      hiddenRoute: true,
    },
    props: { code: 403, message: ERROR_ACCESS_CLOSED },
  },
  {
    path: "/:catchAll(.*)",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "Error" */ "@/views/Error.vue"),
    meta: {
      hiddenRoute: true,
    },
    props: { code: 404, message: ERROR_NOTING },
  },
];
