import { reactive, ref, Ref } from "vue";

export interface DeleteData {
  items: string[] | number[];
  type: string;
}

const isDeleteModalOpened: Ref<boolean> = ref(false);
const deleteData: DeleteData = reactive({
  items: [],
  type: "",
});

const openDeleteModal = (items: [], type = ""): void => {
  deleteData.items = items;
  deleteData.type = type;
  isDeleteModalOpened.value = true;
};

const closeDeleteModal = (): void => {
  isDeleteModalOpened.value = false;
};

interface Result {
  isDeleteModalOpened: Ref<boolean>;
  deleteData: DeleteData;
  openDeleteModal: (items: [], type?: string) => void;
  closeDeleteModal: () => void;
}

export default (): Result => ({
  isDeleteModalOpened,
  deleteData,
  openDeleteModal,
  closeDeleteModal,
});
