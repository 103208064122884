import { ServiceName } from "@/constants";

export const COMMUNICATION_SERVICE_ENDPOINT = `${ServiceName.communication}/api/v1`;

export enum CommunicationServicePointName {
  systems = "systems",
  systemTokens = "system_tokens",
  providerAccounts = "provider_accounts",
  providerTypes = "provider_types",
  providerTypeProps = "provider_type_props",
  templates = "templates",
  templateProps = "template_props",
  messages = "messages",
  messageData = "message_data",
  messageStatusLogs = "message_status_logs",
}

export function getCommunicationServicePathByEndpoint(
  endpoint: CommunicationServicePointName
): string {
  return `${COMMUNICATION_SERVICE_ENDPOINT}/${endpoint}`;
}

export function getCommunicationServicePageLinkByEndpoint(
  endpoint: CommunicationServicePointName | string = ""
): string {
  return `/${ServiceName.communication}/${endpoint}`;
}
