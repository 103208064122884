import { user } from "@/model/user";
import { Ref, ref } from "vue";
import { impersonate } from "@/api/points/auth-api/users";
import { ICommand } from "@/components/working-sections/default/components/actions/types";

export interface IImpersonation {
  showUserImpersonateModal: Ref<boolean>;
  impersonatePayload: Ref<Record<string, string>>;
  impersonateUser: () => Promise<void>;
  runImpersonateCommand: (command: ICommand) => Promise<void>;
  hideUserImpersonateModal: () => void;
}

export default (): IImpersonation => {
  const showUserImpersonateModal = ref(false);
  const impersonatePayload = ref({
    id: "",
    name: "",
    role: "",
  });

  const impersonateUser = async () => {
    try {
      const userTokens = await impersonate({ id: impersonatePayload.value.id });
      user.setToken(userTokens.data.token, userTokens.data.refresh);
      window.location.replace("/");
      user.removeUserOptions();
    } catch (error) {
      showUserImpersonateModal.value = false;
    }
  };

  const runImpersonateCommand = async (command: ICommand) => {
    impersonatePayload.value = {
      id: command.data?.id,
      name: command.data?.name,
      role: command.data?.relationships?.groups[0]?.attributes?.name,
    };
    showUserImpersonateModal.value = true;
  };

  const hideUserImpersonateModal = () => {
    showUserImpersonateModal.value = false;
  };

  return {
    showUserImpersonateModal,
    impersonatePayload,
    impersonateUser,
    runImpersonateCommand,
    hideUserImpersonateModal,
  };
};
