import { SERVICE_POINT_NAME } from "@/api/points/dictionaries-api/service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/counteragent_contracts`, {
  list: {
    include: "mainContract",
  },
  item: { include: "mainContract" },
});

export default service;
export const getCounteragentContracts = service.list.bind(service);
export const getCounteragentContractsById = service.item.bind(service);
export const createCounteragentContracts = service.create.bind(service);
export const updateCounteragentContracts = service.update.bind(service);
export const deleteCounteragentContracts = service.delete.bind(service);
