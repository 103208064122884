export const NOTIFICATION_AUTH = "Необходимо авторизоваться";
export const NOTIFICATION_EXIT = "Вы вышли из системы";
export const NOTIFICATION_CHANGE_PASSWORD_CONFIRMATION =
  "Проверьте почту и введите код подтверждения";
export const NOTIFICATION_CHANGE_PASSWORD_SUCCESS = "Ваш пароль был изменён";
export const NOTIFICATION_RESTORE_PASSWORD_SUCCESS =
  "Пароль был успшено сброшен.";
export const NOTIFICATION_DATA_SAVE_SUCCESS = "Данные сохранены";
export const NOTIFICATION_DATA_SAVE_ERROR = "Не удалось сохранить данные";
export const NOTIFICATION_DATA_UPDATE_SUCCESS = "Данные обновлены";
export const NOTIFICATION_DATA_DELETE_SUCCESS = "Данные удалены";
export const NOTIFICATION_DATA_DELETE_ERROR =
  "Удаление невозможно, есть связанные записи";
export const NOTIFICATION_HANDLE_RESULT_QUERY_ERROR =
  "Не удалось обработать результат запроса";
export const NOTIFICATION_DATA_NOT_FOUND = "Данные не найдены";
export const NOTIFICATION_NOT_PERMISSIONS = "Недостаточно прав доступа";
export const NOTIFICATION_LOADED_DATA_ERROR =
  "При загрузке данных произошла ошибка";
export const NOTIFICATION_RECOMMEND_UPGRADE_LAST_VERSION =
  "Рекомендуем вам обновиться до последней версии";
export const NOTIFICATION_AVAILABLE_NEW_VERSION = "Доступна новая версия";
export const NOTIFICATION_IMPORT_WAS_SUCCESSFUL = "Импорт прошел успешно";
