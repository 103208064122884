import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";
import { fetchRequest } from "@/api/core";

const extra = {
  list: { sort: "name", include: "logo" },
  item: { include: "logo" },
};
const service = new Service(`${SERVICE_POINT_NAME}/brands`, extra);

export default service;
export const getBrands = service.list.bind(service);
export const getBrandById = service.item.bind(service);
export const createBrand = service.create.bind(service);
export const updateBrand = service.update.bind(service);
export const deleteBrand = service.delete.bind(service);
export const downloadBrand = async (
  id: Array<number | string>
): Promise<any> => {
  try {
    const result = await fetchRequest({
      point: `${SERVICE_POINT_NAME}/brands/export/${id.join(",")}`,
      fetchMethod: "GET",
      rawPromise: true,
    });
    let filename = "attributes.xlsx";
    const blob = await result.blob();
    const contentDisposition = result.headers.get("Content-Disposition");
    if (contentDisposition && /filename="(.*?)"/.test(contentDisposition)) {
      filename = decodeURIComponent(
        contentDisposition.match(/filename="(.*?)"/)[1]
      );
    }
    return {
      blob,
      filename,
      url: URL.createObjectURL(blob),
    };
  } catch (e) {
    return false;
  }
};

export const serviceWizard = new Service(`${SERVICE_POINT_NAME}/brands`, {
  ...extra,
  list: {
    ...extra.list,
    include_count: "all_attributes,current_attributes,wrong_bindings",
  },
});
