import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(
  `${SERVICE_POINT_NAME}/hr_career_opportunity_blocks`,
  {
    item: { include: "hrWorkerCareers,hrOfficeCareers" },
  }
);

export default service;
export const getHRCareerOpportunityBlocks = service.list.bind(service);
export const getHRCareerOpportunityBlockById = service.item.bind(service);
export const createHRCareerOpportunityBlock = service.create.bind(service);
export const updateHRCareerOpportunityBlock = service.update.bind(service);
export const deleteHRCareerOpportunityBlock = service.delete.bind(service);
