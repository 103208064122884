import { onBeforeUnmount, onMounted, onUpdated, Ref } from "vue";
import { GeneratorDeclaration } from "@/plugins/form-generator-json-v2/types";
import { ElForm } from "element-plus";

export const useInvokeLifecycleHooks = (
  declaration: Ref<GeneratorDeclaration>,
  formRef: Ref<InstanceType<typeof ElForm> | undefined>
) => {
  const invokeMountCallback = () => {
    if (
      typeof declaration.value.form?.events?.onMount === "function" &&
      formRef.value
    ) {
      declaration.value.form.events.onMount.call(formRef.value.$parent);
    }
  };

  onMounted(() => {
    invokeMountCallback();
  });

  onBeforeUnmount(() => {
    if (
      typeof declaration.value.form?.events?.onBeforeUnmount === "function" &&
      formRef.value
    ) {
      declaration.value.form.events.onBeforeUnmount.call(formRef.value.$parent);
    }
  });
  onUpdated(() => {
    if (
      typeof declaration.value.form?.events?.onUpdated === "function" &&
      formRef.value
    ) {
      declaration.value.form.events.onUpdated.call(formRef.value.$parent);
    }
  });

  return {
    invokeMountCallback,
  };
};
