import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(
  `${SERVICE_POINT_NAME}/work_with_corporate_client_blocks`,
  {
    item: { include: "image,video" },
  }
);

export default service;
export const getWorkWithCorporateClientBlocks = service.list.bind(service);
export const getWorkWithCorporateClientBlockById = service.item.bind(service);
export const createWorkWithCorporateClientBlock = service.create.bind(service);
export const updateWorkWithCorporateClientBlock = service.update.bind(service);
export const deleteWorkWithCorporateClientBlock = service.delete.bind(service);
