<template>
  <div :class="$style['nipple-progres']">
    <div
      :class="$style['nipple-progres_bar']"
      :style="{ width: `${percentsCalculation}%` }"
    ></div>
    <div :class="$style['nipple-progres_text']">{{ done }} из {{ count }}</div>
    <div
      :class="[
        $style['nipple-progres_text'],
        $style['nipple-progres_text-dark'],
      ]"
      :style="{
        clipPath: `inset(0 0 0 ${percentsCalculation}%)`,
      }"
    >
      {{ done }} из {{ count }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, toRefs } from "vue";

const props = defineProps<{
  count: string;
  done: string;
}>();

const { count, done } = toRefs(props);

const percentsCalculation = computed(() =>
  Math.round((+done.value / +count.value) * 100)
);
</script>

<style lang="scss" module>
.nipple-progres {
  width: 100%;
  height: 26px;
  background: var(--el-fill-color-dark);
  border-radius: 30px;
  font-size: 12px;
  color: var(--el-color-white);
  text-align: center;
  overflow: hidden;
  position: relative;

  &_bar {
    height: 100%;
    background: var(--el-color-primary);
    position: absolute;
    left: 0;
    top: 0;
  }

  &_text {
    width: 100%;
    height: 100%;
    color: var(--el-color-white);
    line-height: 26px;
    position: absolute;
    left: 0;
    top: 0;

    &-dark {
      color: var(--el-text-color-primary);
    }
  }
}
</style>
