<template>
  <bubble-menu v-if="editor" :editor="editor">
    <div
      :class="{
        'el-tiptap-editor__menu-bubble--active': bubbleMenuEnable,
      }"
      class="el-tiptap-editor__menu-bubble"
    >
      <link-bubble-menu v-if="activeMenu === 'link'" :editor="editor">
        <template #prepend>
          <div
            v-if="textMenuEnable"
            class="el-tiptap-editor__command-button"
            @click="linkBack"
            @mousedown.prevent
          >
            <v-icon name="arrow-left" />
          </div>
        </template>
      </link-bubble-menu>

      <template v-else-if="activeMenu === 'default'">
        <component
          :is="spec.component"
          v-for="(spec, i) in generateCommandButtonComponentSpecs()"
          :key="'command-button' + i"
          v-bind="spec.componentProps"
          v-on="spec.componentEvents || {}"
        />
      </template>
    </div>
  </bubble-menu>
</template>

<script lang="ts" setup>
import { computed, inject, ref, watch } from "vue";
import { BubbleMenu, Editor } from "@tiptap/vue-3";
import { getMarkRange } from "@tiptap/core";
import { AllSelection, Selection, TextSelection } from "prosemirror-state";
import VIcon from "../Icon/Icon.vue";
import LinkBubbleMenu from "./LinkBubbleMenu.vue";

const enum MenuType {
  NONE = "none",
  DEFAULT = "default",
  LINK = "link",
}

interface Props {
  editor: Editor;
}

const props = defineProps<Props>();

const activeMenu = ref<MenuType>(MenuType.NONE);
const isLinkBack = ref<boolean>(false);

const t: any = inject("t");

const bubbleMenuEnable = computed(
  () => linkMenuEnable.value || textMenuEnable.value
);

const linkMenuEnable = computed(() => {
  const { schema } = props.editor;

  return !!schema.marks.link;
});

const textMenuEnable = computed(() => {
  const extensionManager = props.editor.extensionManager;

  return extensionManager.extensions.some((extension) => {
    return extension.options.bubble;
  });
});

const isLinkSelection = computed(() => {
  const { state } = props.editor;
  const { tr } = state;
  const { selection } = tr;

  return $_isLinkSelection(selection);
});

watch(
  () => props.editor.state.selection,
  (selection: Selection) => {
    if ($_isLinkSelection(selection)) {
      if (!isLinkBack.value) {
        setMenuType(MenuType.LINK);
      }
    } else {
      activeMenu.value = $_getCurrentMenuType();
      isLinkBack.value = false;
    }
  }
);

const generateCommandButtonComponentSpecs = () => {
  const extensionManager = props.editor.extensionManager;
  return extensionManager.extensions.reduce((acc: any, extension: any) => {
    if (!extension.options.bubble) return acc;
    const { button } = extension.options;
    if (!button || typeof button !== "function") return acc;

    const menuBtnComponentSpec = button({
      editor: props.editor,
      t,
      extension,
    });

    if (Array.isArray(menuBtnComponentSpec)) {
      return [...acc, ...menuBtnComponentSpec];
    }

    return [...acc, menuBtnComponentSpec];
  }, []);
};

const linkBack = () => {
  setMenuType(MenuType.DEFAULT);
  isLinkBack.value = true;
};

const setMenuType = (type: MenuType) => {
  activeMenu.value = type;
};

const $_isLinkSelection = (selection: Selection) => {
  const { schema } = props.editor;
  const linkType = schema.marks.link;
  if (!linkType) return false;
  if (!selection) return false;

  const { $from, $to } = selection;
  const range = getMarkRange($from, linkType);
  if (!range) return false;

  return range.to === $to.pos;
};

const $_getCurrentMenuType = () => {
  if (isLinkSelection.value) return MenuType.LINK;

  if (
    props.editor.state.selection instanceof TextSelection ||
    props.editor.state.selection instanceof AllSelection
  ) {
    return MenuType.DEFAULT;
  }

  return MenuType.NONE;
};
</script>
