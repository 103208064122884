import { FormItemObjDeclaration } from "@/plugins/form-generator-json-v2/types";
import {
  createOrganization,
  getOrganizations,
} from "@/api/points/business-units-api/organizations";
import {
  createBusinessUnitType,
  getBusinessUnitTypes,
} from "@/api/points/business-units-api/business-unit-types";
import {
  createStoreBrand,
  getStoreBrands,
} from "@/api/points/business-units-api/store-brands";
import {
  createDeliveryMethod,
  getDeliveryMethods,
} from "@/api/points/business-units-api/delivery-methods";
import {
  createPaymentMethod,
  getPaymentMethods,
} from "@/api/points/business-units-api/payment-methods";
import {
  createServicePoint,
  getServicePoints,
} from "@/api/points/business-units-api/service-points";
import {
  createServiceZone,
  getServiceZones,
} from "@/api/points/business-units-api/service-zones";
import {
  createClientZone,
  getClientZones,
} from "@/api/points/business-units-api/client-zones";
import {
  createStockZone,
  getStockZones,
} from "@/api/points/business-units-api/stock-zones";
import { createStock, getStocks } from "@/api/points/business-units-api/stocks";
import {
  createBusinessUnit,
  getBusinessUnits,
} from "@/api/points/business-units-api/business-units";
import {
  createBuilding,
  getBuildings,
} from "@/api/points/business-units-api/buildings";
import { asyncSelect } from "@/components/form-declaration/default-elements";

export const organization_id_select = (
  model = "organization_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Контрагенты",
    include: "organization",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const organizations = await getOrganizations({
        "page[size]": 100,
        ...parameters,
      });
      return organizations.data.map((organization: any) => ({
        value: organization,
        label: `id: ${organization.id} - ${organization.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { Organizations } = await import(
          "@/components/form-declaration/business-units/form-declaration-organizations"
        );
        return Organizations;
      },
      method: createOrganization,
    },
  });

export const business_unit_type_id_select = (
  model = "business_unit_type_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Типы бизнес-юнитов",
    include: "businessUnitType",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const businessUnitTypes = await getBusinessUnitTypes({
        "page[size]": 100,
        ...parameters,
      });
      return businessUnitTypes.data.map((businessUnitType: any) => ({
        value: businessUnitType,
        label: `id: ${businessUnitType.id} - ${businessUnitType.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { BusinessUnitTypes } = await import(
          "@/components/form-declaration/business-units/form-declaration-business-unit-types"
        );
        return BusinessUnitTypes;
      },
      method: createBusinessUnitType,
    },
  });

export const store_brand_id_select = (
  model = "store_brand_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Бренды",
    include: "storeBrand",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const storeBrands = await getStoreBrands({
        "page[size]": 100,
        ...parameters,
      });
      return storeBrands.data.map((storeBrand: any) => ({
        value: storeBrand,
        label: `id: ${storeBrand.id} - ${storeBrand.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { StoreBrands } = await import(
          "@/components/form-declaration/business-units/form-declaration-store-brands"
        );
        return StoreBrands;
      },
      method: createStoreBrand,
    },
  });

export const delivery_method_ids_select = (
  model = "delivery_method_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Способы доставки",
    include: "deliveryMethods",
    filterable: false,
    multiple: true,
    clearable: true,
    optionList: async (parameters) => {
      const deliveryMethods = await getDeliveryMethods({
        "page[size]": 100,
        // "filter[is_active]": 1,
        ...parameters,
      });
      return deliveryMethods.data.map((deliveryMethod: any) => ({
        value: deliveryMethod,
        label: `id: ${deliveryMethod.id} - ${deliveryMethod.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить способ доставки",
      form: async () => {
        const { DeliveryMethods } = await import(
          "@/components/form-declaration/business-units/form-declaration-delivery-methods"
        );
        return DeliveryMethods;
      },
      method: createDeliveryMethod,
    },
  });

export const payment_method_ids_select = (
  model = "payment_method_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Способы оплаты",
    include: "paymentMethods",
    filterable: false,
    multiple: true,
    clearable: true,
    optionList: async (parameters) => {
      const paymentMethods = await getPaymentMethods({
        "page[size]": 100,
        // "filter[is_active]": 1,
        ...parameters,
      });
      return paymentMethods.data.map((paymentMethod: any) => ({
        value: paymentMethod,
        label: `id: ${paymentMethod.id} - ${paymentMethod.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить способ оплаты",
      form: async () => {
        const { PaymentMethods } = await import(
          "@/components/form-declaration/business-units/form-declaration-payment-methods"
        );
        return PaymentMethods;
      },
      method: createPaymentMethod,
    },
  });

export const service_point_ids_select = (
  model = "service_point_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Сервисные посты",
    include: "servicePoints",
    filterable: false,
    multiple: true,
    clearable: true,
    optionList: async (parameters) => {
      const servicePoints = await getServicePoints({
        "page[size]": 100,
        // "filter[is_active]": 1,
        ...parameters,
      });
      return servicePoints.data.map((servicePoint: any) => ({
        value: servicePoint,
        label: `id: ${servicePoint.id} - ${servicePoint.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить пост",
      form: async () => {
        const { ServicePoints } = await import(
          "@/components/form-declaration/business-units/form-declaration-service-points"
        );
        return ServicePoints;
      },
      method: createServicePoint,
    },
  });

export const service_zone_ids_select = (
  model = "service_zone_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Сервисные зоны",
    include: "serviceZones",
    filterable: false,
    multiple: true,
    clearable: true,
    optionList: async (parameters) => {
      const serviceZones = await getServiceZones({
        "page[size]": 100,
        // "filter[is_active]": 1,
        ...parameters,
      });
      return serviceZones.data.map((serviceZone: any) => ({
        value: serviceZone,
        label: `id: ${serviceZone.id} - ${serviceZone.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить зону",
      form: async () => {
        const { ServiceZones } = await import(
          "@/components/form-declaration/business-units/form-declaration-service-zones"
        );
        return ServiceZones;
      },
      method: createServiceZone,
    },
  });

export const client_zone_id_select = (
  model = "client_zone_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Клиентские зоны",
    include: "clientZone",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const clientZones = await getClientZones({
        "page[size]": 100,
        // "filter[is_active]": 1,
        ...parameters,
      });
      return clientZones.data.map((clientZone: any) => ({
        value: clientZone,
        label: `id: ${clientZone.id} - ${clientZone.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить зону",
      form: async () => {
        const { ClientZones } = await import(
          "@/components/form-declaration/business-units/form-declaration-client-zones"
        );
        return ClientZones;
      },
      method: createClientZone,
    },
  });

export const stock_zone_ids_select = (
  model = "stock_zone_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Складские зоны",
    include: "stockZones",
    filterable: false,
    multiple: true,
    clearable: true,
    optionList: async (parameters) => {
      const stockZones = await getStockZones({
        "page[size]": 100,
        // "filter[is_active]": 1,
        ...parameters,
      });
      return stockZones.data.map((stockZone: any) => ({
        value: stockZone,
        label: `id: ${stockZone.id} - ${stockZone.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить зону",
      form: async () => {
        const { StockZones } = await import(
          "@/components/form-declaration/business-units/form-declaration-stock-zones"
        );
        return StockZones;
      },
      method: createStockZone,
    },
  });

export const stock_ids_select = (model = "stock_ids"): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Склады",
    include: "stocks",
    filterable: false,
    multiple: true,
    clearable: true,
    optionList: async (parameters) => {
      const stocks = await getStocks({
        "page[size]": 100,
        ...parameters,
      });
      return stocks.data.map((stock: any) => ({
        value: stock,
        label: `id: ${stock.id} - ${stock.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value: any) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить склад",
      form: async () => {
        const { Stocks } = await import(
          "@/components/form-declaration/business-units/form-declaration-stocks"
        );
        return Stocks;
      },
      method: createStock,
    },
  });

export const business_unit_id_select = (
  model = "business_unit_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Бизнес-юнит",
    include: "businessUnit",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const businessUnits = await getBusinessUnits({
        "page[size]": 100,
        // "filter[is_active]": 1,
        ...parameters,
      });
      return businessUnits.data.map((businessUnit: any) => ({
        value: businessUnit,
        label: `id: ${businessUnit.id} - ${businessUnit.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить зону",
      form: async () => {
        const { BusinessUnits } = await import(
          "@/components/form-declaration/business-units/form-declaration-business-units"
        );
        return BusinessUnits;
      },
      method: createBusinessUnit,
    },
  });

export const business_unit_ids_select = (
  model = "business_unit_ids"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Бизнес-юнит",
    include: "businessUnits",
    filterable: false,
    multiple: true,
    clearable: true,
    optionList: async (parameters) => {
      const businessUnits = await getBusinessUnits({
        "page[size]": 100,
        // "filter[is_active]": 1,
        ...parameters,
      });
      return businessUnits.data.map((businessUnit: any) => ({
        value: businessUnit,
        label: `id: ${businessUnit.id} - ${businessUnit.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить зону",
      form: async () => {
        const { BusinessUnits } = await import(
          "@/components/form-declaration/business-units/form-declaration-business-units"
        );
        return BusinessUnits;
      },
      method: createBusinessUnit,
    },
  });

export const building_id_select = (
  model = "building_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Здание",
    include: "building",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const buildings = await getBuildings({
        "page[size]": 100,
        // "filter[is_active]": 1,
        ...parameters,
      });
      return buildings.data.map((building: any) => ({
        value: building,
        label: `id: ${building.id} - ${building.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить здание",
      form: async () => {
        const { Buildings } = await import(
          "@/components/form-declaration/business-units/form-declaration-buildings"
        );
        return Buildings;
      },
      method: createBuilding,
    },
  });
