import { Service } from "@/api/core/service";
import {
  CommunicationServicePointName,
  getCommunicationServicePathByEndpoint,
} from "@/api/points/communication-api/service-config";
import { providerAccountFieldsStorage } from "@/model/provider-type";
import {
  CustomListRequestOptions,
  GetParametersArgumentsDeclaration,
  IDefaultDeleteResponse,
  IDefaultListResponse,
} from "@/api/core/types";
import { IProviderAccountResponse } from "@/api/points/communication-api/provider-accounts/types";

const extraOptions = {
  list: {
    include: "providerType,providerTypeProps",
  },
  item: {
    include: "providerType",
  },
};

const endpoint = getCommunicationServicePathByEndpoint(
  CommunicationServicePointName.providerAccounts
);

class ExtendedProviderAccountsService extends Service {
  public async item(id: number): Promise<any> {
    try {
      const response = await super.item(id);

      providerAccountFieldsStorage.fieldsData = response.data.attributes.data;

      return response;
    } catch (err) {
      console.error(err);
    }
  }

  public async create(payload: Record<string, any>): Promise<any> {
    try {
      const preparedPayload = getPreparedPayload(payload);
      const response = await super.create(preparedPayload);

      providerAccountFieldsStorage.fieldsData = response.data.attributes.data;

      return response;
    } catch (err) {
      console.error(err);
    }
  }

  public async update(id: number, payload: Record<string, any>): Promise<any> {
    try {
      const preparedPayload = getPreparedPayload(payload);
      const response = await super.update(id, preparedPayload);

      providerAccountFieldsStorage.fieldsData = response.data.attributes.data;

      return response;
    } catch (err) {
      console.error(err);
    }
  }
}

const getPreparedPayload = (
  payload: Record<string, any>
): Record<string, any> => {
  const { columns_by_type, ...restPayload } = payload;
  const preparedDataField = JSON.stringify(columns_by_type);

  return { ...restPayload, data: preparedDataField };
};

const service = new ExtendedProviderAccountsService(endpoint, extraOptions);

export default service;

export const getCommunicationAccountProviders = service.list.bind(service) as (
  parameters: GetParametersArgumentsDeclaration,
  options?: CustomListRequestOptions
) => Promise<IDefaultListResponse<IProviderAccountResponse[]>>;

export const getCommunicationAccountProvider = service.item.bind(service) as (
  id: number
) => Promise<IProviderAccountResponse>;

export const createCommunicationAccountProvider = service.create.bind(
  service
) as (payload: Record<string, any>) => Promise<IProviderAccountResponse>;

export const updateCommunicationAccountProvider = service.update.bind(
  service
) as (
  id: number,
  payload: Record<string, any>
) => Promise<IProviderAccountResponse>;

export const deleteCommunicationAccountProvider = service.delete.bind(
  service
) as (
  id: number | string
) => Promise<IDefaultDeleteResponse<IProviderAccountResponse>>;
