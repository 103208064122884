import { SERVICE_POINT_NAME } from "@/api/points/auth-api/service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/system_services`);

export default service;
export const getSystemServices = service.list.bind(service);
export const getSystemServiceById = service.item.bind(service);
export const createSystemService = service.create.bind(service);
export const updateSystemService = service.update.bind(service);
export const deleteSystemService = service.delete.bind(service);
