export function debounce<F extends (...args: Array<any>) => any>(
  fn: F,
  ms: number
) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: Array<any>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
}
