import { fetchRequest } from "@/api/core";
import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const extra = {
  list: {
    "fields[generations]": "id,name,from_to,year_from",
    "fields[models]": "id,name",
    "fields[brands]": "id,name",
    include: "bodies,regions,generation.model.brand",
    sort: "name",
  },
  item: {
    include: "bodies,regions,generation.model.brand",
  },
};
const service = new Service(`${SERVICE_POINT_NAME}/modifications`, extra);

export default service;
export const getModifications = service.list.bind(service);
export const getModificationById = service.item.bind(service);
export const createModification = service.create.bind(service);
export const updateModification = service.update.bind(service);
export const deleteModification = service.delete.bind(service);
export const copyModification = service.copy.bind(service);
export const attributesStatModification = async (id: string): Promise<any> => {
  try {
    const queryParams = new URLSearchParams({
      include_count: "wheels,activeWheels",
    }).toString();

    const result = await fetchRequest({
      point: `${SERVICE_POINT_NAME}/modifications/${id}/?${queryParams}`,
      fetchMethod: "GET",
    });
    return result.data.count_relationships;
  } catch (e) {
    return false;
  }
};
export const serviceWizard = new Service(
  `${SERVICE_POINT_NAME}/modifications`,
  {
    ...extra,
    list: {
      include_count: "wheels,activeWheels",
      ...extra.list,
    },
  }
);
