<template>
  <section :class="$style['filter-tags']">
    <el-tag
      v-show="isShowClearBtn"
      :class="$style['tag']"
      type="info"
      @click="emit('clear')"
    >
      Очистить
      <el-icon size="12px">
        <Delete />
      </el-icon>
    </el-tag>
    <el-tag
      v-for="tag in filterTags"
      :key="tag.key"
      :class="$style['tag']"
      :closable="tag.showClearTag"
      @click="removeTag(tag)"
      @close="removeTag(tag, 'close')"
    >
      {{ tag.label }}:
      {{ d(tag.value) }}
    </el-tag>
  </section>
</template>

<script lang="ts" setup>
import { Delete } from "@element-plus/icons-vue";
import { LocationQueryValue, useRoute } from "vue-router";
import { ref, watch } from "vue";
import { d } from "@/helpers/dictionary";
import {
  FormItemObjDeclaration,
  GeneratorDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import { getRegex } from "@/helpers/regex";
import { MessageField } from "@/components/form-declaration/communication/form-declaration-messages/form-elements";
import { ServiceName } from "@/constants";
import formServiceRow from "@/helpers/formServiceRow";
import { WSEvent } from "@/composables/hooks/useWebsocket";

interface Props {
  data: Record<string, any>;
  filterForm: GeneratorDeclaration;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits(["clear", "remove-tag"]);

const route = useRoute();
const isCurrentRouteWizard: boolean = route.path.includes(ServiceName.wizard);
const isCdp = route.path.includes(ServiceName.cdp);
const isNipple = route.path.includes(ServiceName.nipple);

const filterTags = ref();

const isShowClearBtn = ref(
  isCurrentRouteWizard ? filterTags.value?.length > 1 : true
);

const getData = async () => {
  const getTagFromQuery = async (value: LocationQueryValue, key: string) => {
    let label = key.slice(key.indexOf("[") + 1, key.indexOf("]"));
    if (label === "body_name") label = "Каталог";
    if (label === "ws_body_name") label = "WS";
    if (label === "package_id") label = "Номер пакета";
    if (label === "filter_the_rear") {
      value === "false" ? (value = "да") : (value = "нет");
    }
    if (label === "my_tasks") {
      value = value === "true" ? "да" : "нет";
    }

    const labels = key.match(/\[(.*?)\]/g);
    const secondLabel = labels?.[1]
      ? labels[1].substring(1, labels[1].length - 1)
      : "";

    if (value && label === MessageField.createdAtLe) {
      const matches = value.match(new RegExp(getRegex("YYYY-MM-DD")));
      const matchDate = matches && matches[0];

      if (matchDate) {
        value = matchDate;
      }
    }

    const booleanKeys = [
      "active",
      "is_active",
      "is_hidden",
      "is_common",
      "wheels_active",
      "trashed",
    ];

    const nullKeys = [
      "speed_index",
      "rear_speed_index",
      "load_index",
      "rear_load_index",
    ];

    const yesNoKeys = ["is_strength", "rear_is_strength"];

    if (value === "1" || value === "0") {
      if (booleanKeys.includes(label)) {
        value = value === "1" ? "активные" : "неактивные";
      } else if (yesNoKeys.includes(label)) {
        value = value === "1" ? "да" : "нет";
      }
    }

    if (nullKeys.includes(label) && value === null) {
      value = "не выбрано";
    }

    if (
      props.data[key] &&
      typeof props.data[key] === "object" &&
      props.data[key]?.attributes?.name
    ) {
      value = props.data[key]?.attributes?.name;
    }

    const tagId = {
      id: null,
      isReversName: false,
    };

    const formItem = props.filterForm.items.find(
      (item) =>
        item.model === `filter[${label}]` ||
        item.model === `filter[${label}][${secondLabel}]`
    );

    if (formItem) {
      const options = formItem.selectOptions?.length
        ? formItem.selectOptions
        : formItem.asyncSelectOptionsMethod
        ? await formItem.asyncSelectOptionsMethod()
        : [];

      if (options?.length) {
        const selectedItem = options.find(
          (item: any) => String(item.value) === value
        );

        if (selectedItem) {
          value = selectedItem.label.toString();
          tagId.id = selectedItem.value;
        }
      }
    }

    const changeTagLabel = (items: FormItemObjDeclaration[], tag: string) => {
      for (const item of items) {
        if (item.model === tag) {
          for (const tag in item.options) {
            if (value && item.options[tag].value === +value) {
              value = item.options[tag].label;
              tagId.id = item.options[tag].value;
              tagId.isReversName = true;
            }
          }
        }
      }
    };

    if (key === "filter[body_id]")
      changeTagLabel(props.filterForm.items, "filter[body_id]");
    if (key === "filter[ws_body_id]")
      changeTagLabel(props.filterForm.items, "filter[ws_body_id]");
    if (
      [
        "filter[service_center_id]",
        "filter[brinex_id]",
        "filter[vehicle_type]",
        "filter[vehicle_type][]",
        "filter[manufacturer_id]",
      ].includes(key)
    )
      tagId.isReversName = true;

    if (key === "filter[operation_id][]") {
      const data = props.data?.["filter[operation_id][]"]?.attributes;
      value = data ? formServiceRow(data) : value;
    }

    const showClearTag = label !== "attributes";

    return {
      key,
      label: d(label, undefined, isCdp, isNipple),
      value,
      showClearTag,
      tagId,
    };
  };

  const tags = [];

  for (const tag in route.query) {
    if (tag.indexOf("filter[") === -1) {
      continue;
    }

    if (Array.isArray(route.query[tag])) {
      for (const value of route.query[tag]) {
        tags.push(await getTagFromQuery(value, tag));
      }
    } else {
      tags.push(await getTagFromQuery(route.query[tag] as string, tag));
    }
  }

  const getAttributesKey = (key: string): string =>
    key.slice(key.indexOf("[") + 1, key.indexOf("]"));

  const attributesArr = tags.filter(
    (tag) => getAttributesKey(tag.key) === "attributes"
  );

  if (attributesArr.length > 1) {
    for (const tag of tags) {
      if (getAttributesKey(tag.key) === "attributes") {
        tag.showClearTag = true;
      }
    }
  }

  filterTags.value = tags;
};

watch(
  props.data,
  async () => {
    await getData();
  },
  {
    immediate: true,
  }
);

const removeTag = (tag: any, type?: string) => {
  if (tag.tagId.isReversName) tag.value = tag.tagId.id;
  if (type === "close") {
    window.dispatchEvent(new CustomEvent(WSEvent.resetSectionsCreatedInstance));
    emit("remove-tag", tag);
  }
};
</script>

<style lang="scss" module>
.filter-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 4px;
  margin-top: 16px;
}

.tag {
  display: flex;
  align-items: center;
  height: 24px;
  font-size: 0.75rem;
  line-height: 1.25rem;
  padding-left: 10px;
  padding-right: 12px;
  cursor: pointer;

  :global .el-tag__content {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}
</style>
