<template>
  <el-dropdown :hide-on-click="false" :placement="placement" trigger="click">
    <el-button :icon="MoreFilled" type="default" />
    <template #dropdown>
      <el-dropdown-menu class="el-dropdown-menu skimmed">
        <el-dropdown-item
          v-if="active !== undefined"
          :disabled="!hasUpdateAccess"
          @click="toggleActivationItem"
          >{{ active ? "Деактивировать" : "Активировать" }}
        </el-dropdown-item>
        <el-popconfirm
          v-if="confirmDelete"
          :icon="InfoFilled"
          cancel-button-text="Нет"
          confirm-button-text="Да"
          icon-color="red"
          title="Вы уверены, что хотите удалить?"
          width="300"
          @confirm="deleteItem"
        >
          <template #reference>
            <div>
              <el-dropdown-item
                :disabled="!hasDestroyAccess"
                class="el-dropdown-menu__item--danger"
              >
                Удалить
              </el-dropdown-item>
            </div>
          </template>
        </el-popconfirm>
        <el-dropdown-item
          v-else
          :disabled="!hasDestroyAccess"
          class="el-dropdown-menu__item--danger"
          @click="deleteItem"
        >
          Удалить
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script lang="ts" setup>
import { computed, toRefs } from "vue";
import { InfoFilled, MoreFilled } from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import useAccessRights from "@/composables/hooks/useAccessRights";
import { Placement } from "element-plus";
import { FormModel } from "@/plugins/form-generator-json-v2/types";

const emit = defineEmits(["delete", "toggleActivation"]);

const router = useRouter();
const { hasDestroyAccess, hasUpdateAccess } = useAccessRights(router);

interface Props {
  formModel: FormModel;
  confirmDelete: boolean;
  placement: Placement;
}

const props = withDefaults(defineProps<Props>(), { confirmDelete: true });
const { formModel } = toRefs(props);
const active = computed(() =>
  formModel.value?.active !== undefined
    ? formModel.value?.active
    : formModel.value?.is_active
);

const deleteItem = () => {
  emit("delete");
};

const toggleActivationItem = () => {
  emit("toggleActivation");
};
</script>
