import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(
  `${SERVICE_POINT_NAME}/business_to_consumer_blocks`,
  {
    item: { include: "image" },
  }
);

export default service;
export const getBusinessToConsumerBlocks = service.list.bind(service);
export const getBusinessToConsumerBlockById = service.item.bind(service);
export const createBusinessToConsumerBlock = service.create.bind(service);
export const updateBusinessToConsumerBlock = service.update.bind(service);
export const deleteBusinessToConsumerBlock = service.delete.bind(service);
