<template>
  <el-dropdown
    :disabled="isCodeViewMode"
    placement="bottom"
    popper-class="el-tiptap-dropdown-popper"
    trigger="click"
    @command="toggleHeading"
  >
    <command-button
      :disabled="isCodeViewMode"
      :enable-tooltip="enableTooltip"
      :is-active="editor.isActive('heading')"
      :readonly="isCodeViewMode"
      :tooltip="t('editor.extensions.Heading.tooltip')"
      icon="heading"
    >
      Headings
    </command-button>
    <template #dropdown>
      <el-dropdown-menu class="el-tiptap-dropdown-menu">
        <el-dropdown-item
          v-for="level in [0, ...levels]"
          :key="level"
          :command="level"
        >
          <div
            :class="[
              {
                'el-tiptap-dropdown-menu__item--active':
                  level > 0
                    ? editor.isActive('heading', {
                        level,
                      })
                    : editor.isActive('paragraph'),
              },
              'el-tiptap-dropdown-menu__item',
            ]"
          >
            <template v-if="level > 0">
              <component :is="'h' + level" data-item-type="heading">
                {{ t("editor.extensions.Heading.buttons.heading") }} {{ level }}
              </component>
            </template>
            <span v-else>{{
              t("editor.extensions.Heading.buttons.paragraph")
            }}</span>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script lang="ts" setup>
import { inject } from "vue";
import { ElDropdown, ElDropdownItem, ElDropdownMenu } from "element-plus";
import type { Level } from "@tiptap/extension-heading";
import { Editor } from "@tiptap/core";
import CommandButton from "./CommandButton.vue";

interface Props {
  editor: Editor;
  levels: Array<any>;
}

const props = defineProps<Props>();

const t: any = inject("t");
const enableTooltip = inject("enableTooltip", true);
const isCodeViewMode = inject("isCodeViewMode", false);

const toggleHeading = (level: Level) => {
  if (level > 0) {
    props.editor.commands.toggleHeading({ level });
  } else {
    props.editor.commands.setParagraph();
  }
};
</script>
