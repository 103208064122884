export default (data: Record<string, any>) => {
  if (data.type === "front") {
    if (data.relationships?.rear) {
      return "Передняя";
    } else {
      return "Универсальная";
    }
  } else {
    return "Задняя";
  }
};
