import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/contact_blocks`);

export default service;
export const getContactBlocks = service.list.bind(service);
export const getContactBlockById = service.item.bind(service);
export const createContactBlock = service.create.bind(service);
export const updateContactBlock = service.update.bind(service);
export const deleteContactBlock = service.delete.bind(service);
