export const DATE_FIELD_NAMES = ["updated_at", "created_at"];

export default (
  value: string,
  withSecond = false,
  noTimezone = false
): string => {
  if ((value || "").trim() === "") {
    return "";
  }

  return new Date(
    noTimezone ? dateWithNoTimezone(value) : value
  ).toLocaleDateString("ru-RU", {
    weekday: "short",
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: withSecond ? "2-digit" : void 0,
  });
};

export const toYMDDate = (value: string): string => {
  if ((value || "").trim() === "") {
    return "";
  }
  return new Date(value).toLocaleDateString("ru-RU", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const toWdYMDDate = (value: string): string => {
  if ((value || "").trim() === "") {
    return "";
  }
  return new Date(value).toLocaleDateString("ru-RU", {
    weekday: "short",
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });
};

export const toDMYDate = (value: string): string =>
  (value || "").trim() === ""
    ? ""
    : new Date(value).toLocaleDateString("ru-RU", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

export const nippleDate = (value: string): string => {
  if ((value || "").trim() === "") return "";

  return (
    ("0" + new Date(value).getDate()).slice(-2) +
    "." +
    ("0" + (new Date(value).getMonth() + 1)).slice(-2) +
    "." +
    ("" + new Date(value).getFullYear()).slice(-2) +
    ", " +
    ("0" + new Date(value).getHours()).slice(-2) +
    ":" +
    ("0" + new Date(value).getMinutes()).slice(-2) +
    ":" +
    ("0" + new Date(value).getSeconds()).slice(-2)
  );
};

const padZero = (num: number) => ("0" + num).slice(-2);

export const formatDate = (date: Date): string => {
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  return `${year}-${month}-${day}`;
};

export const formatTime = (date: Date): string => {
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());
  const seconds = padZero(date.getSeconds());
  return `${hours}:${minutes}:${seconds}`;
};

export const formatTimezoneOffset = (): string => {
  const timezoneDate = new Date();
  const timezoneOffset = Math.abs(timezoneDate.getTimezoneOffset());
  const offsetHours = padZero(Math.floor(timezoneOffset / 60));
  const offsetMinutes = padZero(timezoneOffset % 60);

  return `+${offsetHours}:${offsetMinutes}`;
};

export const formatDateTimeString = (
  date: string,
  type: string
): string | null => {
  if (!date) return null;

  const parsedDate = new Date(date);
  const formattedDate = formatDate(parsedDate);

  if (type === "datetime") {
    const formattedTime = formatTime(parsedDate);
    const formattedOffset = formatTimezoneOffset();
    return `${formattedDate}T${formattedTime}${formattedOffset}`;
  }

  return formattedDate;
};

export const dateWithNoTimezone = (value: string): Date => {
  const date = new Date(value);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() + userTimezoneOffset);
};
