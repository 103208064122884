import { RouteAccess } from "@/router/types";
import WorkingSectionView from "@/views/WorkingSectionView.vue";
import { RouteRecordRaw } from "vue-router";

const access: RouteAccess = {
  service: "landings-cms",
  object: "",
  command: "index",
};

export const franchiseBlocksRoutesList: RouteRecordRaw & {
  isCategory: boolean;
  hasChildren: boolean;
} = {
  path: "/landings/franchise-blocks",
  name: "franchise",
  isCategory: true,
  hasChildren: true,
  component: (): Promise<typeof import("*.vue")> =>
    import(/* webpackChunkName: "franchiseBlocks" */ "../../../views/Root.vue"),
  meta: {
    title: "Блоки - франшиза",
  },
  children: [
    {
      path: "/landings/franchise-blocks/menu-item-blocks",
      name: "franchise-menu-item-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Меню"',
        access: {
          ...access,
          object: "menu_item_blocks",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/menu-items",
      name: "franchise-menu-items",
      component: WorkingSectionView,
      meta: {
        title: "Пункты меню",
        access: {
          ...access,
          object: "menu_items",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/franchise-first-blocks",
      name: "franchise-first-blocks",
      component: WorkingSectionView,
      meta: {
        title: "Франшиза - первый блок",
        access: {
          ...access,
          object: "franchise_first_blocks",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/franchise-contact-blocks",
      name: "franchise-contact-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Франшиза - Блок "Контакты"',
        access: {
          ...access,
          object: "franchise_contact_blocks",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/business-advantage-blocks",
      name: "franchise-business-advantage-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Бизнес-преимущества"',
        access: {
          ...access,
          object: "business_advantage_blocks",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/business-advantages",
      name: "franchise-business-advantages",
      component: WorkingSectionView,
      meta: {
        title: "Преимущества",
        access: {
          ...access,
          object: "business_advantages",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/partnership-blocks",
      name: "franchise-partnership-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Заявка на сотрудничество"',
        access: {
          ...access,
          object: "partnership_blocks",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/business-format-blocks",
      name: "business-format-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Форматы работы"',
        access: {
          ...access,
          object: "business_format_blocks",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/business-formats",
      name: "business-formats",
      component: WorkingSectionView,
      meta: {
        title: "Форматы бизнеса",
        access: {
          ...access,
          object: "business_formats",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/package-options",
      name: "package-options",
      component: WorkingSectionView,
      meta: {
        title: "Пакетные опции",
        access: {
          ...access,
          object: "package_options",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/franchisee-incomes",
      name: "franchisee-incomes",
      component: WorkingSectionView,
      meta: {
        title: "Доход франчайзи",
        access: {
          ...access,
          object: "franchisee_incomes",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/support-blocks",
      name: "support-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Поддержка"',
        access: {
          ...access,
          object: "support_blocks",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/income-calculator-blocks",
      name: "income-calculator-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Калькулятор дохода"',
        access: {
          ...access,
          object: "income_calculator_blocks",
        },
      },
    },
    {
      path: "/landings/franchise-blocks/our-network-blocks",
      name: "our-network-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Наша сеть"',
        access: {
          ...access,
          object: "our_network_blocks",
        },
      },
    },
  ],
};
