import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import TiptapBulletList from "@tiptap/extension-bullet-list";
import CommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CommandButton.vue";
import ListItem from "./list-item";

const BulletList = TiptapBulletList.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      button({ editor, t, isCodeViewMode }: CommandButtonArgs) {
        return {
          component: CommandButton,
          componentProps: {
            command: () => {
              editor.commands.toggleBulletList();
            },
            isActive: editor.isActive("bulletList"),
            icon: "list-ul",
            tooltip: t("editor.extensions.BulletList.tooltip"),
            isCodeViewMode,
          },
        };
      },
    };
  },

  addExtensions() {
    return [ListItem];
  },
});

export default BulletList;
