import { ref } from "vue";

export const useLoader = () => {
  const loading = ref<boolean>(false);
  const setLoading = (value: boolean): void => {
    loading.value = value;
  };

  return {
    loading,
    setLoading,
  };
};
