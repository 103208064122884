import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import TiptapHorizontalRule from "@tiptap/extension-horizontal-rule";
import CommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CommandButton.vue";

const HorizontalRule = TiptapHorizontalRule.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      button({ editor, t, isCodeViewMode }: CommandButtonArgs) {
        return {
          component: CommandButton,
          componentProps: {
            command: () => {
              editor.commands.setHorizontalRule();
            },
            icon: "horizontal-rule",
            tooltip: t("editor.extensions.HorizontalRule.tooltip"),
            isCodeViewMode,
          },
        };
      },
    };
  },
});

export default HorizontalRule;
