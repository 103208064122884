import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/income_calculator_blocks`);

export default service;
export const getIncomeCalculatorBlocks = service.list.bind(service);
export const getIncomeCalculatorBlockById = service.item.bind(service);
export const createIncomeCalculatorBlock = service.create.bind(service);
export const updateIncomeCalculatorBlock = service.update.bind(service);
export const deleteIncomeCalculatorBlock = service.delete.bind(service);
