import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/payment_methods`);

export default service;
export const getPaymentMethods = service.list.bind(service);
export const getPaymentMethodById = service.item.bind(service);
export const createPaymentMethod = service.create.bind(service);
export const updatePaymentMethod = service.update.bind(service);
export const deletePaymentMethod = service.delete.bind(service);
