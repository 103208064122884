import {
  DefaultJsonFormContext,
  FormItemObjDeclaration,
} from "@/plugins/form-generator-json-v2/types";

import router from "@/router";

interface OuterLinkModelFilter {
  name: string;
  model: string;
}

interface OuterLinkValueFilter {
  name: string;
  value: any;
}

type OuterLinkFilter = OuterLinkModelFilter | OuterLinkValueFilter;

function isModelFilter(
  filter: OuterLinkModelFilter | OuterLinkValueFilter
): filter is OuterLinkModelFilter {
  return (filter as OuterLinkModelFilter).model !== undefined;
}

export const defaultOuterLink = (
  text = "Ссылка",
  path: string,
  filters: Record<string, any>,
  isOpenNewTab = false
): FormItemObjDeclaration => ({
  name: "defaultOuterLink",
  id: "defaultOuterLink",
  model: "",
  element: "button",
  text,
  formItemAttrs: {
    class: "el-form-item--outer-link",
  },
  events: {
    onClick: function (this: DefaultJsonFormContext): void {
      if (!path) return;
      const query = filters.reduce((acc: any, filter: OuterLinkFilter) => {
        acc[filter.name] = isModelFilter(filter)
          ? this.formModel[filter.model]
          : filter.value;

        return acc;
      }, {});

      if (isOpenNewTab) {
        const link = router.resolve({ path, query });
        window.open(link.href, "_blank");
      } else {
        router.push({ path, query });
      }
    },
  },
});
