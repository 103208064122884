import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/stock_zones`);

export default service;
export const getStockZones = service.list.bind(service);
export const getStockZoneById = service.item.bind(service);
export const createStockZone = service.create.bind(service);
export const updateStockZone = service.update.bind(service);
export const deleteStockZone = service.delete.bind(service);
