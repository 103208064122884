import { RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { CONTROL } from "@/dictionary/header";
import { RouteAccess } from "../types";

const access: RouteAccess = {
  service: "vehicles",
  object: "wizard",
  command: "index",
};

export const catalogVehiclesWizardRoute: RouteRecordRaw & {
  query: Record<string, string>;
} = {
  path: "/catalog-vehicles/wizard",
  name: "wizard",
  component: (): Promise<typeof import("*.vue")> =>
    import(
      /* webpackChunkName: "Wizard" */ "@/views/catalog-vehicles/Wizard.vue"
    ),
  query: { "filter[attributes]": "wheels" },
  meta: {
    title: CONTROL,
    access,
  },
  redirect: (): RouteLocationRaw => ({
    name: "wizard-brands",
    params: { actionModal: "" },
  }),
  children: [
    {
      path: "/catalog-vehicles/wizard",
      name: "wizard-brands",
      component: (): Promise<typeof import("*.vue")> =>
        import(
          /* webpackChunkName: "WizardBrands" */ "@/views/catalog-vehicles-wizard/Brands.vue"
        ),
      meta: {
        access: {
          ...access,
          object: "brands",
        },
      },
    },
    {
      path: "/catalog-vehicles/wizard/brand/:id",
      name: "wizard-models",
      component: (): Promise<typeof import("*.vue")> =>
        import(
          /* webpackChunkName: "WizardModels" */ "@/views/catalog-vehicles-wizard/Models.vue"
        ),
      meta: {
        access: {
          ...access,
          object: "models",
        },
        preset: "brand_id",
        filter: "filter[brand_id]",
      },
    },
    {
      path: "/catalog-vehicles/wizard/model/:id",
      name: "wizard-generations",
      component: (): Promise<typeof import("*.vue")> =>
        import(
          /* webpackChunkName: "WizardGenerations" */ "@/views/catalog-vehicles-wizard/Generations.vue"
        ),
      meta: {
        access: {
          ...access,
          object: "generations",
        },
        preset: "model_id",
        filter: "filter[model_id]",
      },
    },
    {
      path: "/catalog-vehicles/wizard/generation/:id",
      name: "wizard-modifications",
      component: (): Promise<typeof import("*.vue")> =>
        import(
          /* webpackChunkName: "WizardModifications" */ "@/views/catalog-vehicles-wizard/Modifications.vue"
        ),
      meta: {
        access: {
          ...access,
          object: "modifications",
        },
        preset: "generation_id",
        filter: "filter[generation_id]",
      },
    },
    {
      path: "/catalog-vehicles/wizard/attributes/:id/:attribute?",
      name: "wizard-attributes",
      component: (): Promise<typeof import("*.vue")> =>
        import(
          /* webpackChunkName: "WizardAttributes" */ "@/views/catalog-vehicles-attributes/Page.vue"
        ),
      meta: {
        access: {
          ...access,
          object: "wheel_pairs",
        },
        preset: "modification_id",
        filter: "filter[modification_id]",
      },
    },
  ],
};
