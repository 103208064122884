<template>
  <el-dropdown
    :disabled="isCodeViewMode"
    placement="bottom"
    trigger="click"
    @command="toggleFontType"
  >
    <command-button
      :enable-tooltip="enableTooltip"
      :readonly="isCodeViewMode"
      :tooltip="t('editor.extensions.FontType.tooltip')"
      icon="font-family"
    />

    <template #dropdown>
      <el-dropdown-menu class="el-tiptap-dropdown-menu">
        <el-dropdown-item
          v-for="name in fontFamilies"
          :key="name"
          :class="{
            'el-tiptap-dropdown-menu__item--active': name === activeFontFamily,
          }"
          :command="name"
          class="el-tiptap-dropdown-menu__item"
        >
          <span :data-font="name" :style="{ 'font-family': name }">{{
            name
          }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script lang="ts" setup>
import { computed, inject } from "vue";
import { Editor, getMarkAttributes } from "@tiptap/vue-3";
import { ElDropdown, ElDropdownItem, ElDropdownMenu } from "element-plus";
import CommandButton from "./CommandButton.vue";

interface Props {
  editor: Editor;
}

const props = defineProps<Props>();

const t: any = inject("t");
const enableTooltip = inject("enableTooltip", true);
const isCodeViewMode = inject("isCodeViewMode", false);

const fontFamilies = computed(() => {
  const fontFamilyOptions = props.editor.extensionManager.extensions.find(
    (e) => e.name === "fontFamily"
  )?.options;
  return fontFamilyOptions.fontFamilyMap;
});

const activeFontFamily = computed(() => {
  return getMarkAttributes(props.editor.state, "textStyle").fontFamily || "";
});

const toggleFontType = (name: string) => {
  if (name === activeFontFamily.value) {
    props.editor.commands.unsetFontFamily();
  } else {
    props.editor.commands.setFontFamily(name);
  }
};
</script>
