import { Editor } from "@tiptap/core";
import { nextTick, provide, ref, ShallowRef, unref, watch } from "vue";
import { TipTapExtensionId } from "@/plugins/tiptap-editor/constants";

export default function useCodeView(
  editor: ShallowRef<Editor | undefined>
): any {
  let cmEditor: any;
  const cmTextAreaRef = ref();
  const isCodeViewMode = ref(false);
  const toggleIsCodeViewMode = (val: boolean) => {
    isCodeViewMode.value = val;
  };

  const initCodemirror = () => {
    const codeViewExtension = unref(editor)?.extensionManager.extensions.find(
      (e) => e.name === TipTapExtensionId.codeView
    );
    if (codeViewExtension) {
      const { codemirror, codemirrorOptions } = codeViewExtension.options;
      if (codemirror) {
        const cmOptions = {
          ...codemirrorOptions,
        };
        cmEditor = codemirror.fromTextArea(cmTextAreaRef.value, cmOptions);
        cmEditor.setValue(unref(editor)?.getHTML());

        cmEditor.on("change", function (cmEditorInstance: any) {
          unref(editor)?.commands.setContent(
            cmEditorInstance.getValue(),
            true /* emitUpdate */
          );
        });
      }
    }
  };

  const destroyCodemirror = () => {
    const element = cmEditor.doc.cm.getWrapperElement();
    element && element.remove && element.remove();
    cmEditor = null;
  };

  watch(
    isCodeViewMode,
    (val: boolean) => {
      if (val) {
        nextTick(() => {
          if (!cmEditor) {
            initCodemirror();
          }
        });
      } else if (cmEditor) {
        const content = cmEditor.getValue();
        unref(editor)?.commands.setContent(content, true /* emitUpdate */);
        destroyCodemirror();
      }
    },
    {
      immediate: true,
    }
  );

  provide("isCodeViewMode", isCodeViewMode);
  provide("toggleIsCodeViewMode", toggleIsCodeViewMode);

  return {
    cmTextAreaRef,
    isCodeViewMode,
    toggleIsCodeViewMode,
  };
}
