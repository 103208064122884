import { Extension } from "@tiptap/core";
import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import CommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CommandButton.vue";
import {
  createIndentCommand,
  IndentProps,
} from "@/plugins/tiptap-editor/utils/indent";

export interface IndentOptions {
  types: string[];
  minIndent: number;
  maxIndent: number;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    indent: {
      /**
       * Set the indent attribute
       */
      indent: () => ReturnType;
      /**
       * Set the outdent attribute
       */
      outdent: () => ReturnType;
    };
  }
}

const Indent = Extension.create<IndentOptions>({
  name: "indent",

  addOptions() {
    return {
      types: ["paragraph", "heading", "blockquote"],
      minIndent: IndentProps.min,
      maxIndent: IndentProps.max,
      button({ editor, t, isCodeViewMode }: CommandButtonArgs) {
        return [
          {
            component: CommandButton,
            componentProps: {
              command: () => {
                editor.commands.indent();
              },
              icon: "indent",
              tooltip: t("editor.extensions.Indent.buttons.indent.tooltip"),
              isCodeViewMode,
            },
          },
          {
            component: CommandButton,
            componentProps: {
              command: () => {
                editor.commands.outdent();
              },
              icon: "outdent",
              tooltip: t("editor.extensions.Indent.buttons.outdent.tooltip"),
              isCodeViewMode,
            },
          },
        ];
      },
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          indent: {
            default: 0,
            parseHTML: (element) => {
              const identAttr = element.getAttribute("data-indent");
              return (identAttr ? parseInt(identAttr, 10) : 0) || 0;
            },
            renderHTML: (attributes) => {
              if (!attributes.indent) {
                return {};
              }

              return { ["data-indent"]: attributes.indent };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      indent: () =>
        createIndentCommand({
          delta: IndentProps.more,
          types: this.options.types,
        }),
      outdent: () =>
        createIndentCommand({
          delta: IndentProps.less,
          types: this.options.types,
        }),
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: () => this.editor.commands.indent(),
      "Shift-Tab": () => this.editor.commands.outdent(),
    };
  },
});

export default Indent;
