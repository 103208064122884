import router from "@/router";
import { QUERY_PARAM } from "@/components/config";

export const instanceId = (): string =>
  router.currentRoute.value.params[QUERY_PARAM]?.toString() || "";

export const getPath = (): string =>
  `/${router.currentRoute.value.path
    .split("/")
    .filter((route) => route.match(/[a-z]/) && route !== "create")
    .join("/")}`;

export const getPathParams = (): {
  service: string;
  section: string;
} => {
  const [service, section] = splitPath();

  return {
    service: service || "",
    section: section || "",
  };
};

export const splitPath = (): string[] =>
  router.currentRoute.value.path
    .split("/")
    .filter((route) => route.match(/[a-z]/) && route !== "create");

export const redirectToFirstPage = (withFilter = false) => {
  let queryStr = "pageNumber=1";

  if (withFilter) {
    const queryParams = router.currentRoute.value.query;
    for (const key in queryParams) {
      if (key === "pageNumber") {
        continue;
      }
      queryStr = `${queryStr}&${key}=${queryParams[key]}`;
    }
  }
  window.location.href = `${router.currentRoute.value.path}?${queryStr}`;
};
