const getRelationship = (name: string, data: Record<string, any>): any => {
  if (!data?.relationships) {
    return;
  }
  if (data.relationships[name] === null) {
    return;
  }
  if (data.relationships[name]) {
    return data.relationships[name];
  }
  const relationshipKeys = Object.keys(data.relationships);

  if (name === "users" && relationshipKeys.includes("linkedUsers")) {
    return data.relationships["linkedUsers"];
  }

  while (relationshipKeys.length) {
    const firstKey = relationshipKeys.shift();
    if (firstKey) {
      const relationshipData = getRelationship(
        name,
        data.relationships[firstKey]
      );
      if (relationshipData) {
        return relationshipData;
      }
    } else {
      break;
    }
  }
  return;
};

export default getRelationship;
