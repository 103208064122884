export const ACCESS_CONTROL = "Управление доступом";
export const USER_GROUPS = "Группы пользователей";
export const USERS = "Пользователи (все)";
export const USERS_STAFF = "Пользователи (сотрудники)";
export const SERVICES = "Сервисы";
export const OBJECTS = "Объекты";
export const COMMANDS = "Команды";
export const BUSINESS_UNITS = "Бизнес-юниты";
export const BUILDINGS = "Здания";
export const ORGANIZATIONS = "Контрагенты";
export const BUSINESS_UNIT_TYPES = "Типы бизнес-юнитов";
export const STORE_BRANDS = "Бренды";
export const DELIVERY_METHODS = "Способы доставки";
export const PAYMENT_METHODS = "Способы оплаты";
export const SERVICE_ZONES = "Сервисные зоны";
export const SERVICE_POINTS = "Сервисные посты";
export const CLIENT_ZONES = "Клиентские зоны";
export const STOCKS = "Склады";
export const STOCKS_ZONES = "Складские зоны";
export const CATALOG_CAR = "Каталог ТС";
export const UPDATE = "Обновления";
export const MODELS = "Модели";
export const TYPES = "Типы";
export const BRANDS = "Марки";
export const CATEGORIES = "Виды";
export const MODIFICATIONS = "Модификации";
export const BODIES = "Кузова";
export const WHEELS = "Колеса";
export const REGIONS = "Регионы";
export const COUNTRIES = "Страны";
export const GENERATIONS = "Поколения";
export const FOOTER = "© 2022-2023 ООО «Колёса Даром». Все права защищены.";
export const CONTROL = "Управление";
export const HOME_PAGE = "Главная страница";
export const CHANGE_HISTORY = "История изменений";
export const ACTION_LOG = "Лог действий";
export const USER = "Пользователь";
export const SERVICE_VERSION = "Версии сервисов";
export const LANDINGS_CMS = "Лендинги CMS";
export const LANDINGS = "Лендинги";
export const DICTIONARIES = "Справочники";
export const USER_ROLES = "Роли пользователей";
export const COUNTERAGENTCONTRACTS = "Договор/ДС";
export const COUNTERAGENTS = "Контрагенты";
export const GEOREGIONS = "Регионы продаж";
export const CDP = "Бонусная программа B2B";
export const PROGRAMS = "Программа для клиентов";
export const PROGRAMS_CREATE = "Создание программы";
export const PROGRAMS_EDIT = "Редактирование программы";
export const COMMUNICATION = "Коммуникационный шлюз";
export const COMMUNICATION_SYSTEMS = "Системы";
export const COMMUNICATION_PROVIDER_ACCOUNTS = "Аккаунты провайдеров";
export const COMMUNICATION_SYSTEM_TOKENS = "Токены доступа";
export const SUPPLIER_CONDITIONS = "Условия от производителя";
export const COMMUNICATION_TEMPLATES = "Шаблоны";
export const COMMUNICATION_MESSAGES = "Сообщения";
export const SETTINGS = "Настройки";
export const DKU = "ДКУ";
export const DKU_CREATE = "Создание ДКУ";
export const DKU_EDIT = "Редактирование ДКУ";
export const NIPPLE = "Ниппель";
export const NIPPLE_IMPORTS = "Импорты из 1С";
export const OPERATIONS_TYPES = "Типы услуг";
export const SERVICE_CENTERS = "Сервисные центры";
export const TERMINALS = "Терминалы (посты)";
export const TOKENS = "Токены";
export const USERS_NIPPLE = "Пользователи";
export const CAR_MODELS = "Модели";
export const MANUFACTURERS = "Производители";
export const OPERATIONS = "Услуги";
export const OPERATIONS_PRICES = "Цены услуг";
export const WHEEL_STORAGES = "Сезонное хранение";
export const WHEEL_STORAGES_EDIT = "Сезонное хранение";
export const SEASON_STORAGE_OPERATIONS = "Услуги сезонного хранения";
