import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/corporate_solutions`, {
  item: { include: "image" },
});

export default service;
export const getCorporateSolutions = service.list.bind(service);
export const getCorporateSolutionById = service.item.bind(service);
export const createCorporateSolution = service.create.bind(service);
export const updateCorporateSolution = service.update.bind(service);
export const deleteCorporateSolution = service.delete.bind(service);
