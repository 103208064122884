<template>
  <div :class="$style['label']">
    {{ data?.nb_statements }}
    statements were executed
  </div>
  <div
    v-for="(elem, index) in data?.statements"
    :key="index"
    :class="[$style['record'], $style['space'], { stripped: index % 2 }]"
  >
    <el-row>
      <el-col
        :class="$style['clickable']"
        :span="16"
        @click="queryLineTrace[index] = !queryLineTrace[index]"
      >
        {{ elem.sql }}.
      </el-col>
      <el-col :span="8">
        <div>
          <b>{{ elem.duration_str }}</b>
        </div>
        <div>{{ elem.stmt_id }}</div>
      </el-col>
    </el-row>

    <div
      :class="$style['darkBlock']"
      :style="{ display: queryLineTrace[index] ? 'block' : 'none' }"
    >
      <el-row v-for="trace in elem.backtrace" :key="trace.index + elem.stmt_id">
        <el-col :span="2">{{ trace.index }}.</el-col>
        <el-col :span="18">
          <b>{{ trace.name }}</b>
        </el-col>
        <el-col :span="4">line: {{ trace.line }}</el-col>
      </el-row>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { ElCol, ElRow } from "element-plus";
import { DebugElementsProps } from "@/components/debugbar/types";

defineProps<DebugElementsProps>();

const queryLineTrace = ref<boolean[]>([]);
</script>

<style lang="scss" module>
.record {
  color: var(--el-text-color-regular);
  font-size: 14px;
  line-height: 22px;
  padding: 5px;

  .label {
    display: inline-block;
    width: 200px;
  }

  .value {
    display: inline-block;
    width: calc(100% - 300px);
  }
}

.space {
  padding-left: 20px;
}

.stripped {
  background-color: var(--debugbar-color-stripe);
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

.darkBlock {
  background-color: var(--debugbar-color-background-dark);
  border-radius: 10px;
  padding: 5px;
  width: 60%;
}
</style>
