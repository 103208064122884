import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";
import {
  CustomListRequestOptions,
  GetParametersArgumentsDeclaration,
  IDefaultListResponse,
} from "@/api/core/types";
import { IManufacturesResponse } from "@/api/points/nipple-api/manufacturers/types";

const service = new Service(`${SERVICE_POINT_NAME}/manufacturers`, {
  list: { include: "carModels" },
  item: { include: "carModels" },
});

const filterSservice = new Service(`${SERVICE_POINT_NAME}/manufacturers`);

export default service;
export const getManufacturers = service.list.bind(service) as (
  parameters: GetParametersArgumentsDeclaration,
  options?: CustomListRequestOptions
) => Promise<IDefaultListResponse<IManufacturesResponse[]>>;

export const getFilterManufacturers = service.list.bind(filterSservice) as (
  parameters: GetParametersArgumentsDeclaration,
  options?: CustomListRequestOptions
) => Promise<IDefaultListResponse<IManufacturesResponse[]>>;

export const getManufacturerById = service.item.bind(service) as (
  id: number
) => Promise<IManufacturesResponse>;
