import { ELEMENT_TIPTAP_TIP } from "@/plugins/tiptap-editor/constants";

export default class Logger {
  static warn(msg: string): void {
    console.warn(`${ELEMENT_TIPTAP_TIP} ${msg}`);
  }

  static error(msg: string): void {
    console.error(`${ELEMENT_TIPTAP_TIP} ${msg}`);
  }
}
