import { createVNode, h, ObjectDirective, render } from "vue";
import { ElTooltip } from "element-plus";

export default <ObjectDirective>{
  mounted(el, binding) {
    const buttonAttrs: any = Array.from(el.attributes).reduce(
      (attrs: any, attr: any) => {
        attrs[attr.nodeName] = attr.nodeValue;
        return attrs;
      },
      {}
    );

    const buttonVNode = createVNode(
      el.tagName.toLowerCase(),
      {
        ...buttonAttrs,
        onClick: binding.value.handler,
      },
      {
        default: () => [h("div", { innerHTML: el.innerHTML })],
      }
    );

    const buttonDivContainer = createVNode(
      "div",
      {},
      {
        default: () => [buttonVNode],
      }
    );

    const tooltipVNode = createVNode(
      ElTooltip,
      {
        content: binding.value.text,
        effect: binding.value.effect || "light",
        disabled: binding.value.disabled,
        placement: binding.value.placement || "top",
        "popper-class": "tooltip-default",
        "hide-after": binding.value["hide-after"],
      },
      {
        default: () => [buttonDivContainer],
      }
    );

    const tooltipContainer = document.createElement("div");

    render(tooltipVNode, tooltipContainer);
    el.parentElement.replaceChild(tooltipContainer.children[0], el);
  },
};
