import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/package_options`);

export default service;
export const getPackageOptions = service.list.bind(service);
export const getPackageOptionById = service.item.bind(service);
export const createPackageOption = service.create.bind(service);
export const updatePackageOption = service.update.bind(service);
export const deletePackageOption = service.delete.bind(service);
