import {
  FormItemAttrsObjDeclaration,
  FormItemObjDeclaration,
  SelectOptionItemDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import { Sort } from "element-plus/es/components/table/src/table/defaults";
import { PropertiesTableRenderSettings } from "@/components/base/table/types";

export const defaultPropertiesTable = ({
  model,
  renderSettings,
}: {
  model: string;
  renderSettings?: PropertiesTableRenderSettings;
}): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model,
  element: "list-item-table",
  isPayload: false,
  renderSettings,
  propertiesViewMode: true,
});

export const defaultTable = ({
  model,
  include,
  associateLabelSettings,
  excludedFields,
  sortRule,
  attrs,
}: {
  model: string;
  include: string;
  associateLabelSettings?: SelectOptionItemDeclaration[];
  excludedFields?: string[];
  sortRule?: Sort;
  attrs?: FormItemAttrsObjDeclaration;
}): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model,
  isPayload: false,
  include,
  associateLabelSettings,
  excludedFields,
  element: "table",
  sortRule,
  attrs,
});
