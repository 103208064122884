import ru from "./locales/ru";

type TranslateByPath = (path: string) => string;

export const Trans = {
  buildI18nHandler(locale: typeof ru = ru): TranslateByPath {
    return function t(path: string): string {
      const target = path.split(".").reduce((prev: any, curr: any) => {
        return prev[curr];
      }, locale);

      return target as string;
    };
  },
};
