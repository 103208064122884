import {
  DefaultJsonFormContext,
  FormItemEventsObjDeclaration,
  FormItemObjDeclaration,
  SelectOptionItemDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import {
  createSystemService,
  getSystemServices,
} from "@/api/points/auth-api/system_services";
import {
  createSystemObject,
  getSystemObjects,
} from "@/api/points/auth-api/system_objects";
import {
  createUsersGroup,
  getUsersGroups,
} from "@/api/points/auth-api/users_groups";
import { createUser, getUsers } from "@/api/points/auth-api/users";
import { getWheels } from "@/api/points/vehicles-api/wheels";
import { createType, getTypes } from "@/api/points/vehicles-api/types";
import {
  createCategory,
  getCategories,
} from "@/api/points/vehicles-api/categories";
import { createRegion, getRegions } from "@/api/points/vehicles-api/regions";
import {
  createClassifier,
  getClassifiers,
} from "@/api/points/pim-api/classifiers";
import { getBrands } from "@/api/points/pim-api/brands";
import {
  pimClassifierChange,
  pimValueRequireChange,
  pimValueTypeChange,
} from "../default-handlers/onChange";
import { MEASUREMENT_LIST, TYPE_LIST } from "@/helpers/pimProperties";
import { getProperties } from "@/api/points/pim-api/properties";
import { getPropertyModel } from "@/helpers/pimProduct";
import { attributesList } from "@/helpers/attributesList";
import { d } from "@/helpers/dictionary";
import { getBodies } from "@/api/points/vehicles-api/bodies";
import { getWsCars } from "@/api/points/vehicles-ws/ws-cars";
import { getCounteragentContracts } from "@/api/points/dictionaries-api/counteragent-contracts";
import { getCommunicationProviderTypes } from "@/api/points/communication-api/provider-types";
import { getCommunicationAccountProviders } from "@/api/points/communication-api/provider-accounts";
import { getCommunicationTemplates } from "@/api/points/communication-api/templates";
import { getServiceCenters } from "@/api/points/nipple-api/service-centers";
import { getFilterManufacturers } from "@/api/points/nipple-api/manufacturers";

export const defaultSelect = (
  model: string,
  label: string,
  optionList: SelectOptionItemDeclaration[],
  placeholder = "Выберите значение",
  presetValue?:
    | string
    | number
    | boolean
    | Record<string, unknown>
    | null
    | undefined,
  events?: FormItemEventsObjDeclaration,
  submitMapDataCallback?: FormItemObjDeclaration["submitMapDataCallback"],
  multiple = false,
  note = "",
  disabled = false,
  valueKey?: string,
  clearable = false
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model,
  element: "select",
  isPayload: true,
  selectOptions: optionList,
  presetValue,
  attrs: {
    placeholder,
    multiple,
    disabled,
    "value-key": valueKey,
    clearable,
  },
  formItemAttrs: {
    label,
    note,
  },
  events,
  submitMapDataCallback,
});

export const asyncSelect = ({
  model,
  label,
  placeholder = "Выберите значение",
  optionList,
  optionListParams = {},
  include = "",
  submitMapDataCallback,
  modalForm = null,
  events = {},
  multiple = false,
  filterable = false,
  isPayload = true,
  clearable = false,
  note = "",
  presetValue,
  filterValueModalForm,
  disabled = false,
  valueKey = "id",
}: {
  model: string;
  label: string;
  placeholder?: string;
  optionList: (
    parameters?: Record<string, unknown>
  ) => Promise<Array<SelectOptionItemDeclaration>>;
  optionListParams?: Record<string, unknown>;
  include?: string;
  submitMapDataCallback?: (...arg: any) => Promise<any>;
  modalForm?: any;
  events?: FormItemEventsObjDeclaration;
  multiple?: boolean;
  filterable?: boolean;
  isPayload?: boolean;
  clearable?: boolean;
  note?: string;
  presetValue?: string[];
  filterValueModalForm?: any;
  disabled?: boolean;
  valueKey?: string;
}): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model,
  element: multiple ? "multiple-select" : "select",
  isPayload,
  include,
  selectOptions: [],
  presetValue,
  asyncSelectOptionsMethod: optionList,
  asyncSelectOptionsParams: optionListParams,
  submitMapDataCallback,
  attrs: {
    "value-key": valueKey,
    multiple,
    filterable,
    placeholder,
    clearable,
    disabled,
  },
  formItemAttrs: {
    label,
    note,
  },
  modalForm,
  events,
  filterValueModalForm,
});

export const engine_fuel: FormItemObjDeclaration = defaultSelect(
  "engine_fuel",
  "Топливо",
  [
    { value: null, label: "Не выбрано" },
    { value: "petrol", label: "Бензин" },
    { value: "dieasel", label: "Дизель" },
    { value: "gaz", label: "Газ" },
    { value: "electro", label: "Электричество" },
    { value: "hybrid", label: "Гибрид" },
  ]
);

export const lock_type: FormItemObjDeclaration = defaultSelect(
  "lock_type",
  "Крепеж колес",
  [
    { value: "nut", label: "Гайка" },
    { value: "bolt", label: "Болт" },
  ]
);

export const value_type: FormItemObjDeclaration = defaultSelect(
  "type",
  "Тип значения",
  TYPE_LIST,
  "Выберите значение",
  "numeric",
  {
    onChange: pimValueTypeChange,
  }
);

export const value_type_filter: FormItemObjDeclaration = defaultSelect(
  "type",
  "Тип значения",
  [...TYPE_LIST, { value: undefined, label: "Любой" }],
  "Выберите значение"
);

export const measurement: FormItemObjDeclaration = defaultSelect(
  "measurement",
  "Ед. измерения",
  MEASUREMENT_LIST,
  undefined,
  undefined,
  {
    onChange: function (this: DefaultJsonFormContext, value: string): void {
      (this.formModel.classifiers as any[]).forEach((item: any) => {
        item.measurement = value;
      });
    },
  }
);

export const measurement_filter: FormItemObjDeclaration = defaultSelect(
  "measurement",
  "Ед. измерения",
  MEASUREMENT_LIST
);

export const value_is_required: FormItemObjDeclaration = defaultSelect(
  "is_required",
  "Обязательно к заполнению",
  [
    { value: true, label: "Да" },
    { value: false, label: "Нет" },
  ],
  undefined,
  false,
  {
    onChange: pimValueRequireChange,
  }
);

export const value_is_required_filter: FormItemObjDeclaration = defaultSelect(
  "is_required",
  "Обязательно к заполнению",
  [
    { value: true, label: "Да" },
    { value: false, label: "Нет" },
  ],
  undefined,
  undefined,
  undefined,
  (v: boolean) => +v
);

export const type: FormItemObjDeclaration = {
  ...defaultSelect(
    "type",
    "Ось",
    [
      { value: "front", label: "Передняя" },
      { value: "rear", label: "Задняя" },
      { value: "front-rear", label: "Передняя и задняя" },
    ],
    "Выберите ось"
  ),
};

export const tire_sizing_system: FormItemObjDeclaration = defaultSelect(
  "tire_sizing_system",
  "Шина - система калибровки",
  [
    { value: "metric", label: "Метрическая" },
    { value: "flotation", label: "Флотационная" },
    { value: "lt-metric", label: "Легкогрузовая метрическая" },
    { value: "lt-numeric", label: "Легкогрузовая числовая" },
  ]
);

export const speed_index: FormItemObjDeclaration = defaultSelect(
  "speed_index",
  "Индекс скорости",
  [
    { value: null, label: "Не выбрано" },
    { value: "K", label: "K" },
    { value: "L", label: "L" },
    { value: "M", label: "M" },
    { value: "N", label: "N" },
    { value: "P", label: "P" },
    { value: "Q", label: "Q" },
    { value: "R", label: "R" },
    { value: "S", label: "S" },
    { value: "T", label: "T" },
    { value: "U", label: "U" },
    { value: "H", label: "H" },
    { value: "V", label: "V" },
    { value: "W", label: "W" },
    { value: "Y", label: "Y" },
    { value: "Z", label: "Z" },
  ],
  "Выберите значение"
);

export const price_category: FormItemObjDeclaration = defaultSelect(
  "price_category",
  "Ценовая категория",
  [
    { value: 1, label: "Легковая" },
    { value: 2, label: "Внедорожник/Минивэн" },
  ],
  "Выберите категорию"
);

export const tire_construction: FormItemObjDeclaration = defaultSelect(
  "tire_construction",
  "Шина - строение корда",
  [
    { value: "R", label: "R" },
    { value: "B", label: "B" },
    { value: "D", label: "D" },
  ]
);

export const command_id_select: FormItemObjDeclaration = defaultSelect(
  "command",
  "Команда",
  [
    { value: "create", label: "Создание" },
    { value: "update", label: "Обновление" },
    { value: "delete", label: "Удаление" },
  ]
);

export const service_id_select = (
  model = "service_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Сервис",
    include: "service",
    filterable: true,
    optionList: async () => {
      const services = await getSystemServices({
        "page[size]": 100,
        sort: "name",
      });
      return services.data.map((service: any) => ({
        value: service,
        label: service.attributes.name,
      }));
    },
    submitMapDataCallback: (value) => value.id,
    modalForm: {
      createModalTitle: "Добавить сервис",
      form: async () => {
        const { Services } = await import(
          "@/components/form-declaration/administration/form-declaration-services"
        );
        return Services;
      },
      method: createSystemService,
    },
    events: {
      onChange: function (this: DefaultJsonFormContext, value: any): void {
        if (this.formModel && this.formModel.object_id) {
          this.formModel.object_id = null;
        }

        const objectId = this.getFormItem("object_id");
        if (objectId) {
          objectId.asyncSelectOptionsParams = {
            "filter[service_id]": value.id,
          };
        }
      },
    },
  });

export const object_id_select = (model = "object_id"): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Объект",
    include: "object",
    filterable: true,
    optionList: async (parameters) => {
      const objects = await getSystemObjects({
        "page[size]": 100,
        sort: "name",
        ...parameters,
      });
      return objects.data.map((object: any) => ({
        value: object,
        label: `${object.attributes.name} / ${object.relationships.service.attributes.name}`,
      }));
    },
    submitMapDataCallback: (value) => value.id,
    modalForm: {
      createModalTitle: "Добавить объект",
      form: async () => {
        const { Objects } = await import(
          "@/components/form-declaration/administration/form-declaration-objects"
        );
        return Objects;
      },
      method: createSystemObject,
    },
  });

export const groups_select = asyncSelect({
  model: "groups",
  label: "Группы пользователей",
  include: "groups",
  multiple: true,
  filterable: true,
  optionList: async (parameters) => {
    const groups = await getUsersGroups({
      "page[size]": 100,
      sort: "name",
      ...parameters,
    });
    return groups.data.map((group: any) => ({
      value: group,
      label: group.attributes.name,
    }));
  },
  submitMapDataCallback: (value) => value.map((item: any) => item.id),
  modalForm: {
    createModalTitle: "Добавить группу",
    form: async () => {
      const { UsersGroups } = await import(
        "@/components/form-declaration/administration/form-declaration-users-groups"
      );
      return {
        ...UsersGroups,
        items: UsersGroups.items.filter(
          (item) => !["users"].includes(item.name)
        ),
      };
    },
    method: createUsersGroup,
  },
});

export const classifiers_select = asyncSelect({
  model: "classifiers_list",
  label: "Набор характеристик",
  include: "classifiers",
  multiple: true,
  filterable: true,
  isPayload: false,
  optionList: async (parameters) => {
    const groups = await getClassifiers({
      "page[size]": 100,
      sort: "name",
      ...parameters,
    });
    return groups.data.map((group: any) => ({
      value: group,
      label: group.attributes.name,
    }));
  },
  events: {
    onChange: pimClassifierChange,
  },
  submitMapDataCallback: (value) => value.map((item: any) => item.id),
});

export const classifiers_filter = asyncSelect({
  model: "classifier_id",
  label: "Набор характеристик",
  include: "classifiers",
  filterable: true,
  isPayload: true,
  optionList: async (parameters) => {
    const groups = await getClassifiers({
      "page[size]": 100,
      sort: "name",
      ...parameters,
    });
    return groups.data.map((group: any) => ({
      value: group,
      label: group.attributes.name,
    }));
  },
  submitMapDataCallback: (value) => value.id,
});

export const users_select = (
  options?: Record<string, any>
): FormItemObjDeclaration => {
  const {
    model = "users",
    label = "Пользователи",
    isFilter = false,
    multiple = true,
  } = options || {};
  return asyncSelect({
    model,
    label,
    include: "users",
    multiple,
    filterable: true,
    optionList: async (parameters) => {
      const users = await getUsers({
        "page[size]": 100,
        sort: "name",
        ...parameters,
      });
      return users.data.map((user: any) => ({
        value: user,
        label: `[${user.id}] ${user.attributes.last_name || ""} ${
          user.attributes.second_name || ""
        } ${user.attributes.name || ""}`,
      }));
    },
    submitMapDataCallback: multiple
      ? (value) => value.filter((item: any) => item).map((item: any) => item.id)
      : (value) => value.id,
    modalForm: !isFilter
      ? {
          createModalTitle: "Добавить пользователя",
          form: async () => {
            const { Users } = await import(
              "@/components/form-declaration/administration/form-declaration-users"
            );
            return {
              ...Users,
              items: Users.items.filter(
                (item) => !["groups"].includes(item.name)
              ),
            };
          },
          method: createUser,
        }
      : null,
  });
};

const frontId = asyncSelect({
  model: "front_id",
  label: "Передняя ось",
  include: "front",
  filterable: false,
  optionList: async (parameters) => {
    if (parameters && !parameters["filter[modification_id]"]) {
      return [];
    }
    const wheels = await getWheels({
      "page[size]": 100,
      ...parameters,
    });
    if (parameters?.id) {
      wheels.data = wheels.data.filter(function (item: any) {
        return item.id.toString() !== parameters?.id;
      });
    }
    return wheels.data.map((wheel: any) => {
      const {
        tire_width,
        tire_aspect_ratio,
        tire_diameter,
        rim_width,
        rim_offset,
        rim_diameter,
      } = wheel.attributes;
      const tire = `${tire_width} / ${tire_aspect_ratio} R${tire_diameter}`;
      const rim = `${rim_width} / ${rim_offset} R${rim_diameter}`;

      return {
        value: wheel,
        label: `Шина: ${tire}, Диск: ${rim} - id: ${wheel.id}`,
      };
    });
  },
  submitMapDataCallback: (value) => value?.id || null,
});

export const PIM_brand_id = asyncSelect({
  model: "brand_id",
  label: "Бренд",
  include: "brand",
  filterable: true,
  optionList: async (parameters) => {
    const brands = await getBrands({
      "page[size]": 100,
      ...parameters,
    });

    return brands.data.map((brand: any) => ({
      value: brand.id,
      label: brand.attributes.name,
    }));
  },
});

export const front_id: FormItemObjDeclaration = {
  ...frontId,
  doNotPassEmpty: false,
  isPayload: true,
  attrs: {
    ...frontId.attrs,
    noDataText: "Нет подходящих данных или не выбрана модификация",
  },
};

export const type_id_select = (
  {
    multiple,
    submitMapDataCallback,
  }: {
    multiple?: boolean;
    submitMapDataCallback?: any;
  } = {
    multiple: true,
    submitMapDataCallback: (value: any) => value.map((item: any) => item.id),
  }
): FormItemObjDeclaration =>
  asyncSelect({
    model: "vehicle_type_id",
    label: "Тип",
    include: "type",
    filterable: false,
    multiple,
    optionList: async (parameters) => {
      const types = await getTypes({
        "page[size]": 100,
        sort: "name",
        ...parameters,
      });
      return types.data.map((type: any) => ({
        value: type,
        label: `${type.attributes.name} - id: ${type.id}`,
      }));
    },
    submitMapDataCallback,
    modalForm: {
      createModalTitle: "Добавить тип",
      form: async () => {
        const { default: typesCreateForm } = await import(
          "@/declarations/catalog-vehicles/types/forms/create"
        );

        return typesCreateForm;
      },
      method: createType,
    },
  });

export const classifierSelect = (
  model = "classifier_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Набор характеристик",
    include: "classifier",
    filterable: true,
    optionList: async (parameters) => {
      const groups = await getClassifiers({
        "page[size]": 100,
        sort: "name",
        ...parameters,
      });
      return groups.data.map((group: any) => ({
        value: group,
        label: group.attributes.name,
      }));
    },
    events: {
      onChange: async function (
        this: DefaultJsonFormContext,
        value: Record<string, any>
      ): Promise<void> {
        try {
          this.setFormItemAttr("propertiesListing", "isLoading", true);
          this.setFormItemAttr("properties", "isLoading", true);

          const { data } = await getProperties({
            "page[size]": 100,
            [`filter[classifier_id]`]: value.id,
          });

          this.formModel.properties_list = data;

          this.formModel.properties = data.flatMap(
            (property: Record<string, any>) =>
              property.attributes.is_active ? getPropertyModel(property) : []
          );
        } catch (error) {
          console.error(error);
        } finally {
          this.setFormItemAttr("propertiesListing", "isLoading", false);
          this.setFormItemAttr("properties", "isLoading", false);
        }
      },
    },
    submitMapDataCallback: (value) => value.id,
    modalForm: {
      createModalTitle: "Добавить набор характеристик",
      form: async () => {
        const { Classifiers } = await import(
          "@/components/form-declaration/pim/form-declaration-classifiers"
        );
        return Classifiers;
      },
      method: createClassifier,
    },
  });

export const brandSelect = (model = "brand_id"): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Производитель",
    include: "brand",
    filterable: true,
    optionList: async (parameters) => {
      const groups = await getBrands({
        "page[size]": 100,
        sort: "name",
        ...parameters,
      });
      return groups.data.map((group: any) => ({
        value: group,
        label: group.attributes.name,
      }));
    },
    submitMapDataCallback: (value) => value.id,
  });

export const category_id_select = (
  model = "vehicle_category_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Вид",
    include: "category",
    filterable: false,
    multiple: false,
    optionList: async (parameters) => {
      const categories = await getCategories({
        "page[size]": 100,
        sort: "name",
        ...parameters,
      });
      return categories.data.map((category: any) => ({
        value: category,
        label: `${category.attributes.name} - id: ${category.id}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id,
    modalForm: {
      createModalTitle: "Добавить вид",
      form: async () => {
        const { default: categoriesCreateForm } = await import(
          "@/declarations/catalog-vehicles/categories/forms/create"
        );

        return categoriesCreateForm;
      },
      method: createCategory,
    },
  });

export const region_id_select = (
  model = "regions_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Регионы",
    include: "regions",
    filterable: false,
    multiple: true,
    optionList: async (parameters) => {
      const regions = await getRegions({
        "page[size]": 100,
        sort: "name",
        ...parameters,
      });
      return regions.data.map((region: any) => ({
        value: region,
        label: `${region.attributes.code} - id: ${region.id}`,
      }));
    },
    submitMapDataCallback: (value) => value.map((item: any) => item.id),
    modalForm: {
      createModalTitle: "Добавить регион",
      form: async () => {
        const { default: regionsCreateForm } = await import(
          "@/declarations/catalog-vehicles/regions/forms/create"
        );

        return {
          ...regionsCreateForm,
          items: regionsCreateForm.items.filter(
            (item) => !["countries_id"].includes(item.name)
          ),
        };
      },
      method: createRegion,
    },
  });

export const wheels_active: FormItemObjDeclaration = defaultSelect(
  "wheels_active",
  "Активность колес",
  [
    { label: "Активные", value: "1" },
    { label: "Неактивные", value: "0" },
  ]
);

export const bundle: FormItemObjDeclaration = defaultSelect(
  "is_stock",
  "Комплектация",
  [
    { value: 1, label: "Заводская комплектация" },
    { value: 0, label: "Альтернативная комплектация" },
  ]
);

export const tire_width_select = (
  model = "tire_width"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Шина - ширина",
    multiple: true,
    filterable: true,
    optionList: async () => {
      const wheels = await getWheels({
        "page[size]": 1000,
        group: "tire_width",
      });
      return wheels.data?.map(
        ({ attributes }: { attributes: { tire_width: number } }) => ({
          value: attributes.tire_width,
          label: attributes.tire_width.toString(),
        })
      );
    },
  });

export const tire_aspect_ratio_select = (
  model = "tire_aspect_ratio"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Шина - высота профиля",
    multiple: true,
    filterable: true,
    optionList: async () => {
      const wheels = await getWheels({
        "page[size]": 1000,
        group: "tire_aspect_ratio",
      });
      return wheels.data?.map(
        ({ attributes }: { attributes: { tire_aspect_ratio: number } }) => ({
          value: attributes.tire_aspect_ratio,
          label: attributes.tire_aspect_ratio.toString(),
        })
      );
    },
  });

export const rim_width_select = (model = "rim_width"): FormItemObjDeclaration =>
  asyncSelect({
    model: model,
    label: "Диск - ширина",
    multiple: true,
    filterable: true,
    optionList: async () => {
      const wheels = await getWheels({
        "page[size]": 1000,
        group: "rim_width",
      });
      return wheels.data?.map(
        ({ attributes }: { attributes: { rim_width: number } }) => ({
          value: attributes.rim_width,
          label: attributes.rim_width.toString(),
        })
      );
    },
  });

export const rim_offset_select = (
  model = "rim_offset"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Диск - вылет",
    multiple: true,
    filterable: true,
    optionList: async () => {
      const wheels = await getWheels({
        "page[size]": 1000,
        group: "rim_offset",
      });
      return wheels.data?.map(
        ({
          attributes,
        }: {
          attributes: { rim_offset: number };
          type: "wheels";
        }) => ({
          value: attributes.rim_offset,
          label: attributes.rim_offset.toString(),
        })
      );
    },
  });

export const tire_diameter_select = (
  model = "tire_diameter"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Шина и Диск - диаметр",
    multiple: true,
    filterable: true,
    optionList: async () => {
      const wheels = await getWheels({
        "page[size]": 1000,
        group: "tire_diameter",
      });
      return wheels.data?.map(
        ({ attributes }: { attributes: { tire_diameter: number } }) => ({
          value: attributes.tire_diameter,
          label: attributes.tire_diameter.toString(),
        })
      );
    },
  });

export const body_name = (model: string): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: d(model),
    multiple: true,
    filterable: true,
    optionList: async () => {
      const names = await getWsCars({
        "page[size]": 1000,
      });
      return names.data.map((item: any) => ({
        value: item.id,
        label: item.attributes.name,
      }));
    },
  });

export const body_name_ws = (model: string): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: d(model),
    multiple: true,
    filterable: true,
    optionList: async () => {
      const names = await getBodies({
        "page[size]": 1000,
      });
      return names.data.map((item: any) => ({
        value: item.id,
        label: item.attributes.name,
      }));
    },
  });

export const load_index_select = (model = "load_index") =>
  asyncSelect({
    model,
    label: "Индекс нагрузки",
    multiple: true,
    filterable: true,
    optionList: async () => {
      const wheels = await getWheels({
        "page[size]": 1000,
        group: "load_index",
      });

      const data =
        wheels.data
          ?.filter(
            ({ attributes }: { attributes: { load_index: number } }) =>
              attributes.load_index
          )
          ?.map(({ attributes }: { attributes: { load_index: number } }) => ({
            value: attributes.load_index,
            label: attributes.load_index.toString(),
          })) || [];

      return [{ value: null, label: "Не выбрано" }, ...data];
    },
  });

export const attributes: FormItemObjDeclaration = defaultSelect(
  "attributes",
  "Атрибуты",
  attributesList
);

export const binding: FormItemObjDeclaration = defaultSelect(
  "binding",
  "Связи",
  [
    { value: 1, label: "Есть связи" },
    { value: 0, label: "Нет связей" },
  ]
);

export const archive_items: FormItemObjDeclaration = defaultSelect(
  "trashed",
  "Архивные товары",
  [
    { value: 0, label: "Не учитывать" },
    { value: 1, label: "Учитывать" },
  ],
  undefined,
  0
);

export const active_select = (
  options: { model: string } = { model: "active" }
): FormItemObjDeclaration =>
  defaultSelect(
    options.model,
    "Статус",
    [
      { value: null, label: "Любой" },
      { value: 1, label: "Активные" },
      { value: 0, label: "Неактивные" },
    ],
    undefined,
    null
  );

export const is_buyer_select = (
  options: { model: string } = { model: "is_buyer" }
): FormItemObjDeclaration =>
  defaultSelect(
    options.model,
    "Покупатель",
    [
      { value: null, label: "Не выбрано" },
      { value: 1, label: "Да" },
      { value: 0, label: "Нет" },
    ],
    undefined,
    null
  );

export const is_supplier_select = (
  options: { model: string } = { model: "is_supplier" }
): FormItemObjDeclaration =>
  defaultSelect(
    options.model,
    "Поставщик",
    [
      { value: null, label: "Не выбрано" },
      { value: 1, label: "Да" },
      { value: 0, label: "Нет" },
    ],
    undefined,
    null
  );

export const is_exclude_from_bonus_program_select = (
  options: { model: string } = { model: "is_exclude_from_bonus_program" }
): FormItemObjDeclaration =>
  defaultSelect(
    options.model,
    "Исключен",
    [
      { value: null, label: "Не выбрано" },
      { value: 1, label: "Да" },
      { value: 0, label: "Нет" },
    ],
    undefined,
    null
  );

export const trashed_select = (
  options: { model: string } = { model: "trashed" }
): FormItemObjDeclaration =>
  defaultSelect(
    options.model,
    "Удален",
    [
      { value: null, label: "Не выбрано" },
      { value: 1, label: "Да" },
      { value: 0, label: "Нет" },
    ],
    undefined,
    null
  );

export const is_canceled_select = (
  options: { model: string } = { model: "is_canceled" }
): FormItemObjDeclaration =>
  defaultSelect(
    options.model,
    "Аннулирован",
    [
      { value: null, label: "Не выбрано" },
      { value: 1, label: "Да" },
      { value: 0, label: "Нет" },
    ],
    undefined,
    null
  );

export const contract_type_select = (): FormItemObjDeclaration =>
  asyncSelect({
    model: "type",
    label: "Тип",
    filterable: true,
    optionList: async () => {
      const types = await getCounteragentContracts({
        "page[size]": 1000,
        group: "type",
      });
      return types.data?.map(
        ({ attributes }: { attributes: { type: string } }) => ({
          value: attributes.type,
          label: attributes.type,
        })
      );
    },
  });

export const contract_bonus_type_select = (): FormItemObjDeclaration =>
  asyncSelect({
    model: "bonus_type",
    label: "Вид бонуса",
    filterable: true,
    optionList: async () => {
      const types = await getCounteragentContracts({
        "page[size]": 1000,
        group: "bonus_type",
      });
      return types.data?.map(
        ({ attributes }: { attributes: { bonus_type: string } }) => ({
          value: attributes.bonus_type,
          label: attributes.bonus_type,
        })
      );
    },
  });

export const provider_account_type_select = (
  options: {
    label: string;
    include: string;
    model: string;
  } = {
    label: "Тип",
    include: "providerType",
    model: "provider_type_id",
  }
): FormItemObjDeclaration =>
  asyncSelect({
    model: options.model,
    label: options.label,
    include: options.include,
    filterable: false,
    multiple: false,
    optionList: async (parameters) => {
      const { data: providerTypes } = await getCommunicationProviderTypes({
        "page[size]": 100,
        sort: "type",
        ...parameters,
      });

      return providerTypes.map((type) => ({
        value: type,
        label: `${type.attributes.name} - id: ${type.id}`,
      }));
    },

    submitMapDataCallback: (value) => value.id,
  });

export const provider_accounts_select = (
  options: {
    model: string;
    include?: string;
  } = {
    model: "provider_type_id",
    include: "",
  }
): FormItemObjDeclaration =>
  asyncSelect({
    model: options.model,
    include: options.include,
    label: "Аккаунт провайдера",
    filterable: false,
    multiple: false,
    optionList: async (parameters) => {
      const { data: accountProviders } = await getCommunicationAccountProviders(
        {
          "page[size]": 100,
          ...parameters,
        }
      );

      return accountProviders.map((type) => ({
        value: type,
        label: `${type.attributes.name} - id: ${type.id}`,
      }));
    },
    clearable: true,

    submitMapDataCallback: (value) => value.id,
  });

export const body_type_filter_select = (): FormItemObjDeclaration =>
  asyncSelect({
    model: "body_type",
    label: "Тип тела сообщения",
    filterable: false,
    multiple: false,
    optionList: async () => {
      const { data: body_formats } = await getCommunicationTemplates({
        "page[size]": 100,
        group: "body_type",
      });

      return body_formats.map((format: Record<string, any>) => ({
        value: format.attributes.body_type,
        label: format.attributes.body_type,
      }));
    },
    clearable: true,
  });

export const is_strength_select: FormItemObjDeclaration = defaultSelect(
  "is_strength",
  "Усиленная",
  [
    { value: true, label: "Да" },
    { value: false, label: "Нет" },
  ]
);

export const is_strength_filter_select = (
  model = "is_strength"
): FormItemObjDeclaration =>
  defaultSelect(model, "Усиленная", [
    { value: null, label: "Не выбрано" },
    { value: 1, label: "Да" },
    { value: 0, label: "Нет" },
  ]);

export const service_centers_filter = asyncSelect({
  model: "service_center_id",
  label: "Сервисные центры",
  multiple: true,
  filterable: true,
  optionList: async () => {
    const service_centers = await getServiceCenters({
      "page[size]": 1000,
    });

    return service_centers?.data?.map((service_center: any) => ({
      value: service_center.id,
      label: service_center.attributes.name,
    }));
  },
});

export const service_centers = asyncSelect({
  model: "service_center_id",
  label: "Сервисные центры",
  include: "serviceCenters",
  multiple: true,
  disabled: true,
  optionList: async () => {
    const service_centers = await getServiceCenters({
      "page[size]": 1000,
    });

    return service_centers?.data?.map((service_center: any) => ({
      value: service_center,
      label: service_center.attributes.name,
    }));
  },
});

export const has_terminals_select = (
  options: { model: string } = { model: "has_terminals" }
): FormItemObjDeclaration =>
  defaultSelect(
    options.model,
    "Есть терминалы",
    [
      { value: null, label: "Любой" },
      { value: 1, label: "Есть" },
      { value: 0, label: "Нет" },
    ],
    undefined,
    null
  );

export const nipple_manufacturers_select_filter = asyncSelect({
  model: "manufacturer_id",
  label: "Производители",
  include: "manufacturer",
  multiple: true,
  filterable: true,
  optionList: async () => {
    const manufacturers = await getFilterManufacturers({
      "page[size]": 1000,
    });

    return manufacturers?.data?.map((manufacturer: any) => ({
      value: manufacturer.id,
      label: manufacturer.attributes.name,
    }));
  },
});

export const nipple_type_select = (
  options: { model: string } = { model: "type" }
): FormItemObjDeclaration =>
  defaultSelect(
    options.model,
    "Тип",
    [
      { value: null, label: "Любой" },
      { value: "is_leasing", label: "Лизинговый СЦ" },
      { value: "is_only_pvz", label: "Только ПВЗ" },
      { value: "other", label: "Не ПВЗ" },
    ],
    undefined,
    null
  );

export const nipple_model_type_select_filter = (
  model = "type"
): FormItemObjDeclaration =>
  defaultSelect(model, "Тип", [
    { value: null, label: "Не выбрано" },
    { value: 1, label: "Легковой" },
    { value: 2, label: "Легкогрузовой" },
    { value: 3, label: "Кроссовер" },
    { value: 4, label: "Мото" },
  ]);

export const nipple_users_select = asyncSelect({
  model: "brinex_id",
  label: "Пользователь Brinex",
  include: "auth_user",
  filterable: true,
  optionList: async () => {
    const users = await getUsers({
      "page[size]": 1000,
    });

    return users?.data.map((user: any) => ({
      value: user,
      label: `${user.attributes.name} ${user.attributes.last_name || ""}`,
    }));
  },
  submitMapDataCallback: (value) => value?.id,
});

export const nipple_users_filter = asyncSelect({
  model: "brinex_id",
  label: "Пользователи Brinex",
  multiple: true,
  filterable: true,
  optionList: async () => {
    const users = await getUsers({
      "page[size]": 1000,
    });

    return users?.data.map((user: any) => ({
      value: user.id,
      label: `${user.attributes.name} ${user.attributes.last_name || ""}`,
    }));
  },
});
