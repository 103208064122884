import { readonly, ref, Ref } from "vue";
import { Router, useRouter } from "vue-router";

let _router: Router;

const sortProp = ref("id");

const setSortProp = (value: any): void => {
  sortProp.value = value;
};

export const enum SortOrderType {
  desc = "descending",
  asc = "ascending",
}

const sortOrder = ref<SortOrderType>(SortOrderType.desc);

const setSortOrder = (value: any): void => {
  sortOrder.value = value;
};

export interface InitialSortParams {
  prop?: string;
  order?: SortOrderType;
}

interface Return {
  sortProp: Readonly<Ref>;
  sortOrder: Readonly<Ref>;
  setSortProp: (value: any) => void;
  setSortOrder: (value: any) => void;
}

export default (initParams?: InitialSortParams): Return => {
  _router = useRouter();

  const { sortProp: prop, sortOrder: order } = _router.currentRoute.value.query;

  sortProp.value = initParams?.prop
    ? initParams.prop
    : (sortProp.value = (prop as string) || "id");
  sortOrder.value = initParams?.order
    ? initParams.order
    : (sortOrder.value = (order as SortOrderType) || SortOrderType.desc);

  return {
    sortProp: readonly(sortProp),
    sortOrder: readonly(sortOrder),
    setSortProp,
    setSortOrder,
  };
};
