import LayoutWorkingSection from "@/layout/LayoutWorkingSection.vue";
import WorkingSectionView from "@/views/WorkingSectionView.vue";
import { Collection } from "@element-plus/icons-vue";
import {
  COUNTERAGENTCONTRACTS,
  COUNTERAGENTS,
  DICTIONARIES,
  GEOREGIONS,
  USER_ROLES,
} from "@/dictionary/header";
import { RouteRecordRaw } from "vue-router";
import { RouteAccess } from "../types";

const access: RouteAccess = {
  service: "dictionaries",
  object: "",
  command: "index",
};

export const dictionariesRoutesList: Array<RouteRecordRaw> = [
  {
    path: "/dictionaries",
    name: "dictionaries",
    component: LayoutWorkingSection,
    meta: {
      title: DICTIONARIES,
      icon: Collection,
      clickable: false,
      isDev: true,
    },
    children: [
      {
        path: "/dictionaries/user-roles",
        name: "user-roles",
        component: WorkingSectionView,
        meta: {
          title: USER_ROLES,
          access: {
            ...access,
            object: "roles",
          },
        },
      },
      {
        path: "/dictionaries/counteragents",
        name: "counteragents",
        component: WorkingSectionView,
        meta: {
          title: COUNTERAGENTS,
          access: {
            ...access,
            object: "counteragents",
          },
        },
      },
      {
        path: "/dictionaries/geo-regions",
        name: "geo-regions",
        component: WorkingSectionView,
        meta: {
          title: GEOREGIONS,
          access: {
            ...access,
            object: "geo_regions",
          },
        },
      },
      {
        path: "/dictionaries/counteragent-contracts",
        name: "counteragent-contracts",
        component: WorkingSectionView,
        meta: {
          title: COUNTERAGENTCONTRACTS,
          access: {
            ...access,
            object: "counteragent_contracts",
          },
        },
      },
    ],
  },
];
