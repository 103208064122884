export const randomNumber = (): number => {
  const array = new Uint32Array(10);
  const randomArrFill = window.crypto.getRandomValues(array);
  const result = randomArrFill.reduce((acc, number) => acc + number, 0);

  return result + Date.now();
};

export const generateToken = (length = 40): string => {
  const chars =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charLength = chars.length;
  const tokenArray = new Uint8Array(length);

  window.crypto.getRandomValues(tokenArray);

  return Array.from(tokenArray, (byte) => chars[byte % charLength]).join("");
};
