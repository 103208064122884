import { Service } from "@/api/core/service";
import {
  CommunicationServicePointName,
  getCommunicationServicePathByEndpoint,
} from "@/api/points/communication-api/service-config";
import {
  CustomListRequestOptions,
  GetParametersArgumentsDeclaration,
  IDefaultDeleteResponse,
  IDefaultListResponse,
} from "@/api/core/types";
import {
  ITemplateResponse,
  TemplateAttrs,
} from "@/api/points/communication-api/templates/types";
import { TemplatePropAttrs } from "@/api/points/communication-api/template-props/types";

const extraOptions = {
  list: { include: "providerAccount.providerType" },
  item: { include: "providerAccount.providerType,templateProps" },
};

const endpoint = getCommunicationServicePathByEndpoint(
  CommunicationServicePointName.templates
);

class ExtendedTemplatesService extends Service {
  public async create(
    payload: Record<string, any> & TemplateAttrs
  ): Promise<any> {
    try {
      const { templatesPayload } = preparePayloads(payload);

      const response = await super.create(templatesPayload);

      return response;
    } catch (err) {
      console.error(err);
    }
  }

  public async update(
    id: number,
    payload: Record<string, any> & TemplateAttrs
  ): Promise<any> {
    try {
      const { templatesPayload } = preparePayloads(payload);

      const response = await super.update(id, templatesPayload);

      return response;
    } catch (err) {
      console.error(err);
    }
  }

  public async item(id: number): Promise<any> {
    try {
      const response = await super.item(id);

      response.data.attributes.template_props =
        response.data?.relationships?.templateProps || [];

      return response;
    } catch (err) {
      console.error(err);
    }
  }
}

export const enum BatchMethod {
  put = "put",
  post = "post",
  delete = "delete",
}

const preparePayloads = (
  payload: Record<string, any> & TemplateAttrs
): {
  templatePropsPayload: Record<string, TemplatePropAttrs>;
  templatesPayload: TemplateAttrs;
} => {
  const {
    is_active,
    name,
    alias,
    provider_account_id,
    subject,
    body_type,
    body,
    comment,
    is_communication_template,
    ...templatePropsPayload
  } = payload;

  let templatesPayload: TemplateAttrs = {
    is_active,
    name,
    alias,
    provider_account_id,
    subject,
    body_type,
    body,
    comment,
    is_communication_template,
  };

  if (!payload.is_communication_template) {
    templatesPayload = {
      ...templatesPayload,
      template_props: Object.values(templatePropsPayload)
        .filter((item) => item.state !== "removed")
        .map((item) =>
          (({ code, name, type, comment, max_length }) => ({
            code,
            name,
            type,
            comment,
            max_length,
          }))(item)
        ),
    };
  }

  return {
    templatePropsPayload,
    templatesPayload,
  };
};

const service = new ExtendedTemplatesService(endpoint, extraOptions);

export default service;

export const getCommunicationTemplates = service.list.bind(service) as (
  parameters: GetParametersArgumentsDeclaration,
  options?: CustomListRequestOptions
) => Promise<IDefaultListResponse<ITemplateResponse[]>>;

export const getCommunicationTemplate = service.item.bind(service) as (
  id: number
) => Promise<ITemplateResponse>;

export const createCommunicationTemplate = service.create.bind(service) as (
  payload: Record<string, any>
) => Promise<ITemplateResponse>;

export const updateCommunicationTemplate = service.update.bind(service) as (
  id: number,
  payload: Record<string, any>
) => Promise<ITemplateResponse>;

export const deleteCommunicationTemplate = service.delete.bind(service) as (
  id: number | string
) => Promise<IDefaultDeleteResponse<ITemplateResponse>>;
