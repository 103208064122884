import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import TiptapCodeBlock from "@tiptap/extension-code-block";
import CommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CommandButton.vue";

const CodeBlock = TiptapCodeBlock.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      button({ editor, t, isCodeViewMode }: CommandButtonArgs) {
        return {
          component: CommandButton,
          componentProps: {
            command: () => {
              editor.commands.toggleCodeBlock();
            },
            isActive: editor.isActive("codeBlock"),
            icon: "code",
            tooltip: t("editor.extensions.CodeBlock.tooltip"),
            isCodeViewMode,
          },
        };
      },
    };
  },
});

export default CodeBlock;
