<template>
  <el-tabs v-model="activeTab" @tab-change="onTabChange">
    <el-tab-pane
      v-for="(tab, index) in rowItem.tabs"
      :key="index"
      :disabled="tab.disabled"
      :label="tab.label"
      :name="tab.id"
    >
      <form-view-default
        v-for="(tabRowItem, index) in tab.rows"
        :key="index + '-default'"
        ref="formDefaultValue"
        :row-index="index"
        :row-item="tabRowItem"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script lang="ts" setup>
import {
  computed,
  ComputedRef,
  defineEmits,
  defineExpose,
  inject,
  onBeforeUnmount,
  ref,
  toRefs,
  watch,
} from "vue";
import {
  FormLayoutRowPropertyDeclaration,
  FormTabDeclaration,
  PublicFormContextDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import FormViewDefault from "@/plugins/form-generator-json-v2/components/view/FormViewDefault.vue";

const props = defineProps<{
  rowItem: FormLayoutRowPropertyDeclaration;
}>();
const { rowItem } = toRefs(props);

const publicFormContext = inject(
  "publicFormContext"
) as PublicFormContextDeclaration;
const { layoutDeclaration } = publicFormContext;

const emit = defineEmits(["tab-change"]);

const onTabChange = (tabPaneName: string | number) => {
  tabs.value.forEach(
    (tab: FormTabDeclaration) => (tab.isActive = tab?.id === tabPaneName)
  );
  emit("tab-change", tabPaneName);
};

const tabs: ComputedRef<FormTabDeclaration[]> = computed(
  () => layoutDeclaration?.rows?.find((node) => node.tabs)?.tabs || []
);
const activeTab = ref<string | number>();

const setActiveTab = (tabId: string, activate = false) => {
  const tab = tabs.value.find((tab) => tab.id === tabId);

  if (tab) {
    activeTab.value = tabId;
    tab.isActive = true;

    if (activate) {
      tab.disabled = false;
    }
  }
};

const toggleNextTab = (activate = false) => {
  const tabId = tabs.value.findIndex((tab) => tab.id === activeTab.value);

  if (tabId === -1) {
    return;
  }

  if (tabId === tabs.value.length - 1) {
    activeTab.value = tabs.value[0]?.id;
  } else {
    const nextTab = tabs.value[tabId + 1];
    activeTab.value = tabs.value[tabId + 1]?.id;

    if (activate && nextTab) {
      nextTab.disabled = false;
    }
  }
};

const onValidateForm = (prop: string, isValid: boolean): void => {
  if (!isValid && tabs.value.length) {
    const failedTabId = tabs.value.find((tab) => {
      const tabItems = tab.rows?.reduce(
        (prev: any, curr: Record<string, any>) => prev.concat(curr.cols),
        []
      );

      return !!tabItems.find((item: any) =>
        Array.isArray(item.id) ? item.id.includes(prop) : item.id === prop
      );
    })?.id as string;

    if (failedTabId && activeTab.value !== failedTabId) {
      setActiveTab(failedTabId);
    }
  }
};

onBeforeUnmount(() => {
  tabs.value.forEach((tab) => (tab.isActive = false));
});

watch(
  tabs,
  (value) => {
    if (value.length) {
      const activeTabDeclaration = value.find(
        (tab: FormTabDeclaration) => !!tab?.isActive
      );

      activeTab.value = activeTabDeclaration
        ? activeTabDeclaration.id
        : (value && value[0]?.id) || "default";
    }
  },
  { immediate: true, deep: true }
);

defineExpose({
  setActiveTab,
  toggleNextTab,
  onValidateForm,
  tabs,
});
</script>
