import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/cooperation_card_blocks`, {
  item: { include: "cooperationCards" },
});

export default service;
export const getCooperationCardBlocks = service.list.bind(service);
export const getCooperationCardBlockById = service.item.bind(service);
export const createCooperationCardBlock = service.create.bind(service);
export const updateCooperationCardBlock = service.update.bind(service);
export const deleteCooperationCardBlock = service.delete.bind(service);
