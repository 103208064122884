import LayoutWorkingSection from "@/layout/LayoutWorkingSection.vue";
import WorkingSectionView from "@/views/WorkingSectionView.vue";
import { Box } from "@element-plus/icons-vue";
import { RouteRecordRaw } from "vue-router";
import { RouteAccess } from "../types";
import { ServiceName } from "@/constants";

const access: RouteAccess = {
  service: ServiceName.pim,
  object: "",
  command: "index",
};

const PIM_PATH = `/${ServiceName.pim}`;

export const pimRoutesList: RouteRecordRaw[] = [
  {
    path: PIM_PATH,
    name: ServiceName.pim,
    component: LayoutWorkingSection,
    meta: {
      title: "PIM",
      icon: Box,
      clickable: false,
      isDev: true,
    },
    children: [
      {
        path: `${PIM_PATH}/classifiers`,
        name: "pim-classifiers",
        component: WorkingSectionView,
        meta: {
          title: "Наборы характеристик",
          access: {
            ...access,
            object: "classifiers",
          },
        },
      },
      {
        path: `${PIM_PATH}/properties`,
        name: "pim-properties",
        component: WorkingSectionView,
        meta: {
          title: "Характеристики",
          access: {
            ...access,
            object: "properties",
          },
        },
      },
      {
        path: `${PIM_PATH}/products`,
        name: "pim-products",
        component: WorkingSectionView,
        meta: {
          title: "Карточки товаров",
          access: {
            ...access,
            object: "products",
          },
        },
      },
      {
        path: `${PIM_PATH}/tools`,
        name: "pim-tools",
        component: (): Promise<typeof import("*.vue")> =>
          import(/* webpackChunkName: "Tools" */ "@/views/pim/Tools.vue"),
        meta: {
          title: "Импорт/Экспорт",
          access: {
            ...access,
            object: "tools",
          },
        },
      },
      {
        path: `${PIM_PATH}/product_types`,
        name: "pim-product-types",
        component: WorkingSectionView,
        meta: {
          title: "Номенклатурные группы",
          access: {
            ...access,
            object: "product_types",
          },
        },
      },
      {
        path: `${PIM_PATH}/manufacturers`,
        name: "pim-manufacturers",
        component: WorkingSectionView,
        meta: {
          title: "Производители",
          access: {
            ...access,
            object: "manufacturers",
          },
        },
      },
      {
        path: `${PIM_PATH}/brands`,
        name: "pim-brands",
        component: WorkingSectionView,
        meta: {
          title: "Бренды",
          access: {
            ...access,
            object: "brands",
          },
        },
      },
      {
        path: `${PIM_PATH}/models`,
        name: "pim-models",
        component: WorkingSectionView,
        meta: {
          title: "Модели",
          access: {
            ...access,
            object: "models",
          },
        },
      },
    ],
  },
];
