import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/hr_about_blocks`);

export default service;
export const getHRAboutBlocks = service.list.bind(service);
export const getHRAboutBlockById = service.item.bind(service);
export const createHRAboutBlock = service.create.bind(service);
export const updateHRAboutBlock = service.update.bind(service);
export const deleteHRAboutBlock = service.delete.bind(service);
