export const AVAILABLE_VALUES = `
        <h4>Доступные значения</h4>
        <div><b>contactConfirmation</b> - подтверждение контактов пользователя</div>
        <div><b>index</b> - список</div>
        <div><b>show</b> - просмотр записи</div>
        <div><b>store</b> - создание</div>
        <div><b>update</b> - обновление</div>
        <div><b>destroy</b> - удаление</div>
        `;
export const AVAILABLE_FORMAT_FILE = "Доступные форматы файла: __FORMAT__";
export const SELECT = {
  note: "Элементы будут отображаться на лендинге в том порядке, в котором они будут прикреплены в этом поле.",
};
export const TIP = "Желательно уточнить у разработчиков";
export const IMAGE = {
  upload: {
    label: "Изображения",
    default_label: "Изображение по умолчанию",
    type: {
      logo: "Логотип",
      footnote: "Логотип в сноске",
    },
    color: "Цветное изображение",
    monochrome: "Монохромное изображение",
    background: "Фоновое изображение",
  },
};
