<template>
  <command-button
    :command="() => editor.commands.unsetLink()"
    :enable-tooltip="enableTooltip"
    :tooltip="t('editor.extensions.Link.unlink.tooltip')"
    icon="unlink"
  />
</template>

<script lang="ts" setup>
import { inject } from "vue";
import { Editor } from "@tiptap/vue-3";
import CommandButton from "../CommandButton.vue";

interface Props {
  editor: Editor;
}

defineProps<Props>();

const t: any = inject("t");
const enableTooltip = inject("enableTooltip", true);
</script>
