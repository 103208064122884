export const enum VehicleType {
  light = "light",
  lightTruck = "lightTruck",
  suv = "suv",
  moto = "moto",
}

export const vehicleNameByTypeMap: Record<VehicleType, string> = {
  [VehicleType.light]: "Легковой",
  [VehicleType.lightTruck]: "Легкогрузовой",
  [VehicleType.suv]: "Кроссовер",
  [VehicleType.moto]: "Мото",
};

export const wheelStoragesNameByStatusMap: Record<number, string> = {
  10: "Хранение",
  20: "В пути",
  30: "Частично выдан",
  60: "Выдано",
  70: "Утилизировано",
};

export const wheelStoragesNameBySourceMap: Record<string, string> = {
  mobile: "Мобильный",
  "1c": "1с",
};
