<template>
  <div v-if="isMobile" :class="$style['mobile-bottom']">
    <router-link
      :class="$style['mobile-bottom__el']"
      to="/"
      @click="setMobileMenuVisibility(false)"
    >
      <el-icon :class="$style['mobile-bottom__icon']" color="black" size="20">
        <House />
      </el-icon>
    </router-link>
    <div
      :class="[
        $style['mobile-bottom__el'],
        {
          [$style['active']]: isMobileMenuVisible && isDeclarationSearchVisible,
        },
      ]"
      @click="openMenu(true)"
    >
      <el-icon :class="$style['mobile-bottom__icon']" color="black" size="20">
        <Search />
      </el-icon>
    </div>
    <the-tasks />
    <router-link
      :class="$style['mobile-bottom__el']"
      to="/user"
      @click="setMobileMenuVisibility(false)"
    >
      <el-icon :class="$style['mobile-bottom__icon']" color="black" size="20">
        <User />
      </el-icon>
    </router-link>
    <div
      :class="[
        $style['mobile-bottom__el'],
        {
          [$style['active']]:
            isMobileMenuVisible && !isDeclarationSearchVisible,
        },
      ]"
      @click="openMenu(false)"
    >
      <el-icon :class="$style['mobile-bottom__icon']" color="black" size="20">
        <Tickets />
      </el-icon>
    </div>
  </div>
</template>

<script lang="ts" setup>
import TheTasks from "@/components/the/TheTasks.vue";
import { House, Search, Tickets, User } from "@element-plus/icons-vue";
import useAdaptive from "@/composables/hooks/useAdaptive";
import useDeclarationSearch from "@/composables/hooks/useDeclarationSearch";
import useMobileMenu from "@/composables/hooks/useMobileMenu";

const { isMobile } = useAdaptive();
const { isDeclarationSearchVisible, setDeclarationSearchVisibility } =
  useDeclarationSearch();
const {
  isMobileMenuVisible,
  setMobileMenuVisibility,
  toggleMobileMenuVisibility,
} = useMobileMenu();

const openMenu = (shouldOpenDeclarationSearch: boolean): void => {
  const shouldToggleMenu =
    !isMobileMenuVisible.value ||
    shouldOpenDeclarationSearch === isDeclarationSearchVisible.value;

  if (shouldToggleMenu) {
    toggleMobileMenuVisibility();
  }

  setDeclarationSearchVisibility(shouldOpenDeclarationSearch);
};
</script>

<style lang="scss" module>
.mobile-bottom {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--el-color-white);
  box-shadow: var(--el-box-shadow-lighter);
  z-index: 6;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;

  &__el {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;

    &.active {
      :global {
        .el-icon {
          color: var(--el-color-primary);
        }
      }
    }
  }

  &__icon {
    margin: 0 0 2px;
  }

  :global {
    .router-link-active {
      .el-icon {
        color: var(--el-color-primary);
      }

      color: var(--el-color-primary);
    }
  }
}
</style>
