import LayoutWorkingSection from "@/layout/LayoutWorkingSection.vue";
import { Notebook } from "@element-plus/icons-vue";
import { ACTION_LOG, CHANGE_HISTORY } from "@/dictionary/header";
import { RouteRecordRaw } from "vue-router";
import { RouteAccess } from "../types";

const access: RouteAccess = {
  service: "objects-history",
  object: "",
  command: "index",
};

export const historyRoutesList: Array<RouteRecordRaw> = [
  {
    path: "/history",
    name: "history",
    component: LayoutWorkingSection,
    meta: {
      title: CHANGE_HISTORY,
      icon: Notebook,
      clickable: false,
    },
    children: [
      {
        path: "/history/actions",
        name: "actions",
        components: {
          default: (): Promise<typeof import("*.vue")> =>
            import(
              /* webpackChunkName: "Actions" */ "@/views/history/Actions.vue"
            ),
        },
        meta: {
          title: ACTION_LOG,
          access: {
            ...access,
            object: "log",
          },
        },
      },
    ],
  },
];
