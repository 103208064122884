import {
  default_col_input,
  default_col_logical,
  default_col_range,
  default_col_select,
  LOGICAL_LIST,
  measurement_col,
  VALUE_TYPES,
} from "@/helpers/pimProperties";
import { DefaultJsonFormContext } from "@/plugins/form-generator-json-v2/types";
import {
  FRANCHISE_LANDING_CASE,
  HR_LANDING_CASE,
  MAIN_LANDING_CASE,
} from "@/helpers/landingBlockCase";
import { validation } from "../validation";
import { ColumnType } from "@/model/working-sections/constants";

type ChangeValue = { id: string | number } | undefined | null;

export const resetServiceDependencies = (isFilter: boolean) =>
  function (this: DefaultJsonFormContext, value: ChangeValue): void {
    if (this.formModel) {
      const objectId = isFilter ? "filter[object_id]" : "object_id";
      if (this.formModel[objectId]) {
        this.formModel[objectId] = null;
      }
      const commandId = isFilter ? "filter[command_id]" : "command_id";
      if (this.formModel[commandId]) {
        this.formModel[commandId] = null;
      }
    }

    const objectId = this.getFormItem(
      isFilter ? "filter[object_id]" : "object_id"
    );
    if (objectId) {
      objectId.attrs && (objectId.attrs.disabled = !value);
      objectId.asyncSelectOptionsParams = {
        "filter[service_id]": value?.id,
      };
    }
    const commandId = this.getFormItem(
      isFilter ? "filter[command_id]" : "command_id"
    );
    if (commandId && commandId.attrs) {
      commandId.attrs.disabled = true;
    }
  };

export const resetBrandDependencies = (isFilter: boolean) =>
  function (this: DefaultJsonFormContext, value: ChangeValue): void {
    if (this.formModel) {
      const modelId = isFilter ? "filter[model_id]" : "model_id";
      if (this.formModel[modelId]) {
        this.formModel[modelId] = null;
      }
      const generationId = isFilter ? "filter[generation_id]" : "generation_id";
      if (this.formModel[generationId]) {
        this.formModel[generationId] = null;
      }
      const modificationId = isFilter
        ? "filter[modification_id]"
        : "modification_id";
      if (this.formModel[modificationId]) {
        this.formModel[modificationId] = null;
      }
      const typeId = isFilter ? "filter[vehicle_type_id]" : "vehicle_type_id";
      if (this.formModel[typeId]) {
        this.formModel[typeId] = null;
      }

      const categoryId = isFilter
        ? "filter[vehicle_category_id]"
        : "vehicle_category_id";
      if (this.formModel[categoryId]) {
        this.formModel[categoryId] = null;
      }
    }

    const modelId = this.getFormItem(
      isFilter ? "filter[model_id]" : "model_id"
    );
    if (modelId) {
      modelId.attrs && (modelId.attrs.disabled = !value);
      modelId.asyncSelectOptionsParams = {
        "filter[brand_id]": value?.id,
      };
    }
    const generationId = this.getFormItem(
      isFilter ? "filter[generation_id]" : "generation_id"
    );
    if (generationId && generationId.attrs) {
      generationId.attrs.disabled = true;
    }
    const modificationId = this.getFormItem(
      isFilter ? "filter[modification_id]" : "modification_id"
    );
    if (modificationId && modificationId.attrs) {
      modificationId.attrs.disabled = true;
    }
    const typeId = this.getFormItem(
      isFilter ? "filter[vehicle_type_id]" : "vehicle_type_id"
    );
    if (typeId) {
      typeId.attrs && (typeId.attrs.disabled = !value);
    }
    const categoryId = this.getFormItem(
      isFilter ? "filter[vehicle_category_id]" : "vehicle_category_id"
    );
    if (categoryId && categoryId.attrs) {
      categoryId.attrs.disabled = true;
    }
  };

export const resetModelDependencies = (isFilter: boolean) =>
  function (this: DefaultJsonFormContext, value: ChangeValue): void {
    if (this.formModel) {
      const generationId = isFilter ? "filter[generation_id]" : "generation_id";
      if (this.formModel[generationId]) {
        this.formModel[generationId] = null;
      }
      const modificationId = isFilter
        ? "filter[modification_id]"
        : "modification_id";
      if (this.formModel[modificationId]) {
        this.formModel[modificationId] = null;
      }
    }

    const generationId = this.getFormItem(
      isFilter ? "filter[generation_id]" : "generation_id"
    );
    if (generationId) {
      generationId.attrs && (generationId.attrs.disabled = !value);
      generationId.asyncSelectOptionsParams = {
        "filter[model_id]": value?.id,
      };
    }
    const modificationId = this.getFormItem(
      isFilter ? "filter[modification_id]" : "modification_id"
    );
    if (modificationId && modificationId.attrs) {
      modificationId.attrs.disabled = true;
    }
  };

export const resetGenerationDependencies = (isFilter: boolean) =>
  function (this: DefaultJsonFormContext, value: ChangeValue): void {
    if (this.formModel) {
      const modificationId = isFilter
        ? "filter[modification_id]"
        : "modification_id";
      if (this.formModel[modificationId]) {
        this.formModel[modificationId] = null;
      }
    }

    const modificationId = this.getFormItem(
      isFilter ? "filter[modification_id]" : "modification_id"
    );
    if (modificationId) {
      modificationId.attrs && (modificationId.attrs.disabled = !value);
      modificationId.asyncSelectOptionsParams = {
        "filter[generation_id]": value?.id,
      };
    }
  };

export const resetModificationDependencies = function (
  this: DefaultJsonFormContext,
  value: ChangeValue
): void {
  if (this.formModel) {
    const frontId = "front_id";
    if (this.formModel[frontId]) {
      this.formModel[frontId] = null;
    }
  }

  const frontId = this.getFormItem("front_id");
  if (frontId) {
    frontId.asyncSelectOptionsParams = {
      "filter[modification_id]": value?.id,
    };
  }
};

export const resetTypeDependencies = (isFilter: boolean) =>
  function (this: DefaultJsonFormContext, value: ChangeValue): void {
    if (this.formModel) {
      const categoryId = isFilter
        ? "filter[vehicle_category_id]"
        : "vehicle_category_id";
      if (this.formModel[categoryId]) {
        this.formModel[categoryId] = null;
      }
    }

    const categoryId = this.getFormItem(
      isFilter ? "filter[vehicle_category_id]" : "vehicle_category_id"
    );
    if (categoryId) {
      categoryId.attrs && (categoryId.attrs.disabled = !value);
      categoryId.asyncSelectOptionsParams = {
        "filter[vehicle_type_id]": categoryId?.submitMapDataCallback(value),
      };
    }
  };

export const pimValueTypeChange = function (
  this: DefaultJsonFormContext,
  value: string
): void {
  this.formModel.default = undefined;
  this.formModel.values = [];

  const isList = value.endsWith("_list") || value.endsWith("_multiple");

  const isNumeric = [
    VALUE_TYPES.NUMERIC,
    VALUE_TYPES.NUMERIC_LIST,
    VALUE_TYPES.NUMERIC_LIST_MULTIPLE,
    VALUE_TYPES.RANGE,
  ].includes(value);

  const isDate = value === VALUE_TYPES.DATE;
  const isLogical = value === VALUE_TYPES.LOGICAL;
  const isSelector = isList || isLogical;
  const isRange = VALUE_TYPES.RANGE === value;
  const isValuesInput = isList || isRange;

  this.setFormItemVisibility("values", isValuesInput);
  this.setFormItemVisibility("measurement", isNumeric);
  this.declaration.form.rules.measurement[0].required = isNumeric;

  const valuesInput = this.getFormItem("values");
  const defaultInput = this.getFormItem("default");

  if (defaultInput) {
    defaultInput.element = isSelector ? "select" : "input";

    if (isLogical) {
      defaultInput.selectOptions = LOGICAL_LIST;
    }

    if (isRange) {
      defaultInput.element = "number-input";
    }
  }

  this.setFormItemVisibility(
    "default",
    true,
    isNumeric ? "number" : isDate ? ColumnType.date : ColumnType.text
  );

  this.setFormItemAttr("values", "inputType", isNumeric ? "number" : "text");

  if (valuesInput) {
    if (isRange) {
      valuesInput.element = "range-input";
      if (validation.required) {
        this.setValidationRules("values", validation.required);
      }

      if (valuesInput.formItemAttrs) {
        valuesInput.formItemAttrs.label = "Диапазон (от-до)";
      }
    } else {
      valuesInput.element = "array-input";
      this.removeValidationRules("values");

      if (valuesInput.formItemAttrs) {
        valuesInput.formItemAttrs.label = "Список значений";
      }
    }
  }

  if (!isNumeric) {
    this.formModel.measurement = undefined;
  }

  const classifiersTable = this.getFormItem("classifiers");

  if (isNumeric && !classifiersTable?.attrs?.cols?.measurement) {
    classifiersTable?.attrs &&
      (classifiersTable.attrs.cols.measurement = measurement_col);
  } else if (!isNumeric) {
    delete classifiersTable?.attrs?.cols?.measurement;
    this.formModel.classifiers = (
      (this.formModel?.classifiers as any[]) || []
    ).map((item: Record<string, any>) => {
      delete item.measurement;

      return item;
    });
  }

  if (classifiersTable?.attrs?.cols?.default) {
    classifiersTable.attrs.cols.default = isList
      ? default_col_select
      : default_col_input;

    if (!isList && !isLogical) {
      classifiersTable.attrs.cols.default.attrs.type = isNumeric
        ? "number"
        : isDate
        ? ColumnType.date
        : ColumnType.text;
    }

    if (isLogical) {
      classifiersTable.attrs.cols.default = default_col_logical;
    }

    if (isRange) {
      classifiersTable.attrs.cols.default = default_col_range;
    }
  }

  this.formModel.classifiers = (this.formModel?.classifiers as any[]).map(
    (item: Record<string, any>) => {
      item.default = null;

      return item;
    }
  );
};

export const pimValueRequireChange = function (
  this: DefaultJsonFormContext,
  value: boolean
): void {
  ((this.formModel.classifiers as any[]) || []).forEach((item: any) => {
    item.is_required = value;
  });
};

export const pimClassifierChange = async function (
  this: DefaultJsonFormContext,
  value: any[]
): Promise<void> {
  const classifiers = (this.formModel.classifiers as any[]) || [];

  if (value.length > classifiers.length) {
    const newValue: Record<string, any> = value[value.length - 1];

    const item = {
      name: newValue.attributes.name,
      id: newValue.id,
      is_required:
        newValue.attributes.is_required ?? this.formModel.is_required,
      default: "",
      measurement:
        newValue.attributes.measurement ?? this.formModel.measurement,
    };

    this.formModel.classifiers = classifiers.concat(item);
  } else {
    const valueIds = value.map((i) => i.id);
    const classifiersIds = classifiers.map((i) => i.id);

    const diffId = classifiersIds.find((id) => !valueIds.includes(id));

    this.formModel.classifiers = classifiers.filter(
      (item) => item.id !== diffId
    );
  }
};

export const shouldBrandsAndCertificatesSelectsBeDisabled = () =>
  function (this: DefaultJsonFormContext): void {
    if (this.formModel) {
      const allActiveBrandsForPopup = "all_active_brands_for_popup";
      const allActiveCertificates = "all_active_certificates";
      const popupBrandIds = this.getFormItem("popup_brand_ids");
      const certificateIds = this.getFormItem("certificate_ids");

      if (popupBrandIds?.attrs) {
        popupBrandIds.attrs.disabled =
          !!this.formModel[allActiveBrandsForPopup];
        if (this.formModel[allActiveBrandsForPopup]) {
          popupBrandIds.asyncSelectOptionsParams = {};
        }
      }
      if (certificateIds?.attrs) {
        certificateIds.attrs.disabled = !!this.formModel[allActiveCertificates];
        if (this.formModel[allActiveCertificates]) {
          certificateIds.asyncSelectOptionsParams = {};
        }
      }
    }
  };

export const shouldFormScriptAndUrlInputBeDisabled = () =>
  function (this: DefaultJsonFormContext): void {
    if (this.formModel) {
      const type = "type";
      const menu = "menu";
      const formScript = this.getFormItem("form_script");
      const url = this.getFormItem("url");

      if (url?.attrs) {
        url.attrs.disabled = this.formModel[type] === "text-with-phone";
      }

      if (formScript?.attrs) {
        formScript.attrs.disabled =
          this.formModel[type] !== "text-with-phone" ||
          this.formModel[menu] === "footer";
      }
    }
  };

export const resetFormScriptAndUrlInputDisable = () =>
  function (this: DefaultJsonFormContext): void {
    if (this.formModel) {
      const formScript = this.getFormItem("form_script");
      const url = this.getFormItem("url");

      if (url?.attrs) {
        url.attrs.disabled = false;
      }

      if (formScript?.attrs) {
        formScript.attrs.disabled = false;
      }
    }
  };

export const setBlockInputsDisableValue = (
  blockModelName: string,
  disableValue: boolean,
  blockSortModelName?: string | undefined
) =>
  function (this: DefaultJsonFormContext): void {
    const inputItem = this.getFormItem(blockModelName);
    if (disableValue) {
      if (inputItem?.attrs) {
        inputItem.attrs.disabled = disableValue;
        inputItem.asyncSelectOptionsParams = {};
        this.formModel[blockModelName] = null;
      }
      if (blockSortModelName) {
        const inputSortItem = this.getFormItem(blockSortModelName);
        if (inputSortItem?.attrs) {
          inputSortItem.attrs.disabled = disableValue;
          this.formModel[blockSortModelName] = null;
        }
      }
    } else {
      if (inputItem?.attrs) {
        inputItem.attrs.disabled = disableValue;
      }
      if (blockSortModelName) {
        const inputSortItem = this.getFormItem(blockSortModelName);
        if (inputSortItem?.attrs) {
          inputSortItem.attrs.disabled = disableValue;
          if (
            (this.formModel?.[blockModelName] as Record<string, any>)
              ?.attributes?.sort_order
          ) {
            this.formModel[blockSortModelName] = (
              this.formModel?.[blockModelName] as Record<string, any>
            ).attributes?.sort_order;
          }
        }
      }
    }
  };

export const disableBlockInputs = () =>
  function (this: DefaultJsonFormContext): void {
    if (this.formModel) {
      const landingType = "landing_type";

      switch (this.formModel[landingType]) {
        case "main":
          MAIN_LANDING_CASE.forEach(({ block_id, disableValue, sort_id }) =>
            setBlockInputsDisableValue(block_id, disableValue, sort_id).call(
              this
            )
          );
          break;
        case "franchise":
          FRANCHISE_LANDING_CASE.forEach(
            ({ block_id, disableValue, sort_id }) =>
              setBlockInputsDisableValue(block_id, disableValue, sort_id).call(
                this
              )
          );
          break;
        case "hr":
          HR_LANDING_CASE.forEach(({ block_id, disableValue, sort_id }) =>
            setBlockInputsDisableValue(block_id, disableValue, sort_id).call(
              this
            )
          );
          break;
      }
    }
  };
