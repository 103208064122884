import { readonly } from "vue";
import { Router, useRouter } from "vue-router";

let _router: Router;

interface Return {
  data: Readonly<Record<string, string>>;
  getDefaultFilters: () => Record<string, string>;
}

const getDefaultFilters = (): Record<string, string> => {
  const { meta, params } = _router.currentRoute.value;

  return (
    meta?.filter
      ? {
          [meta.filter as string]: params.id,
        }
      : {}
  ) as Record<string, string>;
};

export default (): Return => {
  _router = useRouter();
  const data = {} as Record<string, string>;
  const query = _router.currentRoute.value.query;

  Object.keys(query).forEach((key) => {
    if (key.startsWith("filter[")) {
      data[key] = query[key] as string;
    }
  });

  return {
    data: readonly(data),
    getDefaultFilters,
  };
};
