import { ref, Ref } from "vue";

const isTableSearchShown = ref(false);

const setTableSearchVisibility = (val: boolean): void => {
  isTableSearchShown.value = val;
};

interface Result {
  isTableSearchShown: Ref<boolean>;
  setTableSearchVisibility: (val: boolean) => void;
}

export default (): Result => ({
  isTableSearchShown,
  setTableSearchVisibility,
});
