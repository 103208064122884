import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/menu_items`);

export default service;
export const getMenuItems = service.list.bind(service);
export const getMenuItemById = service.item.bind(service);
export const createMenuItem = service.create.bind(service);
export const updateMenuItem = service.update.bind(service);
export const deleteMenuItem = service.delete.bind(service);
