import { SERVICE_POINT_NAME } from "@/api/points/auth-api/service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/users_groups`, {
  list: {
    sort: "name",
    include: "users",
  },
  item: {
    "fields[users]": "id,name",
    "fields[commands]": "id,name",
    include: "users,commands",
  },
});

export default service;
export const getUsersGroups = service.list.bind(service);
export const getUsersGroupById = service.item.bind(service);
export const createUsersGroup = service.create.bind(service);
export const updateUsersGroup = service.update.bind(service);
export const deleteUsersGroup = service.delete.bind(service);
