import { ResponseMeta } from "@/components/types";
import { readonly, ref, Ref } from "vue";
import { Router, useRouter } from "vue-router";

let _router: Router;

interface Paging extends ResponseMeta {
  loading?: boolean;
}

const data = ref<Paging>({
  loading: true,
  current_page: 1,
  from: 0,
  last_page: 0,
  links: [],
  path: "",
  per_page: 20,
  to: 0,
  total: 0,
});

const setData = (value: any): void => {
  if (!value) {
    return;
  }

  const keysLength = Object.keys(value).length;

  if (keysLength === 1 && "loading" in value) {
    data.value.loading = value.loading;
    return;
  }

  if (keysLength) {
    Object.assign(data.value, value);
  }

  data.value.loading = false;
};

const pageSize = ref(10);
const pageNumber = ref(1);

const setPageSize = (value: any): void => {
  pageSize.value = +value;
};

const setPageNumber = (value: any): void => {
  pageNumber.value = +value;
};

interface Params {
  size?: number | string;
  page?: number | string;
}

interface Return {
  data: Readonly<Ref>;
  pageSize: Readonly<Ref>;
  pageNumber: Readonly<Ref>;
  setData: (value: any) => void;
  setPageSize: (value: any) => void;
  setPageNumber: (value: any) => void;
}

export default (initParams?: Params): Return => {
  _router = useRouter();

  const { pageNumber: page, pageSize: size } = _router.currentRoute.value.query;

  pageSize.value = Number(initParams?.size ?? size ?? 20);
  pageNumber.value = Number(initParams?.page ?? page ?? 1);

  return {
    data: readonly(data),
    pageSize: readonly(pageSize),
    pageNumber: readonly(pageNumber),
    setData,
    setPageSize,
    setPageNumber,
  };
};

export type { Paging };
