import { computed, ComputedRef, onUnmounted, ref, Ref } from "vue";
import { debounce } from "@/helpers/functions";

const isMobileDevice: Ref<boolean> = ref(
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
);
const innerWidth: Ref<number> = ref(window.innerWidth);

const checkAdaptive = (): void => {
  isMobileDevice.value = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  innerWidth.value = window.innerWidth;
};

window.addEventListener("resize", debounce(checkAdaptive, 100));
onUnmounted(() => window.removeEventListener("resize", checkAdaptive));

const isMobile: ComputedRef<boolean> = computed(
  (): boolean => isMobileDevice.value && innerWidth.value <= 640
);

export default (): {
  isMobile: ComputedRef<boolean>;
} => ({
  isMobile,
});
