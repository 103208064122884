import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/brands`, {
  item: { include: "image,additional_image" },
});

export default service;
export const getBrands = service.list.bind(service);
export const getBrandById = service.item.bind(service);
export const createBrand = service.create.bind(service);
export const updateBrand = service.update.bind(service);
export const deleteBrand = service.delete.bind(service);
