import {
  DefaultJsonFormContext,
  FormItemObjDeclaration,
} from "@/plugins/form-generator-json-v2/types";

export const nextTabButton = (
  id = "nextTab",
  text = "Далее",
  disabled = true
): FormItemObjDeclaration => ({
  name: id,
  id,
  model: "",
  element: "button",
  text,
  attrs: {
    type: "primary",
    disabled,
  },
  formItemAttrs: {
    class: "el-form-item--submit",
  },
  events: {
    onClick: function (this: DefaultJsonFormContext): void {
      this.toggleNextTab(true);
    },
  },
});

export const update_properties_button: FormItemObjDeclaration = {
  name: "updateProperties",
  id: "updateProperties",
  model: "",
  element: "pim-autofill-button",
  text: "Автозаполнение",
  formItemAttrs: {
    class: "el-form-item--submit",
  },
};
