import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/hr_company_values`);

export default service;
export const getHRCompanyValues = service.list.bind(service);
export const getHRCompanyValueById = service.item.bind(service);
export const createHRCompanyValue = service.create.bind(service);
export const updateHRCompanyValue = service.update.bind(service);
export const deleteHRCompanyValue = service.delete.bind(service);
