import { FormItemObjDeclaration } from "@/plugins/form-generator-json-v2/types";
import {
  createIntroBlock,
  getIntroBlocks,
} from "@/api/points/landings-cms-api/intro-blocks";
import {
  createFranchiseFirstBlock,
  getFranchiseFirstBlocks,
} from "@/api/points/landings-cms-api/franchise-first-blocks";
import {
  createFranchiseContactBlock,
  getFranchiseContactBlocks,
} from "@/api/points/landings-cms-api/franchise-contact-blocks";
import {
  createHRIntroBlock,
  getHRIntroBlocks,
} from "@/api/points/landings-cms-api/hr-intro-blocks";
import {
  createHRCareerOpportunityBlock,
  getHRCareerOpportunityBlocks,
} from "@/api/points/landings-cms-api/hr-career-opportunity-blocks";
import {
  createHRCompanyInfoBlock,
  getHRCompanyInfoBlocks,
} from "@/api/points/landings-cms-api/hr-company-info-blocks";
import {
  createHRAboutUsBlock,
  getHRAboutUsBlocks,
} from "@/api/points/landings-cms-api/hr-about-us-blocks";
import {
  createHRAboutBlock,
  getHRAboutBlocks,
} from "@/api/points/landings-cms-api/hr-about-blocks";
import {
  createHRVacancyBlock,
  getHRVacancyBlocks,
} from "@/api/points/landings-cms-api/hr-vacancy-blocks";
import {
  createAutomotiveProductsBlock,
  getAutomotiveProductsBlocks,
} from "@/api/points/landings-cms-api/automotive-products-blocks";
import {
  createBrandAndCertificateBlock,
  getBrandAndCertificateBlocks,
} from "@/api/points/landings-cms-api/brand-and-certificate-blocks";
import {
  createGeographyBlock,
  getGeographyBlocks,
} from "@/api/points/landings-cms-api/geography-blocks";
import {
  createFeedbackBlock,
  getFeedbackBlocks,
} from "@/api/points/landings-cms-api/feedback-blocks";
import {
  createWorkWithCorporateClientBlock,
  getWorkWithCorporateClientBlocks,
} from "@/api/points/landings-cms-api/work-with-corporate-client-blocks";
import {
  createWholesaleCustomerBlock,
  getWholesaleCustomerBlocks,
} from "@/api/points/landings-cms-api/wholesale-customer-blocks";
import {
  createBusinessAdvantageBlock,
  getBusinessAdvantageBlocks,
} from "@/api/points/landings-cms-api/business-advantage-blocks";
import {
  createAnalyticBlock,
  getAnalyticBlocks,
} from "@/api/points/landings-cms-api/analytic-blocks";
import {
  createPartnershipBlock,
  getPartnershipBlocks,
} from "@/api/points/landings-cms-api/partnership-blocks";
import {
  createContactBlock,
  getContactBlocks,
} from "@/api/points/landings-cms-api/contact-blocks";
import {
  createCorporateSolutionAndProductBlock,
  getCorporateSolutionAndProductBlocks,
} from "@/api/points/landings-cms-api/corp-solution-and-product-blocks";
import {
  createBusinessOfferBlock,
  getBusinessOfferBlocks,
} from "@/api/points/landings-cms-api/business-offer-blocks";
import {
  createOurClientCaseBlock,
  getOurClientCaseBlocks,
} from "@/api/points/landings-cms-api/our-client-case-blocks";
import {
  createCooperationCardBlock,
  getCooperationCardBlocks,
} from "@/api/points/landings-cms-api/cooperation-card-blocks";
import {
  createBusinessFormatBlock,
  getBusinessFormatBlocks,
} from "@/api/points/landings-cms-api/business-format-blocks";
import {
  createSupportBlock,
  getSupportBlocks,
} from "@/api/points/landings-cms-api/support-blocks";
import {
  createIncomeCalculatorBlock,
  getIncomeCalculatorBlocks,
} from "@/api/points/landings-cms-api/income-calculator-blocks";
import {
  createOurNetworkBlock,
  getOurNetworkBlocks,
} from "@/api/points/landings-cms-api/our-network-blocks";
import { asyncSelect } from "@/components/form-declaration/default-elements";
import {
  createMenuItemBlock,
  getMenuItemBlocks,
} from "@/api/points/landings-cms-api/menu-item-blocks";
import {
  createVideoPresentationBlock,
  getVideoPresentationBlocks,
} from "@/api/points/landings-cms-api/video-presentation-blocks";
import {
  createTeamBlock,
  getTeamBlocks,
} from "@/api/points/landings-cms-api/team-blocks";
import {
  createBusinessToConsumerBlock,
  getBusinessToConsumerBlocks,
} from "@/api/points/landings-cms-api/business-to-consumer-blocks";
import {
  createHrBlock,
  getHrBlocks,
} from "@/api/points/landings-cms-api/hr-blocks";

export const intro_block_id_select = (
  model = "intro_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Вступительный блок",
    include: "introBlocks",
    filterable: false,
    multiple: false,
    optionList: async (parameters) => {
      const introBlocks = await getIntroBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return introBlocks.data.map((introBlock: any) => ({
        value: introBlock,
        label: `id: ${introBlock.id} - ${introBlock.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { IntroBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-intro-blocks"
        );
        return IntroBlocks;
      },
      method: createIntroBlock,
    },
  });

export const franchise_first_block_id_select = (
  model = "franchise_first_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "Франшиза - первый блок",
    include: "franchiseFirstBlocks",
    filterable: false,
    multiple: false,
    optionList: async (parameters) => {
      const franchiseFirstBlocks = await getFranchiseFirstBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return franchiseFirstBlocks.data.map((franchiseFirstBlock: any) => ({
        value: franchiseFirstBlock,
        label: `id: ${franchiseFirstBlock.id} - ${franchiseFirstBlock.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { FranchiseFirstBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-franchise-first-blocks"
        );
        return FranchiseFirstBlocks;
      },
      method: createFranchiseFirstBlock,
    },
  });
export const franchise_contact_block_id_select = (
  model = "franchise_contact_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Франшиза - Блок "Контакты"',
    include: "franchiseContactBlocks",
    filterable: false,
    multiple: false,
    optionList: async (parameters) => {
      const franchiseContactBlocks = await getFranchiseContactBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return franchiseContactBlocks.data.map((franchiseContactBlock: any) => ({
        value: franchiseContactBlock,
        label: `id: ${franchiseContactBlock.id} - ${
          franchiseContactBlock.attributes.title
        } ${franchiseContactBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { FranchiseContactBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-franchise-contact-blocks"
        );
        return FranchiseContactBlocks;
      },
      method: createFranchiseContactBlock,
    },
  });

export const hr_intro_block_id_select = (
  model = "hr_intro_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: "HR Первый блок",
    include: "hrIntroBlocks",
    filterable: false,
    multiple: false,
    optionList: async (parameters) => {
      const hrIntroBlocks = await getHRIntroBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return hrIntroBlocks.data.map((hrIntroBlock: any) => ({
        value: hrIntroBlock,
        label: `id: ${hrIntroBlock.id} - ${hrIntroBlock.attributes.title}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { HRIntroBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-hr-intro-blocks"
        );
        return HRIntroBlocks;
      },
      method: createHRIntroBlock,
    },
  });

export const hr_career_opportunity_block_id_select = (
  model = "hr_career_opportunity_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'HR Блок "Карьерные возможности"',
    include: "hrCareerOpportunityBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const hrCareerOpportunityBlocks = await getHRCareerOpportunityBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return hrCareerOpportunityBlocks.data.map(
        (hrCareerOpportunityBlock: any) => ({
          value: hrCareerOpportunityBlock,
          label: `id: ${hrCareerOpportunityBlock.id} - ${
            hrCareerOpportunityBlock.attributes.title
          } ${hrCareerOpportunityBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
        })
      );
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { HRCareerOpportunityBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-hr-career-opportunity-blocks"
        );
        return HRCareerOpportunityBlocks;
      },
      method: createHRCareerOpportunityBlock,
    },
  });

export const hr_company_info_block_id_select = (
  model = "hr_company_info_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'HR Блок "О компании"',
    include: "hrCompanyInfoBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const hrCompanyInfoBlocks = await getHRCompanyInfoBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return hrCompanyInfoBlocks.data.map((hrCompanyInfoBlock: any) => ({
        value: hrCompanyInfoBlock,
        label: `id: ${hrCompanyInfoBlock.id} - ${
          hrCompanyInfoBlock.attributes.title
        } ${hrCompanyInfoBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { HRCompanyInfoBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-hr-company-info-blocks"
        );
        return HRCompanyInfoBlocks;
      },
      method: createHRCompanyInfoBlock,
    },
  });

export const hr_about_us_block_id_select = (
  model = "hr_about_us_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'HR Блок "Кто мы"',
    include: "hrAboutUsBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const hrAboutUsBlocks = await getHRAboutUsBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return hrAboutUsBlocks.data.map((hrAboutUsBlock: any) => ({
        value: hrAboutUsBlock,
        label: `id: ${hrAboutUsBlock.id} - ${hrAboutUsBlock.attributes.title} ${
          hrAboutUsBlock.attributes.is_hidden ? "(скрыт)" : ""
        }`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { HRAboutUsBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-hr-about-us-blocks"
        );
        return HRAboutUsBlocks;
      },
      method: createHRAboutUsBlock,
    },
  });

export const hr_about_block_id_select = (
  model = "hr_about_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'HR Блок "О нас"',
    include: "hrAboutBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const hrAboutBlocks = await getHRAboutBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return hrAboutBlocks.data.map((hrAboutBlock: any) => ({
        value: hrAboutBlock,
        label: `id: ${hrAboutBlock.id} - ${hrAboutBlock.attributes.title} ${
          hrAboutBlock.attributes.is_hidden ? "(скрыт)" : ""
        }`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { HRAboutBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-hr-about-blocks"
        );
        return HRAboutBlocks;
      },
      method: createHRAboutBlock,
    },
  });

export const hr_vacancy_block_id_select = (
  model = "hr_vacancy_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'HR Блок "Вакансии"',
    include: "hrVacancyBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const hrVacancyBlocks = await getHRVacancyBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return hrVacancyBlocks.data.map((hrVacancyBlock: any) => ({
        value: hrVacancyBlock,
        label: `id: ${hrVacancyBlock.id} - ${hrVacancyBlock.attributes.title} ${
          hrVacancyBlock.attributes.is_hidden ? "(скрыт)" : ""
        }`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { HRVacancyBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-hr-vacancy-blocks"
        );
        return HRVacancyBlocks;
      },
      method: createHRVacancyBlock,
    },
  });

export const automotive_product_block_id_select = (
  model = "automotive_product_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Автотовары"',
    include: "automotiveProductBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const automotiveProductBlocks = await getAutomotiveProductsBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return automotiveProductBlocks.data.map(
        (automotiveProductBlock: any) => ({
          value: automotiveProductBlock,
          label: `id: ${automotiveProductBlock.id} - ${
            automotiveProductBlock.attributes.title
          } ${automotiveProductBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
        })
      );
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { AutomotiveProductsBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-automotive-product-blocks"
        );
        return AutomotiveProductsBlocks;
      },
      method: createAutomotiveProductsBlock,
    },
  });

export const brand_and_certificate_block_id_select = (
  model = "brand_and_certificate_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Бренды и Сертификаты"',
    include: "brandAndCertificateBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const brandAndCertificateBlocks = await getBrandAndCertificateBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return brandAndCertificateBlocks.data.map(
        (brandAndCertificateBlock: any) => ({
          value: brandAndCertificateBlock,
          label: `id: ${brandAndCertificateBlock.id} - ${
            brandAndCertificateBlock.attributes.title
          } ${brandAndCertificateBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
        })
      );
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { BrandAndCertificateBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-brand-and-certificate-blocks"
        );
        return BrandAndCertificateBlocks;
      },
      method: createBrandAndCertificateBlock,
    },
  });

export const geography_block_id_select = (
  model = "geography_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "География присутствия"',
    include: "geographyBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const geographyBlocks = await getGeographyBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return geographyBlocks.data.map((geographyBlock: any) => ({
        value: geographyBlock,
        label: `id: ${geographyBlock.id} - ${geographyBlock.attributes.title} ${
          geographyBlock.attributes.is_hidden ? "(скрыт)" : ""
        }`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { GeographyBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-geography-blocks"
        );
        return GeographyBlocks;
      },
      method: createGeographyBlock,
    },
  });

export const feedback_block_id_select = (
  model = "feedback_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Отзывы"',
    include: "feedbackBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const feedbackBlocks = await getFeedbackBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return feedbackBlocks.data.map((feedbackBlock: any) => ({
        value: feedbackBlock,
        label: `id: ${feedbackBlock.id} - ${feedbackBlock.attributes.title} ${
          feedbackBlock.attributes.is_hidden ? "(скрыт)" : ""
        }`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { FeedbackBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-feedback-blocks"
        );
        return FeedbackBlocks;
      },
      method: createFeedbackBlock,
    },
  });

export const work_with_corporate_client_block_id_select = (
  model = "work_with_corporate_client_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Работа с корпоративными клиентами"',
    include: "workWithCorporateClientBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const workWithCorporateClientBlocks =
        await getWorkWithCorporateClientBlocks({
          "page[size]": 100,
          ...parameters,
        });
      return workWithCorporateClientBlocks.data.map(
        (workWithCorporateClientBlock: any) => ({
          value: workWithCorporateClientBlock,
          label: `id: ${workWithCorporateClientBlock.id} - ${
            workWithCorporateClientBlock.attributes.title
          } ${
            workWithCorporateClientBlock.attributes.is_hidden ? "(скрыт)" : ""
          }`,
        })
      );
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { WorkWithCorporateClientBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-work-with-corporate-client-blocks"
        );
        return WorkWithCorporateClientBlocks;
      },
      method: createWorkWithCorporateClientBlock,
    },
  });

export const wholesale_customer_block_id_select = (
  model = "wholesale_customer_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Работа с оптовыми клиентами"',
    include: "wholesaleCustomerBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const wholesaleCustomerBlocks = await getWholesaleCustomerBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return wholesaleCustomerBlocks.data.map(
        (wholesaleCustomerBlock: any) => ({
          value: wholesaleCustomerBlock,
          label: `id: ${wholesaleCustomerBlock.id} - ${
            wholesaleCustomerBlock.attributes.title
          } ${wholesaleCustomerBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
        })
      );
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { WholesaleCustomerBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-wholesale-customer-blocks"
        );
        return WholesaleCustomerBlocks;
      },
      method: createWholesaleCustomerBlock,
    },
  });

export const business_advantage_block_id_select = (
  model = "business_advantage_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Бизнес-преимущества"',
    include: "businessAdvantageBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const businessAdvantageBlocks = await getBusinessAdvantageBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return businessAdvantageBlocks.data.map(
        (businessAdvantageBlock: any) => ({
          value: businessAdvantageBlock,
          label: `id: ${businessAdvantageBlock.id} - ${
            businessAdvantageBlock.attributes.title
          } ${businessAdvantageBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
        })
      );
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { BusinessAdvantageBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-business-advantage-blocks"
        );
        return BusinessAdvantageBlocks;
      },
      method: createBusinessAdvantageBlock,
    },
  });

export const analytic_block_id_select = (
  model = "analytic_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Аналитика"',
    include: "analyticBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const analyticBlocks = await getAnalyticBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return analyticBlocks.data.map((analyticBlock: any) => ({
        value: analyticBlock,
        label: `id: ${analyticBlock.id} - ${analyticBlock.attributes.title} ${
          analyticBlock.attributes.is_hidden ? "(скрыт)" : ""
        }`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { AnalyticBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-analytic-blocks"
        );
        return AnalyticBlocks;
      },
      method: createAnalyticBlock,
    },
  });

export const partnership_block_id_select = (
  model = "partnership_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Заявка на сотрудничество"',
    include: "partnershipBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const partnershipBlocks = await getPartnershipBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return partnershipBlocks.data.map((partnershipBlock: any) => ({
        value: partnershipBlock,
        label: `id: ${partnershipBlock.id} - ${
          partnershipBlock.attributes.title
        } ${partnershipBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { PartnershipBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-partnership-blocks"
        );
        return PartnershipBlocks;
      },
      method: createPartnershipBlock,
    },
  });

export const contact_block_id_select = (
  model = "contact_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Контакты"',
    include: "contactBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const contactBlocks = await getContactBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return contactBlocks.data.map((contactBlock: any) => ({
        value: contactBlock,
        label: `id: ${contactBlock.id} - ${contactBlock.attributes.title} ${
          contactBlock.attributes.is_hidden ? "(скрыт)" : ""
        }`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { ContactBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-contact-blocks"
        );
        return ContactBlocks;
      },
      method: createContactBlock,
    },
  });

export const corporate_solution_and_product_block_id_select = (
  model = "corporate_solution_and_product_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Услуги B2G"',
    include: "corporateSolutionAndProductBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const corporateSolutionAndProductBlocks =
        await getCorporateSolutionAndProductBlocks({
          "page[size]": 100,
          ...parameters,
        });
      return corporateSolutionAndProductBlocks.data.map(
        (corporateSolutionAndProductBlock: any) => ({
          value: corporateSolutionAndProductBlock,
          label: `id: ${corporateSolutionAndProductBlock.id} - ${
            corporateSolutionAndProductBlock.attributes.title
          } ${
            corporateSolutionAndProductBlock.attributes.is_hidden
              ? "(скрыт)"
              : ""
          }`,
        })
      );
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { CorporateSolutionAndProductBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-corp-solution-and-product-blocks"
        );
        return CorporateSolutionAndProductBlocks;
      },
      method: createCorporateSolutionAndProductBlock,
    },
  });

export const business_offer_block_id_select = (
  model = "business_offer_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Предложения для бизнеса"',
    include: "businessOfferBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const businessOfferBlocks = await getBusinessOfferBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return businessOfferBlocks.data.map((businessOfferBlock: any) => ({
        value: businessOfferBlock,
        label: `id: ${businessOfferBlock.id} - ${
          businessOfferBlock.attributes.title
        } ${businessOfferBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { BusinessOfferBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-business-offer-blocks"
        );
        return BusinessOfferBlocks;
      },
      method: createBusinessOfferBlock,
    },
  });

export const our_client_case_block_id_select = (
  model = "our_client_case_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Кейсы клиентов"',
    include: "ourClientCaseBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const ourClientCaseBlocks = await getOurClientCaseBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return ourClientCaseBlocks.data.map((ourClientCaseBlock: any) => ({
        value: ourClientCaseBlock,
        label: `id: ${ourClientCaseBlock.id} - ${
          ourClientCaseBlock.attributes.title
        } ${ourClientCaseBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { OurClientCaseBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-our-client-case-blocks"
        );
        return OurClientCaseBlocks;
      },
      method: createOurClientCaseBlock,
    },
  });

export const cooperation_card_block_id_select = (
  model = "cooperation_card_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Направления B2G"',
    include: "cooperationCardBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const cooperationCardBlocks = await getCooperationCardBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return cooperationCardBlocks.data.map((cooperationCardBlock: any) => ({
        value: cooperationCardBlock,
        label: `id: ${cooperationCardBlock.id} - ${
          cooperationCardBlock.attributes.title
        } ${cooperationCardBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { CooperationCardBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-cooperation-card-blocks"
        );
        return CooperationCardBlocks;
      },
      method: createCooperationCardBlock,
    },
  });

export const business_format_block_id_select = (
  model = "business_format_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Форматы работы"',
    include: "businessFormatBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const businessFormatBlocks = await getBusinessFormatBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return businessFormatBlocks.data.map((businessFormatBlock: any) => ({
        value: businessFormatBlock,
        label: `id: ${businessFormatBlock.id} - ${
          businessFormatBlock.attributes.title
        } ${businessFormatBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { BusinessFormatBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-business-format-blocks"
        );
        return BusinessFormatBlocks;
      },
      method: createBusinessFormatBlock,
    },
  });

export const support_block_id_select = (
  model = "support_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Поддержка"',
    include: "supportBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const supportBlocks = await getSupportBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return supportBlocks.data.map((supportBlock: any) => ({
        value: supportBlock,
        label: `id: ${supportBlock.id} - ${supportBlock.attributes.title} ${
          supportBlock.attributes.is_hidden ? "(скрыт)" : ""
        }`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { SupportBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-support-blocks"
        );
        return SupportBlocks;
      },
      method: createSupportBlock,
    },
  });

export const income_calculator_block_id_select = (
  model = "income_calculator_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Калькулятор дохода"',
    include: "incomeCalculatorBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const incomeCalculatorBlocks = await getIncomeCalculatorBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return incomeCalculatorBlocks.data.map((incomeCalculatorBlock: any) => ({
        value: incomeCalculatorBlock,
        label: `id: ${incomeCalculatorBlock.id} - ${
          incomeCalculatorBlock.attributes.title
        } ${incomeCalculatorBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { IncomeCalculatorBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-income-calculator-blocks"
        );
        return IncomeCalculatorBlocks;
      },
      method: createIncomeCalculatorBlock,
    },
  });

export const our_network_block_id_select = (
  model = "our_network_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Наша сеть"',
    include: "ourNetworkBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const ourNetworkBlocks = await getOurNetworkBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return ourNetworkBlocks.data.map((ourNetworkBlock: any) => ({
        value: ourNetworkBlock,
        label: `id: ${ourNetworkBlock.id} - ${
          ourNetworkBlock.attributes.title
        } ${ourNetworkBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { OurNetworkBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-our-network-blocks"
        );
        return OurNetworkBlocks;
      },
      method: createOurNetworkBlock,
    },
  });

export const menu_item_block_id_select = (
  model = "menu_item_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Меню"',
    include: "menuItemBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const menuItemBlocks = await getMenuItemBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return menuItemBlocks.data.map((menuItemBlock: any) => ({
        value: menuItemBlock,
        label: `id: ${menuItemBlock.id} - ${menuItemBlock.attributes.title} ${
          menuItemBlock.attributes.is_hidden ? "(скрыт)" : ""
        }`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { MenuItemBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-menu-item-blocks"
        );
        return MenuItemBlocks;
      },
      method: createMenuItemBlock,
    },
  });

export const video_presentation_block_id_select = (
  model = "video_presentation_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Видеопрезентации"',
    include: "videoPresentationBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const videoPresentationBlocks = await getVideoPresentationBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return videoPresentationBlocks.data.map(
        (videoPresentationBlock: any) => ({
          value: videoPresentationBlock,
          label: `id: ${videoPresentationBlock.id} - ${
            videoPresentationBlock.attributes.title
          } ${videoPresentationBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
        })
      );
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { VideoPresentationBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-video-presentation-blocks"
        );
        return VideoPresentationBlocks;
      },
      method: createVideoPresentationBlock,
    },
  });

export const team_block_id_select = (
  model = "team_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Команда"',
    include: "teamBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const teamBlocks = await getTeamBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return teamBlocks.data.map((teamBlock: any) => ({
        value: teamBlock,
        label: `id: ${teamBlock.id} - ${teamBlock.attributes.title} ${
          teamBlock.attributes.is_hidden ? "(скрыт)" : ""
        }`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { TeamBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-team-blocks"
        );
        return TeamBlocks;
      },
      method: createTeamBlock,
    },
  });

export const business_to_consumer_block_id_select = (
  model = "business_to_consumer_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "Услуги B2C"',
    include: "businessToConsumerBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const businessToConsumerBlocks = await getBusinessToConsumerBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return businessToConsumerBlocks.data.map(
        (businessToConsumerBlock: any) => ({
          value: businessToConsumerBlock,
          label: `id: ${businessToConsumerBlock.id} - ${
            businessToConsumerBlock.attributes.title
          } ${businessToConsumerBlock.attributes.is_hidden ? "(скрыт)" : ""}`,
        })
      );
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { BusinessToConsumerBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-business-to-consumer-blocks"
        );
        return BusinessToConsumerBlocks;
      },
      method: createBusinessToConsumerBlock,
    },
  });

export const hr_block_id_select = (
  model = "hr_block_id"
): FormItemObjDeclaration =>
  asyncSelect({
    model,
    label: 'Блок "HR-блок"',
    include: "hrBlocks",
    filterable: false,
    multiple: false,
    clearable: true,
    optionList: async (parameters) => {
      const hrBlocks = await getHrBlocks({
        "page[size]": 100,
        ...parameters,
      });
      return hrBlocks.data.map((hrBlock: any) => ({
        value: hrBlock,
        label: `id: ${hrBlock.id} - ${hrBlock.attributes.title} ${
          hrBlock.attributes.is_hidden ? "(скрыт)" : ""
        }`,
      }));
    },
    submitMapDataCallback: (value) => value?.id || null,
    modalForm: {
      createModalTitle: "Добавить блок",
      form: async () => {
        const { HrBlocks } = await import(
          "@/components/form-declaration/landings-cms/form-declaration-hr-blocks"
        );
        return HrBlocks;
      },
      method: createHrBlock,
    },
  });
