import { fetchRequest } from "@/api/core";
import { SERVICE_POINT_NAME } from "@/api/points/auth-api/service-config";
import { Service } from "@/api/core/service";

export const USERS_SERVICE_POINT_NAME = `service/${SERVICE_POINT_NAME}/users`;
export const POINT_NAME = `${SERVICE_POINT_NAME}/users`;
const service = new Service(`${POINT_NAME}`, {
  list: {
    "fields[groups]": "id,name",
    include: "groups,contacts",
  },
  item: { include: "groups,tokens,contacts,roles" },
});

export default service;
export const getUsers = service.list.bind(service);
export const getUserById = service.item.bind(service);
export const createUser = service.create.bind(service);
export const updateUser = service.update.bind(service);
export const deleteUser = service.delete.bind(service);
export const getUserProfile = async (
  showNotification = false,
  include = "groups,avatar,contacts"
): Promise<any> => {
  try {
    const queryParams = new URLSearchParams({
      include,
    }).toString();

    const result = await fetchRequest({
      point: `${POINT_NAME}/profile?${queryParams}`,
      fetchMethod: "GET",
      showNotification,
      canAborted: false,
    });
    return result;
  } catch (e) {
    return false;
  }
};
export const updateUserProfile = async (payload = {}): Promise<any> => {
  try {
    const result = await fetchRequest({
      point: `${SERVICE_POINT_NAME}/users/profile`,
      fetchMethod: "PUT",
      payload,
    });
    return result;
  } catch (e) {
    return false;
  }
};

export const confirmContact = async (
  id: string | number,
  userId: string | number
): Promise<any> => {
  try {
    const result = await fetchRequest({
      point: `${POINT_NAME}/${userId}/contact_confirmation/${id}`,
      fetchMethod: "PUT",
    });
    return result;
  } catch (e) {
    return false;
  }
};

export const getUserAvatar = async (): Promise<any> => {
  try {
    const user = await getUserProfile(false, "avatar");

    if (user?.data?.relationships?.avatar) {
      return user.data.relationships.avatar;
    }

    return false;
  } catch (e) {
    return false;
  }
};

export const impersonate = async (payload: {
  id: string;
}): Promise<{ data: { refresh: string; token: string } }> => {
  try {
    const result = await fetchRequest({
      point: `${POINT_NAME}/impersonate`,
      fetchMethod: "POST",
      payload,
      showNotification: true,
    });
    return result;
  } catch (e) {
    throw new Error(e as string);
  }
};
