export const OBJECTS_LIST = [
  {
    label: "Бренды",
    value: "brand",
  },
  {
    label: "Набор характеристик",
    value: "classifier",
  },
  {
    label: "Характеристики",
    value: "property",
  },
  {
    label: "Карточки товаров",
    value: "product",
  },
];

export const FILE_TYPES = [
  {
    label: "XLS",
    value: "xls",
  },
];

export const TASK_STATUSES = {
  CREATED: "created",
  PENDING: "pending",
  FAILED: "failed",
  SUCCESS: "success",
};

export const EXPORT_TYPES = {
  BY_OBJECT: "object",
  BY_URL: "url",
};

export const TASK_INTERVAL_TIMEOUT = 5000;
