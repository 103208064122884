<template>
  <div :class="$style['notification']">
    <span :class="$style['message']" @click="emit('on-message-click')">{{
      message
    }}</span>
  </div>
</template>

<script lang="ts" setup>
interface Props {
  message: string;
}

const emit = defineEmits(["on-message-click"]);

defineProps<Props>();
</script>

<style lang="scss" module>
.notification {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 100%;

  background-color: #ffc83d;
  border-radius: 4px;
}

.message {
  font-size: 14px;
  color: var(--el-color-white);
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
</style>
