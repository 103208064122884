import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/team_blocks`, {
  item: { include: "employees" },
});

export default service;
export const getTeamBlocks = service.list.bind(service);
export const getTeamBlockById = service.item.bind(service);
export const createTeamBlock = service.create.bind(service);
export const updateTeamBlock = service.update.bind(service);
export const deleteTeamBlock = service.delete.bind(service);
