import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import router from "@/router";

const windowExtended = <any>window;

if (!windowExtended.GLOBAL_ENV) {
  windowExtended.GLOBAL_ENV = {};
}

export const sentryConfig = windowExtended.GLOBAL_ENV.sentry
  ? {
      dsn: windowExtended.GLOBAL_ENV.sentry.token,
      logErrors: true,
      trackComponents: true,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [
            windowExtended.GLOBAL_ENV.sentry.origin || window.location.origin,
            /^\//,
          ],
        }),
      ],
      tracesSampleRate: +windowExtended.GLOBAL_ENV.sentry.sampleRate || 0.0,
    }
  : {};
