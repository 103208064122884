<template>
  <el-popover
    :disabled="isCodeViewMode"
    placement="bottom"
    popper-class="el-tiptap-popper"
    trigger="click"
  >
    <div class="el-tiptap-popper__menu">
      <div
        class="el-tiptap-popper__menu__item"
        @click="imageUploadDialogVisible = true"
      >
        <span>{{
          t("editor.extensions.Image.buttons.insert_image.upload")
        }}</span>
      </div>
    </div>

    <template #reference>
      <span>
        <command-button
          :enable-tooltip="enableTooltip"
          :readonly="isCodeViewMode"
          :tooltip="t('editor.extensions.Image.buttons.insert_image.tooltip')"
          icon="image"
        />
      </span>
    </template>
  </el-popover>

  <el-dialog
    v-model="imageUploadDialogVisible"
    :title="t('editor.extensions.Image.control.upload_image.title')"
    append-to-body
  >
    <el-upload
      :http-request="uploadImage"
      :show-file-list="false"
      accept="image/*"
      action="#"
      class="el-tiptap-upload"
      drag
    >
      <div class="el-tiptap-upload__icon">
        <i class="fa fa-upload" />
      </div>
      <div class="el-tiptap-upload__text">
        {{ t("editor.extensions.Image.control.upload_image.button") }}
      </div>
    </el-upload>
  </el-dialog>
</template>

<script lang="ts" setup>
import { computed, inject, nextTick, ref } from "vue";
import { Editor } from "@tiptap/core";
import { readFileDataUrl } from "@/plugins/tiptap-editor/utils/shared";
import Logger from "@/plugins/tiptap-editor/utils/logger";
import CommandButton from "../CommandButton.vue";
import { ElLoading } from "element-plus";

interface Props {
  editor: Editor;
}

const props = defineProps<Props>();

const t: any = inject("t");
const enableTooltip = inject("enableTooltip", true);
const isCodeViewMode = inject("isCodeViewMode", false);

const imageUploadDialogVisible = ref(false);

const imageNodeOptions = computed(() => {
  return props.editor.extensionManager.extensions.find(
    (e) => e.name === "image"
  )?.options;
});

const uploadImage = async (requestOptions: any) => {
  const { file } = requestOptions;

  const uploadRequest = imageNodeOptions.value.uploadImageMethod;

  const loadingInstance = ElLoading.service({
    target: ".el-tiptap-upload",
  });
  try {
    const data = await (uploadRequest
      ? uploadRequest(file, imageNodeOptions.value.model)
      : readFileDataUrl(file));

    props.editor.commands.setImage({
      src: data.url,
    });
    imageUploadDialogVisible.value = false;
  } catch (e) {
    Logger.error(String(e));
  } finally {
    nextTick(() => {
      loadingInstance.close();
    });
  }
};
</script>
