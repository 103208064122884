import { FormItemObjDeclaration } from "@/plugins/form-generator-json-v2/types";
import { ColumnType } from "@/model/working-sections/constants";

export const defaultHiddenInput = (
  model: string,
  isPayload = true
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model,
  isPayload,
  element: "input",
  attrs: {
    type: ColumnType.hidden,
  },
  formItemAttrs: {
    class: "el-form-item--hidden",
  },
});

export const brand_id_hidden = defaultHiddenInput("brand_id");
export const model_id_hidden = defaultHiddenInput("model_id");
export const generation_id_hidden = defaultHiddenInput("generation_id");
export const modification_id_hidden = defaultHiddenInput("modification_id");
export const id_hidden = defaultHiddenInput("id", false);
export const short_name_hidden = defaultHiddenInput("short_name");
