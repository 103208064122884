import { FormItemObjDeclaration } from "@/plugins/form-generator-json-v2/types";
import { d } from "@/helpers/dictionary";

export const defaultTimeSelect = (
  model: string,
  label?: string,
  note?: string,
  placeholder = "Выберите время",
  start = "07:00",
  end = "24:00",
  step = "00:05"
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model,
  isPayload: true,
  element: "time-select",
  attrs: {
    placeholder,
    start,
    end,
    step,
  },
  formItemAttrs: {
    label: label ?? d(model),
    note,
  },
});

export const work_schedule_start = defaultTimeSelect("work_schedule_start");
export const work_schedule_end = defaultTimeSelect("work_schedule_end");
