<template>
  <el-autocomplete
    v-model="state.search"
    :class="$style['search']"
    :fetch-suggestions="querySearch"
    :popper-class="`${$style['search-popper']} ${state.popper}`"
    :prefix-icon="Search"
    :trigger-on-focus="false"
    highlight-first-item
    placeholder="Поиск"
    @select="onSelect"
  >
    <template #default="{ item }">
      <router-link
        v-if="item.route"
        :to="item.route"
        class="el-autocomplete__link"
        v-html="highlightTitle(item.title)"
      />
      <template v-else>{{ item.title }}</template>
    </template>
  </el-autocomplete>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import { Search } from "@element-plus/icons-vue";
import { RouteLocation, useRouter } from "vue-router";
import { RESPONSE_NOTHING_FOUND } from "@/dictionary/response";
import useMobileMenu from "@/composables/hooks/useMobileMenu";

const { setMobileMenuVisibility } = useMobileMenu();

interface Link {
  route: RouteLocation;
  title: string;
}

const router = useRouter();
const links = ref<Link[]>([]);

links.value = router
  .getRoutes()
  .filter(
    (route) =>
      route.meta.title && !route.children.length && !route.meta.hiddenRoute
  )
  .map((route) => {
    const routeNormalized = router.resolve(route);
    const title = routeNormalized.matched
      .map((match) => match.meta.title)
      .join(" / ");
    return { route: routeNormalized, title };
  });

const state = reactive({
  search: "",
  popper: "",
});

const createFilter = (queryString: string) => (link: Link) =>
  link.route.meta.title
    .toString()
    .toLowerCase()
    .indexOf(queryString.toLowerCase()) !== -1;

const querySearch = (queryString: string, cb: any) => {
  let results: Partial<Link>[] = queryString
    ? links.value.filter(createFilter(queryString))
    : [];
  state.popper = "";

  if (queryString && !results.length) {
    results = [{ title: RESPONSE_NOTHING_FOUND }];
    state.popper = "el-popper--empty";
  }

  cb(results);
};

const highlightTitle = (title: string) => {
  const partsTitle = title.split("/");
  const lastPartTitle = partsTitle.pop() || "";
  return partsTitle
    .concat(lastPartTitle.replace(new RegExp(state.search, "i"), "<b>$&</b>"))
    .join("/");
};

const onSelect = (item: Record<string, any>) => {
  if (item.route) {
    router.push(item.route);
    setMobileMenuVisibility(false);
  }
};
</script>

<style lang="scss" module>
.search {
  margin-left: 24px;
  margin-right: 24px;
  width: 100%;
  border-radius: 4px;
  height: 40px;
  overflow: hidden;

  @media (max-width: 640px) {
    margin: 0;
  }

  :global {
    .el-input__inner {
      border: none;
    }

    .el-input__prefix {
      font-size: 17px;
    }
  }
}

.search-popper :global {
  @media (max-width: 640px) {
    max-width: calc(100% - 32px);
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto;
  }

  .el-autocomplete-suggestion {
    &:not(li.highlighted:hover) {
      @media (max-width: 640px) {
        background-color: transparent;
      }
    }

    li {
      @media (max-width: 640px) {
        line-height: 120%;
        white-space: inherit;
        margin: 0 0 10px;
      }
    }
  }

  .el-popper--empty {
    li {
      color: var(--el-text-color-secondary);
      font-size: var(--el-select-font-size);
      cursor: default;
      pointer-events: none;
      text-align: center;

      &.highlighted {
        background-color: var(--el-color-white);
      }
    }
  }
}
</style>
