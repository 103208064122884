import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/client_directions`, {
  item: { include: "ourClients" },
});

export default service;
export const getClientDirections = service.list.bind(service);
export const getClientDirectionById = service.item.bind(service);
export const createClientDirection = service.create.bind(service);
export const updateClientDirection = service.update.bind(service);
export const deleteClientDirection = service.delete.bind(service);
