import JoditEditor from "./JoditEditor.vue";

export { Jodit } from "jodit";
export { JoditEditor };

export default {
  install: (app: any) => {
    app.component("JoditEditor", JoditEditor);
  },
};
