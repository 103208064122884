import LayoutWorkingSection from "@/layout/LayoutWorkingSection.vue";
import WorkingSectionView from "@/views/WorkingSectionView.vue";
import { Setting } from "@element-plus/icons-vue";
import {
  ACCESS_CONTROL,
  COMMANDS,
  COMMUNICATION_SYSTEMS as EXTERNAL_SYSTEMS,
  OBJECTS,
  SERVICES,
  TOKENS as EXTERNAL_SYSTEM_TOKENS,
  USER_GROUPS,
  USERS,
  USERS_STAFF,
} from "@/dictionary/header";
import { RouteRecordRaw } from "vue-router";
import { RouteAccess } from "../types";

const access: RouteAccess = {
  service: "auth",
  object: "",
  command: "index",
};

export const accessControlRoutesList: Array<RouteRecordRaw> = [
  {
    path: "/access-control",
    name: "access-control",
    component: LayoutWorkingSection,
    meta: {
      title: ACCESS_CONTROL,
      icon: Setting,
      clickable: false,
    },
    children: [
      {
        path: "/access-control/users-groups",
        name: "users-groups",
        component: WorkingSectionView,
        meta: {
          title: USER_GROUPS,
          access: {
            ...access,
            object: "users_groups",
          },
        },
      },
      {
        path: "/access-control/users",
        name: "users",
        component: WorkingSectionView,
        meta: {
          title: USERS,
          access: {
            ...access,
            object: "users",
          },
        },
      },
      {
        path: "/access-control/users-staff",
        name: "users-staff",
        component: WorkingSectionView,
        meta: {
          title: USERS_STAFF,
          access: {
            ...access,
            object: "users",
          },
        },
      },
      {
        path: "/access-control/system-services",
        name: "system-services",
        component: WorkingSectionView,
        meta: {
          title: SERVICES,
          access: {
            ...access,
            object: "system_services",
          },
        },
      },
      {
        path: "/access-control/system-objects",
        name: "system-objects",
        component: WorkingSectionView,
        meta: {
          title: OBJECTS,
          access: {
            ...access,
            object: "system_objects",
          },
        },
      },
      {
        path: "/access-control/system-commands",
        name: "system-commands",
        component: WorkingSectionView,
        meta: {
          title: COMMANDS,
          access: {
            ...access,
            object: "system_commands",
          },
        },
      },
      {
        path: "/access-control/external-systems",
        name: "external-systems",
        component: WorkingSectionView,
        meta: {
          title: EXTERNAL_SYSTEMS,
          access: {
            ...access,
            object: "external_systems",
          },
        },
      },
      {
        path: "/access-control/external-system-tokens",
        name: "external-tokens",
        component: WorkingSectionView,
        meta: {
          title: EXTERNAL_SYSTEM_TOKENS,
          access: {
            ...access,
            object: "external_system_tokens",
          },
        },
      },
    ],
  },
];
