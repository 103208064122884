import { ref, Ref, watch } from "vue";
import { RouteParams, Router, useRouter } from "vue-router";
import { Breadcrumb, DataList } from "@/components/types";

const WIZARD_PATH = "/catalog-vehicles/wizard";
let _router: Router;
const breadcrumbs = ref<Breadcrumb[]>([]);

const createBreadcrumb = (
  path: string,
  title: string,
  query: any = {}
): Breadcrumb => ({
  path,
  meta: { title },
  query,
});

const getBreadcrumbs = (data: any): Breadcrumb[] => {
  if (!data) return [createBreadcrumb(WIZARD_PATH, "Марки")];

  switch (data.type) {
    case "brands":
      return [createBreadcrumb(WIZARD_PATH, "Марки", data.query)];
    case "models":
      return [
        createBreadcrumb(WIZARD_PATH, "Управление", data.query),
        createBreadcrumb(
          _router.currentRoute.value.path,
          data.relationships.brand.attributes.name,
          data.query
        ),
        { meta: { title: "Модели" } },
      ];
    case "generations":
      return [
        createBreadcrumb(WIZARD_PATH, "Управление", data.query),
        createBreadcrumb(
          `${WIZARD_PATH}/brand/${data.relationships.model.relationships.brand.id}`,
          data.relationships.model.relationships.brand.attributes.name,
          data.query
        ),
        createBreadcrumb(
          _router.currentRoute.value.path,
          data.relationships.model.attributes.name,
          data.query
        ),
        { meta: { title: "Поколения" } },
      ];
    case "modifications":
      return [
        createBreadcrumb(WIZARD_PATH, "Управление", data.query),
        createBreadcrumb(
          `${WIZARD_PATH}/brand/${data.relationships.generation.relationships.model.relationships.brand.id}`,
          data.relationships.generation.relationships.model.relationships.brand
            .attributes.name,
          data.query
        ),
        createBreadcrumb(
          `${WIZARD_PATH}/model/${data.relationships.generation.relationships.model.id}`,
          data.relationships.generation.relationships.model.attributes.name,
          data.query
        ),
        createBreadcrumb(
          _router.currentRoute.value.path,
          data.relationships.generation.attributes.name,
          data.query
        ),
        { meta: { title: "Модификации" } },
      ];
    case "attributes":
      return [
        createBreadcrumb(WIZARD_PATH, "Управление", data.query),
        createBreadcrumb(
          `${WIZARD_PATH}/brand/${data.relationships.modification.relationships.generation.relationships.model.relationships.brand.id}`,
          data.relationships.modification.relationships.generation.relationships
            .model.relationships.brand.attributes.name,
          data.query
        ),
        createBreadcrumb(
          `${WIZARD_PATH}/model/${data.relationships.modification.relationships.generation.relationships.model.id}`,
          data.relationships.modification.relationships.generation.relationships
            .model.attributes.name,
          data.query
        ),
        createBreadcrumb(
          `${WIZARD_PATH}/generation/${data.relationships.modification.relationships.generation.id}`,
          data.relationships.modification.relationships.generation.attributes
            .name,
          data.query
        ),
        createBreadcrumb(
          _router.currentRoute.value.path,
          data.relationships.modification.attributes.name,
          data.query
        ),
        { meta: { title: "Группы атрибутов" } },
      ];
    default:
      return [createBreadcrumb(WIZARD_PATH, "Марки", data.query)];
  }
};

const fetchDataByPreset = async (
  preset: string,
  params: RouteParams,
  query: Record<string, any>
) => {
  const id = parseInt(params.id as string);

  const fetchingFunctions: Record<string, () => Promise<any>> = {
    brand_id: async () => {
      const { getBrandById } = await import("@/api/points/vehicles-api/brands");
      return getBrandById(id);
    },
    model_id: async () => {
      const { getModelById } = await import("@/api/points/vehicles-api/models");
      return getModelById(id);
    },
    generation_id: async () => {
      const { getGenerationById } = await import(
        "@/api/points/vehicles-api/generations"
      );
      return getGenerationById(id);
    },
    modification_id: async () => {
      const { getModificationById } = await import(
        "@/api/points/vehicles-api/modifications"
      );
      return getModificationById(id);
    },
  };

  const breadcrumbTypes: Record<string, string> = {
    brand_id: "models",
    model_id: "generations",
    generation_id: "modifications",
    modification_id: "attributes",
  };

  if (!fetchingFunctions[preset]) {
    return;
  }

  const response = await (fetchingFunctions[preset] as () => Promise<any>)();

  if (response && response.data) {
    breadcrumbs.value = getBreadcrumbs({
      type: breadcrumbTypes[preset],
      relationships: { [preset.split("_")[0] as string]: response.data },
      query,
    });
  }
};

interface Return {
  breadcrumbs: Readonly<Ref<Array<Breadcrumb>>>;
  getBreadcrumbs: (data: any) => Array<Breadcrumb>;
}

export default (dataList: DataList): Return => {
  _router = useRouter();

  watch(
    dataList,
    async (data) => {
      const { meta, params, query: routeQuery } = _router.currentRoute.value;
      const query = {
        "filter[attributes]": routeQuery["filter[attributes]"] || "wheels",
        ...routeQuery,
      };

      if (data.value && data.value.length) {
        breadcrumbs.value = getBreadcrumbs({ query, ...data.value[0] });
        return;
      }

      await fetchDataByPreset(meta.preset as string, params, query);
    },
    { immediate: true }
  );

  return {
    breadcrumbs,
    getBreadcrumbs,
  };
};
