import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/organizations`);

export default service;
export const getOrganizations = service.list.bind(service);
export const getOrganizationById = service.item.bind(service);
export const createOrganization = service.create.bind(service);
export const updateOrganization = service.update.bind(service);
export const deleteOrganization = service.delete.bind(service);
