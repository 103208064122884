export function noop(): void {
  return void 0;
}

export function isNaN(val: unknown): boolean {
  return Number.isNaN(val);
}

export function clamp(val: number, min: number, max: number): number {
  if (val < min) {
    return min;
  }
  if (val > max) {
    return max;
  }
  return val;
}

export function readFileDataUrl(file: File): Promise<any> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (readerEvent) => resolve(readerEvent.target?.result);
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export function cached(fn: (...args: any) => void): any {
  const cache = Object.create(null);

  return function cachedFn(str: string): string {
    const hit = cache[str];
    return hit || (cache[str] = fn(str));
  };
}

export const capitalize = cached((str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
});

export function isPlainObject(obj: unknown): boolean {
  return Object.prototype.toString.call(obj) === "[object Object]";
}
