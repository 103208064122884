import { fetchRequest } from "@/api/core";
import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const extra = {
  list: {
    "fields[brands]": "id,name",
    include: "brand,category,type",
    sort: "name",
  },
  item: { include: "brand,category,type" },
};
const service = new Service(`${SERVICE_POINT_NAME}/models`, extra);

export default service;
export const getModels = service.list.bind(service);
export const getModelById = service.item.bind(service);
export const createModel = service.create.bind(service);
export const updateModel = service.update.bind(service);
export const deleteModel = service.delete.bind(service);
export const downloadModel = async (
  id: Array<number | string>
): Promise<any> => {
  try {
    const result = await fetchRequest({
      point: `${SERVICE_POINT_NAME}/models/export/${id.join(",")}`,
      fetchMethod: "GET",
      rawPromise: true,
    });
    let filename = "attributes.xlsx";
    const blob = await result.blob();
    const contentDisposition = result.headers.get("Content-Disposition");
    if (contentDisposition && /filename="(.*?)"/.test(contentDisposition)) {
      filename = decodeURIComponent(
        contentDisposition.match(/filename="(.*?)"/)[1]
      );
    }
    return {
      blob,
      filename,
      url: URL.createObjectURL(blob),
    };
  } catch (e) {
    return false;
  }
};

export const serviceWizard = new Service(`${SERVICE_POINT_NAME}/models`, {
  ...extra,
  list: {
    ...extra.list,
    include_count: "all_attributes,current_attributes,wrong_bindings",
  },
});
