import {
  FormItemAttrsObjDeclaration,
  FormItemEventsObjDeclaration,
  FormItemObjDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import { d } from "@/helpers/dictionary";
import { getRegex } from "@/helpers/regex";
import { confirmContact } from "@/api/points/auth-api/users";

export const defaultTextInput = (
  model: string,
  label?: string,
  note?: string,
  placeholder?: string,
  attrs?: FormItemAttrsObjDeclaration,
  submitMapDataCallback?: FormItemObjDeclaration["submitMapDataCallback"],
  events?: FormItemEventsObjDeclaration,
  extractedFrom?: string,
  type?: string
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model,
  isPayload: true,
  element: "input",
  attrs: {
    placeholder,
    type,
    ...attrs,
  },
  submitMapDataCallback,
  formItemAttrs: {
    label: label ?? d(model),
    note,
  },
  events,
  extractedFrom,
});

/**
 * Создает input-range элемент из двух input элементов
 * @param {FormItemObjDeclaration} firstForm - Input Элемент, используется для ввода первого значения
 * @param {FormItemObjDeclaration} secondForm - Input Элемент, используется для ввода второго значения
 * @param {string} label - Общий label для полей ввода
 * @param {string} note - Общая подсказка для полей ввода
 * @return {Array<FormItemObjDeclaration>} Массив элементов, содержащий переданные элементы, а также объединенный в input-range элемент
 * @example
 * defaultTextInputRange(rim_width_ge, rim_width_le, "Диск - ширина")
 * // Возвращает массив форм который можно использовать в GeneratorDeclaration
 * // Например, формирование формы фильтрации от минимального значения ширины диска до максимального значения ширины диска
 * // form: { items: [...defaultTextInputRange(toFilter(rim_width_ge), toFilter(rim_width_le), "Диск - ширина")] }
 */
export const defaultTextInputRange = (
  firstForm: FormItemObjDeclaration,
  secondForm: FormItemObjDeclaration,
  label?: string,
  note?: string
): Array<FormItemObjDeclaration> => [
  firstForm,
  secondForm,
  {
    name: `${firstForm.model}&${secondForm.model}`,
    id: `${firstForm.model}&${secondForm.model}`,
    model: `${firstForm.model}&${secondForm.model}`,
    isPayload: false,
    element: "input-range",
    attrs: {
      placeholder: `${firstForm.attrs?.placeholder || ""}&${
        secondForm.attrs?.placeholder || ""
      }`,
    },
    formItemAttrs: {
      label: label || d(`${firstForm.model}&${secondForm.model}`),
      note,
    },
  },
];

export const defaultInputActionButton = (
  model: string,
  confirmable: boolean,
  label?: string,
  outerId?: string,
  submitMapDataCallback?: FormItemObjDeclaration["submitMapDataCallback"],
  extractedFrom?: string,
  actionText?: string,
  handleClick?: FormItemObjDeclaration["handlerCallback"],
  buttonDisabledByDefault?: boolean
): FormItemObjDeclaration => ({
  name: model,
  confirmable: true,
  id: model,
  outerId,
  model,
  isPayload: true,
  element: "input-action-button",
  formItemAttrs: {
    label,
  },
  submitMapDataCallback,
  extractedFrom,
  actionText,
  handleClick,
  buttonDisabledByDefault: buttonDisabledByDefault || true,
});

export const defaultInputWithOpenCloseButton = (
  model: string,
  disabled = false,
  label?: string,
  placeholder?: string,
  popoverText?: string,
  required?: boolean
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model,
  isPayload: true,
  element: "input-with-open-close-button",
  attrs: {
    type: "textarea",
    autosize: { minRows: 4 },
    label,
    placeholder,
    popoverText,
    required,
  },
  formItemAttrs: {
    label: label ?? d(model),
  },
  disabled,
});

const textareaInput = (
  model: string,
  attrs?: FormItemAttrsObjDeclaration
): FormItemObjDeclaration =>
  defaultTextInput(model, undefined, undefined, attrs?.placeholder, {
    type: "textarea",
    ...attrs,
  });

export const defaultTextareaInput = (
  model: string,
  label?: string,
  note?: string,
  placeholder?: string
): FormItemObjDeclaration =>
  defaultTextInput(
    model,
    label,
    note,
    placeholder,
    undefined,
    undefined,
    undefined,
    undefined,
    "textarea"
  );

const placeholderedTextInput = (
  model: string,
  placeholder: string
): FormItemObjDeclaration =>
  defaultTextInput(model, undefined, undefined, placeholder);

export const landingSortTextInput = (model: string): FormItemObjDeclaration => {
  const input = defaultTextInput(model, "Сортировка");

  return {
    ...input,
    attrs: {
      ...input.attrs,
      mask: {
        regex: getRegex("landing_sort"),
      },
    },
  };
};

export const disabled_input = (
  model: string,
  label?: string,
  placeholder?: string
): FormItemObjDeclaration =>
  defaultTextInput(model, label, undefined, placeholder, {
    disabled: true,
  });

export const disabled_date_input = (
  model: string,
  label?: string,
  placeholder?: string
): FormItemObjDeclaration =>
  defaultTextInput(model, label, undefined, placeholder, {
    disabled: true,
    date: true,
  });

export const disabled_textarea = (
  model: string,
  label?: string,
  placeholder?: string
): FormItemObjDeclaration =>
  defaultTextInput(
    model,
    label,
    undefined,
    placeholder,
    {
      disabled: true,
    },
    undefined,
    undefined,
    undefined,
    "textarea"
  );

const submitArrayHandler = (value: any) => {
  return !Array.isArray(value) ? (value ? [value] : []) : value;
};
export const name = (
  label?: string,
  placeholder?: string,
  attrs?: Record<string, any>
): FormItemObjDeclaration =>
  defaultTextInput("name", label, undefined, placeholder, attrs);
export const name_en = (): FormItemObjDeclaration => {
  const input = defaultTextInput("name_en");

  return {
    ...input,
    attrs: {
      ...input.attrs,
      mask: {
        regex: getRegex("name_en"),
      },
    },
  };
};

export const contact_phone = (): FormItemObjDeclaration => {
  const input = defaultTextInput("phone");

  return {
    ...input,
    attrs: {
      ...input.attrs,
      mask: {
        regex: getRegex("tel_number"),
      },
    },
  };
};
export const franchise_phone = (): FormItemObjDeclaration => {
  const input = defaultTextInput("phone", "Телефон поддержки");

  return {
    ...input,
    attrs: {
      ...input.attrs,
      mask: {
        regex: getRegex("tel_number"),
      },
    },
  };
};
export const uuid = (): FormItemObjDeclaration => {
  const input = defaultTextInput("uuid");

  return {
    ...input,
    attrs: {
      ...input.attrs,
      mask: {
        regex: d("uuid"),
      },
    },
  };
};
export const email = defaultTextInput("email");

export const property_code = {
  ...defaultTextInput("code", "Код", undefined),
  attrs: {
    placeholder: "Укажите код",
    mask: {
      regex: "[a-zA-Z0-9_]*",
    },
  },
};

export const last_name = defaultTextInput("last_name");
export const second_name = defaultTextInput("second_name");
export const defaultPhone = defaultTextInput("phone");
export const defaultEmail = defaultTextInput("email");
export const phoneWithAction: FormItemObjDeclaration = defaultInputActionButton(
  "phone",
  true,
  "Телефон",
  "",
  submitArrayHandler,
  "contacts",
  "Подтвердить",
  confirmContact
);
export const full_name = defaultTextInput("full_name");
export const feedback_full_name = defaultTextInput("feedback_full_name");
export const employee_full_name = defaultTextInput("employee_full_name");
export const original_name = defaultTextInput("original_name");
export const platform = defaultTextInput("platform");

export const emailWithAction: FormItemObjDeclaration = defaultInputActionButton(
  "email",
  true,
  "Email",
  "",
  submitArrayHandler,
  "contacts",
  "Подтвердить",
  confirmContact
);

export const year_from = placeholderedTextInput(
  "year_from",
  `Пример: ${new Date().getFullYear() - 1}`
);
export const year_to = placeholderedTextInput(
  "year_to",
  `Пример: ${new Date().getFullYear()}`
);

export const year_from_to = defaultTextInput("year_from_to");
export const production_year_from = placeholderedTextInput(
  "production_year_from",
  `Пример: ${new Date().getFullYear() - 1}`
);
export const production_year_to = placeholderedTextInput(
  "production_year_to",
  `Пример: ${new Date().getFullYear()}`
);
export const stud_holes = placeholderedTextInput("stud_holes", "Пример: 5");
export const pcd = placeholderedTextInput("pcd", "Пример: 112");
export const dia = placeholderedTextInput("dia", "Пример: 57.1");
export const abbr = defaultTextInput("abbr");
export const country_codes = defaultTextInput("country_codes");

// pim-brands
export const brands_description = textareaInput("description", {
  maxlength: "1000",
  "show-word-limit": true,
  rows: 6,
  placeholder: "Укажите описание",
});
export const brands_name = defaultTextInput(
  "name",
  "Название",
  undefined,
  "Укажите название"
);

export const lock_thread_size = placeholderedTextInput(
  "lock_thread_size",
  "Пример: 14x1.5"
);
export const bolt_pattern = placeholderedTextInput(
  "bolt_pattern",
  "Пример: 5x112"
);
export const engine_power = placeholderedTextInput(
  "engine_power",
  "Пример: 110"
);
export const engine_type = defaultTextInput("engine_type");
export const engine_code = defaultTextInput("engine_code");
export const engine_capacity = placeholderedTextInput(
  "engine_capacity",
  "Пример: 2.0"
);
export const rim_width = defaultTextInput("rim_width");
export const rim_width_ge = defaultTextInput("rim_width-ge");
export const rim_width_le = defaultTextInput("rim_width-le");
export const rim_size = defaultTextInput("rim_size");
export const rim_diameter = defaultTextInput("rim_diameter");
export const rim_diameter_ge = defaultTextInput("rim_diameter-ge");
export const rim_diameter_le = defaultTextInput("rim_diameter-le");
export const rim_offset = defaultTextInput("rim_offset");
export const rim_offset_ge = defaultTextInput("rim_offset-ge");
export const rim_offset_le = defaultTextInput("rim_offset-le");
export const tire_width = defaultTextInput("tire_width");
export const tire_width_ge = defaultTextInput("tire_width-ge");
export const tire_width_le = defaultTextInput("tire_width-le");
export const tire_diameter = defaultTextInput("tire_diameter");
export const tire_diameter_ge = defaultTextInput("tire_diameter-ge");
export const tire_diameter_le = defaultTextInput("tire_diameter-le");
export const tire_aspect_ratio = defaultTextInput("tire_aspect_ratio");
export const tire_aspect_ratio_ge = defaultTextInput("tire_aspect_ratio-ge");
export const tire_aspect_ratio_le = defaultTextInput("tire_aspect_ratio-le");
export const tire_pressure = defaultTextInput("tire_pressure");
export const tire_section_width = defaultTextInput("tire_section_width");
export const load_index = defaultTextInput(
  "load_index",
  void 0,
  "Диапазоны значений<br>Легковые ТС: 60 - 125<br>Грузовые ТС: 126 - 175"
);
export const load_index_ge = defaultTextInput("load_index-ge");
export const load_index_le = defaultTextInput("load_index-le");
export const slug = defaultTextInput("slug");
export const external_code = defaultTextInput("external_code");
export const prefix_code = defaultTextInput("prefix_code");
export const id = defaultTextInput("id");
export const _id = defaultTextInput("_id", "Id");
export const domain = defaultTextInput("domain");
export const title = defaultTextareaInput("title");
export const admin_panel_title = defaultTextInput(
  "title",
  "Название для админ-панели"
);
export const hinted_title = defaultTextInput(
  "title",
  "Заголовок",
  "Данный заголовок будет выводиться на лендинге."
);
export const heading = defaultTextareaInput(
  "heading",
  undefined,
  "Данный заголовок будет выводиться на лендинге."
);
export const intro_block_title = defaultTextareaInput(
  "title",
  undefined,
  "Этот блок всегда должен располагаться в конструкторе первым." +
    " Перемещать или удалять его не рекомендуется — это позволит" +
    " избежать неожиданного поведения других блоков на странице."
);
export const subtitle = defaultTextInput("subtitle");
export const value = defaultTextInput("value");
export const value_with_business = defaultTextInput("value_with_business");
export const position = defaultTextInput("position");
export const company = defaultTextInput("company");
export const text = defaultTextInput("text");
export const button_text = defaultTextInput("button_text");
export const button_url = defaultTextInput("button_url");
export const description = defaultTextInput("description");
export const description_with_business = defaultTextInput(
  "description_with_business"
);
export const url = defaultTextInput("url");
export const whatsapp_url = defaultTextInput("url", "Ссылка на Whatsapp чат");
export const stores = defaultTextInput("stores");
export const cities = defaultTextInput("cities");
export const regions = defaultTextInput("regions");
export const frame = defaultTextInput(
  "frame",
  undefined,
  "Если вы загрузите превью для видео с YouTube вручную," +
    " приоритет будет отдаваться загруженному вами превью.",
  "https://www.youtube.com/watch?v={Идентификатор}"
);
export const vacancy_id = defaultTextInput(
  "vacancy_id",
  `Идентификатор вакансии: https://ekaterinburg.hh.ru/vacancy/{Идентификатор}`
);
export const investment_amount = defaultTextInput("investment_amount");
export const service_area = defaultTextInput("service_area");
export const project_payback = defaultTextInput("project_payback");
export const lump_sum = defaultTextInput("lump_sum");
export const royalty = defaultTextInput("royalty");
export const room_area = defaultTextInput("room_area");
export const investment_amount_with_business = defaultTextInput(
  "investment_amount_with_business"
);
export const lump_sum_with_business = defaultTextInput(
  "lump_sum_with_business"
);
export const royalty_with_business = defaultTextInput("royalty_with_business");
export const room_area_with_business = defaultTextInput(
  "room_area_with_business"
);
export const retail_units = defaultTextInput("retail_units");
export const franchise_units = defaultTextInput("franchise_units");
export const launching_units = defaultTextInput("launching_units");
export const network_cities = defaultTextInput("network_cities");
export const status = defaultTextInput("status");
export const legal_address = defaultTextInput("legal_address");
export const dadata_hid = defaultTextInput("dadata_hid");
export const keywords = defaultTextInput("keywords");
export const salary = defaultTextInput("salary");
export const service_points_type = defaultTextInput("type");
export const storage_area = defaultTextInput("storage_area");
export const stock_type = defaultTextInput("stock_type");
export const slots_interval = defaultTextInput("slots_interval");

export const kladr_id = defaultTextInput("kladr_id");
export const fias_id = defaultTextInput("fias_id");
export const country = defaultTextInput("country");
export const federal_district = defaultTextInput("federal_district");
export const region = defaultTextInput("region");
export const district = defaultTextInput("district");
export const city = defaultTextInput("city");
export const full_address = defaultTextInput("full_address");
export const metro = defaultTextInput("metro");
export const geo_lat = defaultTextInput("geo_lat");
export const geo_lon = defaultTextInput("geo_lon");
export const iso = defaultTextInput("iso");
export const fias_level = defaultTextInput("fias_level");
export const okato_code = defaultTextInput("okato_code");
export const oktmo_code = defaultTextInput("oktmo_code");
export const ifns_code = defaultTextInput("ifns_code");
export const cdek = defaultTextInput("cdek");
export const boxberry = defaultTextInput("boxberry");
export const dpd = defaultTextInput("dpd");
export const time_zone = defaultTextInput("time_zone");

export const employee_description = defaultTextareaInput("description");
export const form_script = defaultTextareaInput("form_script");
export const head_script = defaultTextareaInput("head_script");
export const body_script = defaultTextareaInput("body_script");
export const seo_title = defaultTextareaInput("seo_title");
export const seo_description = defaultTextareaInput("seo_description");
export const note_text = defaultTextareaInput("note_text");
export const menu_form_script = defaultTextareaInput(
  "form_script",
  undefined,
  "В это поле вставляется скрипт с формой обратной связи," +
    " сгенерированный в CRM Битрикс для конкретного лендинга." +
    " Поле доступно только при выборе типа ссылки “Телефон”."
);
export const partnership_form_script = defaultTextareaInput(
  "form_script",
  undefined,
  "В это поле вставляется скрипт с формой обратной связи," +
    " сгенерированный в CRM Битрикс для конкретного лендинга."
);
export const contact_form_script = defaultTextareaInput(
  "form_script",
  undefined,
  "В это поле вставляется скрипт с формой обратной связи для кнопки" +
    " “Заказать обратный звонок”, сгенерированный в CRM" +
    " Битрикс для конкретного лендинга."
);
export const video_description = defaultTextareaInput("video_description");
export const textarea_description = defaultTextareaInput("description");

export const employee_count = defaultTextInput("employee_count");
export const store_count = defaultTextInput("store_count");
export const city_count = defaultTextInput("city_count");
export const region_count = defaultTextInput("region_count");

export const object_id_text = defaultTextInput("object_id");
export const letter_code = defaultTextInput("letter_code");
export const code = (
  parameters: string | Record<string, any> = "Код"
): FormItemObjDeclaration => {
  let input;
  if (typeof parameters == "string") {
    input = defaultTextInput("code", parameters);
  } else {
    const { placeholder = "Код", note } = parameters;
    input = defaultTextInput("code", placeholder, note);
  }
  return {
    ...input,
    attrs: {
      ...input.attrs,
      readonly: true,
      ...(typeof parameters == "object" ? parameters.attrs : {}),
    },
  };
};

export const package_id: FormItemObjDeclaration = defaultTextInput(
  "package_id",
  "Номер пакета"
);

export const code_ne: FormItemObjDeclaration = defaultTextInput("code-ne");

export const dia_ge: FormItemObjDeclaration = defaultTextInput(
  "dia-ge",
  "Диаметр от",
  "Диаметр больше или равен переданному значению"
);

export const dia_le: FormItemObjDeclaration = defaultTextInput(
  "dia-le",
  "Диаметр до",
  "Диаметр меньше или равен переданному значению"
);

export const wheels_tire_width: FormItemObjDeclaration = defaultTextInput(
  "wheels_tire_width",
  "Ширина шины",
  "Модификации с колесами, где ширина шины равна переданному значению"
);

export const wheels_tire_width_ge: FormItemObjDeclaration = defaultTextInput(
  "wheels_tire_width-ge",
  "Ширина шины"
);

export const wheels_tire_width_le: FormItemObjDeclaration = defaultTextInput(
  "wheels_tire_width-le",
  "Ширина шины"
);

export const wheels_tire_aspect_ratio: FormItemObjDeclaration =
  defaultTextInput(
    "wheels_tire_aspect_ratio",
    "Высота профиля шины",
    "Модификации с колесами, где высота профиля шины равна переданному значению"
  );

export const wheels_tire_aspect_ratio_ge: FormItemObjDeclaration =
  defaultTextInput("wheels_tire_aspect_ratio-ge");

export const wheels_tire_aspect_ratio_le: FormItemObjDeclaration =
  defaultTextInput("wheels_tire_aspect_ratio-le");

export const wheels_tire_diameter: FormItemObjDeclaration = defaultTextInput(
  "wheels_tire_diameter",
  "Диаметр шины",
  "Модификации с колесами, где диаметр шины равен переданному значению"
);

export const wheels_tire_diameter_ge: FormItemObjDeclaration = defaultTextInput(
  "wheels_tire_diameter-ge"
);

export const wheels_tire_diameter_le: FormItemObjDeclaration = defaultTextInput(
  "wheels_tire_diameter-le"
);

export const wheels_rim_width: FormItemObjDeclaration = defaultTextInput(
  "wheels_rim_width",
  "Ширина диска",
  "Модификации с колесами, где ширина диска равна переданному значению"
);

export const wheels_rim_width_ge: FormItemObjDeclaration = defaultTextInput(
  "wheels_rim_width-ge",
  "Ширина диска от",
  "Модификации с колесами, где ширина диска больше или равна переданному значению"
);

export const wheels_rim_width_le: FormItemObjDeclaration = defaultTextInput(
  "wheels_rim_width-le",
  "Ширина диска до",
  "Модификации с колесами, где ширина диска меньше или равна переданному значению"
);

export const wheels_rim_diameter: FormItemObjDeclaration = defaultTextInput(
  "wheels_rim_diameter",
  "Диаметр диска",
  "Модификации с колесами, где диаметр диска равен переданному значению"
);

export const wheels_rim_diameter_ge: FormItemObjDeclaration = defaultTextInput(
  "wheels_rim_diameter-ge"
);

export const wheels_rim_diameter_le: FormItemObjDeclaration = defaultTextInput(
  "wheels_rim_diameter-le"
);

export const wheels_rim_offset: FormItemObjDeclaration = defaultTextInput(
  "wheels_rim_offset",
  "Вылет диска",
  "Модификации с колесами, где вылет диска равен переданному значению"
);

export const wheels_rim_offset_ge: FormItemObjDeclaration = defaultTextInput(
  "wheels_rim_offset-ge",
  "Вылет диска от",
  "Модификации с колесами, где вылет диска больше или равен переданному значению"
);

export const wheels_rim_offset_le: FormItemObjDeclaration = defaultTextInput(
  "wheels_rim_offset-le",
  "Вылет диска до",
  "Модификации с колесами, где вылет диска меньше или равен переданному значению"
);

export const hr_career_opportunity_block_sort = landingSortTextInput(
  "hr_career_opportunity_block_sort"
);
export const hr_company_info_block_sort = landingSortTextInput(
  "hr_company_info_block_sort"
);
export const hr_about_us_block_sort = landingSortTextInput(
  "hr_about_us_block_sort"
);
export const hr_about_block_sort = landingSortTextInput("hr_about_block_sort");
export const hr_vacancy_block_sort = landingSortTextInput(
  "hr_vacancy_block_sort"
);
export const automotive_product_block_sort = landingSortTextInput(
  "automotive_product_block_sort"
);
export const brand_and_certificate_block_sort = landingSortTextInput(
  "brand_and_certificate_block_sort"
);
export const video_presentation_block_sort = landingSortTextInput(
  "video_presentation_block_sort"
);
export const team_block_sort = landingSortTextInput("team_block_sort");
export const business_to_consumer_block_sort = landingSortTextInput(
  "business_to_consumer_block_sort"
);
export const hr_block_sort = landingSortTextInput("hr_block_sort");
export const geography_block_sort = landingSortTextInput(
  "geography_block_sort"
);
export const feedback_block_sort = landingSortTextInput("feedback_block_sort");
export const work_with_corporate_client_block_sort = landingSortTextInput(
  "work_with_corporate_client_block_sort"
);
export const wholesale_customer_block_sort = landingSortTextInput(
  "wholesale_customer_block_sort"
);
export const business_advantage_block_sort = landingSortTextInput(
  "business_advantage_block_sort"
);
export const analytic_block_sort = landingSortTextInput("analytic_block_sort");
export const partnership_block_sort = landingSortTextInput(
  "partnership_block_sort"
);
export const contact_block_sort = landingSortTextInput("contact_block_sort");
export const corporate_solution_and_product_block_sort = landingSortTextInput(
  "corporate_solution_and_product_block_sort"
);
export const business_offer_block_sort = landingSortTextInput(
  "business_offer_block_sort"
);
export const our_client_case_block_sort = landingSortTextInput(
  "our_client_case_block_sort"
);
export const cooperation_card_block_sort = landingSortTextInput(
  "cooperation_card_block_sort"
);
export const business_format_block_sort = landingSortTextInput(
  "business_format_block_sort"
);
export const support_block_sort = landingSortTextInput("support_block_sort");
export const income_calculator_block_sort = landingSortTextInput(
  "income_calculator_block_sort"
);
export const our_network_block_sort = landingSortTextInput(
  "our_network_block_sort"
);

export const ws_body = (
  label?: string,
  placeholder?: string
): FormItemObjDeclaration =>
  defaultTextInput("wsBody", label, undefined, placeholder, {
    disabled: true,
  });

export const ws_body_id = (
  label?: string,
  placeholder?: string
): FormItemObjDeclaration =>
  defaultTextInput("ws_body_id", label, undefined, placeholder, {
    disabled: true,
  });

export const token: FormItemObjDeclaration = defaultTextInput("token", "Токен");

export const likeDefaultTextInput = (
  model: string,
  label: string,
  note: string,
  placeholder: string
): FormItemObjDeclaration =>
  defaultTextInput(`${model}:like`, label, note, placeholder, {
    mask: { regex: getRegex("like") },
  });
