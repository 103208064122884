<template>
  <el-form
    :id="formId"
    :key="reRenderKey"
    ref="formRef"
    v-loading="loading"
    :="formAttrs"
    :class="[$style['form'], className]"
    :model="formModel"
    :rules="rules"
    :validate-on-rule-change="false"
    require-asterisk-position="right"
    @keyup="readonly ? null : onEnter"
    @validate="handleValidateEvent"
  >
    <template v-if="!isFlat">
      <template v-for="(rowItem, index) in layoutDeclaration?.rows">
        <form-view-tabs
          v-if="rowItem.tabs?.length"
          :key="index + '-tab'"
          ref="formTabsView"
          :row-item="rowItem"
        />
        <form-view-default
          v-else
          :key="index + '-default'"
          ref="formDefaultView"
          :row-index="index"
          :row-item="rowItem"
        />
      </template>
    </template>
    <form-view-flat v-if="isFlat" ref="formFlatView" />
  </el-form>
</template>
<script lang="ts" setup>
import {
  ComponentInternalInstance,
  computed,
  getCurrentInstance,
  provide,
  reactive,
  ref,
  toRefs,
  watch,
  withKeys,
} from "vue";
import { ElForm } from "element-plus";
import {
  FormItemAttrsObjDeclaration,
  FormItemObjDeclaration,
  GeneratorDeclaration,
  PublicFormTabsView,
} from "@/plugins/form-generator-json-v2/types";
import { useLink } from "vue-router";
import { getDefaultValue } from "@/plugins/form-generator-json-v2/core";
import FormViewTabs from "@/plugins/form-generator-json-v2/components/view/FormViewTabs.vue";
import FormViewDefault from "@/plugins/form-generator-json-v2/components/view/FormViewDefault.vue";
import FormViewFlat from "@/plugins/form-generator-json-v2/components/view/FormViewFlat.vue";
import { useFormItem } from "@/plugins/form-generator-json-v2/composeble/useFormItem";
import { useFormValidation } from "@/plugins/form-generator-json-v2/composeble/useFormValidation";
import { useLayout } from "@/plugins/form-generator-json-v2/composeble/useLayout";
import { hasParentsClass } from "@/helpers/DOMtools";
import { useFilterMode } from "@/plugins/form-generator-json-v2/composeble/useFilterMode";
import { useLoader } from "@/composables/hooks/useLoader";
import { useInvokeLifecycleHooks } from "@/plugins/form-generator-json-v2/composeble/useInvokeLifecycleHooks";
import useAdaptive from "@/composables/hooks/useAdaptive";
import { useRerenderForm } from "@/plugins/form-generator-json-v2/composeble/useRerenderForm";
import { ColumnType } from "@/model/working-sections/constants";

const { isMobile } = useAdaptive();

const props = withDefaults(
  defineProps<{
    declaration: GeneratorDeclaration;
    presetValues?: Record<string, any> | undefined | null;
    readonly?: boolean;
    isCopy?: boolean;
    isCodeEditable?: boolean;
    showServiceFields?: boolean;
  }>(),
  {
    presetValues: null,
    readonly: false,
    isCopy: false,
    isCodeEditable: false,
    showServiceFields: true,
  }
);
const {
  declaration,
  presetValues,
  readonly,
  isCopy,
  isCodeEditable,
  showServiceFields,
} = toRefs(props);

const { reRenderKey, reRenderForm } = useRerenderForm(declaration);

const formCtx = ref<ComponentInternalInstance | null>(getCurrentInstance());
const formModel = reactive<Record<string, unknown>>({});
const formId = ref(declaration.value.form.name || declaration.value.form);
const { loading, setLoading } = useLoader();
const formAttrs = reactive({
  "label-position": "top",
  ...declaration.value.form?.attrs,
});
const formRef = ref<InstanceType<typeof ElForm>>();
const formTabsView = ref<InstanceType<typeof ElForm> & PublicFormTabsView>();

const { layoutDeclaration, isFlat, setLayout } = useLayout(declaration);
const { rules, setValidationRules, removeValidationRules } =
  useFormValidation(declaration);

watch(
  () => presetValues.value,
  (value, oldValue) => {
    if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
      if (declaration.value.items) {
        declaration.value.items.forEach((item: FormItemObjDeclaration) => {
          if (
            item.element !== "button" ||
            (!item.isService && !item.isPayload)
          ) {
            if (
              item?.presetValue === null ||
              item?.presetValue === undefined ||
              item?.presetValue === ""
            ) {
              if (item.groupList && !item.isPayload) {
                item.groupList.forEach((groupItem) => {
                  formModel[groupItem.model] = getDefaultValue(
                    groupItem.element
                  );
                });
              } else {
                formModel[item.model] = getDefaultValue(item.element);
              }
            } else {
              formModel[item.model] = item?.presetValue;
            }
          }
          if (item.element.includes("select")) {
            item.attrs["collapse-tags"] = isMobile.value;
          }
        });
      }
      if (value && Object.keys(value).length) {
        for (const key in value) {
          formModel[key] = value[key];
        }
      }
    }
  },
  { immediate: true, deep: true }
);

useFilterMode(
  declaration,
  layoutDeclaration,
  formCtx,
  formRef,
  presetValues,
  formModel
);

const {
  getFormItem,
  addFormItem,
  getFormGroupItem,
  setFormItemWrapAttr,
  setPresetValue,
  setFormItemVisibility,
  setFormItemAttr,
  setFormGroupItemAttr,
  setFormGroupItemWrapAttr,
  setFormItemReRenderKey,
  removeFormGroup,
} = useFormItem(declaration);

const className = computed(() => {
  const result = [];
  isFlat.value && result.push("el-form--flat");
  readonly.value && result.push("el-form--readonly");
  return result;
});
watch(
  () => declaration.value.items,
  (items) => {
    items.forEach((item: FormItemObjDeclaration) => {
      let isVisible = item.isVisible ?? true;
      if (typeof isVisible == "function") {
        isVisible = isVisible(formModel);
      }
      let className: string = (item?.formItemAttrs?.class as string) || "";
      className = className.replaceAll("el-form-item--hidden", "").trim();
      if (!isVisible) {
        className += " el-form-item--hidden";
      }
      if (item?.attrs?.type !== ColumnType.hidden) {
        setFormItemWrapAttr(item.id.toString(), "class", className);
      }
    });
  },
  { immediate: true, deep: true }
);

const handleValidateEvent = formTabsView.value?.onValidateForm;
const onEnter = withKeys(
  (event: KeyboardEvent) => {
    const element = event.target as HTMLElement;
    const elementName = element.nodeName.toLowerCase();
    const isNotInput = elementName !== "input";
    const isExcludeInput =
      elementName == "input" &&
      ["checkbox", "radio"].includes((element as HTMLInputElement).type);
    const isSpecialInput = hasParentsClass(event, "el-select");
    if (isNotInput || isExcludeInput || isSpecialInput) {
      return;
    }

    const { onSubmit } = formCtx.value?.attrs as FormItemAttrsObjDeclaration;
    if (typeof onSubmit === "function" && formRef.value) {
      onSubmit(formRef.value.$parent, true);
    }
  },
  ["enter"]
);

const { invokeMountCallback } = useInvokeLifecycleHooks(declaration, formRef);

const logsLink = useLink({
  ...props,
  to: { name: "actions", query: presetValues.value?.log },
});
const publicFormContext = reactive({
  getFormItem,
  addFormItem,
  setFormItemAttr,
  setFormItemWrapAttr,
  getFormGroupItem,
  setFormGroupItemAttr,
  setFormGroupItemWrapAttr,
  setFormItemReRenderKey,
  removeFormGroup,
  setPresetValue,
  setFormItemVisibility,
  setValidationRules,
  removeValidationRules,
  reRenderForm,
  formRef,
  formTabsView,
  declaration,
  formModel,
  rules,
  onEnter,
  loading,
  readonly,
  setLoading,
  invokeMountCallback,
  logsLink,
  layoutDeclaration,
  setLayout,
  handleValidateEvent,
  isCopy,
  isCodeEditable,
  showServiceFields,
  presetValues,
  $emit: formCtx.value?.emit,
  activeTab: formTabsView.value?.activeTab,
  setActiveTab: formTabsView.value?.setActiveTab,
  toggleNextTab: formTabsView.value?.toggleNextTab,
  onValidateForm: formTabsView.value?.onValidateForm,
});
defineExpose(publicFormContext);
provide("publicFormContext", publicFormContext);
</script>

<style lang="scss" module>
.form {
  &:global.el-form--label-top .el-form-item__label {
    line-height: 17px;
    padding-bottom: 7px;
  }

  &:global.el-form--label-top .el-form-item--service .el-form-item__label {
    padding: 0;
  }

  &:global.el-form--flat,
  &:global.el-col--flat {
    &::after {
      display: block;
      clear: both;
      content: "";
    }

    .el-form-item:last-of-type {
      .el-collapse-item {
        margin-bottom: -22px;
      }
    }
  }

  &.data-form {
    & + .el-collapse {
      margin-top: 22px;
    }
  }

  :global {
    .is-justify-space-between .el-button {
      width: 100%;
    }

    .el-dialog--edit .mode-add,
    .el-dialog--create .mode-edit {
      display: none;
    }

    .el-col--bottom {
      position: absolute;
      bottom: 0;
    }

    .el-form--readonly {
      .el-form-item--submit {
        display: none;
      }

      .el-form-item--add {
        display: none;
      }
    }

    .el-form--novalid-icon {
      .el-icon.el-input__icon.el-input__validateIcon {
        display: none;
      }
    }

    .el-form-item__wrapper {
      display: flex;
      align-items: center;

      .el-icon--hint {
        margin-right: 15px;
      }
    }

    .el-form-item--hint {
      .el-icon--hint {
        width: auto;
        height: auto;
        line-height: 0;
        vertical-align: text-top;
        margin-left: 8px;

        svg {
          width: 1.2em;
          height: 1.2em;
        }
      }
    }

    .el-form-item--hidden {
      display: none !important;
    }

    .el-form-item--service {
      margin-bottom: 10px;

      .el-textarea {
        margin-top: 5px;
      }

      .el-input__wrapper {
        background-color: var(--el-disabled-bg-color);
      }
    }

    .el-form-item--service [readonly] {
      background-color: var(--el-disabled-bg-color);
      border-color: var(--el-border-color);
    }

    .system-fields-collapse {
      margin-top: -1 px;
    }

    .modal-subtitle {
      font-weight: 700;
      margin-bottom: 16px;
      color: var(--el-text-color-primary);
      line-height: 22px;
    }

    .el-form-item.hidden {
      display: none;
    }

    .el-col {
      .pc-title {
        @media (max-width: 1200px) {
          display: none;
        }
      }
    }
  }
}
</style>
