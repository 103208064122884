import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";
import {
  CustomListRequestOptions,
  GetParametersArgumentsDeclaration,
  IDefaultListResponse,
} from "@/api/core/types";
import { IServiceCentersResponse } from "@/api/points/nipple-api/service-centers/types";

const service = new Service(`${SERVICE_POINT_NAME}/service_centers`, {
  list: {},
  item: {},
});

export default service;
export const getServiceCenters = service.list.bind(service) as (
  parameters: GetParametersArgumentsDeclaration,
  options?: CustomListRequestOptions
) => Promise<IDefaultListResponse<IServiceCentersResponse[]>>;

export const getServiceCentersById = service.item.bind(service) as (
  id: number
) => Promise<IServiceCentersResponse>;
