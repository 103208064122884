<template>
  <el-dropdown
    v-if="!isMassEditing && hasActiveField"
    :hide-on-click="false"
    trigger="click"
    @command="clickMassiveMenuHandler"
  >
    <el-button :icon="MoreFilled" type="default" />
    <template #dropdown>
      <el-dropdown-menu
        :ref="(el: any) => dropdownMultipleInstances = el"
        class="el-dropdown-menu skimmed"
      >
        <el-dropdown-item
          :command="{ type: Command.ACTIVATE }"
          :disabled="!hasUpdateAccess"
          >Активировать
        </el-dropdown-item>
        <el-dropdown-item
          :command="{ type: Command.DEACTIVATE }"
          :disabled="!hasUpdateAccess"
          >Деактивировать
        </el-dropdown-item>
        <el-dropdown-item
          v-if="isWizard && isCreateIn"
          :command="{ type: Command.EXPORT }"
          :disabled="!hasShowAccess"
          >Экспорт
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { MoreFilled } from "@element-plus/icons-vue";
import useAccessRights from "@/composables/hooks/useAccessRights";
import { Command } from "@/components/working-sections/default/components/actions/constants";
import { isActive } from "@/helpers/сomparisonStaticValues";
import {
  BaseActionsProps,
  ICommand,
} from "@/components/working-sections/default/components/actions/types";

interface Props extends BaseActionsProps {
  clickMassiveMenuHandler: (command: ICommand) => Promise<void>;
}

const props = defineProps<Props>();

const dropdownMultipleInstances = ref<any>("");

const router = useRouter();

const { hasShowAccess, hasUpdateAccess } = useAccessRights(router);
const hasActiveField = props.createForm?.items.some(
  (item: Record<string, any>) => isActive(item.id)
);
</script>
