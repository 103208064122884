import { SERVICE_VERSION, USER } from "@/dictionary/header";
import { RouteRecordRaw } from "vue-router";

export const pageRoutesList: Array<RouteRecordRaw> = [
  {
    path: "/user",
    name: "user",
    component: (): Promise<typeof import("*.vue")> =>
      import(/* webpackChunkName: "User" */ "@/views/User.vue"),
    meta: {
      title: USER,
      hiddenRoute: true,
    },
  },
  {
    path: "/service_versions",
    name: "service_versions",
    component: (): Promise<typeof import("*.vue")> =>
      import(/* webpackChunkName: "Versions" */ "@/views/Versions.vue"),
    meta: {
      title: SERVICE_VERSION,
      hiddenRoute: true,
    },
  },
];
