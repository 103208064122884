export const enum UploadType {
  avatar = "avatar",
  logoId = "logo_id",
  logo = "logo",
  image = "image",
  video = "video",
  file = "file",
  additionalImage = "additional_image",
}

export const FILE_TOO_LARGE_STATUS_KEY = 413;
export const DEFAULT_MAX_FILE_SIZE = 512000;
