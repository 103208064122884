<template>
  <el-popover
    ref="popoverRef"
    placement="top"
    popper-class="el-tiptap-popper"
    trigger="click"
  >
    <div class="el-tiptap-popper__menu">
      <div
        v-for="display in displayCollection"
        :key="display"
        :class="{
          'el-tiptap-popper__menu__item--active': display === currDisplay,
        }"
        class="el-tiptap-popper__menu__item"
        @click="updateAttrs!({ display })"
        @mousedown="hidePopover"
      >
        <span>{{
          t(`editor.extensions.Image.buttons.display.${display}`)
        }}</span>
      </div>
    </div>

    <template #reference>
      <span>
        <command-button
          :enable-tooltip="enableTooltip"
          :tooltip="t('editor.extensions.Image.buttons.display.tooltip')"
          icon="image-align"
        />
      </span>
    </template>
  </el-popover>
</template>

<script lang="ts" setup>
import { computed, inject, ref } from "vue";
import { ImageDisplay } from "@/plugins/tiptap-editor/utils/image";
import CommandButton from "../CommandButton.vue";
import { Node as ProseMirrorNode } from "@tiptap/pm/model";
import { ElPopover } from "element-plus";

interface Props {
  node: ProseMirrorNode;
  updateAttrs: (attributes: Record<string, any>) => void;
}

const props = defineProps<Props>();

const displayCollection = ref([
  ImageDisplay.INLINE,
  ImageDisplay.BREAK_TEXT,
  ImageDisplay.FLOAT_LEFT,
  ImageDisplay.FLOAT_RIGHT,
]);
const popoverRef = ref<typeof ElPopover>();

const t = inject<any>("t");
const enableTooltip = inject("enableTooltip", true);

const currDisplay = computed(() => props.node?.attrs.display);

const hidePopover = () => {
  popoverRef.value?.hide();
};
</script>
