import {
  FormItemObjDeclaration,
  GeneratorDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import { reactive, Ref } from "vue";
import { randomNumber } from "@/helpers/math";
import { ColumnType } from "@/model/working-sections/constants";

export const useFormItem = (declaration: Ref<GeneratorDeclaration>) => {
  const getFormItem = (id: string) => {
    const formItem = reactive(declaration.value.items).find(
      (item: FormItemObjDeclaration) => item.id === id
    );
    return formItem || null;
  };

  const getFormGroupItem = (groupId: string, elementId: string) => {
    const formGroup = reactive(declaration.value.items).find(
      (item: FormItemObjDeclaration) => item.id === groupId
    );
    if (!formGroup || !formGroup.groupList) return null;
    const formGroupItem = formGroup.groupList.find(
      (item) => item.id === elementId
    );
    return formGroupItem || null;
  };

  const setFormItemWrapAttr = (id: string, attr: string, value: any): void => {
    const formItem = getFormItem(id);
    if (formItem && !formItem.formItemAttrs) {
      formItem.formItemAttrs = {};
    }
    if (formItem && formItem.formItemAttrs) {
      formItem.formItemAttrs[attr] = value;
    }
  };

  const setFormGroupItemWrapAttr = ({
    groupId,
    elemId,
    attr,
    value,
  }: {
    groupId: string;
    elemId: string;
    attr: string;
    value: any;
  }): void => {
    const formItem = getFormGroupItem(groupId, elemId);

    if (formItem && !formItem.formItemAttrs) {
      formItem.formItemAttrs = {};
    }

    if (formItem && formItem.formItemAttrs) {
      formItem.formItemAttrs[attr] = value;
    }
  };

  const setPresetValue = (id: string, value: any) => {
    const formItem = getFormItem(id);
    if (formItem) {
      formItem.presetValue = value;
    }
  };

  const setFormItemVisibility = (id: string, value: boolean, altType = "") => {
    setFormItemWrapAttr(id, "class", value ? altType : "el-form-item--hidden");
    setFormItemAttr(id, "type", value ? altType : ColumnType.hidden);
  };

  const setFormItemAttr = (id: string, attr: string, value: any) => {
    const formItem = getFormItem(id);
    if (formItem && !formItem.attrs) {
      formItem.attrs = {};
    }
    if (formItem && formItem.attrs) {
      formItem.attrs[attr] = value;
    }
    return formItem;
  };

  const setFormGroupItemAttr = (
    groupId: string,
    elementId: string,
    attr: string,
    value: Record<string, unknown>
  ) => {
    const formItem = getFormGroupItem(groupId, elementId);
    if (formItem && formItem.attrs) {
      formItem.attrs[attr] = value;
    }
    return formItem;
  };

  const addFormItem = (
    componentDeclaration: FormItemObjDeclaration | FormItemObjDeclaration[],
    groupId?: string,
    replace = true,
    useRerender = true
  ) => {
    let target = declaration.value.items as
      | FormItemObjDeclaration[]
      | undefined;

    if (groupId) {
      const group = getFormItem(groupId);

      if (group?.groupList && group?.groupList.length) {
        target = group?.groupList;
      }
    }

    if (!target) {
      return;
    }

    const pushItemWithReplace = (
      item: FormItemObjDeclaration,
      target: FormItemObjDeclaration[]
    ) => {
      const elementIndex = target.findIndex(
        (targetItem) => targetItem.id === item.id
      );
      const isElemExists = replace && elementIndex !== -1;

      if (isElemExists) {
        target[elementIndex] = item;
      } else {
        target.push(item);
      }
    };

    if (Array.isArray(componentDeclaration)) {
      for (let i = 0; i < componentDeclaration.length; i++) {
        pushItemWithReplace(
          componentDeclaration[i] as FormItemObjDeclaration,
          target
        );
      }
    } else {
      pushItemWithReplace(componentDeclaration, target);
    }

    if (useRerender && declaration.value?.reRender) {
      declaration.value?.reRender();
    }
  };

  const removeFormGroup = (id: string) => {
    declaration.value.items = declaration.value.items.filter(
      (item: FormItemObjDeclaration) => item.id !== id
    );
  };

  const setFormItemReRenderKey = ({
    elemId = "",
    groupId = null,
  }: {
    elemId: string;
    groupId?: string | null;
  }) => {
    const formItem = groupId
      ? getFormGroupItem(groupId, elemId)
      : getFormItem(elemId);

    if (formItem) {
      formItem.key = randomNumber();
    }
  };

  return {
    addFormItem,
    getFormItem,
    getFormGroupItem,
    setFormItemWrapAttr,
    setPresetValue,
    setFormItemVisibility,
    setFormItemAttr,
    setFormGroupItemAttr,
    setFormGroupItemWrapAttr,
    removeFormGroup,
    setFormItemReRenderKey,
  };
};
