<template>
  <el-dropdown
    :disabled="isCodeViewMode"
    placement="bottom"
    trigger="click"
    @command="(lineHeight: any) => editor.commands.setLineHeight(lineHeight)"
  >
    <command-button
      :enable-tooltip="enableTooltip"
      :readonly="isCodeViewMode"
      :tooltip="t('editor.extensions.LineHeight.tooltip')"
      icon="text-height"
    />
    <template #dropdown>
      <el-dropdown-menu class="el-tiptap-dropdown-menu">
        <el-dropdown-item
          v-for="lineHeight in lineHeights"
          :key="lineHeight"
          :class="{
            'el-tiptap-dropdown-menu__item--active': isLineHeight(lineHeight),
          }"
          :command="lineHeight"
          class="el-tiptap-dropdown-menu__item"
        >
          <span>{{ lineHeight }}</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script lang="ts" setup>
import { computed, inject } from "vue";
import { Editor } from "@tiptap/vue-3";
import { ElDropdown, ElDropdownItem, ElDropdownMenu } from "element-plus";
import { isLineHeightActive } from "@/plugins/tiptap-editor/utils/line-height";
import CommandButton from "./CommandButton.vue";

interface Props {
  editor: Editor;
}

const props = defineProps<Props>();

const t: any = inject("t");
const enableTooltip = inject("enableTooltip", true);
const isCodeViewMode = inject("isCodeViewMode", false);

const lineHeights = computed(() => {
  const lineHeightOptions = props.editor.extensionManager.extensions.find(
    (e) => e.name === "lineHeight"
  )?.options;
  return lineHeightOptions.lineHeights;
});

const isLineHeight = (lineHeight: string) => {
  return isLineHeightActive(props.editor.state, lineHeight);
};
</script>
