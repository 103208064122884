import { createApp } from "vue";
import ElementPlus from "element-plus";
import TiptapEditorPlugin from "@/plugins/tiptap-editor";
import JoditVue from "@/components/base/BaseJoditEditor/core";
import * as Sentry from "@sentry/vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { sentryConfig } from "./sentry";
import ru from "element-plus/dist/locale/ru.mjs";
import "dayjs/locale/ru";
import { customize } from "./helpers/localeCustomizer";
import { debounce } from "./helpers/functions";

declare global {
  interface Window {
    GLOBAL_ENV: {
      is_prod: "0" | "1";
    };
  }
}

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, {
  locale: customize(ru),
});
app.use(TiptapEditorPlugin);
app.use(JoditVue);
app.mount("#app");

if (sentryConfig && Object.keys(sentryConfig).length) {
  Sentry.init({ app, ...sentryConfig });
}

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback: ResizeObserverCallback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
