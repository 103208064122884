export const isActive = (value: string): boolean => {
  return ["active", "is_active"].includes(value);
};

export const isContacts = (value: string): boolean => {
  return ["phone", "email"].includes(value);
};

export const isButtonWithoutInput = (value: string): boolean => {
  return value.includes("button") && !value.includes("input");
};
