import { SelectOptionItemDeclaration } from "@/plugins/form-generator-json-v2/types";

export const attributesList: SelectOptionItemDeclaration[] = [
  { value: "wheels", label: "Атрибуты - колесо", disabled: false },
  { value: "2", label: "Атрибуты - аккумуляторы", disabled: true },
  { value: "3", label: "Атрибуты - масла моторные", disabled: true },
  { value: "4", label: "Атрибуты - масла трансмиссионные", disabled: true },
  { value: "5", label: "Атрибуты - щетки стеклоочистителя", disabled: true },
  { value: "6", label: "Атрибуты - датчики давления", disabled: true },
];
