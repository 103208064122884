import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import { Extension } from "@tiptap/core";
import CommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CommandButton.vue";

const SelectAll = Extension.create({
  name: "selectAll",

  addOptions() {
    return {
      ...this.parent?.(),
      button({ editor, t, isCodeViewMode }: CommandButtonArgs) {
        return {
          component: CommandButton,
          componentProps: {
            command: () => {
              editor.commands.selectAll();
            },
            icon: "select-all",
            tooltip: t("editor.extensions.SelectAll.tooltip"),
            isCodeViewMode,
          },
        };
      },
    };
  },
});

export default SelectAll;
