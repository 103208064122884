import { readonly, ref } from "vue";

export default (initValue?: boolean) => {
  const isToggled = ref(initValue ?? false);

  const setToggleValue = (value: boolean): void => {
    isToggled.value = value;
  };

  const toggleValue = (): void => {
    isToggled.value = !isToggled.value;
  };

  return {
    isToggled: readonly(isToggled),
    setToggleValue,
    toggleValue,
  };
};
