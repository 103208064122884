<template>
  <el-dropdown
    ref="dropdown"
    :hide-on-click="false"
    trigger="click"
    @command="handler"
  >
    <el-button :icon="MoreFilled" size="small" />
    <template #dropdown>
      <el-dropdown-menu
        :ref="(el: any) => dropdownInstances[scope.row.id] = el"
        class="el-dropdown-menu skimmed"
      >
        <el-dropdown-item
          :command="{
            type: Command.EDIT,
            id: scope.row.id,
          }"
          :disabled="!hasUpdateAccess"
          >Редактировать
        </el-dropdown-item>
        <el-dropdown-item
          v-if="isCopyable"
          :command="{ type: Command.COPY, id: scope.row.id }"
          :disabled="!hasStoreAccess"
          >Создать копию
        </el-dropdown-item>
        <el-dropdown-item
          v-if="isWizard && isModification"
          :command="{
            type: Command.COPYWITHDEPENDENCIES,
            id: scope.row.id,
          }"
          :disabled="!hasCopyAccess"
        >
          Создать копию с атрибутами
        </el-dropdown-item>
        <el-dropdown-item
          v-if="isWizard && isCreateIn"
          :command="{ type: Command.EXPORT, id: scope.row.id }"
          :disabled="!exportAccess"
        >
          Экспорт
        </el-dropdown-item>
        <el-dropdown-item
          v-if="
            user.data.profile.admin &&
            accessService === 'auth' &&
            accessObject === 'users'
          "
          :command="{
            type: Command.IMPERSONATE_USER,
            data: scope.row,
          }"
        >
          Войти на сайт
        </el-dropdown-item>
        <el-dropdown-item
          v-if="isToggleable"
          :command="{
            type: Command.TOGGLE_ACTIVATION,
            id: scope.row.id,
            active: scope.row.active || scope.row.is_active,
            data: scope.row,
          }"
          :disabled="!hasUpdateAccess"
          >{{
            scope.row.active || scope.row.is_active
              ? "Деактивировать"
              : "Активировать"
          }}
        </el-dropdown-item>
        <el-dropdown-item
          v-if="isRestorable"
          :command="{
            type: Command.RESTORE,
            id: scope.row.id,
          }"
          :disabled="!hasRestoreAccess"
        >
          Восстановить
        </el-dropdown-item>
        <el-dropdown-item
          v-if="isDeletable"
          :command="{
            type: Command.OPEN_DELETE_MODAL,
            data: scope.row,
          }"
          :disabled="!hasDestroyAccess"
          class="el-dropdown-menu__item--danger"
          @click="deleteHandler(scope.row)"
        >
          Удалить
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { MoreFilled } from "@element-plus/icons-vue";
import { RouteAccess } from "@/router/types";
import { user } from "@/model/user";
import useAccessRights from "@/composables/hooks/useAccessRights";
import { Command } from "@/components/working-sections/default/components/actions/constants";
import {
  BaseActionsProps,
  ICommand,
} from "@/components/working-sections/default/components/actions/types";
import { ServiceName } from "@/constants";

interface Props extends BaseActionsProps {
  scope: Record<string, any>;
  exportAccess: boolean;
  isModification: boolean;
  clickMenuHandler: (command: ICommand) => Promise<void>;
}

const props = defineProps<Props>();

const emit = defineEmits(["openDeleteModal"]);

const router = useRouter();

const dropdown = ref();
const dropdownInstances = ref<any[]>([]);

const { service: accessService, object: accessObject } =
  (router.currentRoute.value?.meta?.access as RouteAccess) || {};
const {
  hasUpdateAccess,
  hasStoreAccess,
  hasDestroyAccess,
  hasCopyAccess,
  hasRestoreAccess,
} = useAccessRights(router);
const isCopyable =
  accessService !== ServiceName.pim &&
  [
    "brands",
    "models",
    "generations",
    "modifications",
    "bodies",
    "wheel_pairs",
    "supplier_conditions",
    "programs",
    "commercial_terms_documents",
  ].includes(accessObject);

const isToggleable =
  !props.scope.row.deleted_at &&
  (props.scope.row.active !== undefined ||
    props.scope.row.is_active !== undefined);

const isRestorable =
  accessService === ServiceName.pim &&
  accessObject === "products" &&
  props.scope.row.deleted_at;

const isDeletable =
  hasDestroyAccess &&
  !props.scope.row.admin &&
  !props.scope.row.deleted_at &&
  !props.hideDelete;

const handler = async (command: ICommand) => {
  await props.clickMenuHandler(command);
  command.type !== Command.REMOVE && dropdown.value.handleClose();
};

const deleteHandler = (data: Record<string, any>) => {
  emit("openDeleteModal", [data.id]);
};
</script>
