import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const extra = {
  list: {
    "fields[models]": "id,name",
    "fields[brands]": "id,name",
    include: "model.brand",
    sort: "name",
  },
  item: { include: "model.brand" },
};
const service = new Service(`${SERVICE_POINT_NAME}/generations`, extra);

export default service;
export const getGenerations = service.list.bind(service);
export const getGenerationById = service.item.bind(service);
export const createGeneration = service.create.bind(service);
export const updateGeneration = service.update.bind(service);
export const deleteGeneration = service.delete.bind(service);

export const serviceWizard = new Service(`${SERVICE_POINT_NAME}/generations`, {
  ...extra,
  list: {
    ...extra.list,
    include_count: "all_attributes,current_attributes,wrong_bindings",
  },
});
