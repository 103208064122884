import { computed, onUnmounted, ref, Ref, watch } from "vue";
import { useRoute } from "vue-router";
import { WorkingSectionsDefaultInterface } from "@/model/working-sections/types";
import { FormItemObjDeclaration } from "@/plugins/form-generator-json-v2/types";
import { setStretchColumn } from "@/helpers/setStretchColumn";
import { isButtonWithoutInput } from "@/helpers/сomparisonStaticValues";
import { defaultSectionSettings } from "@/plugins/form-generator-json-v2/core/settings";
import { ColumnType } from "@/model/working-sections/constants";

const ignoreFields: Record<string, Array<string>> = {
  "/catalog-vehicles/bodies": ["bitrix_id", "prefix_code", "sort"],
  "/catalog-vehicles/wheels": ["bitrix_id", "tire_section_width"],
  "/catalog-vehicles/brands": ["prefix_code", "sort"],
  "/catalog-vehicles/models": ["name_en", "type", "sort"],
  "/catalog-vehicles/generations": ["sort"],
  "/catalog-vehicles/modifications": ["bitrix_id", "prefix_code", "sort"],
  "/catalog-vehicles/regions": ["bitrix_id", "sort"],
  "/catalog-vehicles/wizard": ["prefix_code", "sort"],
  "/catalog-vehicles/wizard/brand": [
    "brand_id",
    "prefix_code",
    "sort",
    "type",
    "name_en",
    "code",
  ],
  "/catalog-vehicles/wizard/model": ["model_id", "sort"],
  "/catalog-vehicles/wizard/generation": [
    "prefix_code",
    "sort",
    "bitrix_id",
    "generation_id",
  ],
  "/access-control/users": ["password", "tokens"],
  "/access-control/users-staff": ["password", "tokens"],
};

const displayFields: Record<string, Record<string, string>> = {
  "/catalog-vehicles/regions": {
    countries: "letter_code",
  },
};

const COLUMN_PADDING = 16;

interface ColsUserOptions {
  name: string;
  width: number;
}

export default (
  section: WorkingSectionsDefaultInterface
): Ref<WorkingSectionsDefaultInterface> => {
  const route = useRoute();
  const path = computed(() => route.path.replaceAll(/\/[0-9]+/g, ""));
  const sectionWithSettings = ref<WorkingSectionsDefaultInterface>(section);
  const userOptions = ref<Record<string, any>>(
    JSON.parse(localStorage.getItem("user_options") ?? "{}")
  );
  const colsWidth = userOptions.value[path.value]?.colsWidth || [];
  sectionWithSettings.value.editableCols = {};

  const updateUserOptions = (event: any) => {
    userOptions.value = event.detail.userOptions;
  };

  const getOptimalColumnWidth = (
    sectionColumn: Record<string, any> | undefined,
    column: FormItemObjDeclaration
  ) => {
    let width = sectionColumn?.width || 200;

    switch (column.element) {
      case "switch":
        width = 80;
        break;
      case "async-dependent-entity-select":
      case "multiple-select":
        width = 400;
        break;
    }

    return width + COLUMN_PADDING * 2;
  };

  const getFormCols = () => {
    let cols: Array<FormItemObjDeclaration> = [];

    if (section.forms.table) {
      cols = section.forms.table.items;
    } else {
      const formItems = [
        ...section.forms.create.items,
        ...(section.forms.service?.items || []),
      ];
      formItems.forEach((sectionItem) => {
        if (sectionItem.groupList?.length) {
          sectionItem.groupList.forEach((item) => cols.push(item));
        } else {
          cols.push(sectionItem);
        }
      });
    }

    return cols;
  };

  const currentColsWidth = (
    colsUserOptions: Array<ColsUserOptions>,
    defaultWidth: number,
    key: string | number
  ): number => {
    if (key === "admin") {
      return 80;
    }

    const col = colsUserOptions.find((item) => item.name === key);

    return col ? col.width : defaultWidth;
  };

  const filterCols = (cols: Array<FormItemObjDeclaration>) => {
    return cols.filter((col) => {
      const key = col.include ? col.include : col.id;
      return !(
        ignoreFields[path.value]?.includes(key.toString()) ||
        isButtonWithoutInput(col.element)
      );
    });
  };

  const configureColumnSettings = (
    col: FormItemObjDeclaration,
    sectionWithSettings: Ref<WorkingSectionsDefaultInterface>
  ) => {
    const key = col.include ? col.include : col.id;
    let currentColumn = sectionWithSettings.value.cols[key];
    const isColUnchecked =
      defaultUncheckableCols.includes(col.model) &&
      !!currentColumn?.settings &&
      columnsQty >= 5;

    currentColumn = {
      ...(section.cols[key] || {}),
      width:
        currentColsWidth(colsWidth, section.cols[key]?.width ?? 0, key) || 150,
      settings: {
        ...(currentColumn?.settings || {}),
        visible: !isColUnchecked,
      },
    };

    if (col.element.includes("select")) {
      if (col.include) {
        currentColumn.isRelationship = true;
      }
      if (col.selectOptions?.length) {
        currentColumn.selectOptions = col.selectOptions;
      }
    } else if (col.element.includes("switch")) {
      currentColumn.type = ColumnType.boolean;
    } else if (key === "logo") {
      currentColumn.type = ColumnType.picture;
      currentColumn.width = 82;
      currentColumn.resize = false;
      currentColumn.isRelationship = true;
    }

    if (displayFields[path.value]) {
      currentColumn.displayFields = displayFields[path.value];
    }

    sectionWithSettings.value.cols[key] = {
      ...sectionWithSettings.value.cols[key],
      ...currentColumn,
    };
  };

  const setupEditableCols = (
    cols: Array<FormItemObjDeclaration>,
    sectionWithSettings: Ref<WorkingSectionsDefaultInterface>
  ) => {
    cols.forEach((col) => {
      const key = col.include ? col.include : col.id;
      (sectionWithSettings.value.editableCols as Record<string, any>)[key] = {
        ...sectionWithSettings.value.cols[key],
        width: getOptimalColumnWidth(section.cols[key], col),
      };
    });
  };

  const handleUserOptionsChange = (
    newSection: WorkingSectionsDefaultInterface,
    newUserOptions: Record<string, any>
  ) => {
    if (!Object.keys(newUserOptions).length || path.value.includes("/wizard"))
      return;

    for (const col in newSection.cols) {
      if (newUserOptions[path.value]?.cols?.[col]) {
        const section = sectionWithSettings.value.cols[col];
        if (section) {
          section.settings = newUserOptions[path.value].cols[col];
          sectionWithSettings.value.cols[col] = section;
        }
      }
    }

    setStretchColumn(
      sectionWithSettings.value.cols,
      route.name === "comparisons",
      route.params.category !== undefined &&
        route.params.category !== "body-migrations",
      path.value
    );
  };

  const cols = filterCols(getFormCols());
  const DEFAULT_COL_QTY = 1;
  const columnsQty = cols.length + DEFAULT_COL_QTY;
  const defaultUncheckableCols = ["created_at", "updated_at"];

  cols.forEach((col) => configureColumnSettings(col, sectionWithSettings));
  setupEditableCols(cols, sectionWithSettings);

  watch(
    [() => section, userOptions],
    ([newSection, newUserOptions]) => {
      handleUserOptionsChange(newSection, newUserOptions);
    },
    { immediate: true }
  );

  sectionWithSettings.value.settings =
    section.settings || defaultSectionSettings;

  window.addEventListener("user-options-changed", updateUserOptions);
  onUnmounted(() =>
    window.removeEventListener("user-options-changed", updateUserOptions)
  );

  return sectionWithSettings;
};
