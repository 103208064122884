import { SectionSettings } from "@/plugins/form-generator-json-v2/types";

export const defaultSectionSettings: SectionSettings = {
  hideCreateButton: false,
  hideDelete: false,
  hideHeader: false,
  hideBurgerMenu: false,
  hideModalActions: false,
  hideServiceFields: false,
  showWSNotification: false,
  showDateColumns: true,
  dateColumnsSortingEnabled: false,
};

export const readonlySectionSettings: SectionSettings = {
  ...defaultSectionSettings,
  hideCreateButton: true,
  hideDelete: true,
  hideBurgerMenu: true,
  hideModalActions: true,
};
