import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/store_brands`);

export default service;
export const getStoreBrands = service.list.bind(service);
export const getStoreBrandById = service.item.bind(service);
export const createStoreBrand = service.create.bind(service);
export const updateStoreBrand = service.update.bind(service);
export const deleteStoreBrand = service.delete.bind(service);
