import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import TiptapUnderline, { UnderlineOptions } from "@tiptap/extension-underline";
import CommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CommandButton.vue";

const Underline = TiptapUnderline.extend<
  UnderlineOptions & { bubble?: boolean }
>({
  addOptions() {
    return {
      ...this.parent?.(),
      button({ editor, t, isCodeViewMode }: CommandButtonArgs) {
        return {
          component: CommandButton,
          componentProps: {
            command: () => {
              editor.commands.toggleUnderline();
            },
            isActive: editor.isActive("underline"),
            icon: "underline",
            tooltip: t("editor.extensions.Underline.tooltip"),
            isCodeViewMode,
          },
        };
      },
    };
  },
});

export default Underline;
