<template>
  <!--  <section
		v-loading.fullscreen.lock="!layoutComponent"
		:element-loading-spinner="svg"
		element-loading-background="rgba(0, 0, 0, 0.8)"
		element-loading-svg-view-box="-10, -10, 50, 50"
		element-loading-text="Загрузка..."
	/>-->
  <router-view v-slot="{ Component }">
    <transition
      :name="(route?.meta?.transition as string) || 'fade'"
      mode="in-out"
    >
      <component :is="Component" />
    </transition>
  </router-view>
  <el-backtop />
  <debug-bar v-if="!isMobile" />
</template>

<script lang="ts" setup>
import DebugBar from "@/components/debugbar/DebugBar.vue";
import { useRoute } from "vue-router";
import useAdaptive from "@/composables/hooks/useAdaptive";

const { isMobile } = useAdaptive();

const route = useRoute();
const windowExtended = window.GLOBAL_ENV;
const faviconAppleTouchIcon = document.getElementById(
  "favicon-apple-touch-icon"
) as HTMLDivElement;
const favicon32 = document.getElementById("favicon-32x32") as HTMLDivElement;
const favicon16 = document.getElementById("favicon-16x16") as HTMLDivElement;

if (windowExtended.is_prod === "1") {
  faviconAppleTouchIcon.setAttribute(
    "href",
    `${process.env.BASE_URL}img/favicons/productions/apple-touch-icon.png`
  );
  favicon32.setAttribute(
    "href",
    `${process.env.BASE_URL}img/favicons/productions/favicon-32x32.png`
  );
  favicon16.setAttribute(
    "href",
    `${process.env.BASE_URL}img/favicons/productions/favicon-16x16.png`
  );
} else {
  faviconAppleTouchIcon.setAttribute(
    "href",
    `${process.env.BASE_URL}img/favicons/development/apple-touch-icon.png`
  );
  favicon32.setAttribute(
    "href",
    `${process.env.BASE_URL}img/favicons/development/favicon-32x32.png`
  );
  favicon16.setAttribute(
    "href",
    `${process.env.BASE_URL}img/favicons/development/favicon-16x16.png`
  );
}
</script>

<style lang="scss">
@import "~element-plus/dist/index.css";
@import "style/colors.css";
@import "style/fonts.css";
@import "~normalize.css";

:root {
  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
    --debugbar-line-height: 40px;
  }
}

html {
  font-family: "ALSHauss,Arial,sans-serif;", ALSHauss, "PingFang SC",
    "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

html,
body {
  min-width: 300px;
}

html,
body,
#app {
  width: max(100%, 100vw);
  height: max(100%, 100vh);
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}

.el-header {
  height: 88px;
}

.custom-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.custom-scroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 100px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 1px solid transparent;
  background-clip: content-box;
  background-color: var(--el-color-primary);
}

.el-scrollbar {
  --el-scrollbar-opacity: 1;
  --el-scrollbar-bg-color: var(--el-color-primary);
  --el-scrollbar-hover-opacity: 1;
  --el-scrollbar-hover-bg-color: var(--el-color-primary);

  &__bar.is-vertical {
    width: 3px;
  }
}

.el-autocomplete {
  &__link {
    text-decoration: none;
    color: var(--el-text-color-primary);
    display: block;
  }

  &-suggestion li {
    color: var(--el-text-color-primary);

    &:hover {
      background-color: var(--el-color-primary-light-9);
    }
  }
}

.el-tag {
  border: none;
  --el-tag-text-color: var(--el-color-primary);
  --el-tag-bg-color: var(--el-color-primary-light-9);
}

.el-notification__content {
  text-align: left;
  white-space: pre-wrap;
}

.el-popper {
  @media (max-width: 640px) {
    max-width: calc(100vw - 6px);
  }

  .el-popover--hint {
    max-width: 700px;
    word-break: normal;
  }
}

.el-popover--hint h4 {
  margin-top: 0;
}

.el-button--primary.is-plain {
  --el-button-border-color: var(--el-color-success);
}

.el-button--danger.is-plain {
  --el-button-border-color: var(--el-color-danger);
}

.common-layout {
  @media (max-width: 640px) {
    height: calc(100vh - 52px);
  }

  .main {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background-color: var(--el-color-white);
    border-radius: 8px;
    position: relative;

    .el-breadcrumb {
      margin-bottom: 16px;
    }
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    float: left;
    background-color: var(--el-color-white);
    border-radius: 8px;
    margin-right: 24px;
    padding: 25px;
    position: relative;
  }
}

.el-form-item__text {
  color: var(--el-color-info);
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}

.el-dropdown-menu {
  &.skimmed {
    padding: 0;

    .el-dropdown-menu__item {
      line-height: 22px;
      padding: 5px 12px;

      &:hover {
        color: var(--el-color-primary);
      }
    }
  }

  &--right > * {
    justify-content: end;
  }

  &__item--danger {
    color: var(--el-color-danger);

    &:not(.is-disabled):hover {
      color: #f79694;
    }
  }

  &__item {
    a {
      text-decoration: none;
      color: var(--el-text-color-regular);
    }

    &:hover a {
      color: var(--el-dropdown-menuItem-hover-color);
    }
  }
}

.el-collapse-item__header > div {
  flex: 1 1 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.link {
  color: var(--el-text-color-primary);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--el-color-primary);
  }
}

$marginSmall: 16px;
$marginMedium: 24px;
$marginLarge: 36px;

.el-dialog {
  --el-dialog-padding-primary: 20px;
  --el-dialog-width: 95%;
  margin-top: $marginSmall;
  max-height: calc(100vh - $marginSmall * 2);
  padding: 0;

  @media (min-width: 576px) {
    --el-dialog-width: 85%;
  }

  @media (min-width: 768px) {
    --el-dialog-width: 75%;
  }

  @media (min-width: 992px) {
    --el-dialog-width: 65%;
    margin-top: $marginMedium;
    max-height: calc(100vh - $marginMedium * 2);
  }

  @media (min-width: 1200px) {
    --el-dialog-width: 55%;
    margin-top: $marginLarge;
    max-height: calc(100vh - $marginLarge * 2);
  }

  @media (max-width: 640px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: auto;
  }

  .el-dialog__body {
    max-height: calc(100vh - 185px);
    overflow-y: auto;
    padding: calc(var(--el-dialog-padding-primary) + 10px)
      var(--el-dialog-padding-primary);
    padding-bottom: 0;
    scrollbar-width: thin;
    scrollbar-color: var(--el-text-color-disabled) var(--el-color-white);

    @media (min-width: 992px) {
      max-height: calc(100vh - 200px);
    }

    @media (min-width: 1200px) {
      max-height: calc(100vh - 220px);
    }

    @media (max-width: 640px) {
      height: calc(100vh - 146px);
      max-height: calc(100vh - 146px);
      overflow: auto;
      padding: 24px 16px 100px !important;
    }

    & > form {
      @media (max-width: 640px) {
        height: auto;
      }
    }

    ::-webkit-scrollbar {
      width: 3px;
    }

    .el-form .el-row {
      position: static;

      @media (max-width: 640px) {
        max-width: 100%;
        margin: 0 !important;
      }
    }

    .el-form .el-tabs .el-tabs__content {
      position: static;
    }
  }

  .el-dialog__body::-webkit-scrollbar {
    width: 10px;
  }

  .el-dialog__body::-webkit-scrollbar-track {
    background: var(--el-color-white);
  }

  .el-dialog__body::-webkit-scrollbar-thumb {
    background-color: var(--el-text-color-disabled);
    border-radius: 4px;
    border: 3px solid var(--el-color-white);

    &:hover {
      background-color: var(--el-text-color-secondary);
    }
  }

  .el-dialog__header,
  .el-dialog__footer {
    box-shadow: var(--el-box-shadow-light);
  }

  .el-dialog__header {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    position: relative;
    z-index: 1;
    margin-right: 0;
    padding: var(--el-dialog-padding-primary);
    padding-bottom: calc(var(--el-dialog-padding-primary) / 2);

    @media (max-width: 640px) {
      padding-right: 40px;
    }
  }

  .el-dialog__headerbtn {
    top: 6px;
    width: 54px;
    height: 54px;
  }

  .el-dialog__footer {
    position: relative;
    height: 88px;
    background-color: var(--el-color-white);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: var(--el-dialog-padding-primary);

    .el-button:not(:first-of-type) {
      margin-left: 15px;
    }

    @media (max-width: 640px) {
      width: 100%;
      position: absolute;
      bottom: 0;
    }
  }
}

.el-button.is-plain {
  --el-button-active-text-color: var(--el-color-primary-dark-2);
  --el-button-active-border-color: var(--el-color-primary-dark-2);
}

.tooltip-default {
  font-size: 12px;
  line-height: 20px;
  padding: 6px 12px;
  white-space: pre-wrap;
}

.el-button {
  height: 40px;
  font-weight: 400;
}

.el-button--small {
  --el-button-size: 28px;
  height: var(--el-button-size);
  padding: 9px 15px;
}

.el-input {
  --el-input-height: 40px;
}

.el-select {
  --el-select-height: 40px;
}

.el-select__wrapper {
  min-height: var(--el-select-height);
}

.el-input--small {
  --el-input-height: 34px;
}

.el-form--default.el-form--label-top .el-form-item .el-form-item__label {
  margin-bottom: 0;
}

.el-form-item__content {
  display: block;
  flex-wrap: unset;
  align-items: unset;
  line-height: 32px;
}

.el-radio-group {
  .el-radio {
    height: 40px;
  }
}

.el-radio.is-bordered + .el-radio.is-bordered {
  margin-left: 10px;
}

.el-form-item__label {
  color: var(--el-text-color-primary);
  line-height: 40px;
  height: 40px;
  display: inline-block !important;
}

.el-input--prefix .el-input__prefix-inner {
  padding-left: 10px;
}

.el-input__prefix + .el-input__inner {
  padding: 0;
}

.el-dialog .el-dialog__body {
  padding-bottom: unset;
}

.el-dialog__body > form {
  height: 100%;
  overflow: hidden;
  padding-bottom: 24px;
}

.el-dialog .el-form {
  .el-input__inner {
    color: var(--el-text-color-primary);
  }

  .el-tabs__item {
    font-weight: 400;
  }
}

.el-form-item.autocomplete {
  .el-input__wrapper {
    padding: 1px;
  }

  input.el-input__inner[autocomplete] {
    padding: 1px 11px;
    border-radius: var(--el-input-border-radius, var(--el-border-radius-base));
  }

  input.el-input__inner[type="password"] {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .el-input__suffix {
    padding-right: 11px;
  }
}
</style>
