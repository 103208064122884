<template>
  <el-dialog
    v-if="data"
    :class="$style['nipple-modal']"
    :model-value="isNippleModal"
    @close="closeModal"
  >
    <template #header>Просмотр: {{ data.id }}</template>
    <div :class="$style['nipple-modal-top']">
      <div :class="$style['nipple-modal-block']">
        <el-icon :class="$style['nipple-modal-icon']" size="14px">
          <Calendar />
        </el-icon>
        {{ nippleDate(data.attributes?.start_at) }} -
        <span v-if="data.attributes?.end_at">
          &nbsp;{{ nippleDate(data.attributes?.end_at) }}
        </span>
        <span v-else :class="$style['nipple-modal-loading']">
          <el-icon
            :class="[
              $style['nipple-modal-icon'],
              $style['nipple-modal-icon_loading'],
            ]"
            size="14px"
          >
            <Refresh /> </el-icon
          >Загрузка</span
        >
      </div>
      <div :class="$style['nipple-modal-block']">
        <el-icon :class="$style['nipple-modal-icon']" size="14px">
          <Files />
        </el-icon>
        {{ data.attributes?.done }} из
        {{ data.attributes?.total }}
        {{
          stringDeclension(data.attributes?.total, [
            "элемента",
            "элементов",
            "элементов",
          ])
        }}
        <el-link
          v-if="data.attributes?.file"
          :class="$style['nipple-modal-download']"
          :href="`/service/nipple${data.attributes.file}`"
          download
          type="success"
          >Скачать файл
        </el-link>
      </div>
    </div>
    <el-table :class="$style['nipple-modal-table']" :data="tableData">
      <el-table-column label="Пакеты" prop="code" />
      <el-table-column label="Прогресс" width="200">
        <template #default="scope">
          <the-progres-bar :count="scope.row.count" :done="scope.row.done" />
        </template>
      </el-table-column>
      <el-table-column label="Дата завершения" width="200">
        <template #default="scope">
          {{ scope.row.dateEnd ? nippleDate(scope.row.dateEnd) : "-" }}
        </template>
      </el-table-column>
    </el-table>
    <el-collapse
      v-if="systemFields?.length"
      :class="$style['nipple-modal-collapse']"
    >
      <el-collapse-item title="Служебные поля">
        <template #title> Служебные поля</template>
        <template #default>
          <el-form label-position="top">
            <div v-for="systemField in systemFields" :key="systemField.model">
              <el-form-item :label="systemField.label" label-position="top">
                <el-input
                  :placeholder="
                    systemField.attrs?.date
                      ? formatDate(data.attributes[systemField.model], true)
                      : data.attributes[systemField.model]
                  "
                  disabled
                />
              </el-form-item>
            </div>
          </el-form>
        </template>
      </el-collapse-item>
    </el-collapse>
  </el-dialog>
</template>

<script lang="ts" setup>
import { computed, toRefs } from "vue";
import { Calendar, Files, Refresh } from "@element-plus/icons-vue";
import { WorkingSectionsDefaultInterface } from "@/model/working-sections/types";
import TheProgresBar from "@/components/the/TheProgresBar.vue";
import { d, stringDeclension } from "@/helpers/dictionary";
import formatDate, { nippleDate } from "@/helpers/formatDate";

const props = defineProps<{
  isNippleModal: boolean;
  data: Record<string, any>;
  declaration: WorkingSectionsDefaultInterface;
}>();

const { isNippleModal, data, declaration } = toRefs(props);

const emit = defineEmits(["closeModal"]);

const closeModal = () => {
  emit("closeModal");
};

const systemFields = computed(() =>
  declaration.value.forms.service?.items.map((item: Record<string, any>) => {
    item.label =
      item.name === "created_at"
        ? "Дата начала обработки"
        : item.formItemAttrs?.label
        ? item.formItemAttrs.label
        : d(item.name);
    return item;
  })
);

const tableData = computed(() =>
  data.value ? Object.values(data.value.attributes?.info) : []
);
</script>

<style lang="scss" module>
.nipple-modal {
  padding: 0 0 24px;

  &-top {
    display: flex;
    justify-content: space-between;
    padding: 0 0 12px;
  }

  &-block {
    display: flex;
    align-items: baseline;
  }

  &-icon {
    position: relative;
    top: 2px;
    margin: 0 4px 0 0;

    &_loading {
      animation: spin 1s linear infinite;
    }
  }

  &-loading {
    color: var(--el-color-primary);
    margin: 0 0 0 4px;
  }

  &-download {
    margin: 0 0 0 4px;
  }

  &-table {
    border-top: var(--el-table-border);

    :global {
      .el-table__cell {
        padding: 12px 0;
      }

      td.el-table__cell {
        padding: 10px 0;
      }
    }
  }

  &-collapse {
    border-top: none;

    :global {
      .el-form-item__label {
        padding: 0 0 8px;
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
