<template>
  <div :class="$style['tasks-list']">
    <div
      v-for="item in tasksList"
      :key="item.id"
      :class="[
        $style['tasks-list__link'],
        {
          [$style['tasks-list__link_new']]: item.attributes.is_read === false,
          [$style['tasks-list__link_link']]: isLink(item),
        },
      ]"
      @click="clickHandler(item)"
    >
      <div :class="$style['tasks-list__content']">
        <div :class="$style['tasks-list__title']">
          <b>{{ item.attributes.title }}</b>
        </div>
        <div>
          {{ item.attributes.description }}
        </div>
      </div>
      <el-icon v-if="isLink(item)" :size="16">
        <ArrowRight />
      </el-icon>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toRefs } from "vue";
import { useRouter } from "vue-router";
import { ArrowRight } from "@element-plus/icons-vue";
import { ServiceName } from "@/constants";
import { Task } from "@/components/tasks/types";

const router = useRouter();

const props = defineProps<{
  tasksList: Task[];
}>();
const { tasksList } = toRefs(props);

const clickHandler = async (item: Task) => {
  if (isLink(item)) {
    const needToReload = router.currentRoute.value.path.includes(
      `/${ServiceName.cdp}/programs/`
    );
    await router.push(
      `/${ServiceName.cdp}/programs/edit/${item.attributes.object_id}`
    );
    if (needToReload) router.go(0);
  }
};

const isLink = (item: Task) =>
  item.attributes.service === ServiceName.cdp && item.attributes.object_id;
</script>

<style lang="scss" module>
.tasks-list {
  @media (max-width: 640px) {
    height: 100%;
    overflow: auto;
  }

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--el-border-color);
    color: var(--el-text-color-primary);
    text-decoration: none;
    padding: 24px;
    transition: background 0.3s ease-out;

    &:hover {
      background: var(--el-fill-color-lighter);
    }

    &_link {
      cursor: pointer;
    }

    &_new {
      background: var(--el-color-primary-light-9);
    }
  }

  &__content {
    font-size: 14px;
  }

  &__title {
    margin: 0 0 6px;
  }
}
</style>
