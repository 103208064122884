<template>
  <div v-if="isMobile" :class="$style['table-mobile__title']">
    <template v-if="text">
      {{ text }}
    </template>

    {{ label }}
  </div>
</template>

<script lang="ts" setup>
import useAdaptive from "@/composables/hooks/useAdaptive";
import { d } from "@/helpers/dictionary";
import { computed } from "vue";
import { TableColumn } from "@/model/working-sections/types";

const { isMobile } = useAdaptive();

interface Props {
  col?: TableColumn;
  parentCol?: TableColumn;
  propKey?: string;
  text?: string;
}

const props = defineProps<Props>();
const label = computed(
  (): string =>
    (props.col?.label as string) ||
    d(props.col?.key || "") ||
    d(props?.propKey || "")
);
</script>

<style lang="scss" module>
.table-mobile__title {
  color: var(--el-text-color-placeholder);
  font-weight: 700;
}
</style>
