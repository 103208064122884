//TODO: #refactor Надо переписать всю логику фильтрации, потому что здесь костыль на костыле. Чем раньше тем лучше.

import {
  DefaultJsonFormContext,
  GeneratorDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import { computed, ComputedRef, reactive, ref, Ref, toRaw, watch } from "vue";
import { payloadConstructor } from "@/api/core";
import { Router } from "vue-router";
import { GetParametersArgumentsDeclaration } from "@/api/core/types";
import { mutateArrElemStrToNum } from "@/helpers/mutateArrElemStrToNum";
import { Service } from "@/api/core/service";
import { useFilterPreset } from "@/plugins/form-generator-json-v2/composeble/useFilterPreset";
import { ElForm } from "element-plus";
import { DataList } from "@/components/types";

export default (
  isWizard: boolean,
  service: InstanceType<typeof Service>,
  filterForm: GeneratorDeclaration,
  router: Router,
  setPageNumber: (value: any) => void,
  setFilterToggle: (value: boolean) => void,
  payloadFilter: Readonly<Record<string, string>>,
  paginationData: Readonly<Ref>,
  pageNumber: Readonly<Ref>,
  filter: Ref<{ filterInstance: InstanceType<typeof ElForm> }>,
  dataList: DataList,
  filterDisabled: Ref<boolean>
): {
  filterData: Ref<Record<string, any>>;
  previewFilterData: Ref<Record<string, any>>;
  filterPayload: Ref<Record<string, any>>;
  showFilterTags: ComputedRef<boolean>;
  onRemoveTag: (tag: Record<"key" | "label" | "value", any>) => void;
  filterCleanHandler: (toggleFilter: boolean) => void;
  onCloseFilter: (formModel: any, instance: DefaultJsonFormContext) => void;
  filterChangeHandler: (data: Record<string, any>) => void;
  filterHandler: (data: Record<string, any>) => void;
  dataListFilter: typeof dataListFilter;
  filterDataListHandler: typeof filterDataListHandler;
  filterDisableHandler: typeof filterDisableHandler;
  filterLoading: typeof filterLoading;
} => {
  const filterData: Ref<Record<string, any>> = ref({});
  const previewFilterData: Ref<Record<string, any>> = ref({});
  const filterPayload: Ref<Record<string, any>> = ref(payloadFilter);

  const filterLoading = ref(false);

  const filterPreset = useFilterPreset({
    form: filterForm,
    presetValues: toRaw(payloadFilter),
  });

  watch(
    filterPreset,
    (newFilterPreset) => {
      if (
        filterForm.form.sessionStorage?.length &&
        sessionStorage.getItem(filterForm.form.name)
      ) {
        filterData.value = {
          ...newFilterPreset,
          ...JSON.parse(sessionStorage.getItem(filterForm.form.name) || ""),
        };
      } else {
        filterData.value = newFilterPreset;
      }
    },
    {
      immediate: true,
    }
  );

  const showFilterTags = computed(() => {
    return !!Object.keys(filterPayload.value).filter((item: string) => {
      if (item.indexOf("filter[") === -1) return false;
      if (Array.isArray(filterPayload.value[item])) {
        return !!filterPayload.value[item].length;
      } else if (
        filterPayload.value[item] !== undefined &&
        filterPayload.value[item] !== ""
      ) {
        return true;
      } else {
        return !!filterPayload.value[item];
      }
    }).length;
  });

  const refreshNumberPaging = () => {
    setPageNumber(1);
  };

  const onCloseFilter = async (
    formModel: Record<string, unknown>,
    instance: DefaultJsonFormContext
  ) => {
    !isWizard && refreshNumberPaging();
    filterData.value = Object.assign({}, toRaw(formModel));
    filterPayload.value = payloadConstructor(instance);

    if (filterForm.form?.sessionStorage?.length) {
      const sessionStorageItems: Record<string, any> = {};
      filterForm.form.sessionStorage.forEach((key) => {
        if (filterData.value[key] !== undefined) {
          sessionStorageItems[key] = filterData.value[key];
        }
      });

      if (Object.keys(sessionStorageItems).length) {
        sessionStorage.setItem(
          filterForm.form.name,
          JSON.stringify(sessionStorageItems)
        );
      }
    }

    setFilterToggle(false);
  };

  const filterChangeHandler = (data: Record<string, any>) => {
    if (isWizard) {
      filterData.value = filterPayload.value = data;

      return;
    }

    refreshNumberPaging();
    filterData.value = {
      ...filterData.value,
      ...data,
    };
    filterPayload.value = {
      ...filterPayload.value,
      ...data,
    };
  };

  const filterCleanHandler = (toggleFilter = false): void => {
    !isWizard && refreshNumberPaging();

    const attr = filterData.value["filter[attributes]"];
    filterData.value =
      filterPayload.value =
      previewFilterData.value =
        !isWizard
          ? {}
          : {
              "filter[attributes]": attr,
            };
    setFilterToggle(toggleFilter);

    if (filterForm.form.events?.onClear && filter.value.filterInstance) {
      const onClearEvent = filterForm.form.events.onClear as () => void;
      onClearEvent.call(filter.value.filterInstance);
    }

    if (filterForm.form?.sessionStorage) {
      sessionStorage.removeItem(filterForm.form.name);
    }
  };

  const onRemoveTag = (tag: { key: string; label: string; value: string }) => {
    const query = Object.assign({}, router.currentRoute.value.query);

    if (Array.isArray(query[tag.key])) {
      const filterTagArr = (arr: Array<string>) =>
        arr?.filter((item: string) =>
          item === null
            ? "не выбрано" !== tag.value.toString()
            : item?.toString() !== tag.value.toString()
        );

      query[tag.key] = filterTagArr(query[tag.key] as Array<string>);
      filterPayload.value = {
        ...filterPayload.value,
        [tag.key]: filterTagArr(filterPayload.value[tag.key]),
      };
      filterData.value[tag.key] = filterTagArr(filterData.value[tag.key]);
    } else {
      delete query[tag.key];
      const filterPayloadCopy = toRaw(filterPayload.value);
      delete filterPayloadCopy[tag.key];
      filterPayload.value = {
        ...filterPayloadCopy,
      };
      filterData.value[tag.key] = null;
    }
    router.replace({ query });
  };

  const filterHandler = async (form: any) => {
    if (!service || !Object.keys(form).length) {
      return;
    }

    filterLoading.value = true;

    let hasFilters = false;
    for (const key in form.formModel) {
      if (key.indexOf("filter[") === -1) {
        continue;
      }

      if (
        (Array.isArray(form.formModel[key]) && form.formModel[key].length) ||
        (!Array.isArray(form.formModel[key]) &&
          form.formModel[key] !== null &&
          form.formModel[key] !== undefined)
      ) {
        hasFilters = true;
      }
    }

    if (!hasFilters) {
      previewFilterData.value = {};
      previewFilterData.value.filterCountButtonSubmit =
        paginationData.value.total;

      filterLoading.value = false;
      return;
    }

    const payload: GetParametersArgumentsDeclaration = {
      "page[number]": pageNumber.value,
      "page[size]": 1,
      ...payloadConstructor(form),
    };

    if (
      form.formModel["filter[body_id]"]?.length &&
      form.formModel["filter[body_id]"]?.some(
        (item: unknown) => typeof item === "string"
      )
    ) {
      form.formModel["filter[body_id]"] = mutateArrElemStrToNum(
        form.formModel["filter[body_id]"]
      );
    }

    if (
      form.formModel["filter[ws_body_id]"]?.length &&
      form.formModel["filter[ws_body_id]"]?.some(
        (item: unknown) => typeof item === "string"
      )
    ) {
      form.formModel["filter[ws_body_id]"] = mutateArrElemStrToNum(
        form.formModel["filter[ws_body_id]"]
      );
    }

    if (isWizard) {
      if (router.currentRoute.value.meta.filter) {
        payload[router.currentRoute.value.meta.filter as string] =
          router.currentRoute.value.params.id;
      }
    }

    try {
      const res = await service.list(payload);
      previewFilterData.value = Object.assign(
        {},
        toRaw({
          ...form.formModel,
          filterCountButtonSubmit: res.meta?.total || 0,
        })
      );
    } catch (e) {
      console.error(e);
    }

    filterLoading.value = false;
  };

  const dataListFilter = reactive<DataList>({
    loading: true,
    value: [],
  });

  const filterDataListHandler = async (data: Record<string, string>) => {
    if (!service) {
      return;
    }

    try {
      const res = await service.list(data);

      dataListFilter.value = res.data;

      return res;
    } catch (e) {
      console.error(e);
    } finally {
      dataList.loading = false;
    }
  };

  const filterDisableHandler = async (status: boolean) => {
    filterDisabled.value = status;
  };

  watch(
    filterData,
    (newFilterData) => {
      previewFilterData.value = {
        ...previewFilterData.value,
        ...newFilterData,
      };
    },
    {
      deep: true,
      immediate: true,
    }
  );

  return {
    filterData,
    filterPayload,
    previewFilterData,
    showFilterTags,
    onRemoveTag,
    onCloseFilter,
    filterCleanHandler,
    filterChangeHandler,
    filterHandler,
    dataListFilter,
    filterDataListHandler,
    filterDisableHandler,
    filterLoading,
  };
};
