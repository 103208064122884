import { Editor } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import TiptapImage, { ImageOptions } from "@tiptap/extension-image";
import InsertImageCommandButton from "@/plugins/tiptap-editor/components/MenuCommands/Image/InsertImageCommandButton.vue";
import ImageView from "@/plugins/tiptap-editor/components/ExtensionViews/ImageView.vue";
import { ImageDisplay } from "@/plugins/tiptap-editor/utils/image";
import {
  DEFAULT_IMAGE_DISPLAY,
  DEFAULT_IMAGE_URL_REGEX,
  DEFAULT_IMAGE_WIDTH,
} from "@/plugins/tiptap-editor/constants";

const Image = TiptapImage.extend<
  ImageOptions & {
    model?: Record<string, unknown>;
    uploadImageMethod: (file: File, model: any) => Record<string, any>;
  }
>({
  inline() {
    return true;
  },

  group() {
    return "inline";
  },

  addAttributes() {
    return {
      ...this.parent?.(),
      aspectRatio: {
        default: null,
      },
      width: {
        default: DEFAULT_IMAGE_WIDTH,
        parseHTML: (element) => {
          const width =
            element.style.width || element.getAttribute("width") || null;
          return width == null ? null : parseInt(width, 10);
        },
        renderHTML: (attributes) => {
          return {
            width: attributes.width,
          };
        },
      },
      height: {
        default: null,
        parseHTML: (element) => {
          const height =
            element.style.height || element.getAttribute("height") || null;
          return height == null ? null : parseInt(height, 10);
        },
        renderHTML: (attributes) => {
          return {
            height: attributes.height,
          };
        },
      },
      display: {
        default: DEFAULT_IMAGE_DISPLAY,
        parseHTML: (element) => {
          const { cssFloat, display } = element.style;
          let dp =
            element.getAttribute("data-display") ||
            element.getAttribute("display");
          if (dp) {
            dp = /(inline|block|left|right)/.test(dp)
              ? dp
              : ImageDisplay.INLINE;
          } else if (cssFloat === "left" && !display) {
            dp = ImageDisplay.FLOAT_LEFT;
          } else if (cssFloat === "right" && !display) {
            dp = ImageDisplay.FLOAT_RIGHT;
          } else if (!cssFloat && display === "block") {
            dp = ImageDisplay.BREAK_TEXT;
          } else {
            dp = ImageDisplay.INLINE;
          }

          return dp;
        },
        renderHTML: (attributes) => {
          return {
            ["data-display"]: attributes.display,
          };
        },
      },
    };
  },

  addOptions() {
    return {
      ...this.parent?.(),
      inline: true,
      urlPattern: DEFAULT_IMAGE_URL_REGEX,
      button({ editor }: { editor: Editor }) {
        return {
          component: InsertImageCommandButton,
          componentProps: {
            editor,
          },
        };
      },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageView);
  },

  parseHTML() {
    return [
      {
        tag: "img[src]",
      },
    ];
  },
});

export default Image;
