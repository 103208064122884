import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import TiptapBold, { BoldOptions } from "@tiptap/extension-bold";
import CommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CommandButton.vue";

const Bold = TiptapBold.extend<BoldOptions & { bubble?: boolean }>({
  addOptions() {
    return {
      ...this.parent?.(),
      button({ editor, t, isCodeViewMode }: CommandButtonArgs) {
        return {
          component: CommandButton,
          componentProps: {
            command: () => {
              editor.commands.toggleBold();
            },
            isActive: editor.isActive("bold"),
            icon: "bold",
            tooltip: t("editor.extensions.Bold.tooltip"),
            isCodeViewMode,
          },
        };
      },
    };
  },
});

export default Bold;
