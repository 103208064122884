import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { user } from "@/model/user";
import { defaultRoutesList } from "@/router/default";
import { accessControlRoutesList } from "@/router/administration";
import { catalogVehiclesRoutesList } from "@/router/catalog-vehicles";
import { landingsCMSRoutesList } from "@/router/landings-cms";
// TODO: Раскомментировать после запуска сервиса business-units
// import { businessUnitsRoutesList } from "@/router/business-units";
import { historyRoutesList } from "@/router/history";
import { pageRoutesList } from "@/router/page";
import { pimRoutesList } from "@/router/pim";
import { dictionariesRoutesList } from "@/router/dictionaries";
import { cdpRoutesList } from "@/router/cdp";
import { abortRequests } from "@/api/core/requestsQueue";
import { communicationRoutesList } from "@/router/communication";
import { nippleRoutesList } from "@/router/nipple";

const decorateRoutesWithModalAction = (routes: Array<RouteRecordRaw>) => {
  return routes.map((route) => {
    route.path += "/:actionModal(\\d+|[a-zA-Z0-9-]+|create)?";
    if (route.children) {
      route.children = decorateRoutesWithModalAction(route.children);
    }
    return route;
  });
};

const decorateRoutesWithLog = (routes: Array<RouteRecordRaw>) => {
  return routes.map((route) => {
    route.path += "/:actionModal?";
    return route;
  });
};

const accessControlRoutesListDecorated = accessControlRoutesList.map(
  (route) => {
    route.children = decorateRoutesWithModalAction(route.children || []);
    return route;
  }
);

const catalogVehiclesRoutesListDecorated = catalogVehiclesRoutesList.map(
  (route) => {
    route.children = decorateRoutesWithModalAction(
      route.children || []
    ) as typeof route.children;

    return route;
  }
);

const pimRoutesListDecorated = pimRoutesList.map((route) => {
  route.children = decorateRoutesWithModalAction(route.children || []);
  return route;
});

const landingsCMSRoutesListDecorated = landingsCMSRoutesList.map((route) => {
  route.children = decorateRoutesWithModalAction(route.children || []);
  return route;
});

// TODO: Раскомментировать после запуска сервиса business-units
// const businessUnitsRoutesListDecorated = businessUnitsRoutesList.map(
//   (route) => {
//     route.children = decorateRoutesWithModalAction(route.children);
//     return route;
//   }
// );

const historyRoutesListDecorated = historyRoutesList.map((route) => {
  route.children = decorateRoutesWithLog(route.children || []);
  return route;
});

const dictionariesRoutesListDecorated = dictionariesRoutesList.map((route) => {
  route.children = decorateRoutesWithModalAction(route.children || []);
  return route;
});

const decoratedCommunicationRoutesList = communicationRoutesList.map(
  (route) => {
    route.children = decorateRoutesWithModalAction(route.children || []);
    return route;
  }
);

const cdpRoutesListDecorated = cdpRoutesList.map((route) => {
  route.children = decorateRoutesWithModalAction(route.children || []);
  return route;
});

const nippleRoutesListDecorated = nippleRoutesList.map((route) => {
  route.children = decorateRoutesWithModalAction(route.children || []);
  return route;
});

const routes: Array<RouteRecordRaw> = [
  ...defaultRoutesList,
  ...accessControlRoutesListDecorated,
  ...catalogVehiclesRoutesListDecorated,
  ...landingsCMSRoutesListDecorated,
  // TODO: Раскомментировать после запуска сервиса business-units
  // ...businessUnitsRoutesListDecorated,
  ...historyRoutesListDecorated,
  ...pimRoutesListDecorated,
  ...pageRoutesList,
  ...dictionariesRoutesListDecorated,
  ...decoratedCommunicationRoutesList,
  ...cdpRoutesListDecorated,
  ...nippleRoutesListDecorated,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (route): Promise<any> => {
  const noRequiresAuth = route.meta.noRequiresAuth ?? false;
  if (!user.getToken() && !noRequiresAuth) {
    await user.refresh();
  }
  if (!noRequiresAuth && !user.isLoggedIn) {
    return {
      path: "/login",
      query: route.fullPath !== "/" ? { redirect: route.fullPath } : {},
    };
  }
  if (route.path === "/login" && user.isLoggedIn) {
    return {
      path: route.query?.redirect ? route.query.redirect : "/",
    };
  }

  if (
    route.path.includes("wizard") &&
    !route.path.includes("wizard/attributes") &&
    !route.query["filter[attributes]"]
  ) {
    return {
      path: route.path,
      query: {
        "filter[attributes]": "wheels",
      },
    };
  }
});

router.beforeResolve((route) => {
  if (!route?.meta?.access) {
    return;
  }
  if (!user.checkAccess(route)) {
    return { name: "forbidden", replace: true };
  }
  return;
});

router.beforeEach((route) => {
  if (document) {
    document.title = (route?.meta?.title as string) || "KD";
  }
  abortRequests();
});

user.setRouter(router);

export default router;
