import { Extension } from "@tiptap/core";
import FullscreenCommandButton from "@/plugins/tiptap-editor/components/MenuCommands/FullscreenCommandButton.vue";
import { CommandButtonArgs } from "@/plugins/tiptap-editor";

const Fullscreen = Extension.create({
  name: "fullscreen",

  addOptions() {
    return {
      ...this.parent?.(),
      button({ isCodeViewMode }: CommandButtonArgs) {
        return {
          component: FullscreenCommandButton,
          componentProps: {
            isCodeViewMode,
          },
        };
      },
    };
  },
});

export default Fullscreen;
