import { PRIVATE_SERVICE_POINT_NAME } from "@/api/points/files-api/service-config";
import {
  FormItemObjDeclaration,
  ImageLoaderDeclaration,
  ImageLoaderService,
} from "@/plugins/form-generator-json-v2/types";
import {
  DEFAULT_MAX_FILE_SIZE,
  UploadType,
} from "@/components/base/upload/constants";
import { ServiceName } from "@/constants";

const defaultImageLoaderForm: FormItemObjDeclaration = {
  name: "logo_id",
  id: "logo_id",
  model: UploadType.logoId,
  include: "logo",
  element: "upload-image",
};

const defaultImageLoaderElement: ImageLoaderDeclaration = {
  maxFileSize: DEFAULT_MAX_FILE_SIZE,
  limit: 1,
};

const defaultImageLoaderService: ImageLoaderService = {
  service: ServiceName.pim,
  objectType: "brands",
  submitMapDataCallback: (value: any) => value?.id,
};

export const defaultImageLoader = (
  form = defaultImageLoaderForm,
  loader = defaultImageLoaderElement,
  service = defaultImageLoaderService
): FormItemObjDeclaration => ({
  id: form.id,
  name: form.name,
  model: form.model,
  element: "upload-image",
  isPayload: true,
  include: form.include,
  maxFileSize: loader.maxFileSize ?? DEFAULT_MAX_FILE_SIZE,
  ignoreURLWidth: loader.ignoreURLWidth ?? false,
  submitMapDataCallback: service.submitMapDataCallback,
  attrs: {
    accept: loader.accept,
    action: `${PRIVATE_SERVICE_POINT_NAME}/${service.service}/${service.objectType}`,
    method: "post",
    name: "file",
    buttonText: loader.buttonText ?? "Загрузить фото",
    restrictions: loader.restrictions ?? "Произвольное фото",
    limit: loader.limit ?? 1,
    listType: "picture",
  },
});

export const defaultImageList = (
  form = defaultImageLoaderForm,
  loader = defaultImageLoaderElement,
  service = defaultImageLoaderService
): FormItemObjDeclaration => ({
  id: form.id,
  name: form.name,
  model: form.model,
  element: "pim-images-list",
  isPayload: true,
  include: form.include,
  maxFileSize: loader.maxFileSize ?? DEFAULT_MAX_FILE_SIZE,
  submitMapDataCallback: service.submitMapDataCallback,
  attrs: {
    action: `${PRIVATE_SERVICE_POINT_NAME}/${service.service}/${service.objectType}`,
    method: "post",
    name: "file",
    buttonText: "Загрузить фото/видео",
    restrictions: "форматы: jpg/png/mp4/mpeg/pdf/docx/xlsx",
    listType: "picture",
  },
});

export const defaultCardImageLoader = (
  form = defaultImageLoaderForm,
  loader = defaultImageLoaderElement,
  service = defaultImageLoaderService
): FormItemObjDeclaration => ({
  id: form.id,
  name: form.name,
  model: form.model,
  element: "upload-card-image",
  isPayload: true,
  include: form.include,
  maxFileSize: loader.maxFileSize ?? DEFAULT_MAX_FILE_SIZE,
  fileFormats: loader.fileFormats,
  imageTip: loader.imageTip,
  submitMapDataCallback: service.submitMapDataCallback,
  attrs: {
    action: `${PRIVATE_SERVICE_POINT_NAME}/${service.service}/${service.objectType}`,
    method: "post",
    name: "file",
  },
  formItemAttrs: {
    label: form.label,
  },
});
