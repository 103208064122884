<template>
  <template v-for="(element, index) in permittedDeclarationItems">
    <template v-if="isDefaultSubmit(element.id)">
      <el-form-item :key="`${index}-system-field`">
        <form-system-fields />
      </el-form-item>
      <form-item-wrapper
        v-if="getFormItem('defaultSubmit')"
        :key="`${index}-default-submit`"
        :element="getFormItem('defaultSubmit')"
      />
    </template>
    <form-item-wrapper v-else :key="index" :element="getFormItem(element.id)" />
  </template>
</template>

<script lang="ts" setup>
import { defineAsyncComponent, inject, ref } from "vue";
import {
  FormItemObjDeclaration,
  PublicFormContextDeclaration,
} from "@/plugins/form-generator-json-v2/types";

const FormItemWrapper = defineAsyncComponent(
  () =>
    import("@/plugins/form-generator-json-v2/components/FormItemWrapper.vue")
);
const FormSystemFields = defineAsyncComponent(
  () =>
    import("@/plugins/form-generator-json-v2/components/FormSystemFields.vue")
);

const publicFormContext = inject(
  "publicFormContext"
) as PublicFormContextDeclaration;
const { declaration, getFormItem, showServiceFields } = publicFormContext;

const isDefaultSubmit = (id: number | string) =>
  id === "defaultSubmit" &&
  declaration.items.find((item) => item.isService) &&
  showServiceFields;

const permittedDeclarationItems = ref<FormItemObjDeclaration[]>(
  (declaration.items || []).filter((item) => !item.isService)
);
</script>
