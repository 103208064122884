import { readonly, ref, Ref } from "vue";
import { onBeforeRouteLeave } from "vue-router";

const MAX_SELECTION = 10;

const selections: Record<string, Ref<unknown[]>> = {
  main: ref([]),
};

const setSelections = (key: string, value: unknown[]): void => {
  (selections[key] as Ref).value = value.slice(0, MAX_SELECTION);
};

interface Result {
  selection: Readonly<Ref>;
  setSelection: (value: unknown[]) => void;
  maxSelection: number;
}

export default (key = "main"): Result => {
  if (!selections[key]) {
    selections[key] = ref([]);
  }

  onBeforeRouteLeave(() => setSelections(key, []));

  return {
    selection: readonly(selections[key] ?? ref([])),
    setSelection: setSelections.bind(null, key),
    maxSelection: MAX_SELECTION,
  };
};
