import { FormItemObjDeclaration } from "@/plugins/form-generator-json-v2/types";
import { PRIVATE_SERVICE_POINT_NAME } from "@/api/points/files-api/service-config";
import { UploadType } from "@/components/base/upload/constants";

export const submitMapDataCallback = (value: any): Array<string> =>
  !value ? [] : value.map((item: Record<string, any>) => item?.id);

export const defaultFile = (
  service: string,
  objectType: string,
  model: string,
  label = "Файл",
  multiple = false,
  isPrivate = false,
  availableFileFormats?: string[],
  maxFileSize?: number,
  presetValue = []
): FormItemObjDeclaration => ({
  name: model,
  id: model,
  model,
  element: "upload-file",
  isPayload: true,
  include: model,
  presetValue,
  submitMapDataCallback,
  attrs: {
    action: `${PRIVATE_SERVICE_POINT_NAME}/${service}/${objectType}`,
    method: "post",
    name: "file",
    placeholder: "Выбрать файл",
    listType: "picture",
    multiple,
    isPrivate,
    availableFileFormats,
    maxFileSize,
  },
  formItemAttrs: {
    label: `${label}`,
  },
});

export const uploadImage = (
  model = UploadType.image,
  label = "Изображение"
): FormItemObjDeclaration =>
  defaultFile(
    "landings-cms",
    "common_file_relations",
    model,
    label,
    false,
    false,
    ["jpg", "jpeg", "png", "webp"],
    10
  );

export const uploadBUImage = (
  objectType: string,
  model: UploadType | string = UploadType.image,
  label = "Изображение"
): FormItemObjDeclaration =>
  defaultFile(
    "business-units",
    objectType,
    model,
    label,
    true,
    true,
    ["jpg", "jpeg", "png", "webp"],
    10
  );

export const uploadIcon = (
  model = UploadType.image,
  label = "Иконка"
): FormItemObjDeclaration =>
  defaultFile(
    "landings-cms",
    "common_file_relations",
    model,
    label,
    false,
    false,
    ["svg"],
    2
  );

export const uploadPreview = (
  model = UploadType.image
): FormItemObjDeclaration =>
  defaultFile(
    "landings-cms",
    "common_file_relations",
    model,
    "Превью",
    false,
    false,
    ["jpg", "jpeg", "png", "webp"],
    10
  );

export const uploadVideo = (
  model = UploadType.video
): FormItemObjDeclaration => {
  const upload = defaultFile(
    "landings-cms",
    "common_file_relations",
    model,
    "Видео",
    false,
    false,
    ["mp4", "webm"]
  );

  return {
    ...upload,
    formItemAttrs: {
      ...upload.formItemAttrs,
      note:
        "Если вы одновременно укажете идентификатор видео и загрузите видео вручную," +
        " приоритет будет отдаваться загруженному вами видео.",
    },
  };
};
