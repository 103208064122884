import {
  GetParametersArgumentsDeclaration,
  Notification,
} from "@/api/core/types";
import {
  NOTIFICATION_DATA_DELETE_ERROR,
  NOTIFICATION_DATA_DELETE_SUCCESS,
  NOTIFICATION_DATA_NOT_FOUND,
  NOTIFICATION_DATA_SAVE_ERROR,
  NOTIFICATION_DATA_SAVE_SUCCESS,
  NOTIFICATION_DATA_UPDATE_SUCCESS,
  NOTIFICATION_HANDLE_RESULT_QUERY_ERROR,
  NOTIFICATION_LOADED_DATA_ERROR,
  NOTIFICATION_NOT_PERMISSIONS,
} from "@/dictionary/notification";

// export const BASE_URL = "https://dev-pipe.kolesa-darom.ru/service/auth/api/v1";
export const BASE_URL = "/service/";
export const DEFAULT_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export const defaultGetPaginationParams: GetParametersArgumentsDeclaration = {
  "page[size]": 10,
  "page[number]": 1,
  //sort: "id",
};

export const enum ErrorStatusCode {
  UNPROCESSABLE_CONTENT = 422,
}

export const getNotification = (
  status = 200,
  method = "get",
  message = ""
): Notification => {
  const NOTIFICATION: Record<string, Notification> = {
    post_200: { message: NOTIFICATION_DATA_SAVE_SUCCESS, duration: 2000 },
    put_200: { message: NOTIFICATION_DATA_UPDATE_SUCCESS, duration: 2000 },
    put_422: { message: NOTIFICATION_DATA_SAVE_ERROR, duration: 2000 },
    delete_200: { message: NOTIFICATION_DATA_DELETE_SUCCESS, duration: 2000 },
    delete_400: {
      message: NOTIFICATION_DATA_DELETE_ERROR,
      duration: 2000,
    },
    get_400: {
      message: NOTIFICATION_HANDLE_RESULT_QUERY_ERROR,
      duration: 3000,
    },
    get_404: { message: NOTIFICATION_DATA_NOT_FOUND, duration: 3000 },
    get_403: { message: NOTIFICATION_NOT_PERMISSIONS, duration: 3000 },
    get_500: {
      message: NOTIFICATION_LOADED_DATA_ERROR,
      duration: 5000,
    },
  };

  return (
    NOTIFICATION[`${method.toLowerCase()}_${status}`] || {
      message,
      duration: 0,
    }
  );
};
