import {
  DefaultJsonFormContext,
  FormItemAttrsObjDeclaration,
  FormItemEventsObjDeclaration,
  FormItemObjDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import { PRIVATE_SERVICE_POINT_NAME } from "@/api/points/files-api/service-config";

export * from "@/components/form-declaration/default-elements/async-dependent-entity";
export * from "@/components/form-declaration/default-elements/text-input";
export * from "@/components/form-declaration/default-elements/hidden-input";
export * from "@/components/form-declaration/default-elements/select";
export * from "@/components/form-declaration/default-elements/selects/default-select";
export * from "@/components/form-declaration/default-elements/selects/async-block-select";
export * from "@/components/form-declaration/default-elements/selects/async-block-entity-item-select";
export * from "@/components/form-declaration/default-elements/selects/async-business-unit-select";
export * from "@/components/form-declaration/default-elements/switch";
export * from "@/components/form-declaration/default-elements/time-select";
export * from "@/components/form-declaration/default-elements/upload";
export * from "@/components/form-declaration/default-elements/tag-input";
export * from "@/components/form-declaration/default-elements/select-input";
export * from "@/components/form-declaration/default-elements/image-loader";
export * from "@/components/form-declaration/default-elements/outer-link";
export * from "@/components/form-declaration/default-elements/simple-listing";
export * from "@/components/form-declaration/default-elements/buttons";
export * from "@/components/form-declaration/default-elements/date-picker";
export * from "@/components/form-declaration/default-elements/radio";
export * from "@/components/form-declaration/pim/form-elements";

//TODO: @William #refactor засплитить
export const toFilter = (
  element: FormItemObjDeclaration,
  secondFilter?: string,
  multiple?: boolean
): FormItemObjDeclaration => {
  const copy = Object.assign({}, element);
  const secondFilterString = secondFilter ? `[${secondFilter}]` : "";
  copy.model = `filter[${copy.model}]${secondFilterString}${
    multiple ? "[]" : ""
  }`;
  copy.id = `filter[${copy.id}]${secondFilterString}${multiple ? "[]" : ""}`;
  copy.name = `filter[${copy.name}]${secondFilterString}${
    multiple ? "[]" : ""
  }`;
  copy.doNotPassEmpty = true;
  delete copy.modalForm;
  delete copy?.attrs?.readonly;
  delete copy?.attrs?.disabled;
  return copy;
};

export const toLinked = (
  element: FormItemObjDeclaration,
  props: Partial<FormItemObjDeclaration> = {
    isPayload: false,
    modalForm: null,
  },
  events: FormItemEventsObjDeclaration = {},
  attrs: FormItemAttrsObjDeclaration = {}
): FormItemObjDeclaration => {
  const copy = {
    ...element,
    ...props,
  };
  return {
    ...copy,
    attrs: {
      ...copy.attrs,
      ...attrs,
    },
    events: {
      ...copy.events,
      ...events,
    },
  };
};

export const defaultSubmit = (
  text = "Создать",
  needModalClose = true,
  isOutside = true
): FormItemObjDeclaration => ({
  name: "defaultSubmit",
  id: "defaultSubmit",
  model: "",
  element: "button",
  text,
  isOutside,
  attrs: {
    type: "primary",
  },
  formItemAttrs: {
    class: "el-form-item--submit",
  },
  events: {
    onClick: async function (this: DefaultJsonFormContext) {
      try {
        this.$emit("submit", this, needModalClose);
      } catch (error) {
        console.error(error);
      }
    },
  },
});

export const addSubmit = (
  text = "Сохранить и создать ещё",
  isOutside = true
): FormItemObjDeclaration => ({
  name: "addSubmit",
  id: "addSubmit",
  model: "",
  element: "button",
  text,
  isOutside,
  attrs: {
    class: "mode-add",
  },
  formItemAttrs: {
    class: "el-form-item--add",
  },
  events: {
    onClick: async function (this: DefaultJsonFormContext) {
      try {
        await this.formRef.validate();

        this.$emit("submit", this, false, true, true);
      } catch (error) {
        console.error(error);
      }
    },
  },
});

export const defaultFiles = (): FormItemObjDeclaration => ({
  name: "files",
  id: "files",
  model: "files",
  element: "upload",
  formItemAttrs: {
    label: "Файлы",
  },
});

export const defaultLogo = (
  service = "vehicles",
  objectType = "brands"
): FormItemObjDeclaration => ({
  name: "logo",
  id: "logo",
  model: "logo",
  element: "upload-logo",
  isPayload: true,
  include: "logo",
  submitMapDataCallback: (value: any) => {
    let files = value;
    if (!files) {
      files = [];
    } else if (!Array.isArray(files)) {
      files = [files];
    }
    return files.map((item: any) => item?.id);
  },
  attrs: {
    action: `${PRIVATE_SERVICE_POINT_NAME}/${service}/${objectType}`,
    method: "post",
    name: "file",
    placeholder: "Выбрать файл",
    limit: 1,
    listType: "picture",
  },
  formItemAttrs: {
    label: "Логотип",
  },
});

export const filterCountButtonSubmit = (
  text = "Ничего не найдено",
  needModalClose = true,
  isOutside = true
): FormItemObjDeclaration => ({
  name: "filterCountButtonSubmit",
  id: "filterCountButtonSubmit",
  model: "",
  element: "button",
  text,
  isOutside,
  attrs: {
    type: "primary",
  },
  formItemAttrs: {
    class: "el-form-item--footer el-form-item--submit",
  },
  events: {
    onClick: function (this: DefaultJsonFormContext): void {
      this.$emit("close", this, needModalClose);
    },
  },
});

export const filterClearButton = (
  text = "Очистить",
  needModalClose = false,
  isOutside = true
): FormItemObjDeclaration => ({
  name: "filterClearButton",
  id: "filterClearButton",
  model: "",
  element: "button",
  text,
  isOutside,
  attrs: {
    class: "mode-add",
  },
  formItemAttrs: {
    class: "el-form-item--footer el-form-item--add",
  },
  events: {
    onClick: function (this: DefaultJsonFormContext): void {
      this.$emit("clear", this, needModalClose);
    },
  },
});

export const divider = () => ({
  name: "divider",
  id: "divider",
  model: "",
});

export const defaultGroupItem = (params: {
  id: string;
  name?: string;
  model?: string;
  isPayload?: boolean;
  groupList?: Array<FormItemObjDeclaration>;
}): FormItemObjDeclaration => ({
  name: params.name || params.id,
  id: params.id,
  model: params.model || params.id,
  element: "form-group",
  isPayload: !!params.isPayload,
  groupList: params.groupList,
});
