import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/franchise_first_blocks`);

export default service;
export const getFranchiseFirstBlocks = service.list.bind(service);
export const getFranchiseFirstBlockById = service.item.bind(service);
export const createFranchiseFirstBlock = service.create.bind(service);
export const updateFranchiseFirstBlock = service.update.bind(service);
export const deleteFranchiseFirstBlock = service.delete.bind(service);
