import { reactive } from "vue";
import {
  FormItemObjDeclaration,
  GeneratorDeclaration,
  PresetValues,
} from "../types";
import { useRouter } from "vue-router";
import { SelectOptionItemDeclaration } from "@/plugins/form-generator-json-v2/types";

export const useFilterPreset = ({
  form,
  presetValues,
}: {
  form: GeneratorDeclaration;
  presetValues: PresetValues;
}) => {
  const router = useRouter();
  const hasPresetValues = !!Object.keys(presetValues).length;
  const filterData = reactive<Record<string, any>>({});

  const setDefaultValues = async () => {
    for (const item of form.items) {
      await getFormItemData(item);
    }
  };

  const getFormItemData = async (item: FormItemObjDeclaration) => {
    if (item.groupList?.length) {
      item.groupList.forEach((groupItem) => getFormItemData(groupItem));
      return;
    }

    if (!item.model || !presetValues[item.model]) {
      return;
    }

    switch (item.element) {
      case "select":
      case "multiple-select": {
        if (!item.selectOptions?.length) {
          await setSelectOptions(item);
        }

        if (
          (item.element === "multiple-select" &&
            item.attrs?.multiple !== false) ||
          item.attrs?.multiple
        ) {
          setMultiselectValue(item);
        } else {
          setSelectValue(item);
        }
        break;
      }
      case "async-dependent-entity-select":
        setValuesFromBaseAsyncDependentEntitySelect(item);
        break;
      default:
        setDefaultValue(item);
        break;
    }
  };

  const setDefaultValue = (item: FormItemObjDeclaration) => {
    if (typeof item.presetValue === "number") {
      filterData[item.model] = Number(presetValues[item.model]);
    } else if (typeof item.presetValue === "boolean") {
      filterData[item.model] = Number(Boolean(presetValues[item.model]));
    } else {
      filterData[item.model] = presetValues[item.model];
    }
  };

  const setSelectValue = (item: FormItemObjDeclaration) => {
    const option = item.selectOptions?.find((option) => {
      const value = option.value as Record<string, any> | number;
      return (
        presetValues[item.model] ===
        (typeof value === "object" ? value?.id.toString() : value?.toString())
      );
    });

    filterData[item.model] =
      typeof option === "object" ? option?.value : option;
  };

  const setMultiselectValue = (item: FormItemObjDeclaration) => {
    if (!item.selectOptions?.length) {
      return;
    }

    const optionsIsObject =
      item.selectOptions[0]?.value !== null &&
      typeof item.selectOptions[0]?.value === "object";
    const options: SelectOptionItemDeclaration[] = item.selectOptions.reduce(
      (acc: SelectOptionItemDeclaration[], current: any) => {
        const value = optionsIsObject ? current.value?.id : current.value;
        const stringedValue = value === null ? value : value.toString();

        if (
          (Array.isArray(presetValues[item.model]) &&
            presetValues[item.model].includes(stringedValue)) ||
          (!Array.isArray(presetValues[item.model]) &&
            presetValues[item.model] === stringedValue)
        ) {
          acc.push(current);
        }
        return acc;
      },
      []
    );

    if (item?.submitMapDataCallback) {
      const submitCallbackResult = item
        .submitMapDataCallback(options)
        ?.filter((item: unknown) => item);

      if (submitCallbackResult?.length) {
        filterData[item.model] = submitCallbackResult;
        return;
      }
    } else {
      filterData[item.model] = options.map((option) => option.value);
    }
  };

  const setSelectOptions = async (item: FormItemObjDeclaration) => {
    let selectOptions: SelectOptionItemDeclaration[] = [];

    if (item.asyncSelectOptionsMethod) {
      const payload: Record<string, string> = {};
      const id = presetValues[item.model] ?? null;
      if (id) {
        payload["filter[id]"] = id;
      }
      selectOptions = await item.asyncSelectOptionsMethod(payload);
    }

    item.selectOptions = selectOptions;
  };

  const setValuesFromBaseAsyncDependentEntitySelect = async (
    item: FormItemObjDeclaration
  ) => {
    if (!item.getDataMethod) {
      return;
    }

    const id: string = presetValues[item.model];
    const response = await item.getDataMethod({
      [item.filterId]: id,
    });
    const currentItem = response.data?.find(
      (item: { id: number }) => item.id.toString() === id
    );
    if (!currentItem) {
      return setDefaultValue(item);
    }
    filterData[item.model] = currentItem;
  };

  if (hasPresetValues) {
    setDefaultValues();
  }

  if (router.currentRoute.value.query["filter[attributes]"]) {
    const item = form.items.find(
      (formItem) => formItem.name === "filter[attributes]"
    );
    if (item) {
      setSelectValue(item);
    }
  }

  return filterData;
};
