import { ref, Ref } from "vue";

const isDeclarationSearchVisible = ref(false);

const setDeclarationSearchVisibility = (val?: boolean): void => {
  isDeclarationSearchVisible.value = val ?? !isDeclarationSearchVisible.value;
};

interface Result {
  isDeclarationSearchVisible: Ref<boolean>;
  setDeclarationSearchVisibility: (val?: boolean) => void;
}

export default (): Result => ({
  isDeclarationSearchVisible,
  setDeclarationSearchVisibility,
});
