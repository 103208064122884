import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/automotive_product_blocks`, {
  list: { include: "productCategories" },
  item: { include: "productCategories" },
});

export default service;
export const getAutomotiveProductsBlocks = service.list.bind(service);
export const getAutomotiveProductsBlockById = service.item.bind(service);
export const createAutomotiveProductsBlock = service.create.bind(service);
export const updateAutomotiveProductsBlock = service.update.bind(service);
export const deleteAutomotiveProductsBlock = service.delete.bind(service);
