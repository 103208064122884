import { useRouter } from "vue-router";
import { downloadModel } from "@/api/points/vehicles-api/models";
import { downloadBrand } from "@/api/points/vehicles-api/brands";
import useSelection from "@/composables/hooks/useSelection";
import { Command } from "@/components/working-sections/default/components/actions/constants";
import useSectionModal from "@/composables/hooks/modals/useSectionModal";
import { QUERY_PARAM } from "@/components/config";
import { Service } from "@/api/core/service";
import { WorkingSectionsDefaultInterface } from "@/model/working-sections/types";
import { ICommand } from "@/components/working-sections/default/components/actions/types";

export default (
  isWizard: boolean,
  state: Record<string, any>,
  service: InstanceType<typeof Service>,
  defaultSectionDeclaration: WorkingSectionsDefaultInterface,
  onChangeActive: (value: number) => void,
  massDeleteRows: (items: Array<any>) => Promise<void>,
  editRow: (
    id: number | string,
    options?: {
      copy?: boolean;
      copyWithDependencies?: boolean;
    }
  ) => Promise<void>,
  toggleActivation: (
    id: number | string,
    active: boolean,
    data?: any | undefined
  ) => Promise<void>,
  getData: () => void,
  runImpersonateCommand: (command: ICommand) => Promise<void>,
  showNippleImports: (id: string | number) => void
): {
  doubleClickHandler: (row: { id: number }) => Promise<void>;
  clickMenuHandler: (command: ICommand) => Promise<void>;
  clickMassiveMenuHandler: (command: ICommand) => Promise<void>;
  beforeCloseHandler: () => void;
} => {
  const router = useRouter();

  const { selection, setSelection } = useSelection();
  const { hide: hideModal } = useSectionModal(QUERY_PARAM);

  const clickMassiveMenuHandler = async (command: ICommand) => {
    const ids = selection.value.map((item: any) => item.id);

    switch (command.type) {
      case Command.ACTIVATE:
        onChangeActive(1);
        break;
      case Command.DEACTIVATE:
        onChangeActive(0);
        break;
      case Command.REMOVE:
        await massDeleteRows(ids);
        break;
      case Command.EXPORT:
        setTimeout(async () => {
          let result;

          switch (defaultSectionDeclaration.forms.create.form.name) {
            case "models":
              result = await downloadModel(ids);
              break;
            case "brands":
              result = await downloadBrand(ids);
              break;
            default:
              return;
          }

          if (result) {
            const a = document.createElement("a");
            a.href = result.url;
            a.download = result.filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        }, 0);
        break;
      default:
        return;
    }
  };

  const clickMenuHandler = async (command: ICommand): Promise<void> => {
    switch (command.type) {
      case Command.EDIT:
        if (defaultSectionDeclaration.shouldNavigateToDetailPage) {
          router.push(
            `${router.currentRoute.value.path.replace(/\/+$/, "")}/edit/${
              command.id
            }`
          );
        } else {
          await editRow(command.id);
        }
        break;
      case Command.COPY:
        if (defaultSectionDeclaration.shouldNavigateToDetailPage) {
          router.push(
            `${router.currentRoute.value.path.replace(/\/+$/, "")}/create/${
              command.id
            }`
          );
        } else {
          await editRow(command.id, { copy: true });
        }
        break;
      case Command.TOGGLE_ACTIVATION:
        isWizard
          ? await toggleActivation(command.id, !command.active)
          : await toggleActivation(command.id, !command.active, command.data);
        break;
      case Command.COPYWITHDEPENDENCIES:
        await editRow(command.id, { copy: true, copyWithDependencies: true });
        break;
      case Command.RESTORE:
        service?.restore(command.id).then(() => {
          if (selection.value.length) {
            setSelection([]);
          }
          getData();
        });
        break;
      case Command.IMPERSONATE_USER:
        await runImpersonateCommand(command);
        break;
      case Command.EXPORT:
        setTimeout(async () => {
          let result;

          switch (defaultSectionDeclaration.forms.create.form.name) {
            case "models":
              result = await downloadModel([command.id]);
              break;
            case "brands":
              result = await downloadBrand([command.id]);
              break;
            default:
              return;
          }

          if (result) {
            const a = document.createElement("a");
            a.href = result.url;
            a.download = result.filename;
            document.body.appendChild(a);
            a.click();
            a.remove();
          }
        }, 0);
        break;
      default:
        return;
    }
  };

  const doubleClickHandler = async (row: { id: number | string }) => {
    if (defaultSectionDeclaration.nippleModal) {
      showNippleImports(row.id);
    } else if (defaultSectionDeclaration.shouldNavigateToDetailPage) {
      router.push(
        `${router.currentRoute.value.path.replace(/\/+$/, "")}/edit/${row.id}`
      );
    } else {
      await editRow(row.id);
    }
  };

  const beforeCloseHandler = () => {
    state.activeCollapseItems = [];

    hideModal();
    state.action = -1;
  };

  return {
    clickMenuHandler,
    clickMassiveMenuHandler,
    doubleClickHandler,
    beforeCloseHandler,
  };
};
