export const ERROR_AUTH = "Ошибка авторизации";
export const ERROR_PROCESSING_REQUEST = "Ошибка обработки запроса";
export const ERROR_LOADED_FILE = "Ошибка загрузки файла";
export const ERROR_SIZE_FILE_BIG = "Размер файла слишком большой";
export const ERROR_FILE_FORMAT =
  "Формат файла не соответствует допустимому формату";
export const ERROR_SIZE_FILE = {
  kb: "Размер изображения не должен превышать __SIZE__ кб",
  mb: "Размер изображения не должен превышать __SIZE__ MB",
};
export const ERROR_FILE_DOES_NOT_SATISFY = "Файл не удовлетворяет требованиям";
export const ERROR_INVALID_FORMAT_FILE = "Недопустимый формат файла";
export const ERROR_AVATAR_FORMAT_JPG = "Аватар должен быть в формате JPG";
export const ERROR_SIZE_AVATAR_BIG = "Размер аватара не должен превышать 2MB";
export const ERROR_GENERATE_FORM = {
  tab: "Ошибка генерации формы: Вкладка с id= __ID__ не найдена",
  next_tab: "Ошибка генерации формы: Следующая вкладка после __ID__ не найдена",
  elements:
    "Ошибка генерации формы: Элемент с id= __ID__ не найден в в массиве items",
};
export const ERROR_ACCESS_CLOSED = "Вам сюда нельзя";
export const ERROR_NOTING = "Тут ничего нет";
