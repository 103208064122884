import { RouteAccess } from "@/router/types";
import WorkingSectionView from "@/views/WorkingSectionView.vue";
import { RouteRecordRaw } from "vue-router";

const access: RouteAccess = {
  service: "landings-cms",
  object: "",
  command: "index",
};

export const mainBlocksRoutesList: RouteRecordRaw & {
  isCategory: boolean;
  hasChildren: boolean;
} = {
  path: "/landings/main-blocks",
  name: "main",
  isCategory: true,
  hasChildren: true,
  component: (): Promise<typeof import("*.vue")> =>
    import(/* webpackChunkName: "GeneralBlocks" */ "../../../views/Root.vue"),
  meta: {
    title: "Основные блоки",
  },
  children: [
    {
      path: "/landings/main-blocks/menu-item-blocks",
      name: "menu-item-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Меню"',
        access: {
          ...access,
          object: "menu_item_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/menu-items",
      name: "menu-items",
      component: WorkingSectionView,
      meta: {
        title: "Пункты меню",
        access: {
          ...access,
          object: "menu_items",
        },
      },
    },
    {
      path: "/landings/main-blocks/intro-blocks",
      name: "intro-blocks",
      component: WorkingSectionView,
      meta: {
        title: "Вступительный блок",
        access: {
          ...access,
          object: "intro_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/cooperation-options",
      name: "cooperation-options",
      component: WorkingSectionView,
      meta: {
        title: "Варианты сотрудничества",
        access: {
          ...access,
          object: "cooperation_options",
        },
      },
    },
    {
      path: "/landings/main-blocks/our-clients",
      name: "our-clients",
      component: WorkingSectionView,
      meta: {
        title: "Клиенты",
        access: {
          ...access,
          object: "our_clients",
        },
      },
    },
    {
      path: "/landings/main-blocks/automotive-product-blocks",
      name: "automotive-product-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Автотовары"',
        access: {
          ...access,
          object: "automotive_product_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/product-categories",
      name: "product-categories",
      component: WorkingSectionView,
      meta: {
        title: "Категории",
        access: {
          ...access,
          object: "product_categories",
        },
      },
    },
    {
      path: "/landings/main-blocks/brand-and-certificate-blocks",
      name: "brand-and-certificate-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Бренды и Сертификаты"',
        access: {
          ...access,
          object: "brand_and_certificate_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/landings-brands",
      name: "landings-brands",
      component: WorkingSectionView,
      meta: {
        title: "Бренды",
        access: {
          ...access,
          object: "brands",
        },
      },
    },
    {
      path: "/landings/main-blocks/certificates",
      name: "certificates",
      component: WorkingSectionView,
      meta: {
        title: "Сертификаты",
        access: {
          ...access,
          object: "certificates",
        },
      },
    },
    {
      path: "/landings/main-blocks/video-presentation-blocks",
      name: "video-presentation-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Видеопрезентации"',
        access: {
          ...access,
          object: "video_presentation_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/video-presentations",
      name: "video-presentations",
      component: WorkingSectionView,
      meta: {
        title: "Видеопрезентации",
        access: {
          ...access,
          object: "video_presentations",
        },
      },
    },
    {
      path: "/landings/main-blocks/business-to-consumer-blocks",
      name: "business-to-consumer-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Услуги B2C"',
        access: {
          ...access,
          object: "business_to_consumer_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/team-blocks",
      name: "team-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Команда"',
        access: {
          ...access,
          object: "team_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/employees",
      name: "employees",
      component: WorkingSectionView,
      meta: {
        title: "Сотрудники",
        access: {
          ...access,
          object: "employees",
        },
      },
    },
    {
      path: "/landings/main-blocks/hr-blocks",
      name: "hr-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "HR-блок"',
        access: {
          ...access,
          object: "hr_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/buttons",
      name: "buttons",
      component: WorkingSectionView,
      meta: {
        title: "Кнопки",
        access: {
          ...access,
          object: "buttons",
        },
      },
    },
    {
      path: "/landings/main-blocks/geography-blocks",
      name: "geography-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "География присутствия"',
        access: {
          ...access,
          object: "geography_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/business-advantage-blocks",
      name: "business-advantage-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Бизнес-преимущества"',
        access: {
          ...access,
          object: "business_advantage_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/business-advantages",
      name: "business-advantages",
      component: WorkingSectionView,
      meta: {
        title: "Преимущества",
        access: {
          ...access,
          object: "business_advantages",
        },
      },
    },
    {
      path: "/landings/main-blocks/feedback-blocks",
      name: "feedback-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Отзывы"',
        access: {
          ...access,
          object: "feedback_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/feedbacks",
      name: "feedbacks",
      component: WorkingSectionView,
      meta: {
        title: "Отзывы",
        access: {
          ...access,
          object: "feedbacks",
        },
      },
    },
    {
      path: "/landings/main-blocks/work-with-corporate-client-blocks",
      name: "work-with-corporate-client-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Работа с корпоративными клиентами"',
        access: {
          ...access,
          object: "work_with_corporate_client_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/wholesale-customer-blocks",
      name: "wholesale-customer-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Работа с оптовыми клиентами"',
        access: {
          ...access,
          object: "wholesale_customer_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/business-offer-blocks",
      name: "business-offer-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Предложения для бизнеса"',
        access: {
          ...access,
          object: "business_offer_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/directions",
      name: "directions",
      component: WorkingSectionView,
      meta: {
        title: "Направления бизнеса",
        access: {
          ...access,
          object: "directions",
        },
      },
    },
    {
      path: "/landings/main-blocks/ready-made-solution-stages",
      name: "ready-made-solution-stages",
      component: WorkingSectionView,
      meta: {
        title: "Этапы развития бизнеса",
        access: {
          ...access,
          object: "ready_made_solution_stages",
        },
      },
    },
    {
      path: "/landings/main-blocks/ready-made-solutions",
      name: "ready-made-solutions",
      component: WorkingSectionView,
      meta: {
        title: "Готовые решения",
        access: {
          ...access,
          object: "ready_made_solutions",
        },
      },
    },
    {
      path: "/landings/main-blocks/analytic-blocks",
      name: "analytic-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Аналитика"',
        access: {
          ...access,
          object: "analytic_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/analytic-cards",
      name: "analytic-cards",
      component: WorkingSectionView,
      meta: {
        title: "Инфографика",
        access: {
          ...access,
          object: "analytic_cards",
        },
      },
    },
    {
      path: "/landings/main-blocks/partnership-blocks",
      name: "partnership-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Заявка на сотрудничество"',
        access: {
          ...access,
          object: "partnership_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/contact-blocks",
      name: "contact-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Контакты"',
        access: {
          ...access,
          object: "contact_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/corp-solution-and-product-blocks",
      name: "corp-solution-and-product-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Услуги B2G"',
        access: {
          ...access,
          object: "corp_solution_and_product_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/corporate-solutions",
      name: "corporate-solutions",
      component: WorkingSectionView,
      meta: {
        title: "Услуги B2G",
        access: {
          ...access,
          object: "corporate_solutions",
        },
      },
    },
    {
      path: "/landings/main-blocks/products",
      name: "products",
      component: WorkingSectionView,
      meta: {
        title: "Продукты",
        access: {
          ...access,
          object: "products",
        },
      },
    },
    {
      path: "/landings/main-blocks/our-client-case-blocks",
      name: "our-client-case-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Кейсы клиентов"',
        access: {
          ...access,
          object: "our_client_case_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/client-directions",
      name: "client-directions",
      component: WorkingSectionView,
      meta: {
        title: "Клиенты - направления",
        access: {
          ...access,
          object: "client_directions",
        },
      },
    },
    {
      path: "/landings/main-blocks/cooperation-card-blocks",
      name: "cooperation-card-blocks",
      component: WorkingSectionView,
      meta: {
        title: 'Блок "Направления B2G"',
        access: {
          ...access,
          object: "cooperation_card_blocks",
        },
      },
    },
    {
      path: "/landings/main-blocks/cooperation-cards",
      name: "cooperation-cards",
      component: WorkingSectionView,
      meta: {
        title: "Примеры сотрудничества",
        access: {
          ...access,
          object: "cooperation_cards",
        },
      },
    },
  ],
};
