import { fetchRequest } from "@/api/core";
import { user } from "@/model/user";
import {
  AuthLoginBody,
  AuthRestorePasswordBody,
  AuthRestoreRequestBody,
} from "@/api/points/auth-api/auth/types";
import { SERVICE_POINT_NAME } from "@/api/points/auth-api/service-config";

const POINT_NAME = `${SERVICE_POINT_NAME}/auth`;
const showNotification = false;

export const authLogin = async (
  body: AuthLoginBody,
  contactType: string
): Promise<any> => {
  try {
    const response = await fetchRequest({
      point: `${POINT_NAME}/login/${contactType}`,
      payload: body,
      fetchMethod: "POST",
      isAuth: true,
      showNotification,
    });

    if (response.data) {
      user.setToken(response.data.token, response.data.refresh);
    }

    return response;
  } catch (e) {
    return e;
  }
};

export const authRefresh = async (token: string): Promise<any> => {
  try {
    return await fetchRequest({
      point: `${POINT_NAME}/refresh`,
      payload: { token },
      fetchMethod: "POST",
      isAuth: true,
      showNotification,
      canAborted: false,
    });
  } catch (e) {
    return e;
  }
};

export const authRestoreRequest = async (
  body: AuthRestoreRequestBody,
  contactType: string
): Promise<any> => {
  try {
    return await fetchRequest({
      point: `${POINT_NAME}/restore_request/${contactType}`,
      payload: body,
      fetchMethod: "POST",
      isAuth: true,
      showNotification,
    });
  } catch (e) {
    return e;
  }
};

export const authRestorePassword = async (
  body: AuthRestorePasswordBody,
  contactType: string
): Promise<any> => {
  try {
    return await fetchRequest({
      point: `${POINT_NAME}/restore_password/${contactType}`,
      payload: body,
      fetchMethod: "POST",
      isAuth: true,
      showNotification,
    });
  } catch (e) {
    return e;
  }
};
