import { VALUE_TYPES } from "@/helpers/pimProperties";
import { FormItemObjDeclaration } from "@/plugins/form-generator-json-v2/types";

export const properties_list: FormItemObjDeclaration = {
  name: "properties",
  id: "properties",
  model: "properties",
  include: "properties",
  isPayload: true,
  element: "properties-list",
  attrs: {
    isLoading: false,
  },
  submitMapDataCallback: (properties: any[]) =>
    properties.map((prop) => {
      const hasValue = ![null, undefined, ""].includes(prop.value);
      const isMultipleProp = [
        VALUE_TYPES.NUMERIC_LIST_MULTIPLE,
        VALUE_TYPES.TEXT_LIST_MULTIPLE,
      ].includes(prop.type);

      if (isMultipleProp) {
        return {
          property_id: prop.id,
          value: hasValue ? String(prop.value) : null,
          manual_filled: prop.manual_filled,
        };
      }

      return {
        property_id: prop.id,
        value: hasValue ? String(prop.value) : null,
        manual_filled: prop.manual_filled,
      };
    }),
};

export const pim_editor: FormItemObjDeclaration = {
  name: "description",
  id: "description",
  model: "description",
  isPayload: true,
  element: "pim-tiptap",
  submitMapDataCallback: (v: string) => (v ? v : undefined),
};

export const pim_progress: FormItemObjDeclaration = {
  name: "completion",
  id: "completion",
  model: "completion",
  isPayload: true,
  element: "pim-progress",
};

export const pim_crosses_list: FormItemObjDeclaration = {
  name: "crosses",
  id: "crosses",
  include: "crosses",
  model: "crosses",
  isPayload: false,
  element: "pim-crosses",
};
