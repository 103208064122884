import { Request } from "@/api/core/types";

export const queueRequests: Map<string, Request> = new Map();

export const abortRequests = (): void => {
  if (!queueRequests.size) {
    return;
  }
  const timestamp = Date.now();
  queueRequests.forEach((request) => {
    if (timestamp - request.timestamp > 500) {
      request.controller.abort();
    }
  });
};

export class RequestsQueueController {
  public requestId;
  public controller;

  constructor(url: string) {
    this.controller = new AbortController();
    this.requestId = `${url}-${Date.now()}`;
  }

  public addRequestToQueue() {
    queueRequests.set(this.requestId, {
      controller: this.controller,
      timestamp: Date.now(),
    });
  }

  public removeRequestFromQueue() {
    queueRequests.delete(this.requestId);
  }
}
