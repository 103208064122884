<template>
  <the-resize-div v-show="isOpen" :class="$style['draggable']" :height="150">
    <div :class="$style['drawer']">
      <el-tabs type="border-card">
        <el-tab-pane v-for="title in debugHeaders" :key="title">
          <template #label>
            <span :class="$style['custom-tabs-label']">
              <span>{{ title }}</span>
              <span
                v-if="
                  debug[selectedService][title]?.nb_statements ||
                  debug[selectedService][title]?.count
                "
                :class="$style['round']"
              >
                {{
                  debug[selectedService][title]?.nb_statements ||
                  debug[selectedService][title]?.count
                }}
              </span>
            </span>
          </template>

          <div v-if="title === 'queries'">
            <Queries :data="debug[selectedService].queries" />
          </div>
          <div v-else-if="title === 'time'">
            <Time :data="debug[selectedService].time" />
          </div>
          <div v-else-if="title === 'models'">
            <Models :data="debug[selectedService].models" />
          </div>
          <div v-else-if="title === 'messages'">
            <Messages :data="debug[selectedService].messages" />
          </div>
          <div v-else-if="title === 'response'">
            <Response :data="debug[selectedService].response" />
          </div>
          <div v-else>
            <Default :data="debug[selectedService][title]" />
          </div>
        </el-tab-pane>
      </el-tabs>

      <div :class="$style['meta']">
        <div :class="$style['highlighted']">
          {{ debug[selectedService]?.memory?.peak_usage_str }}
        </div>
        <div>
          {{ debug[selectedService]?.time?.duration_str }}
        </div>

        <el-dropdown
          class="dropdown-trigger"
          placement="bottom"
          trigger="click"
          @command="selectService"
        >
          <el-button plain type="info">
            <span>{{ selectedService }}</span>
            <el-icon class="el-icon--right">
              <ArrowDown />
            </el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <div v-for="(service, index) in services" :key="index">
                <el-dropdown-item class="filter-by-dropdown" disabled divided>
                  {{ index }}
                </el-dropdown-item>
                <el-dropdown-item
                  v-for="item in service"
                  :key="item"
                  :class="{ active: selectedService === item }"
                  :command="item"
                  class="filter-by-dropdown"
                >
                  {{ item }}
                </el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>

      <div :class="$style['close']">
        <el-icon title="Закрыть панель" @click="close">
          <Close />
        </el-icon>
      </div>
    </div>
  </the-resize-div>
  <div
    v-if="Object.keys(debug).length && !isOpen"
    :class="$style['shortLabel']"
    @click="isOpen = !isOpen"
  >
    Debug bar
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { ElButton, ElTabs } from "element-plus";
import { DebugData, TimeData } from "@/components/debugbar/types";
import { ArrowDown, Close } from "@element-plus/icons-vue";
import TheResizeDiv from "@/components/the/TheResize.vue";
import Time from "@/components/debugbar/sections/Time.vue";
import Response from "@/components/debugbar/sections/Response.vue";
import Messages from "@/components/debugbar/sections/Messages.vue";
import Models from "@/components/debugbar/sections/Models.vue";
import Queries from "@/components/debugbar/sections/Queries.vue";
import Default from "@/components/debugbar/sections/Default.vue";

const isOpen = ref<boolean>(false);
const debug = ref<{ [key: string]: DebugData }>({});
const debugHeaders = ref<string[]>([]);
const services = ref<{ [key: string]: string[] }>({});
const selectedService = ref<string>("");

window.addEventListener("set-debug", function (event: any) {
  const url = event.detail.userOptions.url;
  if (!event.detail.userOptions.data._debugbar || !url) {
    return;
  }

  const data: DebugData = event.detail.userOptions.data._debugbar;

  data.response = {
    data: event.detail.userOptions.data.data,
    status: event.detail.userOptions.status,
  };

  const currentServiceItem = ref<string>("");
  currentServiceItem.value = url.split("?")[0].split("/").slice(-3).join("/");

  const currentService = url.match(/service\/([a-zA-Z0-9]+)/)?.[1];

  currentServiceItem.value += ` ${data.__meta.method}`;
  selectedService.value = currentServiceItem.value;

  if (!services.value[currentService]) {
    services.value[currentService] = [];
  }
  if (!services.value[currentService].includes(currentServiceItem.value)) {
    services.value[currentService].push(currentServiceItem.value);
  }

  debug.value[currentServiceItem.value] = data;
  debugHeaders.value = Object.keys(debug.value[currentServiceItem.value] || {});

  enrichTime(debug.value[currentServiceItem.value]?.time);
});

const enrichTime = (time?: TimeData) => {
  if (!time) {
    return;
  }

  let offset = 0;
  time.measures.forEach((measure) => {
    measure.percent = Number((100 * measure.duration) / time.duration).toFixed(
      2
    );
    measure.offset = offset;
    offset += +measure.percent;
  });
};

const selectService = (item: string) => {
  selectedService.value = item;
};

const close = () => {
  isOpen.value = !isOpen.value;
};
</script>

<style lang="scss" module>
.draggable {
  border-top: 3px solid var(--debugbar-color-accent);
}

.drawer {
  background-color: var(--el-color-white);
  bottom: 0;
  cursor: default;
  display: block;
  height: 100%;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: var(--el-index-popper);

  :global {
    .el-tabs--border-card {
      height: 100%;

      .el-tabs__header {
        height: var(--debugbar-line-height);

        .el-tabs__item {
          &.is-active {
            color: var(--debugbar-color-accent);
          }

          &:hover {
            color: var(--debugbar-color-accent);
          }
        }
      }

      .el-tabs__content {
        height: calc(100% - var(--debugbar-line-height));
        overflow-y: auto;
      }
    }

    .el-dropdown-menu {
      padding: 0;

      &__item--divided {
        margin-top: 0;
      }
    }

    .el-tabs__nav-wrap.is-scrollable {
      width: calc(100% - 370px);
    }
  }

  .meta {
    align-items: center;
    color: var(--el-text-color-regular);
    display: flex;
    position: absolute;
    top: 0;
    right: 35px;

    div {
      padding: 0 5px;
    }
  }

  .close {
    padding: 12px;
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.stripped {
  background-color: var(--debugbar-color-background-dark);
}

.record {
  color: var(--el-text-color-regular);
  font-size: 14px;
  line-height: 22px;
  padding: 5px;

  .label {
    display: inline-block;
    width: 200px;
  }

  .value {
    display: inline-block;
    width: calc(100% - 300px);
  }
}

.shortLabel {
  background-color: var(--debugbar-color-accent);
  border-radius: 10px 10px 0 0;
  bottom: 0;
  color: var(--el-color-white);
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 10px;
  text-align: center;
  vertical-align: middle;
  width: 100px;
  z-index: var(--el-index-popper);

  &:hover {
    cursor: pointer;
  }
}

.highlighted {
  color: var(--debugbar-color-accent);
}

.round {
  background-color: var(--debugbar-color-accent);
  border-radius: 10px;
  color: var(--el-color-white);
  display: inline-block;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  margin: 5px;
  text-align: center;
  width: 20px;
}
</style>
