<template>
  <el-checkbox-button
    v-if="declaration.element === 'checkbox-button'"
    :ref="declaration.id"
    v-model="model"
    :="declaration.attrs"
  />
  <el-checkbox
    v-else-if="declaration.element === 'checkbox'"
    :ref="declaration.id"
    v-model="model"
    :="declaration.attrs"
  >
  </el-checkbox>
  <el-checkbox-group
    v-else-if="declaration.element === 'checkbox-group'"
    :ref="declaration.id"
    v-model="model"
    :="declaration.attrs"
  >
    <component
      :is="'el-' + checkbox.element"
      v-for="(checkbox, key) in declaration?.groupList"
      :key="key"
      :="{
        ...checkbox?.attrs,
        ...checkbox?.events,
      }"
    >
    </component>
  </el-checkbox-group>
  <span v-else />
</template>
<script lang="ts" setup>
import { computed, toRefs } from "vue";
import { BaseComponentProps } from "@/components/types";

const props = defineProps<BaseComponentProps>();

const { declaration, formModel } = toRefs(props);
const model = computed({
  get: () => (formModel.value ? formModel.value[declaration.value.model] : {}),
  set: (val) => {
    if (formModel.value) formModel.value[declaration.value.model] = val;
  },
});
</script>
