import { fetchRequest } from "@/api/core";
import { SERVICE_POINT_NAME } from "@/api/points/tasks-api/service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/tasks`, {
  list: {},
  item: {},
});

export default service;
export const getTasks = service.list.bind(service);
export const getTasksById = service.item.bind(service);
export const createTasks = service.create.bind(service);
export const updateTasks = service.update.bind(service);
export const deleteTasks = service.delete.bind(service);

export const getTasksUser = async (
  queryParams: Record<string, any>
): Promise<any> => {
  try {
    return await fetchRequest({
      point: `${SERVICE_POINT_NAME}/tasks/user/?${new URLSearchParams(
        queryParams
      )}`,
      fetchMethod: "GET",
    });
  } catch (e) {
    return e;
  }
};

export const markRead = async (payload: Record<string, any>): Promise<any> => {
  try {
    return await fetchRequest({
      point: `${SERVICE_POINT_NAME}/tasks/mark_read/`,
      payload,
      fetchMethod: "POST",
      showNotification: false,
    });
  } catch (e) {
    return e;
  }
};
