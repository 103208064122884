<template>
  <div
    v-if="editor"
    :class="[
      {
        'el-tiptap-editor': true,
        'el-tiptap-editor--fullscreen': isFullscreen,
        'el-tiptap-editor--with-footer': showFooter,
      },
      editorClass,
    ]"
    :style="editorStyle"
  >
    <menu-bubble :class="editorBubbleMenuClass" :editor="editor" />

    <menu-bar :class="editorMenubarClass" :editor="editor" />

    <div
      v-if="isCodeViewMode"
      :class="{
        'el-tiptap-editor__codemirror': true,
        'border-bottom-radius': isCodeViewMode,
      }"
    >
      <textarea ref="cmTextAreaRef"></textarea>
    </div>

    <editor-content
      v-show="!isCodeViewMode"
      :class="[
        {
          'el-tiptap-editor__content': true,
        },
        editorContentClass,
      ]"
      :editor="editor"
    />

    <div
      v-if="showFooter"
      :class="[
        {
          'el-tiptap-editor__footer': true,
        },
        editorFooterClass,
      ]"
    >
      <span class="el-tiptap-editor__characters">
        {{ t("editor.characters") }}: {{ characters }}
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, provide, ref, toRaw, unref, watchEffect } from "vue";
import { Editor } from "@tiptap/core";
import { EditorContent, useEditor } from "@tiptap/vue-3";
import TiptapPlaceholder from "@tiptap/extension-placeholder";
import CharacterCount from "@tiptap/extension-character-count";
import { Trans } from "@/plugins/tiptap-editor/i18n";
import {
  useCharacterCount,
  useCodeView,
  useEditorStyle,
} from "@/plugins/tiptap-editor/hooks";

import MenuBar from "./MenuBar/index.vue";
import MenuBubble from "./MenuBubble/index.vue";
import { TipTapExtensionId } from "@/plugins/tiptap-editor/constants";

interface Props {
  content: string;
  extensions: Array<any>;
  placeholder: string;
  lang: string;
  width?: string | number;
  height?: string | number;
  output: string;
  spellcheck?: boolean;
  readonly?: boolean;
  tooltip?: boolean;
  enableCharCount?: boolean;
  charCountMax?: number;
  locale?: any;
  editorClass?: string | Array<any> | Record<string, any>;
  editorContentClass?: string | Array<any> | Record<string, any>;
  editorMenubarClass?: string | Array<any> | Record<string, any>;
  editorBubbleMenuClass?: string | Array<any> | Record<string, any>;
  editorFooterClass?: string | Array<any> | Record<string, any>;
}

const props = withDefaults(defineProps<Props>(), {
  content: "",
  placeholder: "",
  lang: "en",
  spellcheck: false,
  readonly: false,
  tooltip: true,
  enableCharCount: true,
  output: "html",
});

const emit = defineEmits([
  "update:content",
  "onCreate",
  "onTransaction",
  "onFocus",
  "onBlur",
  "onUpdate",
  "onDestroy",
]);

const extensions = props.extensions
  .concat([
    TiptapPlaceholder.configure({
      emptyEditorClass: "el-tiptap-editor--empty",
      emptyNodeClass: "el-tiptap-editor__placeholder",
      showOnlyCurrent: false,
      placeholder: () => {
        return props.placeholder;
      },
    }),
    props.enableCharCount
      ? CharacterCount.configure({
          limit: props.charCountMax,
        })
      : null,
  ])
  .filter(Boolean);

const onUpdate = ({ editor }: { editor: Editor }) => {
  let output;
  if (props.output === "html") {
    output = editor.getHTML();
  } else {
    output = editor.getJSON();
  }
  emit("update:content", output);

  emit("onUpdate", output, editor);
};

const editor = useEditor({
  content: props.content,
  extensions,
  editable: !props.readonly,
  onCreate: (options) => {
    emit("onCreate", options);
  },
  onTransaction: (options) => {
    emit("onTransaction", options);
  },
  onFocus: (options) => {
    emit("onFocus", options);
  },
  onBlur: (options) => {
    emit("onBlur", options);
  },
  onDestroy: (options) => {
    emit("onDestroy", options);
  },
  onUpdate,
});

watchEffect(() => {
  editor.value?.setOptions({
    editorProps: {
      attributes: {
        spellcheck: String(props.spellcheck),
      },
    },
  });
});

const i18nHandler = Trans.buildI18nHandler(toRaw(props.locale));
const t = (...args: any): string => {
  return i18nHandler.apply(Trans, args);
};

const isFullscreen = ref(false);
const toggleFullscreen = (val: boolean) => {
  isFullscreen.value = val;
};
provide("isFullscreen", isFullscreen);
provide("toggleFullscreen", toggleFullscreen);

provide("enableTooltip", props.tooltip);

const { isCodeViewMode, cmTextAreaRef } = useCodeView(editor);

provide("isCodeViewMode", isCodeViewMode);
provide("extensionsAccessibleInCodeViewMode", [TipTapExtensionId.codeView]);

const { characters } = useCharacterCount(editor);

const showFooter = computed((): boolean => {
  return props.enableCharCount && !unref(isCodeViewMode);
});

const editorStyle = useEditorStyle({
  width: props.width,
  height: props.height,
});

provide("t", t);
</script>

<style lang="scss">
@import "../styles/editor.scss";
@import "../styles/command-button.scss";
</style>
