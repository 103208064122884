<template>
  <div>
    <command-button
      :command="
        () => toggleIsCodeViewMode && toggleIsCodeViewMode(!isCodeViewMode)
      "
      :enable-tooltip="enableTooltip"
      :extension-id="TipTapExtensionId.codeView"
      :tooltip="t('editor.extensions.CodeView.tooltip')"
      icon="file-code"
    />
  </div>
</template>

<script lang="ts" setup>
import { inject } from "vue";
import CommandButton from "./CommandButton.vue";
import { TipTapExtensionId } from "@/plugins/tiptap-editor/constants";

const t: any = inject("t");
const enableTooltip = inject("enableTooltip", true);
const isCodeViewMode = inject("isCodeViewMode", false);
const toggleIsCodeViewMode = inject<(val: boolean) => void>(
  "toggleIsCodeViewMode"
);
</script>
