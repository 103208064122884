import {
  DefaultJsonFormContext,
  FormItemObjDeclaration,
  ImageLoaderDeclaration,
  ImageLoaderService,
  SelectOptionItemDeclaration,
} from "@/plugins/form-generator-json-v2/types";
import { UploadType } from "@/components/base/upload/constants";
import { ColumnType } from "@/model/working-sections/constants";
import { ElementPlusComponent } from "@/components/constants";

export const VALUE_TYPES = {
  NUMERIC: "numeric",
  TEXT: "text",
  LOGICAL: "logical",
  TEXT_LIST: "text_list",
  TEXT_LIST_MULTIPLE: "text_list_multiple",
  NUMERIC_LIST: "numeric_list",
  NUMERIC_LIST_MULTIPLE: "numeric_list_multiple",
  RANGE: "numeric_range",
  DATE: "date",
};

export const LOGICAL_KEYS = {
  TRUE: "true",
  FALSE: "false",
  UNDEFINED: null,
};

export const LOGICAL_LIST: SelectOptionItemDeclaration[] = [
  { value: LOGICAL_KEYS.TRUE, label: "Да" },
  { value: LOGICAL_KEYS.FALSE, label: "Нет" },
  { value: LOGICAL_KEYS.UNDEFINED, label: "Неизвестно" },
];

export const MEASUREMENT_LIST = [
  { value: "A", label: "А", attrs: { unit: "A" } },
  { value: "Ah", label: "Ампер*час", attrs: { unit: "А*ч" } },
  { value: "W", label: "Вт", attrs: { unit: "Вт" } },
  { value: "Wb", label: "Вебер", attrs: { unit: "Вб" } },
  { value: "V", label: "Вольт", attrs: { unit: "В" } },
  { value: "ha", label: "гектар", attrs: { unit: "га" } },
  { value: "H", label: "Генри", attrs: { unit: "Гн" } },
  { value: "Hz", label: "Гц", attrs: { unit: "Гц" } },
  { value: "GB", label: "Гб", attrs: { unit: "Гб" } },
  { value: "mol", label: "моль", attrs: { unit: "моль" } },
  { value: "Y", label: "год", attrs: { unit: "год" } },
  { value: "F", label: "°F", attrs: { unit: "°F" } },
  { value: "C", label: "°С", attrs: { unit: "°С" } },
  { value: "g", label: "грамм", attrs: { unit: "г" } },
  { value: "dB", label: "децибел", attrs: { unit: "дБ" } },
  { value: "dg", label: "дециграмм", attrs: { unit: "дг" } },
  { value: "dl", label: "децилитр", attrs: { unit: "дл" } },
  { value: "J", label: "Дж", attrs: { unit: "Дж" } },
  { value: "in", label: "дюйм", attrs: { unit: "дюйм" } },
  { value: "unit", label: "изделие", attrs: { unit: "изд." } },
  { value: "fps", label: "к/с", attrs: { unit: "к/с" } },
  { value: "km2", label: "кв. км", attrs: { unit: "кв. км" } },
  { value: "m2", label: "кв. м", attrs: { unit: "кв. м" } },
  { value: "mm2", label: "кв. мм", attrs: { unit: "кв. мм" } },
  { value: "cm2", label: "кв. см", attrs: { unit: "кв. см" } },
  { value: "dm2", label: "кв. дм", attrs: { unit: "кв. дм" } },
  { value: "KB", label: "Кб", attrs: { unit: "Кб" } },
  { value: "kg", label: "кг", attrs: { unit: "кг" } },
  { value: "kl", label: "килолитр", attrs: { unit: "кл" } },
  { value: "kph", label: "км/ч", attrs: { unit: "км/ч" } },
  { value: "dm3", label: "куб. дм", attrs: { unit: "куб. дм" } },
  { value: "m3", label: "куб. м", attrs: { unit: "куб. м" } },
  { value: "mm3", label: "куб. мм", attrs: { unit: "куб. мм" } },
  { value: "cm3", label: "куб. см", attrs: { unit: "куб. см" } },
  { value: "sheet", label: "лист", attrs: { unit: "лист" } },
  { value: "l", label: "л", attrs: { unit: "л" } },
  { value: "l/m", label: "л/мин", attrs: { unit: "л/мин" } },
  { value: "lx", label: "люкс", attrs: { unit: "лк" } },
  { value: "lm", label: "люмен", attrs: { unit: "лм" } },
  { value: "cd/m2", label: "кд/кв. м", attrs: { unit: "кд/кв. м" } },
  { value: "bit", label: "бит", attrs: { unit: "бит" } },
  { value: "byte", label: "байт", attrs: { unit: "байт" } },
  { value: "MB", label: "Мб", attrs: { unit: "Мб" } },
  { value: "MHz", label: "МГц", attrs: { unit: "МГц" } },
  { value: "Mpx", label: "Мпикс", attrs: { unit: "Мпикс" } },
  { value: "m", label: "мес.", attrs: { unit: "мес." } },
  { value: "M", label: "м", attrs: { unit: "м" } },
  { value: "mps", label: "м/с", attrs: { unit: "м/с" } },
  { value: "mA", label: "мА", attrs: { unit: "мА" } },
  { value: "mg", label: "мг", attrs: { unit: "мг" } },
  { value: "ml", label: "мл", attrs: { unit: "мл" } },
  { value: "mm", label: "мм", attrs: { unit: "мм" } },
  { value: "ms", label: "мс", attrs: { unit: "мс" } },
  { value: "min", label: "мин", attrs: { unit: "мин" } },
  { value: "set", label: "набор", attrs: { unit: "наб." } },
  { value: "week", label: "неделя", attrs: { unit: "нед." } },
  { value: "N", label: "Ньютон", attrs: { unit: "Н" } },
  { value: "ohm", label: "Ом", attrs: { unit: "Ом" } },
  { value: "Pa", label: "Па", attrs: { unit: "Па" } },
  { value: "px", label: "пикс.", attrs: { unit: "пикс." } },
  { value: "percentage", label: "%", attrs: { unit: "%" } },
  { value: "roll", label: "рулон", attrs: { unit: "рул." } },
  { value: "cm", label: "см", attrs: { unit: "см" } },
  { value: "s", label: "сек", attrs: { unit: "сек" } },
  { value: "S", label: "Сименс", attrs: { unit: "См" } },
  { value: "d", label: "сутки", attrs: { unit: "сут." } },
  { value: "T", label: "Тл", attrs: { unit: "Тл" } },
  { value: "t", label: "тонн", attrs: { unit: "т" } },
  { value: "thousand", label: "тыс.", attrs: { unit: "тыс." } },
  { value: "degree", label: "градусов", attrs: { unit: "град." } },
  { value: "pack", label: "упак.", attrs: { unit: "упак." } },
  { value: "K", label: "Кельвин", attrs: { unit: "К" } },
  { value: "dt", label: "центнер", attrs: { unit: "ц" } },
  { value: "pcs", label: "штук", attrs: { unit: "шт." } },
  { value: "element", label: "элем.", attrs: { unit: "элем." } },
];

export const TYPE_LIST = [
  { value: VALUE_TYPES.NUMERIC, label: "Число" },
  { value: VALUE_TYPES.TEXT, label: "Строка" },
  { value: VALUE_TYPES.LOGICAL, label: "Логический" },
  { value: VALUE_TYPES.TEXT_LIST, label: "Список строк" },
  { value: VALUE_TYPES.NUMERIC_LIST, label: "Список чисел" },
  {
    value: VALUE_TYPES.NUMERIC_LIST_MULTIPLE,
    label: "Множественный выбор чисел",
  },
  { value: VALUE_TYPES.TEXT_LIST_MULTIPLE, label: "Множественный выбор строк" },
  { value: VALUE_TYPES.RANGE, label: "Числовой диапазон" },
  { value: VALUE_TYPES.DATE, label: "Дата" },
];

export const getMeasurementLabel = (value: string): string | undefined =>
  MEASUREMENT_LIST.find((v) => v.value === value)?.label;

export const getMeasurementUnit = (value: string): string | undefined =>
  MEASUREMENT_LIST.find((v) => v.value === value)?.attrs.unit;

export const logo_form: FormItemObjDeclaration = {
  name: "logo_id",
  id: "logo_id",
  model: UploadType.logoId,
  include: "logo",
  element: "upload-image",
  label: "Иконка",
};

export const logo_element: ImageLoaderDeclaration = {
  fileFormats: ["image/svg+xml"],
  imageTip: "формат: svg",
};

export const logo_service: ImageLoaderService = {
  service: "pim",
  objectType: "properties",
  submitMapDataCallback: (value: any) => value?.id || null,
};

export const measurement_col = {
  minWidth: 60,
  label: "Единица измерения",
  type: ColumnType.editable,
  component: ElementPlusComponent.select,
  options: MEASUREMENT_LIST,
};

export const default_col_input = {
  minWidth: 60,
  label: "Значение по умолчанию",
  type: ColumnType.editable,
  attrs: {
    type: ColumnType.text,
  },
};

export const default_col_select = {
  minWidth: 60,
  label: "Значение по умолчанию",
  type: ColumnType.editable,
  component: ElementPlusComponent.select,
  options: [],
};

export const default_col_range = {
  minWidth: 60,
  label: "Значение по умолчанию",
  type: ColumnType.editable,
  component: ElementPlusComponent.inputNumber,
  attrs: {
    controls: false,
  },
};

export const default_col_logical = {
  minWidth: 60,
  label: "Значение по умолчанию",
  type: ColumnType.editable,
  component: ElementPlusComponent.select,
  options: LOGICAL_LIST,
};

export const tableChangeEvent = function (this: DefaultJsonFormContext): void {
  (this.formModel.classifiers as any[]).forEach((item: Record<string, any>) => {
    item.default = item.is_required ? undefined : item.default;
  });
};
