export const enum FooterModalType {
  create = "create",
  edit = "edit",
  filter = "filter",
  createOnly = "create-only",
}

export const enum FooterModalActionType {
  restore = "restore",
  delete = "delete",
  activation = "activation",
  deactivation = "deactivation",
}
