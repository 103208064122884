import { CommandButtonArgs } from "@/plugins/tiptap-editor";
import TiptapBlockquote from "@tiptap/extension-blockquote";
import CommandButton from "@/plugins/tiptap-editor/components/MenuCommands/CommandButton.vue";

const Blockquote = TiptapBlockquote.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      button({ editor, t, isCodeViewMode }: CommandButtonArgs) {
        return {
          component: CommandButton,
          componentProps: {
            command: () => {
              editor.commands.toggleBlockquote();
            },
            isActive: editor.isActive("blockquote"),
            icon: "quote-right",
            tooltip: t("editor.extensions.Blockquote.tooltip"),
            isCodeViewMode,
          },
        };
      },
    };
  },
});

export default Blockquote;
