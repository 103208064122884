export const enum Command {
  EDIT,
  COPY,
  COPYWITHDEPENDENCIES,
  TOGGLE_ACTIVATION,
  ACTIVATE,
  DEACTIVATE,
  REMOVE,
  RESTORE,
  IMPERSONATE_USER,
  EXPORT,
  CREATE,
  OPEN_DELETE_MODAL,
}
