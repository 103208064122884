import { fetchRequest } from "@/api/core";
import { Service } from "@/api/core/service";
import { SERVICE_POINT_NAME } from "../service-config";

const POINT_NAME = `${SERVICE_POINT_NAME}/user_options`;
const service = new Service(`${POINT_NAME}`);
export default service;

export const getUserOptions = service.list.bind(service);
export const getUserOptionsById = service.item.bind(service);
export const createUserOptions = service.create.bind(service);
export const deleteUserOptions = service.delete.bind(service);
export const updateUserOptions = async (
  payload: Record<string, any>,
  isNoMessage: boolean
): Promise<any> => {
  try {
    return await fetchRequest({
      point: `${POINT_NAME}`,
      payload,
      fetchMethod: "PUT",
      isNoMessage,
    });
  } catch (e) {
    return e;
  }
};

export const getUserOptionsByUser = async (): Promise<any> => {
  try {
    return await fetchRequest({
      point: `${POINT_NAME}/get_by_user`,
      fetchMethod: "GET",
    });
  } catch (e) {
    return e;
  }
};
