<template>
  <el-dialog
    :before-close="beforeClose"
    :class="updatedRowId ? 'el-dialog--edit' : 'el-dialog--create'"
    :close-on-click-modal="false"
    :model-value="isOpenModal"
    destroy-on-close
    @closed="dialogCloseHandler"
  >
    <template v-if="isOpenModal" #header>
      {{ dialogTitle }}
    </template>
    <json-form
      ref="formInstance"
      :declaration="updatedRowId ? mergedEditForm : createForm"
      :is-code-editable="isMainEntity ? hasUpdateSpecFieldsAccess : undefined"
      :is-copy="isCopy"
      :preset-values="formPresetValues"
      :readonly="
        !isWizardInner && hasShowAccess && !(hasUpdateAccess || hasStoreAccess)
      "
      :show-service-fields="
        !outerProps.defaultSectionDeclaration.settings.hideServiceFields
      "
      class="data-form"
      @delete="massDeleteRows"
      @hideModal="hideModal"
      @submit="dataHandler"
    />
    <template v-if="!hideActions" #footer>
      <modal-footer
        v-if="isOpenModal"
        :actions="actions"
        :default-text="updatedRowId ? 'Сохранить' : 'Создать'"
        :form-model="formModel || {}"
        :is-service-edit-page="!isWizardInner ? !!isServiceEditPage : false"
        :mode="updatedRowId ? FooterModalType.edit : FooterModalType.create"
        @delete="massDeleteRows([formModel?.id])"
        @event="onEvent"
        @hideModal="hideModal"
        @submit="(...args) => dataHandler(formInstance, ...args)"
        @toggleActivation="toggle"
        @fill-system-objects="fillSystemObjectsHandler(formInstance)"
      />
    </template>
  </el-dialog>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, reactive, ref, toRaw, watch } from "vue";
import { RouteAccess } from "@/router/types";
import useAccessRights from "@/composables/hooks/useAccessRights";
import { useRouter } from "vue-router";
import {
  DefaultJsonFormContext,
  GeneratorDeclaration,
  PresetValues,
} from "@/plugins/form-generator-json-v2/types";
import { fillSystemObjects } from "@/api/points/auth-api/system_objects";
import ModalFooter from "@/components/footer/ModalFooter.vue";

import JsonForm from "@/plugins/form-generator-json-v2/index.vue";
import { ElForm } from "element-plus";
import useSectionModal from "@/composables/hooks/modals/useSectionModal";
import { QUERY_PARAM } from "@/components/config";
import { Breadcrumb, DataList } from "@/components/types";
import useMassEditing from "@/components/working-sections/default/composables/useMassEditing";
import useSectionDeclaration from "@/composables/hooks/useSettingsDeclaration";
import useWizardBreadcrumbs from "@/composables/hooks/useWizardBreadcrumbs";
import { payloadConstructor } from "@/api/core";
import { Command } from "@/components/working-sections/default/components/actions/constants";
import { useWheels } from "@/composables/hooks/useWheels";
import { Service } from "@/api/core/service";
import { ServiceName } from "@/constants";
import {
  FooterModalActionType,
  FooterModalType,
} from "@/components/footer/constants";

const props = defineProps<{
  outerProps: any;
  state: any;
  handlers: Record<string, any>;
  dataList: DataList;
  isCopy: boolean;
  isWizard: boolean;
  editForm: Record<string, any>;
  mergedEditForm: GeneratorDeclaration;
  presetValues: PresetValues;
  updatedRowId: number;
  hideActions: boolean;
}>();
const router = useRouter();
const formInstance = ref<InstanceType<typeof ElForm>>();
const formModel = ref<Record<string, unknown>>();
const isMainEntity: boolean = [
  "brands",
  "models",
  "generations",
  "modifications",
  "wizard-brands",
  "wizard-models",
  "wizard-generations",
  "wizard-modifications",
].includes(router.currentRoute.value.name?.toString() ?? "");

const isWizardInner = computed(
  () =>
    props.isWizard ||
    router.currentRoute.value.path.includes(ServiceName.wizard)
);
const createForm = reactive(
  props.outerProps.defaultSectionDeclaration.forms.create ||
    ({} as GeneratorDeclaration)
);
const service = computed(
  (): Service =>
    props.outerProps.defaultSectionDeclaration.service ||
    props.outerProps.defaultSectionDeclaration.methods ||
    (router.currentRoute.value?.meta?.access as RouteAccess).service
);

const fetchAfterForm = ref<boolean>(false);
const {
  hasUpdateAccess,
  hasShowAccess,
  hasStoreAccess,
  hasUpdateSpecFieldsAccess,
} = useAccessRights(router);
const sectionDeclaration = useSectionDeclaration(
  props.outerProps.defaultSectionDeclaration
);
const { updateOrCreateWheels } = useWheels();
const { breadcrumbs } = useWizardBreadcrumbs(props.dataList);
const { massDeleteRows } = useMassEditing(
  sectionDeclaration,
  props.handlers.getData,
  service.value,
  [],
  [],
  false
);
const { isOpen: isOpenModal, hide: hideModal } = useSectionModal(QUERY_PARAM);
const emit = defineEmits(["resetData", "beforeClose"]);
const actions = !props.outerProps.defaultSectionDeclaration.settings.hideDelete
  ? [FooterModalActionType.delete]
  : [];

const formPresetValues = computed(() => {
  return {
    ...props.presetValues.edit,
    ...props.presetValues.service,
    log: { ...props.presetValues.log },
  };
});

const dialogTitle: ComputedRef<string> = computed(() => {
  if (props.hideActions) return "Просмотр";

  const { updateModalTitle, createModalTitle } =
    props.outerProps.defaultSectionDeclaration.text;
  let title = props.updatedRowId ? updateModalTitle : createModalTitle;

  if (breadcrumbs.value.length > 1 && isWizardInner.value) {
    const extra = toRaw(breadcrumbs.value)
      .map((item: Breadcrumb) => item.meta.title)
      .slice(1, -1);
    title += " в " + extra.join(" / ");
  }

  return title;
});

const isServiceEditPage = computed(
  () =>
    router.currentRoute.value.name === "system-services" &&
    router.currentRoute.value.params.actionModal &&
    router.currentRoute.value.params.actionModal !== "create"
);

const onEvent = async (event: string, data: Record<string, any>) => {
  if (!formInstance.value) return;

  const form: DefaultJsonFormContext = formInstance.value;

  form.setLoading(true);

  if (event === "restore") {
    try {
      await service.value?.restore(Number(data.id || form.formModel.id));
    } catch (e) {
      console.error(e);
    }
  }

  form.setLoading(false);
  hideModal();
  props.handlers.getData();
};

const beforeClose = () => {
  emit("beforeClose");
};

const fillSystemObjectsHandler = async (form?: DefaultJsonFormContext) => {
  if (!form) {
    return;
  }

  form.setLoading(true);
  try {
    await fillSystemObjects(form.formModel.code as string);
  } catch (e) {
    console.error(e);
  } finally {
    form.setLoading(false);
  }
};

const toggle = () => {
  props.handlers.toggleActivation(
    formModel?.value?.id,
    !(formModel?.value?.active !== undefined
      ? formModel?.value.active
      : formModel?.value?.is_active),
    props.dataList.value.find((item) => item.id === formModel?.value?.id)
      ?.attributes || {}
  );
};

const dialogCloseHandler = async () => {
  if (fetchAfterForm.value) {
    await props.handlers.getData();
    fetchAfterForm.value = false;
  }
};

//TODO: @William надо абстрагировать всю логику отправки самой формы от работы раздела.
const dataHandler = (
  form?: DefaultJsonFormContext,
  needModalClose?: boolean,
  needFormClear?: boolean,
  needFormRemount?: boolean
): void => {
  if (!form) {
    return;
  }

  const codeField = {
    ...form.declaration.items.find((item) => item.id === "code"),
  };
  codeField &&
    props.updatedRowId &&
    (codeField.isPayload = hasUpdateSpecFieldsAccess);

  form.formRef.validate(async (isValid) => {
    if (!isValid || !service.value) {
      return;
    }

    for (const key of Object.keys(form.formModel)) {
      form.setFormItemWrapAttr(key, "error", null);
    }

    form.setLoading(true);

    const payload = payloadConstructor(form);
    let result = null;

    if (props.editForm.form.name === "wheels") {
      result = await updateOrCreateWheels({
        payload,
        updatedRowId: props.updatedRowId,
        service: service.value,
      });
    } else {
      if (props.state.action === Command.EDIT && props.updatedRowId) {
        result = await service.value.update(props.updatedRowId, payload);
      } else if (
        props.state.action === Command.COPYWITHDEPENDENCIES &&
        props.updatedRowId
      ) {
        result = await service.value.copy(props.updatedRowId, payload);
      } else {
        result = await service.value.create(payload);
      }
    }
    if (result.errors && +result.errors.status === 422) {
      for (const key in result.source) {
        form.setFormItemWrapAttr(key, "error", result.source[key].join(", "));
      }
      form.setLoading(false);
      return;
    }

    form.setLoading(false);

    if (needFormClear) {
      form.formRef.resetFields();
    }

    if (needFormRemount) {
      form.invokeMountCallback();
    }

    if (needModalClose) {
      emit("beforeClose");
    }

    if (needFormClear) {
      fetchAfterForm.value = true;

      return;
    }

    await props.handlers.getData();
    fetchAfterForm.value = false;
  });
};

watch(formInstance, (value) => {
  if (props.updatedRowId && value?.formModel) {
    formModel.value = value?.formModel;
  }
});

watch(isOpenModal, (isOpen) => {
  if (!isOpen) {
    emit("resetData");

    if (!formInstance.value) {
      return;
    }

    const form: Pick<
      DefaultJsonFormContext & InstanceType<typeof ElForm>,
      "formModel" | "setFormItemWrapAttr"
    > = formInstance.value;

    isWizardInner.value && (formModel.value = form.formModel);
    for (const key of Object.keys(form.formModel)) {
      form.setFormItemWrapAttr(key, "error", null);
    }
  }
});
</script>
