import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(
  `${SERVICE_POINT_NAME}/corp_solution_and_product_blocks`,
  {
    item: { include: "products,corporateSolutions" },
  }
);

export default service;
export const getCorporateSolutionAndProductBlocks = service.list.bind(service);
export const getCorporateSolutionAndProductBlockById =
  service.item.bind(service);
export const createCorporateSolutionAndProductBlock =
  service.create.bind(service);
export const updateCorporateSolutionAndProductBlock =
  service.update.bind(service);
export const deleteCorporateSolutionAndProductBlock =
  service.delete.bind(service);
