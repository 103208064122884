<template>
  <section :class="$style['the-header']">
    <el-button
      :class="[
        $style['toggler'],
        $style['the-header-icon-bordered'],
        { [$style['toggler--collapse']]: isMenuCollapsed },
      ]"
      :icon="DownloadHorizontal"
      size="large"
      @click="toggleCollapse"
    />
    <the-menu-search />
    <the-tasks v-if="!isMobile" />
    <el-dropdown
      ref="usermenu"
      :hide-on-click="false"
      trigger="click"
      @command="onClickMenu"
    >
      <div>
        <el-tooltip
          :effect="Effect.LIGHT"
          :show-after="200"
          placement="left-start"
        >
          <template #content>
            {{ userProfile.name }}
            <br />
            {{ userProfile.email }}
          </template>
          <template #default>
            <el-avatar
              :class="$style['the-header-icon']"
              :icon="User"
              :src="avatar"
              fit="cover"
              shape="square"
            >
            </el-avatar>
          </template>
        </el-tooltip>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item :command="COMMAND_PROFILE">
            <router-link v-slot="{ navigate, href }" custom to="/user">
              <a :href="href" @click="navigate">Профиль</a>
            </router-link>
          </el-dropdown-item>
          <el-popconfirm
            cancel-button-text="Нет"
            confirm-button-text="Да"
            hide-icon
            title="Вы уверены, что хотите выйти?"
            width="auto"
            @cancel="onCancelExit"
            @confirm="onConfirmExit"
          >
            <template #reference>
              <div>
                <el-dropdown-item :command="COMMAND_EXIT" divided>
                  Выйти
                </el-dropdown-item>
              </div>
            </template>
          </el-popconfirm>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </section>
</template>

<script lang="ts" setup>
import TheMenuSearch from "@/components/the/TheMenuSearch.vue";
import TheTasks from "@/components/the/TheTasks.vue";
import { inject, ref } from "vue";
import { useRouter } from "vue-router";
import { User } from "@element-plus/icons-vue";
import DownloadHorizontal from "@/static/icons/download-horizontal.svg";
import { user } from "@/model/user";
import useCollapseMenu from "@/composables/hooks/useCollapseMenu";
import useAdaptive from "@/composables/hooks/useAdaptive";
import { Effect } from "element-plus";

const usermenu = ref();
const userProfile = user.getProfile();
const avatar = inject<string>("avatar");

const { isMenuCollapsed, toggleCollapse } = useCollapseMenu();
const { isMobile } = useAdaptive();

const COMMAND_PROFILE = "profile";
const COMMAND_EXIT = "exit";
const router = useRouter();

const onConfirmExit = () => router.push(router.resolve("/logout"));

const onCancelExit = () => usermenu.value?.handleClose();

const onClickMenu = (command: string) => {
  if (command === COMMAND_PROFILE) {
    router.push(router.resolve("/user"));
  }
  if (command === COMMAND_EXIT) {
    return;
  }
  usermenu.value?.handleClose();
};
</script>

<style lang="scss" module>
.the-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: {
    top: 16px;
    bottom: 16px;
  }

  :global {
    .el-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
    }

    .el-avatar > img {
      width: inherit;
    }
  }
}

.the-header-icon {
  border: none;
  cursor: pointer;

  :global .el-icon {
    width: auto;
    height: auto;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    :global .el-icon {
      --color: var(--el-color-primary);
    }
  }
}

.the-header-icon-bordered {
  align-items: center;
  border: 1px solid var(--el-border-color);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 12px 20px 12px 20px;
  height: 40px;
  width: 52px;

  :global .el-icon {
    width: auto;
    height: auto;
    color: var(--el-text-color-regular);

    svg {
      width: 14px;
      height: 12px;
    }
  }
}

.toggler {
  transition: transform 0.2s ease-in 0s;
}

.toggler--collapse {
  transform: rotate(180deg);
  transition: transform 0.2s ease-out 0.35s;
}
</style>
