<template>
  <template v-if="declaration">
    <working-sections :default-section-declaration="declaration" />
  </template>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import WorkingSections from "@/components/working-sections/default/index.vue";
import { computed, ref, watch } from "vue";
import { WorkingSectionsDefaultInterface } from "@/model/working-sections/types";
import { defaultSectionSettings } from "@/plugins/form-generator-json-v2/core/settings";
import { dateColumns } from "@/model/working-sections/constants";

const router = useRouter();
const declaration = ref<WorkingSectionsDefaultInterface | null>(null);
const previousPath = ref("");

const path = computed<string>(() => {
  return `/${router.currentRoute.value.path
    .split("/")
    .filter((route) => route && !/[0-9]/.test(route) && route !== "create")
    .join("/")}`;
});

const loadRoute = async () => {
  try {
    if (!router.currentRoute.value.path) return;

    declaration.value = null;
    const module: {
      default: WorkingSectionsDefaultInterface;
    } = await import(
      /* webpackExclude: /\.d.ts$/ */
      `../model/working-sections${path.value}.ts`
    );

    if (module) {
      const { settings: sectionSettings = {}, cols: sectionCols = {} } =
        module.default;
      const settings = { ...defaultSectionSettings, ...sectionSettings };
      const cols = settings.showDateColumns
        ? { ...sectionCols, ...dateColumns(settings.dateColumnsSortingEnabled) }
        : sectionCols;

      declaration.value = {
        ...module.default,
        settings,
        cols,
      };
      previousPath.value = path.value;
    }
  } catch (error) {
    console.warn(error);
  }
};

loadRoute();

watch(router.currentRoute, () => {
  if (path.value !== previousPath.value) {
    loadRoute();
  }
});
</script>
