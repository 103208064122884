<template>
  <section :class="$style['head']">
    <div>
      <div :class="$style['head__top']">
        <h1 :class="$style['head__title']">
          <el-icon
            v-if="isMobile"
            :class="$style['head__back']"
            size="16"
            @click="historyBack"
          >
            <arrow-left />
          </el-icon>
          {{ props.title }}
        </h1>
        <el-tag v-if="tag" :class="$style['head__tag']" type="success"
          >{{ tag }}
        </el-tag>
      </div>
      <the-breadcrumb v-if="!isMobile" :deep="deep" :title="title" />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useRouter } from "vue-router";
import TheBreadcrumb from "@/components/the/TheBreadcrumb.vue";
import useAdaptive from "@/composables/hooks/useAdaptive";
import { ArrowLeft } from "@element-plus/icons-vue";
import { BreadcrumbProps } from "@/components/types";

const { isMobile } = useAdaptive();

const router = useRouter();

interface Props extends BreadcrumbProps {
  tag?: string;
}

const props = defineProps<Props>();

const historyBack = () => router.back();
</script>

<style lang="scss" module>
.head {
  margin: 0 0 14px;

  @media (max-width: 640px) {
    width: 100%;
    padding: 0 16px;
    margin: 0 0 4px;
  }

  & > * {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
    text-align: left;
    margin: 0;
  }

  &__top {
    display: flex;

    @media (max-width: 640px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__back {
    margin: 0 10px 0 0;
  }

  &__tag {
    margin: 4px 0 0 12px;

    @media (max-width: 640px) {
      margin: 4px 0 0 26px;
    }
  }

  :global {
    .el-breadcrumb {
      margin-top: 4px;
    }
  }
}
</style>
