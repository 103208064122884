<template>
  <svg
    fill="currentColor"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.4645 0.125V2.56435L0.125 4.3612V2.88975C0.125 1.36298 1.363 0.125 2.88978 0.125H3.4645Z"
      fill="currentColor"
    />
    <path
      d="M0.125 5.71714L3.4645 3.92054V8.33153L0.125 10.1284V5.71714Z"
      fill="currentColor"
    />
    <path
      d="M3.4645 14.0987V9.68774L0.125 11.4843V13.1103C0.125 14.0026 0.547534 14.796 1.2038 15.3014L3.4645 14.0987Z"
      fill="currentColor"
    />
    <path
      d="M4.66051 1.92082V0.125H7.99834H11.3394V1.92263L7.99834 0.125L4.66051 1.92082Z"
      fill="currentColor"
    />
    <path
      d="M4.66051 7.68786V3.27688L7.99846 1.48106L11.3395 3.2787V7.68942L7.99846 5.89204L4.66051 7.68786Z"
      fill="currentColor"
    />
    <path
      d="M11.3395 14.8128L7.99846 13.0155L4.66051 14.8113V15.875H11.3395V14.8128Z"
      fill="currentColor"
    />
    <path
      d="M7.99846 7.24808L11.3395 9.04545V13.4564L7.99846 11.6588L4.66051 13.4549V9.04389L7.99846 7.24808Z"
      fill="currentColor"
    />
    <path
      d="M15.875 13.1336V11.4858L12.5355 9.68921V14.1002L14.7959 15.3013C15.4465 14.8006 15.8677 14.0166 15.875 13.1336Z"
      fill="currentColor"
    />
    <path
      d="M15.875 2.86612V4.36276L12.5355 2.56617V0.125H13.1105C14.6295 0.125 15.8625 1.35 15.875 2.86612Z"
      fill="currentColor"
    />
    <path
      d="M15.875 5.7196L12.5355 3.92274V8.33372L15.875 10.1303V5.7196Z"
      fill="currentColor"
    />
  </svg>
</template>
