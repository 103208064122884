import { SERVICE_POINT_NAME } from "../service-config";
import { Service } from "@/api/core/service";

const service = new Service(`${SERVICE_POINT_NAME}/analytic_cards`, {
  item: { include: "image" },
});

export default service;
export const getAnalyticCards = service.list.bind(service);
export const getAnalyticCardById = service.item.bind(service);
export const createAnalyticCard = service.create.bind(service);
export const updateAnalyticCard = service.update.bind(service);
export const deleteAnalyticCard = service.delete.bind(service);
